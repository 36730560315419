import React from "react";
import aboutPageLanguages from "./AboutPageLanguage";
import "../style/AboutPage.scss"; // Import the updated SCSS file

const AboutPage = ({ selectedLanguage }) => {
  const content = aboutPageLanguages[selectedLanguage]?.common;

  // Fallback for unsupported languages
  if (!content) {
    return <div className="about-page__error">Language not supported</div>;
  }

  const {
    companyLiveTextName,
    contactEmail,
    contactPhone,
    companyAddress,
    aboutHeading,
    informationText,
    additionalInformationText,
    closingText,
    whoWeAreHeading,
    whatWeDoHeading,
    ourCommitmentHeading,
    contactUsHeading,
  } = content;

  return (
    <div className="about-page">
      {/* Header Section */}
      <header className="about-page__header">
        <h1 className="about-page__title">{aboutHeading}</h1>
        <p className="about-page__subtitle">{companyLiveTextName}</p>
      </header>

      {/* Main Content */}
      <main className="about-page__main">
        <section className="about-page__section">
          <article className="about-page__card">
            <h2 className="about-page__card-title">{whoWeAreHeading}</h2>
            <p className="about-page__card-text">{informationText}</p>
          </article>
          <article className="about-page__card">
            <h2 className="about-page__card-title">{whatWeDoHeading}</h2>
            <p className="about-page__card-text">{additionalInformationText}</p>
          </article>
          <article className="about-page__card">
            <h2 className="about-page__card-title">{ourCommitmentHeading}</h2>
            <p className="about-page__card-text">{closingText}</p>
          </article>
        </section>

        <aside className="about-page__contact">
          <h2 className="about-page__contact-title">{contactUsHeading}</h2>
          <ul className="about-page__contact-list">
            <li>
              <strong>Email:</strong>{" "}
              <a href={`mailto:${contactEmail}`} className="about-page__link">
                {contactEmail}
              </a>
            </li>
            <li>
              <strong>Phone:</strong>{" "}
              <a href={`tel:${contactPhone}`} className="about-page__link">
                {contactPhone}
              </a>
            </li>
            <li>
              <strong>Address:</strong> {companyAddress}
            </li>
          </ul>
        </aside>
      </main>
    </div>
  );
};

export default AboutPage;


















