import React from 'react';
import '../style/LiveIcon.scss';

const LiveIcon = () => {
  return (
    <div className="live-icon">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="48" />
      </svg>
      <span>Live</span>
    </div>
  );
};

export default LiveIcon;

