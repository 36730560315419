
const terms={
    se:{
    terms: {
      titleHeader: "Allmänna Villkor",
      titleText: "Version 1.0 | Senast uppdaterad: 2024-11-30",
      generalHeader: "Allmänt",
      generalText: "Dessa köpvillkor gäller för alla köp som görs genom Hellström Trading i Göteborg AB (Org nr: 556572-0413) via webbplatsen www.live.bakesoft.se. Genom att använda tjänsterna på vår webbplats accepterar du att följa dessa villkor. Observera att vi kan komma att uppdatera dessa villkor och det är ditt ansvar att hålla dig informerad om de senaste ändringarna.\n\nHellström Trading i Göteborg AB är baserat i Göteborg, Sverige, och alla transaktioner och avtal regleras av svensk lag, särskilt E-handelslagen och Distansavtalslagen. För mer information om dessa lagar, besök Konsumentverkets officiella webbplats.\n\nGenom att använda www.live.bakesoft.se bekräftar du att:\n1. Du är minst 18 år gammal eller har förälders/vårdnadshavares samtycke.\n2. Du förstår och accepterar dessa villkor.",
      orderHeader: "Beställning",
      orderText: "För att göra en beställning på www.live.bakesoft.se måste du skapa ett konto. Vid registrering krävs att du köper minst en (1) kredit för att aktivera kontot. Krediter används för att få åtkomst till våra digitala tjänster och är en del av vårt interna betalningssystem.\n\nVid eventuella problem med kontoregistrering eller beställning, vänligen kontakta vår support via applikationen eller e-post.",
      creditsHeader: "Krediter (Access Tokens)",
      creditsText: "- **Definition:** Krediter är digitala access tokens lagrade i våra servrar och används enbart inom www.live.bakesoft.se. Krediter är inte valuta och kan inte växlas till pengar eller andra valutor.\n- **Användning:** Krediter kan endast användas för betalning av tjänster på vår plattform och får inte överlåtas eller säljas vidare.\n- **Villkor:** Krediter är giltiga så länge ditt konto är aktivt. Vid uppsägning eller avstängning av konto förlorar oanvända krediter sin giltighet.",
      pricesHeader: "Priser",
      pricesText: "Alla priser inkluderar mervärdesskatt (moms), baserat på det land du är registrerad i enligt dina kontoinställningar.\n\nInga extra avgifter tillkommer. Om det sker ändringar i priser kommer detta att meddelas i förväg via applikationen.",
      paymentHeader: "Betalning",
      paymentText: "Vi samarbetar med Stripe för att hantera betalningar och erbjuder flera betalningsmetoder beroende på ditt land och dina preferenser. Våra betalningspartners använder den senaste tekniken för att säkerställa säkerheten vid transaktioner.\n\n- **Efter betalning:** När betalningen är genomförd utförs den beställda tjänsten automatiskt inom några minuter.\n- **Kortuppgifter:** Vi lagrar inga betalningsuppgifter som kortnummer eller annan känslig information på våra servrar.\n\nOm betalningen misslyckas eller om det uppstår problem, vänligen kontakta vår support via applikationen.",
      deliveryHeader: "Leverans",
      deliveryText: "Vid betalning av nya tjänster eller konton aktiveras tjänsten vanligtvis inom 1–5 minuter. Om leveransen försenas på grund av tekniska problem kommer vi att meddela dig och förlänga tillgången motsvarande den tid tjänsten varit otillgänglig.",
      cancellationHeader: "Ångerrätt",
      cancellationText: "Eftersom vi säljer digitala tjänster gäller ingen ångerrätt enligt Distansavtalslagen. När en tjänst har betalats och aktiverats anses avtalet vara bindande.",
      warrantyHeader: "Reklamation och Garanti",
      warrantyText: "Inuti applikationen finns en supportpanel där du kan lämna klagomål, ställa frågor eller begära support. Vi strävar efter att besvara alla förfrågningar inom 24 timmar.\n\nOm tekniska problem uppstår som påverkar tjänstens funktionalitet ansvarar vi för att lösa dessa så snart som möjligt. Vid större störningar kompenseras användare med förlängd tillgång.",
      dataHeader: "Sparad Data",
      dataText: "Vi lagrar ingen personlig data eller användargenererad receptdata på våra servrar. Om du vill radera din data kan detta göras via ditt konto genom att klicka på 'Radera min data'. Detta raderar endast lokal data på din enhet.",
      forceMajeureHeader: "Force Majeure",
      forceMajeureText: "Vi tar inget ansvar för fördröjningar eller brister i tjänster som orsakats av omständigheter utanför vår kontroll, såsom:\n- Naturkatastrofer\n- Krig eller konflikter\n- Myndighetsbeslut\n- Större tekniska fel hos våra leverantörer\n\nVid force majeure kommer vi att informera dig så snart som möjligt och återuppta tjänsterna när situationen tillåter det.",
      disputeHeader: "Tvist",
      disputeText: "Vid tvist mellan kunden och Hellström Trading i Göteborg AB ska ärendet i första hand lösas genom förhandling. Om en överenskommelse inte nås ska tvisten avgöras i Göteborgs tingsrätt enligt svensk lag.",
      personalDataHeader: "Personuppgifter",
      personalDataText: "Vi följer dataskyddsförordningen (GDPR) och skyddar alla personuppgifter enligt de högsta säkerhetsstandarderna.\n\n- **Insamling:** Vi samlar endast in data som krävs för att tillhandahålla tjänster, såsom kontoregistrering och fakturering.\n- **Lagring:** Dina uppgifter lagras säkert på våra servrar hos Amazon Web Services (AWS).\n- **Användning:** Personuppgifter används endast för att leverera tjänster och delas aldrig med tredje part.\n\nDu har rätt att begära åtkomst till, rättelse av eller radering av dina uppgifter när som helst."
    }
  },
    en:{

    terms: {
      titleHeader: "Terms and Conditions",
      titleText: "Version 1.0 | Last updated: 2024-11-30",
      generalHeader: "General",
      generalText: "These terms and conditions apply to all purchases made through Hellström Trading i Göteborg AB (Org nr: 556572-0413) via the website www.live.bakesoft.se. By using the services on our website, you agree to comply with these terms. Please note that we may update these terms, and it is your responsibility to stay informed of the latest changes.\n\nHellström Trading i Göteborg AB is based in Gothenburg, Sweden, and all transactions and agreements are governed by Swedish law, specifically the E-Commerce Act and the Distance Contracts Act. For more information about these laws, visit the official website of the Swedish Consumer Agency.\n\nBy using www.live.bakesoft.se, you confirm that:\n1. You are at least 18 years old or have parental/guardian consent.\n2. You understand and accept these terms.",
      orderHeader: "Order",
      orderText: "To place an order on www.live.bakesoft.se, you must create an account. During registration, you are required to purchase at least one (1) credit to activate your account. Credits are used to access our digital services and are part of our internal payment system.\n\nIf you experience any issues with account registration or ordering, please contact our support team via the application or email.",
      creditsHeader: "Credits (Access Tokens)",
      creditsText: "- **Definition:** Credits are digital access tokens stored on our servers and are used solely within www.live.bakesoft.se. Credits are not currency and cannot be exchanged for money or other currencies.\n- **Usage:** Credits can only be used to pay for services on our platform and may not be transferred or resold.\n- **Conditions:** Credits are valid as long as your account is active. If your account is terminated or deactivated, unused credits will become invalid.",
      pricesHeader: "Prices",
      pricesText: "All prices include value-added tax (VAT) based on the country you are registered in, as specified in your account settings.\n\nNo additional fees will apply. If there are changes to prices, this will be communicated in advance through the application.",
      paymentHeader: "Payment",
      paymentText: "We partner with Stripe to process payments and offer several payment methods depending on your country and preferences. Our payment partners use the latest technology to ensure secure transactions.\n\n- **After Payment:** Once the payment is processed, the requested service will be delivered automatically within a few minutes.\n- **Card Information:** We do not store payment details such as card numbers or other sensitive information on our servers.\n\nIf payment fails or issues arise, please contact our support team via the application.",
      deliveryHeader: "Delivery",
      deliveryText: "When paying for new services or accounts, the service is usually activated within 1–5 minutes. If delivery is delayed due to technical issues, we will notify you and extend access equivalent to the time the service was unavailable.",
      cancellationHeader: "Right of Withdrawal",
      cancellationText: "Since we sell digital services, no right of withdrawal applies under the Distance Contracts Act. Once a service has been paid for and activated, the agreement is considered binding.",
      warrantyHeader: "Complaints and Warranty",
      warrantyText: "Within the application, there is a support panel where you can submit complaints, ask questions, or request support. We strive to respond to all inquiries within 24 hours.\n\nIf technical issues arise that affect the functionality of the service, we are responsible for resolving them as soon as possible. For significant disruptions, users will be compensated with extended access.",
      dataHeader: "Stored Data",
      dataText: "We do not store any personal data or user-generated recipe data on our servers. If you wish to delete your data, this can be done via your account by clicking 'Delete My Data.' This will only delete local data stored on your device.",
      forceMajeureHeader: "Force Majeure",
      forceMajeureText: "We are not responsible for delays or deficiencies in services caused by circumstances beyond our control, such as:\n- Natural disasters\n- War or conflict\n- Government decisions\n- Major technical failures by our suppliers\n\nIn the event of force majeure, we will notify you as soon as possible and resume services when the situation permits.",
      disputeHeader: "Disputes",
      disputeText: "In the event of a dispute between the customer and Hellström Trading i Göteborg AB, the matter shall first be resolved through negotiation. If an agreement cannot be reached, the dispute shall be resolved in the Gothenburg District Court under Swedish law.",
      personalDataHeader: "Personal Data",
      personalDataText: "We comply with the General Data Protection Regulation (GDPR) and protect all personal data according to the highest security standards.\n\n- **Collection:** We only collect data necessary to provide services, such as account registration and billing.\n- **Storage:** Your data is securely stored on our servers with Amazon Web Services (AWS).\n- **Usage:** Personal data is used solely to deliver services and is never shared with third parties.\n\nYou have the right to request access to, correction of, or deletion of your data at any time."
    }
  },
  fr:{
    terms: {
      titleHeader: "Conditions Générales",
      titleText: "Version 1.0 | Dernière mise à jour : 2024-11-30",
      generalHeader: "Généralités",
      generalText: "Ces conditions générales s'appliquent à tous les achats effectués via Hellström Trading i Göteborg AB (Org nr : 556572-0413) sur le site www.live.bakesoft.se. En utilisant les services de notre site, vous acceptez de respecter ces conditions. Veuillez noter que nous pouvons mettre à jour ces conditions, et il est de votre responsabilité de rester informé des dernières modifications.\n\nHellström Trading i Göteborg AB est basé à Göteborg, en Suède, et toutes les transactions et accords sont régis par le droit suédois, notamment la loi sur le commerce électronique et la loi sur les contrats à distance. Pour plus d'informations sur ces lois, veuillez visiter le site officiel de l'Agence suédoise de la consommation.\n\nEn utilisant www.live.bakesoft.se, vous confirmez que :\n1. Vous avez au moins 18 ans ou avez le consentement d'un parent/tuteur.\n2. Vous comprenez et acceptez ces conditions.",
      orderHeader: "Commande",
      orderText: "Pour passer une commande sur www.live.bakesoft.se, vous devez créer un compte. Lors de l'inscription, vous devez acheter au moins un (1) crédit pour activer votre compte. Les crédits sont utilisés pour accéder à nos services numériques et font partie de notre système de paiement interne.\n\nEn cas de problème avec l'inscription ou la commande, veuillez contacter notre service support via l'application ou par e-mail.",
      creditsHeader: "Crédits (Jetons d'Accès)",
      creditsText: "- **Définition :** Les crédits sont des jetons d'accès numériques stockés sur nos serveurs et utilisés exclusivement sur www.live.bakesoft.se. Les crédits ne sont pas une monnaie et ne peuvent pas être échangés contre de l'argent ou d'autres devises.\n- **Utilisation :** Les crédits peuvent uniquement être utilisés pour payer des services sur notre plateforme et ne peuvent pas être transférés ou revendus.\n- **Conditions :** Les crédits sont valables tant que votre compte est actif. Si votre compte est résilié ou désactivé, les crédits non utilisés deviendront invalides.",
      pricesHeader: "Prix",
      pricesText: "Tous les prix incluent la taxe sur la valeur ajoutée (TVA) basée sur le pays dans lequel vous êtes enregistré, tel que spécifié dans vos paramètres de compte.\n\nAucun frais supplémentaire ne s'applique. Si des modifications de prix interviennent, elles seront communiquées à l'avance via l'application.",
      paymentHeader: "Paiement",
      paymentText: "Nous collaborons avec Stripe pour traiter les paiements et proposons plusieurs méthodes de paiement en fonction de votre pays et de vos préférences. Nos partenaires de paiement utilisent les dernières technologies pour garantir des transactions sécurisées.\n\n- **Après le Paiement :** Une fois le paiement traité, le service demandé sera livré automatiquement en quelques minutes.\n- **Informations Bancaires :** Nous ne stockons aucune donnée de paiement, telle que les numéros de carte ou d'autres informations sensibles, sur nos serveurs.\n\nEn cas d'échec du paiement ou de problème, veuillez contacter notre service support via l'application.",
      deliveryHeader: "Livraison",
      deliveryText: "Lors du paiement de nouveaux services ou comptes, le service est généralement activé en 1 à 5 minutes. Si la livraison est retardée en raison de problèmes techniques, nous vous informerons et prolongerons l'accès en fonction de la durée de l'indisponibilité.",
      cancellationHeader: "Droit de Rétractation",
      cancellationText: "Étant donné que nous vendons des services numériques, aucun droit de rétractation ne s'applique en vertu de la loi sur les contrats à distance. Une fois qu'un service a été payé et activé, le contrat est considéré comme contraignant.",
      warrantyHeader: "Réclamations et Garantie",
      warrantyText: "Dans l'application, un panneau de support est disponible pour soumettre des réclamations, poser des questions ou demander de l'assistance. Nous nous efforçons de répondre à toutes les demandes dans un délai de 24 heures.\n\nEn cas de problème technique affectant la fonctionnalité du service, nous sommes responsables de le résoudre dès que possible. Pour des perturbations importantes, les utilisateurs seront dédommagés par une prolongation d'accès.",
      dataHeader: "Données Stockées",
      dataText: "Nous ne stockons aucune donnée personnelle ni les données des recettes générées par les utilisateurs sur nos serveurs. Si vous souhaitez supprimer vos données, cela peut être fait via votre compte en cliquant sur 'Supprimer Mes Données'. Cela supprimera uniquement les données locales stockées sur votre appareil.",
      forceMajeureHeader: "Force Majeure",
      forceMajeureText: "Nous ne sommes pas responsables des retards ou des déficiences dans les services causés par des circonstances indépendantes de notre volonté, telles que :\n- Catastrophes naturelles\n- Guerres ou conflits\n- Décisions gouvernementales\n- Pannes techniques majeures chez nos fournisseurs\n\nEn cas de force majeure, nous vous informerons dès que possible et reprendrons les services lorsque la situation le permettra.",
      disputeHeader: "Litiges",
      disputeText: "En cas de litige entre le client et Hellström Trading i Göteborg AB, le différend sera d'abord réglé par négociation. Si aucun accord ne peut être trouvé, le litige sera tranché par le tribunal de district de Göteborg conformément au droit suédois.",
      personalDataHeader: "Données Personnelles",
      personalDataText: "Nous respectons le règlement général sur la protection des données (RGPD) et protégeons toutes les données personnelles selon les normes de sécurité les plus élevées.\n\n- **Collecte :** Nous ne collectons que les données nécessaires pour fournir des services, telles que l'enregistrement des comptes et la facturation.\n- **Stockage :** Vos données sont stockées en toute sécurité sur nos serveurs avec Amazon Web Services (AWS).\n- **Utilisation :** Les données personnelles sont uniquement utilisées pour fournir des services et ne sont jamais partagées avec des tiers.\n\nVous avez le droit de demander l'accès, la correction ou la suppression de vos données à tout moment."
    }
  },
  es:{
    terms: {
      titleHeader: "Términos y Condiciones",
      titleText: "Versión 1.0 | Última actualización: 2024-11-30",
      generalHeader: "General",
      generalText: "Estos términos y condiciones se aplican a todas las compras realizadas a través de Hellström Trading i Göteborg AB (Org nr: 556572-0413) en el sitio web www.live.bakesoft.se. Al utilizar los servicios de nuestro sitio web, aceptas cumplir con estos términos. Ten en cuenta que podemos actualizar estos términos, y es tu responsabilidad mantenerte informado sobre los cambios más recientes.\n\nHellström Trading i Göteborg AB tiene su sede en Gotemburgo, Suecia, y todas las transacciones y acuerdos están regidos por la legislación sueca, específicamente la Ley de Comercio Electrónico y la Ley de Contratos a Distancia. Para más información sobre estas leyes, visita el sitio web oficial de la Agencia Sueca del Consumidor.\n\nAl utilizar www.live.bakesoft.se, confirmas que:\n1. Tienes al menos 18 años o cuentas con el consentimiento de un padre/madre o tutor.\n2. Entiendes y aceptas estos términos.",
      orderHeader: "Pedido",
      orderText: "Para realizar un pedido en www.live.bakesoft.se, debes crear una cuenta. Durante el registro, se requiere que compres al menos un (1) crédito para activar tu cuenta. Los créditos se utilizan para acceder a nuestros servicios digitales y son parte de nuestro sistema de pago interno.\n\nSi tienes problemas con el registro o el pedido, por favor, contacta a nuestro soporte a través de la aplicación o por correo electrónico.",
      creditsHeader: "Créditos (Tokens de Acceso)",
      creditsText: "- **Definición:** Los créditos son tokens de acceso digitales almacenados en nuestros servidores y se utilizan exclusivamente en www.live.bakesoft.se. Los créditos no son una moneda y no se pueden cambiar por dinero u otras divisas.\n- **Uso:** Los créditos solo pueden utilizarse para pagar servicios en nuestra plataforma y no pueden ser transferidos ni revendidos.\n- **Condiciones:** Los créditos son válidos mientras tu cuenta esté activa. Si tu cuenta es desactivada o eliminada, los créditos no utilizados perderán su validez.",
      pricesHeader: "Precios",
      pricesText: "Todos los precios incluyen el impuesto al valor agregado (IVA), basado en el país en el que estás registrado, según lo especificado en la configuración de tu cuenta.\n\nNo se aplican cargos adicionales. Si se realizan cambios en los precios, estos se comunicarán con antelación a través de la aplicación.",
      paymentHeader: "Pago",
      paymentText: "Colaboramos con Stripe para procesar los pagos y ofrecemos varios métodos de pago según tu país y tus preferencias. Nuestros socios de pago utilizan la tecnología más avanzada para garantizar transacciones seguras.\n\n- **Después del Pago:** Una vez que se procese el pago, el servicio solicitado se entregará automáticamente en unos pocos minutos.\n- **Información de Tarjetas:** No almacenamos detalles de pago como números de tarjeta ni otra información sensible en nuestros servidores.\n\nSi el pago falla o surgen problemas, por favor, contacta a nuestro soporte a través de la aplicación.",
      deliveryHeader: "Entrega",
      deliveryText: "Al pagar por nuevos servicios o cuentas, el servicio se activa generalmente dentro de 1 a 5 minutos. Si la entrega se retrasa debido a problemas técnicos, te informaremos y extenderemos el acceso por un período equivalente al tiempo de inactividad.",
      cancellationHeader: "Derecho de Retracto",
      cancellationText: "Dado que vendemos servicios digitales, no se aplica el derecho de retracto según la Ley de Contratos a Distancia. Una vez que un servicio ha sido pagado y activado, el contrato se considera vinculante.",
      warrantyHeader: "Reclamaciones y Garantía",
      warrantyText: "Dentro de la aplicación, hay un panel de soporte donde puedes enviar reclamaciones, realizar preguntas o solicitar ayuda. Nos esforzamos por responder a todas las solicitudes dentro de las 24 horas.\n\nSi surgen problemas técnicos que afectan la funcionalidad del servicio, somos responsables de resolverlos lo antes posible. En caso de interrupciones importantes, los usuarios serán compensados con una extensión del acceso.",
      dataHeader: "Datos Almacenados",
      dataText: "No almacenamos datos personales ni datos de recetas generados por los usuarios en nuestros servidores. Si deseas eliminar tus datos, puedes hacerlo desde tu cuenta haciendo clic en 'Eliminar mis datos'. Esto solo eliminará los datos locales almacenados en tu dispositivo.",
      forceMajeureHeader: "Fuerza Mayor",
      forceMajeureText: "No somos responsables de retrasos o deficiencias en los servicios causados por circunstancias fuera de nuestro control, tales como:\n- Desastres naturales\n- Guerras o conflictos\n- Decisiones gubernamentales\n- Fallos técnicos importantes de nuestros proveedores\n\nEn caso de fuerza mayor, te informaremos lo antes posible y reanudaremos los servicios cuando la situación lo permita.",
      disputeHeader: "Disputas",
      disputeText: "En caso de una disputa entre el cliente y Hellström Trading i Göteborg AB, el asunto se resolverá primero mediante negociación. Si no se puede llegar a un acuerdo, la disputa se resolverá en el Tribunal de Distrito de Gotemburgo de acuerdo con la legislación sueca.",
      personalDataHeader: "Datos Personales",
      personalDataText: "Cumplimos con el Reglamento General de Protección de Datos (RGPD) y protegemos todos los datos personales según los estándares de seguridad más altos.\n\n- **Recopilación:** Solo recopilamos datos necesarios para proporcionar servicios, como el registro de cuentas y la facturación.\n- **Almacenamiento:** Tus datos se almacenan de forma segura en nuestros servidores con Amazon Web Services (AWS).\n- **Uso:** Los datos personales solo se utilizan para proporcionar servicios y nunca se comparten con terceros.\n\nTienes derecho a solicitar acceso, corrección o eliminación de tus datos en cualquier momento."
    }
  },
  pt:{
    terms: {
      titleHeader: "Termos e Condições",
      titleText: "Versão 1.0 | Última atualização: 2024-11-30",
      generalHeader: "Geral",
      generalText: "Estes termos e condições aplicam-se a todas as compras realizadas através da Hellström Trading i Göteborg AB (Org nr: 556572-0413) no site www.live.bakesoft.se. Ao utilizar os serviços do nosso site, você concorda em cumprir estes termos. Observe que podemos atualizar estes termos, e é sua responsabilidade manter-se informado sobre as alterações mais recentes.\n\nA Hellström Trading i Göteborg AB está sediada em Gotemburgo, Suécia, e todas as transações e acordos estão sujeitos à legislação sueca, especificamente à Lei de Comércio Eletrônico e à Lei de Contratos à Distância. Para mais informações sobre essas leis, visite o site oficial da Agência Sueca do Consumidor.\n\nAo utilizar o www.live.bakesoft.se, você confirma que:\n1. Tem pelo menos 18 anos ou possui o consentimento de um responsável/tutor.\n2. Entende e aceita estes termos.",
      orderHeader: "Pedido",
      orderText: "Para fazer um pedido no www.live.bakesoft.se, você deve criar uma conta. Durante o registro, é necessário comprar pelo menos um (1) crédito para ativar sua conta. Os créditos são usados para acessar nossos serviços digitais e fazem parte do nosso sistema interno de pagamentos.\n\nSe encontrar problemas com o registro ou o pedido, entre em contato com nosso suporte através do aplicativo ou por e-mail.",
      creditsHeader: "Créditos (Tokens de Acesso)",
      creditsText: "- **Definição:** Créditos são tokens de acesso digitais armazenados em nossos servidores e utilizados exclusivamente no www.live.bakesoft.se. Créditos não são uma moeda e não podem ser trocados por dinheiro ou outras moedas.\n- **Uso:** Créditos só podem ser usados para pagar serviços na nossa plataforma e não podem ser transferidos ou revendidos.\n- **Condições:** Créditos são válidos enquanto sua conta estiver ativa. Caso sua conta seja desativada ou encerrada, os créditos não utilizados perderão a validade.",
      pricesHeader: "Preços",
      pricesText: "Todos os preços incluem imposto sobre valor agregado (IVA), com base no país onde você está registrado, conforme especificado nas configurações da sua conta.\n\nNão serão aplicadas taxas adicionais. Se houver alterações nos preços, estas serão comunicadas antecipadamente através do aplicativo.",
      paymentHeader: "Pagamento",
      paymentText: "Colaboramos com a Stripe para processar pagamentos e oferecemos vários métodos de pagamento dependendo do seu país e das suas preferências. Nossos parceiros de pagamento utilizam as mais recentes tecnologias para garantir transações seguras.\n\n- **Após o Pagamento:** Assim que o pagamento for processado, o serviço solicitado será entregue automaticamente em alguns minutos.\n- **Informações de Cartão:** Não armazenamos detalhes de pagamento, como números de cartão ou outras informações sensíveis, em nossos servidores.\n\nCaso o pagamento falhe ou surjam problemas, entre em contato com nosso suporte através do aplicativo.",
      deliveryHeader: "Entrega",
      deliveryText: "Ao pagar por novos serviços ou contas, o serviço geralmente é ativado dentro de 1 a 5 minutos. Caso a entrega seja atrasada devido a problemas técnicos, você será notificado e o acesso será estendido pelo período equivalente ao tempo de indisponibilidade.",
      cancellationHeader: "Direito de Cancelamento",
      cancellationText: "Como vendemos serviços digitais, o direito de cancelamento não se aplica conforme a Lei de Contratos à Distância. Assim que um serviço é pago e ativado, o contrato é considerado vinculativo.",
      warrantyHeader: "Reclamações e Garantia",
      warrantyText: "No aplicativo, existe um painel de suporte onde você pode enviar reclamações, fazer perguntas ou solicitar assistência. Esforçamo-nos para responder a todas as solicitações dentro de 24 horas.\n\nSe surgirem problemas técnicos que afetem a funcionalidade do serviço, somos responsáveis por resolvê-los o mais rápido possível. Em caso de interrupções significativas, os usuários serão compensados com uma extensão de acesso.",
      dataHeader: "Dados Armazenados",
      dataText: "Não armazenamos dados pessoais nem dados de receitas gerados pelos usuários em nossos servidores. Se desejar excluir seus dados, isso pode ser feito na sua conta clicando em 'Excluir Meus Dados'. Isso excluirá apenas os dados locais armazenados no seu dispositivo.",
      forceMajeureHeader: "Força Maior",
      forceMajeureText: "Não somos responsáveis por atrasos ou falhas nos serviços causados por circunstâncias fora do nosso controle, tais como:\n- Desastres naturais\n- Guerras ou conflitos\n- Decisões governamentais\n- Falhas técnicas importantes de nossos fornecedores\n\nEm caso de força maior, notificaremos você o mais rápido possível e retomaremos os serviços assim que a situação permitir.",
      disputeHeader: "Disputas",
      disputeText: "No caso de uma disputa entre o cliente e a Hellström Trading i Göteborg AB, o assunto será resolvido primeiro por negociação. Se não for possível chegar a um acordo, a disputa será resolvida no Tribunal Distrital de Gotemburgo, de acordo com a legislação sueca.",
      personalDataHeader: "Dados Pessoais",
      personalDataText: "Cumprimos o Regulamento Geral de Proteção de Dados (RGPD) e protegemos todos os dados pessoais de acordo com os mais altos padrões de segurança.\n\n- **Coleta:** Apenas coletamos os dados necessários para fornecer serviços, como registro de contas e faturamento.\n- **Armazenamento:** Seus dados são armazenados com segurança em nossos servidores com a Amazon Web Services (AWS).\n- **Uso:** Os dados pessoais são usados exclusivamente para fornecer serviços e nunca são compartilhados com terceiros.\n\nVocê tem o direito de solicitar acesso, correção ou exclusão de seus dados a qualquer momento."
    }
  },
  ja:{
    terms: {
      titleHeader: "利用規約",
      titleText: "バージョン 1.0 | 最終更新日: 2024-11-30",
      generalHeader: "概要",
      generalText: "本利用規約は、Hellström Trading i Göteborg AB（法人番号: 556572-0413）が運営するウェブサイト www.live.bakesoft.se を通じて行われるすべての購入に適用されます。本ウェブサイトのサービスを利用することで、本規約に同意したものとみなされます。本規約は随時更新される可能性がありますので、最新の内容をご確認ください。\n\nHellström Trading i Göteborg AB はスウェーデンのヨーテボリに拠点を置き、すべての取引および契約は、スウェーデンの法律、特に電子商取引法および通信販売法に基づいて規定されます。これらの法律について詳しくは、スウェーデン消費者庁の公式ウェブサイトをご覧ください。\n\nwww.live.bakesoft.se を利用することで、以下を確認したものとみなされます：\n1. あなたは18歳以上である、または親／保護者の同意を得ている。\n2. 本規約を理解し、同意した。",
      orderHeader: "注文",
      orderText: "www.live.bakesoft.se で注文を行うには、アカウントを作成する必要があります。登録時に、少なくとも1つのクレジットを購入してアカウントを有効化する必要があります。クレジットは、当社のデジタルサービスにアクセスするために使用される内部決済システムの一部です。\n\n登録や注文に問題が発生した場合は、アプリケーションまたはメールを通じてサポートにお問い合わせください。",
      creditsHeader: "クレジット（アクセス トークン）",
      creditsText: "- **定義:** クレジットは、当社のサーバーに保存され、www.live.bakesoft.se 内でのみ使用されるデジタルアクセス トークンです。クレジットは通貨ではなく、現金やその他の通貨に交換することはできません。\n- **使用:** クレジットは、当社プラットフォーム上でサービスを支払うためにのみ使用され、譲渡または再販することはできません。\n- **条件:** クレジットは、アカウントがアクティブである限り有効です。アカウントが無効化または削除された場合、未使用のクレジットは失効します。",
      pricesHeader: "料金",
      pricesText: "すべての料金には、アカウント設定で指定された居住国に基づく付加価値税（VAT）が含まれます。\n\n追加料金は発生しません。料金の変更がある場合は、事前にアプリケーションを通じて通知されます。",
      paymentHeader: "支払い",
      paymentText: "当社は、Stripe と提携して支払いを処理しており、居住国やお客様の好みに応じて複数の支払い方法を提供しています。支払いパートナーは、最新の技術を使用して安全な取引を保証します。\n\n- **支払い後:** 支払いが処理されると、要求されたサービスは数分以内に自動的に提供されます。\n- **カード情報:** カード番号などの支払いに関する機密情報は、当社のサーバーに保存されません。\n\n支払いが失敗した場合や問題が発生した場合は、アプリケーションを通じてサポートにお問い合わせください。",
      deliveryHeader: "配信",
      deliveryText: "新しいサービスやアカウントの支払い後、通常は1～5分以内にサービスが有効化されます。技術的な問題で配信が遅れる場合は、通知を行い、利用不可となった期間に応じてアクセス期間を延長します。",
      cancellationHeader: "キャンセル権",
      cancellationText: "デジタルサービスを販売しているため、通信販売法に基づくキャンセル権は適用されません。サービスが支払われ、有効化された時点で、契約は拘束力を持つものとみなされます。",
      warrantyHeader: "苦情および保証",
      warrantyText: "アプリケーション内には、苦情を提出したり、質問をしたり、サポートをリクエストしたりできるサポートパネルがあります。すべてのリクエストに対し、24時間以内の回答を目指しています。\n\nサービスの機能に影響を及ぼす技術的な問題が発生した場合、当社は可能な限り迅速に解決する責任を負います。重大な中断が発生した場合、ユーザーはアクセス期間の延長という形で補償を受けます。",
      dataHeader: "保存データ",
      dataText: "当社は、個人データやユーザー生成のレシピデータをサーバーに保存しません。データを削除したい場合は、アカウントから「データを削除する」をクリックしてください。これにより、デバイスに保存されているローカルデータのみが削除されます。",
      forceMajeureHeader: "不可抗力",
      forceMajeureText: "当社は、以下のような当社の管理外の状況によって引き起こされたサービスの遅延または不備に対して責任を負いません：\n- 自然災害\n- 戦争や紛争\n- 政府の決定\n- サプライヤーによる重大な技術的障害\n\n不可抗力の状況が発生した場合、可能な限り早急に通知を行い、状況が許す限りサービスを再開します。",
      disputeHeader: "紛争",
      disputeText: "Hellström Trading i Göteborg AB とお客様の間で紛争が発生した場合、まず交渉による解決を図ります。合意に達しない場合、紛争はスウェーデンの法律に基づき、ヨーテボリ地方裁判所で解決されます。",
      personalDataHeader: "個人情報",
      personalDataText: "当社は、一般データ保護規則（GDPR）を遵守し、最高のセキュリティ基準に基づいてすべての個人情報を保護します。\n\n- **収集:** 当社は、アカウント登録や請求書発行など、サービス提供に必要なデータのみを収集します。\n- **保存:** あなたのデータは、Amazon Web Services (AWS) を使用して安全に保存されます。\n- **使用:** 個人情報は、サービスを提供するためにのみ使用され、第三者と共有されることはありません。\n\nいつでもデータへのアクセス、修正、または削除を要求する権利があります。"
    }
  },
  tr:{
    terms: {
      titleHeader: "Şartlar ve Koşullar",
      titleText: "Sürüm 1.0 | Son güncelleme: 2024-11-30",
      generalHeader: "Genel",
      generalText: "Bu şartlar ve koşullar, Hellström Trading i Göteborg AB (Şirket No: 556572-0413) tarafından işletilen www.live.bakesoft.se web sitesi üzerinden yapılan tüm satın alımlarda geçerlidir. Web sitemizin hizmetlerini kullanarak bu şartlara uymayı kabul etmiş olursunuz. Bu şartların güncellenebileceğini ve en son değişikliklerden haberdar olmanızın sizin sorumluluğunuzda olduğunu lütfen unutmayın.\n\nHellström Trading i Göteborg AB, İsveç'in Göteborg şehrinde bulunmaktadır ve tüm işlemler ve anlaşmalar İsveç yasalarına, özellikle E-Ticaret Yasası ve Mesafeli Sözleşmeler Yasası'na tabidir. Bu yasalar hakkında daha fazla bilgi için İsveç Tüketici Ajansı'nın resmi web sitesini ziyaret edebilirsiniz.\n\nwww.live.bakesoft.se sitesini kullanarak şunları onaylarsınız:\n1. En az 18 yaşında olduğunuzu veya ebeveyn/veli iznine sahip olduğunuzu.\n2. Bu şartları anladığınızı ve kabul ettiğinizi.",
      orderHeader: "Sipariş",
      orderText: "www.live.bakesoft.se üzerinden sipariş vermek için bir hesap oluşturmanız gerekir. Kayıt sırasında hesabınızı etkinleştirmek için en az bir (1) kredi satın almanız gereklidir. Krediler, dijital hizmetlerimize erişmek için kullanılan dahili ödeme sistemimizin bir parçasıdır.\n\nKayıt veya siparişle ilgili herhangi bir sorun yaşarsanız, lütfen uygulama veya e-posta yoluyla destek ekibimize ulaşın.",
      creditsHeader: "Krediler (Erişim Jetonları)",
      creditsText: "- **Tanım:** Krediler, sunucularımızda saklanan ve yalnızca www.live.bakesoft.se içinde kullanılan dijital erişim jetonlarıdır. Krediler para birimi değildir ve para veya başka bir para birimine dönüştürülemez.\n- **Kullanım:** Krediler yalnızca platformumuzdaki hizmetlerin ödemesi için kullanılabilir ve transfer edilemez veya yeniden satılamaz.\n- **Koşullar:** Krediler, hesabınız aktif olduğu sürece geçerlidir. Hesabınız devre dışı bırakılır veya silinirse, kullanılmamış krediler geçersiz hale gelir.",
      pricesHeader: "Fiyatlar",
      pricesText: "Tüm fiyatlara, hesap ayarlarınızda belirtilen kayıtlı ülkenize göre hesaplanan Katma Değer Vergisi (KDV) dahildir.\n\nEk ücret uygulanmaz. Fiyat değişiklikleri olması durumunda, bu değişiklikler önceden uygulama üzerinden bildirilecektir.",
      paymentHeader: "Ödeme",
      paymentText: "Stripe ile iş birliği yaparak ödemeleri işliyoruz ve ülkenize ve tercihlerinize bağlı olarak çeşitli ödeme yöntemleri sunuyoruz. Ödeme ortaklarımız, güvenli işlemleri sağlamak için en son teknolojiyi kullanır.\n\n- **Ödemeden Sonra:** Ödeme işlendikten sonra, talep edilen hizmet birkaç dakika içinde otomatik olarak sağlanır.\n- **Kart Bilgileri:** Kart numaraları gibi ödeme ile ilgili hassas bilgiler sunucularımızda saklanmaz.\n\nÖdeme başarısız olursa veya sorunlarla karşılaşırsanız, lütfen uygulama üzerinden destek ekibimize ulaşın.",
      deliveryHeader: "Teslimat",
      deliveryText: "Yeni hizmetler veya hesaplar için ödeme yapıldığında, hizmet genellikle 1-5 dakika içinde etkinleştirilir. Teknik sorunlar nedeniyle teslimat gecikirse, sizi bilgilendirecek ve erişiminizi kullanım dışı süreye eşdeğer şekilde uzatacağız.",
      cancellationHeader: "İptal Hakkı",
      cancellationText: "Dijital hizmetler sattığımız için Mesafeli Sözleşmeler Yasası'na göre iptal hakkı geçerli değildir. Bir hizmet ödendikten ve etkinleştirildikten sonra, sözleşme bağlayıcı olarak kabul edilir.",
      warrantyHeader: "Şikayet ve Garanti",
      warrantyText: "Uygulama içinde şikayetlerinizi iletebileceğiniz, sorular sorabileceğiniz veya destek talebinde bulunabileceğiniz bir destek paneli bulunmaktadır. Tüm talepleri 24 saat içinde yanıtlamaya çalışıyoruz.\n\nHizmetin işlevselliğini etkileyen teknik sorunlar ortaya çıkarsa, bunları en kısa sürede çözmekle yükümlüyüz. Önemli kesintiler durumunda, kullanıcılar erişim sürelerinin uzatılmasıyla tazmin edilir.",
      dataHeader: "Saklanan Veriler",
      dataText: "Kişisel verileri veya kullanıcı tarafından oluşturulan tarif verilerini sunucularımızda saklamıyoruz. Verilerinizi silmek istiyorsanız, hesabınızdan 'Verilerimi Sil' seçeneğine tıklayarak bunu gerçekleştirebilirsiniz. Bu yalnızca cihazınızda saklanan yerel verileri silecektir.",
      forceMajeureHeader: "Mücbir Sebep",
      forceMajeureText: "Kontrolümüz dışında gelişen aşağıdaki durumlar nedeniyle meydana gelen hizmet gecikmeleri veya eksikliklerden sorumlu değiliz:\n- Doğal afetler\n- Savaşlar veya çatışmalar\n- Hükümet kararları\n- Tedarikçilerimizin büyük teknik arızaları\n\nMücbir sebep durumunda, sizi mümkün olan en kısa sürede bilgilendireceğiz ve durum izin verdiğinde hizmetleri yeniden başlatacağız.",
      disputeHeader: "Uyuşmazlıklar",
      disputeText: "Müşteri ile Hellström Trading i Göteborg AB arasında bir uyuşmazlık meydana gelirse, öncelikle müzakere yoluyla çözülmeye çalışılacaktır. Anlaşmaya varılamazsa, uyuşmazlık İsveç yasalarına göre Göteborg Bölge Mahkemesi'nde çözülecektir.",
      personalDataHeader: "Kişisel Veriler",
      personalDataText: "Genel Veri Koruma Yönetmeliği'ne (GDPR) uyuyoruz ve tüm kişisel verileri en yüksek güvenlik standartlarına göre koruyoruz.\n\n- **Toplama:** Yalnızca hesap kaydı ve faturalama gibi hizmet sağlamak için gerekli olan verileri topluyoruz.\n- **Depolama:** Verileriniz, Amazon Web Services (AWS) ile güvenli bir şekilde saklanmaktadır.\n- **Kullanım:** Kişisel veriler yalnızca hizmet sağlamak için kullanılır ve asla üçüncü taraflarla paylaşılmaz.\n\nVerilerinize erişim, düzeltme veya silme talep etme hakkına her zaman sahipsiniz."
    }
  },
  ko:{
    terms: {
      titleHeader: "이용 약관",
      titleText: "버전 1.0 | 마지막 업데이트: 2024-11-30",
      generalHeader: "일반",
      generalText: "본 약관은 Hellström Trading i Göteborg AB(등록 번호: 556572-0413)가 운영하는 웹사이트 www.live.bakesoft.se를 통해 이루어진 모든 구매에 적용됩니다. 본 웹사이트의 서비스를 이용함으로써, 귀하는 본 약관을 준수하는 데 동의한 것으로 간주됩니다. 약관은 변경될 수 있으며, 최신 내용을 확인하는 것은 귀하의 책임입니다.\n\nHellström Trading i Göteborg AB는 스웨덴 예테보리에 본사를 두고 있으며, 모든 거래 및 계약은 스웨덴 법률, 특히 전자상거래법 및 거리판매법에 따라 규제됩니다. 이 법률에 대한 자세한 내용은 스웨덴 소비자청 공식 웹사이트를 참조하세요.\n\nwww.live.bakesoft.se를 이용함으로써 귀하는 다음을 확인합니다:\n1. 귀하가 만 18세 이상이거나 부모/보호자의 동의를 받았음을.\n2. 본 약관을 이해하고 동의했음을.",
      orderHeader: "주문",
      orderText: "www.live.bakesoft.se에서 주문하려면 계정을 생성해야 합니다. 등록 시, 계정을 활성화하기 위해 최소 1개의 크레딧을 구매해야 합니다. 크레딧은 당사의 디지털 서비스를 이용하기 위해 사용되는 내부 결제 시스템의 일부입니다.\n\n계정 등록이나 주문에 문제가 발생한 경우, 애플리케이션 또는 이메일을 통해 지원팀에 문의하세요.",
      creditsHeader: "크레딧 (액세스 토큰)",
      creditsText: "- **정의:** 크레딧은 당사의 서버에 저장되며 www.live.bakesoft.se 내에서만 사용되는 디지털 액세스 토큰입니다. 크레딧은 화폐가 아니며, 현금이나 다른 통화로 교환할 수 없습니다.\n- **사용:** 크레딧은 당사 플랫폼에서 서비스 비용을 지불하는 데만 사용되며, 양도하거나 재판매할 수 없습니다.\n- **조건:** 크레딧은 귀하의 계정이 활성 상태일 때만 유효합니다. 계정이 비활성화되거나 삭제되면, 사용하지 않은 크레딧은 무효화됩니다.",
      pricesHeader: "가격",
      pricesText: "모든 가격에는 귀하의 계정 설정에서 지정된 국가에 따라 부과되는 부가가치세(VAT)가 포함되어 있습니다.\n\n추가 요금은 부과되지 않습니다. 가격에 변경이 있는 경우, 애플리케이션을 통해 사전에 공지됩니다.",
      paymentHeader: "결제",
      paymentText: "당사는 Stripe과 협력하여 결제를 처리하며, 귀하의 국가와 선호도에 따라 여러 결제 방법을 제공합니다. 결제 파트너는 최신 기술을 사용하여 안전한 거래를 보장합니다.\n\n- **결제 후:** 결제가 처리되면, 요청한 서비스가 몇 분 내에 자동으로 제공됩니다.\n- **카드 정보:** 카드 번호와 같은 민감한 결제 정보는 당사 서버에 저장되지 않습니다.\n\n결제가 실패하거나 문제가 발생하면, 애플리케이션을 통해 지원팀에 문의하세요.",
      deliveryHeader: "배송",
      deliveryText: "새로운 서비스나 계정에 대한 결제를 완료하면, 서비스는 일반적으로 1~5분 내에 활성화됩니다. 기술적 문제로 인해 배송이 지연될 경우, 귀하에게 알리고, 사용 불가 시간에 해당하는 기간만큼 액세스를 연장합니다.",
      cancellationHeader: "철회 권리",
      cancellationText: "당사는 디지털 서비스를 판매하기 때문에 거리판매법에 따른 철회 권리가 적용되지 않습니다. 서비스가 결제되고 활성화되면 계약은 구속력을 갖는 것으로 간주됩니다.",
      warrantyHeader: "불만 및 보증",
      warrantyText: "애플리케이션 내에는 불만 사항을 제출하거나 질문을 하거나 지원을 요청할 수 있는 지원 패널이 있습니다. 모든 요청에 대해 24시간 이내에 응답할 것을 목표로 합니다.\n\n서비스 기능에 영향을 미치는 기술적 문제가 발생하면, 당사는 가능한 한 신속히 이를 해결할 책임이 있습니다. 심각한 중단이 발생한 경우, 사용자는 액세스 기간 연장을 통해 보상받을 수 있습니다.",
      dataHeader: "저장된 데이터",
      dataText: "당사는 개인 데이터나 사용자가 생성한 레시피 데이터를 서버에 저장하지 않습니다. 데이터를 삭제하려면, 계정에서 '내 데이터 삭제'를 클릭하여 진행할 수 있습니다. 이는 귀하의 장치에 저장된 로컬 데이터만 삭제합니다.",
      forceMajeureHeader: "불가항력",
      forceMajeureText: "다음과 같은 당사의 통제 범위를 벗어난 상황으로 인해 발생한 서비스 지연이나 부족에 대해 책임을 지지 않습니다:\n- 자연 재해\n- 전쟁 또는 갈등\n- 정부 결정\n- 공급업체의 주요 기술적 결함\n\n불가항력 상황이 발생하면, 가능한 한 신속히 귀하에게 알리고 상황이 허락하는 대로 서비스를 재개할 것입니다.",
      disputeHeader: "분쟁",
      disputeText: "고객과 Hellström Trading i Göteborg AB 간에 분쟁이 발생한 경우, 먼저 협상을 통해 해결을 시도합니다. 합의에 도달하지 못할 경우, 분쟁은 스웨덴 법률에 따라 예테보리 지방 법원에서 해결됩니다.",
      personalDataHeader: "개인 정보",
      personalDataText: "당사는 일반 데이터 보호 규정(GDPR)을 준수하며, 최고 수준의 보안 표준에 따라 모든 개인 정보를 보호합니다.\n\n- **수집:** 당사는 계정 등록 및 청구서 발행 등 서비스 제공에 필요한 데이터만 수집합니다.\n- **저장:** 귀하의 데이터는 Amazon Web Services(AWS)를 통해 안전하게 저장됩니다.\n- **사용:** 개인 정보는 서비스 제공 목적으로만 사용되며, 제3자와 공유되지 않습니다.\n\n언제든지 귀하의 데이터에 대한 접근, 수정 또는 삭제를 요청할 권리가 있습니다."
    }
  },
de:{
  terms: {
    titleHeader: "Allgemeine Geschäftsbedingungen",
    titleText: "Version 1.0 | Letzte Aktualisierung: 2024-11-30",
    generalHeader: "Allgemeines",
    generalText: "Diese Allgemeinen Geschäftsbedingungen gelten für alle Käufe, die über Hellström Trading i Göteborg AB (Org.-Nr.: 556572-0413) auf der Website www.live.bakesoft.se getätigt werden. Durch die Nutzung der Dienste unserer Website erklären Sie sich mit diesen Bedingungen einverstanden. Bitte beachten Sie, dass wir diese Bedingungen aktualisieren können und es in Ihrer Verantwortung liegt, sich über die neuesten Änderungen zu informieren.\n\nHellström Trading i Göteborg AB hat seinen Sitz in Göteborg, Schweden, und alle Transaktionen und Vereinbarungen unterliegen schwedischem Recht, insbesondere dem E-Commerce-Gesetz und dem Fernabsatzgesetz. Weitere Informationen zu diesen Gesetzen finden Sie auf der offiziellen Website der schwedischen Verbraucherschutzbehörde.\n\nDurch die Nutzung von www.live.bakesoft.se bestätigen Sie, dass:\n1. Sie mindestens 18 Jahre alt sind oder die Zustimmung eines Elternteils/Erziehungsberechtigten haben.\n2. Sie diese Bedingungen verstehen und akzeptieren.",
    orderHeader: "Bestellung",
    orderText: "Um eine Bestellung auf www.live.bakesoft.se aufzugeben, müssen Sie ein Konto erstellen. Bei der Registrierung ist der Kauf von mindestens einem (1) Kredit erforderlich, um Ihr Konto zu aktivieren. Credits werden verwendet, um auf unsere digitalen Dienste zuzugreifen, und sind Teil unseres internen Zahlungssystems.\n\nSollten Probleme bei der Registrierung oder Bestellung auftreten, wenden Sie sich bitte über die Anwendung oder per E-Mail an unseren Support.",
    creditsHeader: "Credits (Zugriffstoken)",
    creditsText: "- **Definition:** Credits sind digitale Zugriffstoken, die auf unseren Servern gespeichert sind und ausschließlich innerhalb von www.live.bakesoft.se verwendet werden. Credits sind keine Währung und können nicht in Geld oder andere Währungen umgetauscht werden.\n- **Verwendung:** Credits können nur zur Bezahlung von Diensten auf unserer Plattform verwendet werden und dürfen nicht übertragen oder weiterverkauft werden.\n- **Bedingungen:** Credits sind gültig, solange Ihr Konto aktiv ist. Wird Ihr Konto deaktiviert oder gelöscht, verlieren ungenutzte Credits ihre Gültigkeit.",
    pricesHeader: "Preise",
    pricesText: "Alle Preise enthalten die Mehrwertsteuer (MwSt.), basierend auf dem Land, in dem Sie registriert sind, wie in Ihren Kontoeinstellungen angegeben.\n\nEs fallen keine zusätzlichen Gebühren an. Sollten sich Preise ändern, wird dies im Voraus über die Anwendung mitgeteilt.",
    paymentHeader: "Zahlung",
    paymentText: "Wir arbeiten mit Stripe zusammen, um Zahlungen zu verarbeiten, und bieten je nach Ihrem Land und Ihren Vorlieben verschiedene Zahlungsmethoden an. Unsere Zahlungspartner verwenden die neueste Technologie, um sichere Transaktionen zu gewährleisten.\n\n- **Nach der Zahlung:** Sobald die Zahlung verarbeitet wurde, wird der angeforderte Dienst innerhalb weniger Minuten automatisch bereitgestellt.\n- **Kartendaten:** Wir speichern keine Zahlungsdaten wie Kartennummern oder andere sensible Informationen auf unseren Servern.\n\nSollte die Zahlung fehlschlagen oder Probleme auftreten, wenden Sie sich bitte über die Anwendung an unseren Support.",
    deliveryHeader: "Lieferung",
    deliveryText: "Nach der Bezahlung neuer Dienste oder Konten wird der Dienst in der Regel innerhalb von 1 bis 5 Minuten aktiviert. Bei Verzögerungen aufgrund technischer Probleme informieren wir Sie und verlängern den Zugriff um den entsprechenden Zeitraum, in dem der Dienst nicht verfügbar war.",
    cancellationHeader: "Widerrufsrecht",
    cancellationText: "Da wir digitale Dienste verkaufen, gilt kein Widerrufsrecht gemäß dem Fernabsatzgesetz. Sobald ein Dienst bezahlt und aktiviert wurde, gilt der Vertrag als bindend.",
    warrantyHeader: "Reklamation und Garantie",
    warrantyText: "Innerhalb der Anwendung gibt es ein Support-Panel, in dem Sie Reklamationen einreichen, Fragen stellen oder Unterstützung anfordern können. Wir bemühen uns, alle Anfragen innerhalb von 24 Stunden zu beantworten.\n\nSollten technische Probleme auftreten, die die Funktionalität des Dienstes beeinträchtigen, sind wir dafür verantwortlich, diese so schnell wie möglich zu beheben. Bei größeren Ausfällen werden Benutzer durch eine Verlängerung des Zugriffs entschädigt.",
    dataHeader: "Gespeicherte Daten",
    dataText: "Wir speichern keine persönlichen Daten oder von Benutzern erstellten Rezeptdaten auf unseren Servern. Wenn Sie Ihre Daten löschen möchten, können Sie dies in Ihrem Konto durch Klicken auf 'Meine Daten löschen' tun. Dadurch werden nur lokale Daten auf Ihrem Gerät gelöscht.",
    forceMajeureHeader: "Höhere Gewalt",
    forceMajeureText: "Wir übernehmen keine Verantwortung für Verzögerungen oder Mängel bei Diensten, die durch Umstände außerhalb unserer Kontrolle verursacht werden, wie z. B.:\n- Naturkatastrophen\n- Kriege oder Konflikte\n- Regierungsentscheidungen\n- Wichtige technische Ausfälle bei unseren Lieferanten\n\nIm Falle höherer Gewalt werden wir Sie so schnell wie möglich informieren und die Dienste wieder aufnehmen, sobald die Situation dies zulässt.",
    disputeHeader: "Streitigkeiten",
    disputeText: "Im Falle einer Streitigkeit zwischen dem Kunden und Hellström Trading i Göteborg AB wird der Streit zunächst durch Verhandlung beigelegt. Sollte keine Einigung erzielt werden können, wird die Streitigkeit gemäß schwedischem Recht am Bezirksgericht Göteborg entschieden.",
    personalDataHeader: "Persönliche Daten",
    personalDataText: "Wir halten uns an die Datenschutz-Grundverordnung (DSGVO) und schützen alle persönlichen Daten gemäß den höchsten Sicherheitsstandards.\n\n- **Erhebung:** Wir erheben nur Daten, die für die Bereitstellung von Diensten erforderlich sind, wie z. B. Kontoregistrierung und Rechnungsstellung.\n- **Speicherung:** Ihre Daten werden sicher auf unseren Servern mit Amazon Web Services (AWS) gespeichert.\n- **Verwendung:** Persönliche Daten werden ausschließlich zur Bereitstellung von Diensten verwendet und niemals an Dritte weitergegeben.\n\nSie haben jederzeit das Recht, Zugriff auf Ihre Daten zu verlangen, diese zu korrigieren oder zu löschen."
  }
},
vi:{
  terms: {
    titleHeader: "Điều Khoản và Điều Kiện",
    titleText: "Phiên bản 1.0 | Cập nhật lần cuối: 30-11-2024",
    generalHeader: "Tổng Quan",
    generalText: "Các điều khoản và điều kiện này áp dụng cho tất cả các giao dịch mua được thực hiện qua Hellström Trading i Göteborg AB (Số đăng ký: 556572-0413) trên trang web www.live.bakesoft.se. Bằng cách sử dụng dịch vụ của trang web chúng tôi, bạn đồng ý tuân thủ các điều khoản này. Lưu ý rằng chúng tôi có thể cập nhật các điều khoản này, và bạn chịu trách nhiệm đảm bảo bạn luôn cập nhật các thay đổi mới nhất.\n\nHellström Trading i Göteborg AB có trụ sở tại Göteborg, Thụy Điển, và tất cả các giao dịch và thỏa thuận được điều chỉnh bởi luật pháp Thụy Điển, đặc biệt là Luật Thương mại Điện tử và Luật Hợp đồng từ xa. Để biết thêm thông tin về các luật này, vui lòng truy cập trang web chính thức của Cơ quan Bảo vệ Người tiêu dùng Thụy Điển.\n\nKhi sử dụng www.live.bakesoft.se, bạn xác nhận rằng:\n1. Bạn ít nhất 18 tuổi hoặc đã có sự đồng ý từ phụ huynh/người giám hộ.\n2. Bạn hiểu và chấp nhận các điều khoản này.",
    orderHeader: "Đặt Hàng",
    orderText: "Để đặt hàng trên www.live.bakesoft.se, bạn phải tạo một tài khoản. Trong quá trình đăng ký, bạn cần mua ít nhất một (1) tín dụng để kích hoạt tài khoản của mình. Tín dụng được sử dụng để truy cập vào các dịch vụ kỹ thuật số của chúng tôi và là một phần của hệ thống thanh toán nội bộ của chúng tôi.\n\nNếu bạn gặp vấn đề với việc đăng ký hoặc đặt hàng, vui lòng liên hệ với bộ phận hỗ trợ của chúng tôi qua ứng dụng hoặc email.",
    creditsHeader: "Tín Dụng (Token Truy Cập)",
    creditsText: "- **Định nghĩa:** Tín dụng là các token truy cập kỹ thuật số được lưu trữ trên máy chủ của chúng tôi và chỉ được sử dụng trong www.live.bakesoft.se. Tín dụng không phải là tiền tệ và không thể đổi sang tiền mặt hoặc các loại tiền tệ khác.\n- **Sử dụng:** Tín dụng chỉ có thể được sử dụng để thanh toán cho các dịch vụ trên nền tảng của chúng tôi và không được chuyển nhượng hoặc bán lại.\n- **Điều kiện:** Tín dụng có hiệu lực miễn là tài khoản của bạn đang hoạt động. Nếu tài khoản của bạn bị hủy kích hoạt hoặc xóa, tín dụng chưa sử dụng sẽ mất hiệu lực.",
    pricesHeader: "Giá Cả",
    pricesText: "Tất cả giá đều bao gồm thuế giá trị gia tăng (VAT), dựa trên quốc gia mà bạn đã đăng ký, được chỉ định trong cài đặt tài khoản của bạn.\n\nKhông có khoản phí bổ sung nào được áp dụng. Nếu có thay đổi về giá cả, điều này sẽ được thông báo trước qua ứng dụng.",
    paymentHeader: "Thanh Toán",
    paymentText: "Chúng tôi hợp tác với Stripe để xử lý thanh toán và cung cấp nhiều phương thức thanh toán tùy thuộc vào quốc gia của bạn và sở thích của bạn. Các đối tác thanh toán của chúng tôi sử dụng công nghệ tiên tiến nhất để đảm bảo giao dịch an toàn.\n\n- **Sau khi thanh toán:** Khi thanh toán được xử lý, dịch vụ được yêu cầu sẽ tự động được cung cấp trong vòng vài phút.\n- **Thông tin thẻ:** Chúng tôi không lưu trữ thông tin thanh toán như số thẻ hoặc các thông tin nhạy cảm khác trên máy chủ của mình.\n\nNếu thanh toán không thành công hoặc có vấn đề phát sinh, vui lòng liên hệ với bộ phận hỗ trợ của chúng tôi qua ứng dụng.",
    deliveryHeader: "Giao Hàng",
    deliveryText: "Khi thanh toán cho các dịch vụ hoặc tài khoản mới, dịch vụ sẽ thường được kích hoạt trong vòng 1–5 phút. Nếu giao hàng bị chậm trễ do vấn đề kỹ thuật, chúng tôi sẽ thông báo cho bạn và kéo dài quyền truy cập tương ứng với thời gian dịch vụ không khả dụng.",
    cancellationHeader: "Quyền Hủy Bỏ",
    cancellationText: "Do chúng tôi chỉ bán các dịch vụ kỹ thuật số, quyền hủy bỏ theo Luật Hợp đồng từ xa không được áp dụng. Khi một dịch vụ đã được thanh toán và kích hoạt, hợp đồng được coi là ràng buộc.",
    warrantyHeader: "Khiếu Nại và Bảo Hành",
    warrantyText: "Trong ứng dụng, có một bảng hỗ trợ nơi bạn có thể gửi khiếu nại, đặt câu hỏi hoặc yêu cầu hỗ trợ. Chúng tôi cố gắng trả lời tất cả các yêu cầu trong vòng 24 giờ.\n\nNếu có vấn đề kỹ thuật ảnh hưởng đến chức năng của dịch vụ, chúng tôi chịu trách nhiệm giải quyết chúng trong thời gian sớm nhất có thể. Trong trường hợp gián đoạn nghiêm trọng, người dùng sẽ được bồi thường bằng cách gia hạn thời gian truy cập.",
    dataHeader: "Dữ Liệu Lưu Trữ",
    dataText: "Chúng tôi không lưu trữ dữ liệu cá nhân hoặc dữ liệu công thức được tạo bởi người dùng trên máy chủ của mình. Nếu bạn muốn xóa dữ liệu của mình, điều này có thể được thực hiện từ tài khoản của bạn bằng cách nhấp vào 'Xóa Dữ Liệu Của Tôi'. Điều này sẽ chỉ xóa dữ liệu cục bộ được lưu trữ trên thiết bị của bạn.",
    forceMajeureHeader: "Bất Khả Kháng",
    forceMajeureText: "Chúng tôi không chịu trách nhiệm về sự chậm trễ hoặc thiếu sót trong dịch vụ do các tình huống nằm ngoài tầm kiểm soát của chúng tôi, chẳng hạn như:\n- Thiên tai\n- Chiến tranh hoặc xung đột\n- Quyết định của chính phủ\n- Sự cố kỹ thuật lớn từ các nhà cung cấp của chúng tôi\n\nTrong trường hợp bất khả kháng, chúng tôi sẽ thông báo cho bạn sớm nhất có thể và tiếp tục cung cấp dịch vụ ngay khi tình hình cho phép.",
    disputeHeader: "Tranh Chấp",
    disputeText: "Trong trường hợp có tranh chấp giữa khách hàng và Hellström Trading i Göteborg AB, tranh chấp sẽ được giải quyết thông qua thương lượng trước tiên. Nếu không thể đạt được thỏa thuận, tranh chấp sẽ được giải quyết tại Tòa án Quận Göteborg theo luật pháp Thụy Điển.",
    personalDataHeader: "Dữ Liệu Cá Nhân",
    personalDataText: "Chúng tôi tuân thủ Quy định Bảo vệ Dữ liệu Chung (GDPR) và bảo vệ tất cả dữ liệu cá nhân theo các tiêu chuẩn bảo mật cao nhất.\n\n- **Thu thập:** Chúng tôi chỉ thu thập dữ liệu cần thiết để cung cấp dịch vụ, chẳng hạn như đăng ký tài khoản và lập hóa đơn.\n- **Lưu trữ:** Dữ liệu của bạn được lưu trữ an toàn trên các máy chủ của chúng tôi với Amazon Web Services (AWS).\n- **Sử dụng:** Dữ liệu cá nhân chỉ được sử dụng để cung cấp dịch vụ và không bao giờ được chia sẻ với bên thứ ba.\n\nBạn có quyền yêu cầu truy cập, chỉnh sửa hoặc xóa dữ liệu của mình bất kỳ lúc nào."
  }
},
it:{
  terms: {
    titleHeader: "Termini e Condizioni",
    titleText: "Versione 1.0 | Ultimo aggiornamento: 30-11-2024",
    generalHeader: "Generale",
    generalText: "I presenti termini e condizioni si applicano a tutti gli acquisti effettuati tramite Hellström Trading i Göteborg AB (Partita IVA: 556572-0413) sul sito www.live.bakesoft.se. Utilizzando i servizi del nostro sito web, accetti di rispettare questi termini. Tieni presente che potremmo aggiornare questi termini ed è tua responsabilità rimanere informato sulle modifiche più recenti.\n\nHellström Trading i Göteborg AB ha sede a Göteborg, Svezia, e tutte le transazioni e gli accordi sono regolati dalla legislazione svedese, in particolare dalla Legge sul Commercio Elettronico e dalla Legge sui Contratti a Distanza. Per ulteriori informazioni su queste leggi, visita il sito ufficiale dell'Agenzia Svedese per i Consumatori.\n\nUtilizzando www.live.bakesoft.se confermi che:\n1. Hai almeno 18 anni o hai il consenso di un genitore/tutore.\n2. Comprendi e accetti questi termini.",
    orderHeader: "Ordine",
    orderText: "Per effettuare un ordine su www.live.bakesoft.se, è necessario creare un account. Durante la registrazione, è richiesto l'acquisto di almeno un (1) credito per attivare il tuo account. I crediti vengono utilizzati per accedere ai nostri servizi digitali e fanno parte del nostro sistema di pagamento interno.\n\nIn caso di problemi con la registrazione o l'ordine, contatta il nostro supporto tramite l'applicazione o via e-mail.",
    creditsHeader: "Crediti (Token di Accesso)",
    creditsText: "- **Definizione:** I crediti sono token di accesso digitali memorizzati sui nostri server e utilizzati esclusivamente all'interno di www.live.bakesoft.se. I crediti non sono valuta e non possono essere scambiati con denaro o altre valute.\n- **Utilizzo:** I crediti possono essere utilizzati solo per il pagamento di servizi sulla nostra piattaforma e non possono essere trasferiti o rivenduti.\n- **Condizioni:** I crediti sono validi finché il tuo account è attivo. Se il tuo account viene disattivato o eliminato, i crediti inutilizzati perderanno la loro validità.",
    pricesHeader: "Prezzi",
    pricesText: "Tutti i prezzi includono l'imposta sul valore aggiunto (IVA) in base al paese in cui sei registrato, come specificato nelle impostazioni del tuo account.\n\nNon vengono applicati costi aggiuntivi. In caso di modifiche ai prezzi, queste verranno comunicate in anticipo tramite l'applicazione.",
    paymentHeader: "Pagamento",
    paymentText: "Collaboriamo con Stripe per elaborare i pagamenti e offriamo diverse modalità di pagamento a seconda del tuo paese e delle tue preferenze. I nostri partner di pagamento utilizzano le tecnologie più avanzate per garantire transazioni sicure.\n\n- **Dopo il pagamento:** Una volta elaborato il pagamento, il servizio richiesto verrà fornito automaticamente entro pochi minuti.\n- **Dati della carta:** Non memorizziamo sui nostri server informazioni sensibili come numeri di carta o altri dati di pagamento.\n\nIn caso di pagamento non riuscito o di problemi, contatta il nostro supporto tramite l'applicazione.",
    deliveryHeader: "Consegna",
    deliveryText: "Quando paghi per nuovi servizi o account, il servizio viene generalmente attivato entro 1-5 minuti. In caso di ritardi dovuti a problemi tecnici, ti informeremo e prolungheremo l'accesso per un periodo equivalente al tempo in cui il servizio non era disponibile.",
    cancellationHeader: "Diritto di Recesso",
    cancellationText: "Poiché vendiamo servizi digitali, non si applica il diritto di recesso ai sensi della Legge sui Contratti a Distanza. Una volta che un servizio è stato pagato e attivato, il contratto è considerato vincolante.",
    warrantyHeader: "Reclami e Garanzia",
    warrantyText: "All'interno dell'applicazione è disponibile un pannello di supporto dove puoi inviare reclami, porre domande o richiedere assistenza. Ci impegniamo a rispondere a tutte le richieste entro 24 ore.\n\nIn caso di problemi tecnici che influiscano sulla funzionalità del servizio, siamo responsabili della loro risoluzione il prima possibile. In caso di interruzioni significative, gli utenti verranno compensati con un'estensione dell'accesso.",
    dataHeader: "Dati Memorizzati",
    dataText: "Non memorizziamo dati personali o dati delle ricette generati dagli utenti sui nostri server. Se desideri eliminare i tuoi dati, puoi farlo tramite il tuo account cliccando su 'Elimina i miei dati'. Questo eliminerà solo i dati locali memorizzati sul tuo dispositivo.",
    forceMajeureHeader: "Forza Maggiore",
    forceMajeureText: "Non siamo responsabili per ritardi o carenze nei servizi causati da circostanze al di fuori del nostro controllo, come ad esempio:\n- Disastri naturali\n- Guerre o conflitti\n- Decisioni governative\n- Gravi guasti tecnici da parte dei nostri fornitori\n\nIn caso di forza maggiore, ti informeremo il prima possibile e riprenderemo i servizi non appena la situazione lo consentirà.",
    disputeHeader: "Controversie",
    disputeText: "In caso di controversia tra il cliente e Hellström Trading i Göteborg AB, la questione verrà inizialmente risolta tramite negoziazione. Se non è possibile raggiungere un accordo, la controversia verrà risolta presso il Tribunale Distrettuale di Göteborg, in conformità con la legge svedese.",
    personalDataHeader: "Dati Personali",
    personalDataText: "Rispettiamo il Regolamento Generale sulla Protezione dei Dati (GDPR) e proteggiamo tutti i dati personali secondo i più elevati standard di sicurezza.\n\n- **Raccolta:** Raccogliamo solo i dati necessari per fornire i servizi, come la registrazione dell'account e la fatturazione.\n- **Archiviazione:** I tuoi dati vengono archiviati in modo sicuro sui nostri server utilizzando Amazon Web Services (AWS).\n- **Utilizzo:** I dati personali vengono utilizzati esclusivamente per fornire i servizi e non vengono mai condivisi con terze parti.\n\nHai il diritto di richiedere l'accesso, la correzione o la cancellazione dei tuoi dati in qualsiasi momento."
  }
},
no:{
  terms: {
    titleHeader: "Vilkår og Betingelser",
    titleText: "Versjon 1.0 | Sist oppdatert: 30-11-2024",
    generalHeader: "Generelt",
    generalText: "Disse vilkårene gjelder for alle kjøp gjort gjennom Hellström Trading i Göteborg AB (Org.nr: 556572-0413) på nettsiden www.live.bakesoft.se. Ved å bruke tjenestene på vår nettside aksepterer du å følge disse vilkårene. Vær oppmerksom på at vi kan oppdatere disse vilkårene, og det er ditt ansvar å holde deg oppdatert på de siste endringene.\n\nHellström Trading i Göteborg AB har sitt hovedkontor i Göteborg, Sverige, og alle transaksjoner og avtaler er underlagt svensk lov, spesielt e-handelsloven og fjernsalgsloven. For mer informasjon om disse lovene, besøk den offisielle nettsiden til det svenske forbrukerombudet.\n\nVed å bruke www.live.bakesoft.se bekrefter du at:\n1. Du er minst 18 år gammel eller har samtykke fra foreldre/verge.\n2. Du forstår og aksepterer disse vilkårene.",
    orderHeader: "Bestilling",
    orderText: "For å gjøre en bestilling på www.live.bakesoft.se må du opprette en konto. Ved registrering må du kjøpe minst én (1) kreditt for å aktivere kontoen din. Kreditter brukes til å få tilgang til våre digitale tjenester og er en del av vårt interne betalingssystem.\n\nHvis du opplever problemer med registrering eller bestilling, vennligst kontakt vår kundeservice via applikasjonen eller e-post.",
    creditsHeader: "Kreditter (Tilgangstoken)",
    creditsText: "- **Definisjon:** Kreditter er digitale tilgangstoken som lagres på våre servere og kun brukes innenfor www.live.bakesoft.se. Kreditter er ikke valuta og kan ikke veksles til penger eller andre valutaer.\n- **Bruk:** Kreditter kan kun brukes til betaling for tjenester på vår plattform og kan ikke overføres eller videreselges.\n- **Vilkår:** Kreditter er gyldige så lenge kontoen din er aktiv. Hvis kontoen din deaktiveres eller slettes, vil ubrukte kreditter bli ugyldige.",
    pricesHeader: "Priser",
    pricesText: "Alle priser inkluderer merverdiavgift (MVA) basert på landet du er registrert i, som angitt i kontoinnstillingene dine.\n\nIngen ytterligere avgifter påløper. Eventuelle prisendringer vil bli kommunisert på forhånd via applikasjonen.",
    paymentHeader: "Betaling",
    paymentText: "Vi samarbeider med Stripe for å håndtere betalinger og tilbyr flere betalingsmetoder avhengig av ditt land og dine preferanser. Våre betalingspartnere bruker den nyeste teknologien for å sikre trygge transaksjoner.\n\n- **Etter betaling:** Når betalingen er behandlet, vil den forespurte tjenesten bli levert automatisk innen få minutter.\n- **Kortinformasjon:** Vi lagrer ikke betalingsdetaljer som kortnumre eller annen sensitiv informasjon på våre servere.\n\nHvis betalingen mislykkes eller problemer oppstår, vennligst kontakt vår kundeservice via applikasjonen.",
    deliveryHeader: "Levering",
    deliveryText: "Når du betaler for nye tjenester eller kontoer, aktiveres tjenesten vanligvis innen 1–5 minutter. Hvis det oppstår forsinkelser på grunn av tekniske problemer, vil vi informere deg og forlenge tilgangen tilsvarende den tiden tjenesten var utilgjengelig.",
    cancellationHeader: "Angrerett",
    cancellationText: "Siden vi kun selger digitale tjenester, gjelder ikke angreretten i henhold til fjernsalgsloven. Når en tjeneste er betalt og aktivert, anses avtalen som bindende.",
    warrantyHeader: "Reklamasjon og Garanti",
    warrantyText: "Innenfor applikasjonen finnes det et støttepanel hvor du kan sende inn klager, stille spørsmål eller be om hjelp. Vi streber etter å svare på alle forespørsler innen 24 timer.\n\nHvis tekniske problemer påvirker funksjonaliteten til tjenesten, er vi ansvarlige for å løse disse så snart som mulig. Ved større avbrudd vil brukerne bli kompensert med forlenget tilgang.",
    dataHeader: "Lagret Data",
    dataText: "Vi lagrer ingen personopplysninger eller brukeropprettede oppskriftsdata på våre servere. Hvis du ønsker å slette dataene dine, kan dette gjøres via kontoen din ved å klikke på 'Slett mine data'. Dette vil kun slette lokale data lagret på enheten din.",
    forceMajeureHeader: "Force Majeure",
    forceMajeureText: "Vi er ikke ansvarlige for forsinkelser eller mangler i tjenesten som skyldes omstendigheter utenfor vår kontroll, for eksempel:\n- Naturkatastrofer\n- Krig eller konflikter\n- Regjeringsbeslutninger\n- Store tekniske feil hos våre leverandører\n\nVed en force majeure-situasjon vil vi informere deg så snart som mulig og gjenoppta tjenestene når situasjonen tillater det.",
    disputeHeader: "Tvister",
    disputeText: "Ved en tvist mellom kunden og Hellström Trading i Göteborg AB skal tvisten først forsøkes løst gjennom forhandling. Hvis enighet ikke oppnås, skal tvisten avgjøres ved tingretten i Göteborg i henhold til svensk lov.",
    personalDataHeader: "Personopplysninger",
    personalDataText: "Vi overholder GDPR (General Data Protection Regulation) og beskytter alle personopplysninger i samsvar med de høyeste sikkerhetsstandardene.\n\n- **Innsamling:** Vi samler kun inn data som er nødvendige for å levere tjenester, for eksempel konto-registrering og fakturering.\n- **Lagring:** Dine data lagres sikkert på våre servere hos Amazon Web Services (AWS).\n- **Bruk:** Personopplysninger brukes utelukkende for å levere tjenester og deles aldri med tredjeparter.\n\nDu har rett til å be om tilgang til, korrigering eller sletting av dataene dine når som helst."
  }
},
fi:{
  terms: {
    titleHeader: "Käyttöehdot",
    titleText: "Versio 1.0 | Viimeksi päivitetty: 30-11-2024",
    generalHeader: "Yleistä",
    generalText: "Nämä käyttöehdot koskevat kaikkia Hellström Trading i Göteborg AB:n (Y-tunnus: 556572-0413) verkkosivuston www.live.bakesoft.se kautta tehtyjä ostoksia. Käyttämällä verkkosivustomme palveluja hyväksyt nämä ehdot. Huomaa, että voimme päivittää ehtoja, ja on sinun vastuullasi pysyä ajan tasalla muutoksista.\n\nHellström Trading i Göteborg AB sijaitsee Göteborgissa, Ruotsissa, ja kaikki liiketoimet ja sopimukset noudattavat Ruotsin lakia, erityisesti sähköisen kaupankäynnin lakia ja etämyyntilakia. Lisätietoja näistä laeista saat Ruotsin kuluttajaviraston virallisilta verkkosivuilta.\n\nKäyttämällä www.live.bakesoft.se-sivustoa vahvistat, että:\n1. Olet vähintään 18-vuotias tai sinulla on vanhemman/huoltajan lupa.\n2. Ymmärrät ja hyväksyt nämä ehdot.",
    orderHeader: "Tilaus",
    orderText: "Tilataksesi www.live.bakesoft.se-sivustolta sinun tulee luoda käyttäjätili. Rekisteröityessäsi sinun on ostettava vähintään yksi (1) krediitti tilin aktivoimiseksi. Krediittejä käytetään digitaalisten palveluidemme käyttämiseen ja ne ovat osa sisäistä maksujärjestelmäämme.\n\nJos kohtaat ongelmia rekisteröitymisen tai tilauksen kanssa, ota yhteyttä tukeemme sovelluksen tai sähköpostin kautta.",
    creditsHeader: "Krediitit (Pääsytunnisteet)",
    creditsText: "- **Määritelmä:** Krediitit ovat digitaalisia pääsytunnisteita, jotka tallennetaan palvelimillemme ja joita käytetään ainoastaan www.live.bakesoft.se-sivustolla. Krediitit eivät ole valuuttaa, eikä niitä voi vaihtaa rahaksi tai muihin valuuttoihin.\n- **Käyttö:** Krediittejä voidaan käyttää ainoastaan palveluiden maksamiseen alustallamme, eikä niitä voi siirtää tai jälleenmyydä.\n- **Ehdot:** Krediitit ovat voimassa niin kauan kuin käyttäjätilisi on aktiivinen. Jos tilisi suljetaan tai poistetaan, käyttämättömät krediitit mitätöityvät.",
    pricesHeader: "Hinnat",
    pricesText: "Kaikki hinnat sisältävät arvonlisäveron (ALV) rekisteröintimaasi mukaan, kuten käyttäjätilin asetuksissa on määritelty.\n\nMuita lisämaksuja ei peritä. Jos hintoja muutetaan, ilmoitamme tästä etukäteen sovelluksen kautta.",
    paymentHeader: "Maksu",
    paymentText: "Teemme yhteistyötä Stripen kanssa maksujen käsittelyssä ja tarjoamme useita maksutapoja maastasi ja mieltymyksistäsi riippuen. Maksukumppanimme käyttävät uusinta teknologiaa varmistaakseen turvalliset maksutapahtumat.\n\n- **Maksun jälkeen:** Kun maksu on käsitelty, pyydetty palvelu toimitetaan automaattisesti muutaman minuutin kuluessa.\n- **Korttitiedot:** Emme tallenna maksutietoja, kuten korttinumeroita tai muita arkaluonteisia tietoja, palvelimillemme.\n\nJos maksu epäonnistuu tai ilmenee ongelmia, ota yhteyttä tukeemme sovelluksen kautta.",
    deliveryHeader: "Toimitus",
    deliveryText: "Uusien palveluiden tai tilien maksamisen jälkeen palvelu aktivoidaan yleensä 1–5 minuutissa. Jos toimitus viivästyy teknisten ongelmien vuoksi, ilmoitamme sinulle ja jatkamme käyttöoikeuttasi viivästyksen ajaksi.",
    cancellationHeader: "Peruutusoikeus",
    cancellationText: "Koska myymme ainoastaan digitaalisia palveluita, peruutusoikeus ei koske ostoksia etämyyntilain mukaisesti. Kun palvelu on maksettu ja aktivoitu, sopimus katsotaan sitovaksi.",
    warrantyHeader: "Reklamaatiot ja Takuu",
    warrantyText: "Sovelluksessa on tukipaneeli, jossa voit tehdä reklamaatioita, esittää kysymyksiä tai pyytää apua. Pyrimme vastaamaan kaikkiin pyyntöihin 24 tunnin kuluessa.\n\nJos teknisiä ongelmia ilmenee ja ne vaikuttavat palvelun toimintaan, olemme vastuussa niiden korjaamisesta mahdollisimman nopeasti. Merkittävien häiriöiden sattuessa käyttäjiä hyvitetään pidentämällä käyttöoikeusaikaa.",
    dataHeader: "Tallennetut Tiedot",
    dataText: "Emme tallenna käyttäjien henkilökohtaisia tietoja tai käyttäjien luomia reseptitietoja palvelimillemme. Jos haluat poistaa tietosi, tämä voidaan tehdä tililtäsi napsauttamalla 'Poista tietoni'. Tämä poistaa vain laitteellesi tallennetut paikalliset tiedot.",
    forceMajeureHeader: "Ylivoimainen Este",
    forceMajeureText: "Emme ole vastuussa palveluiden viivästyksistä tai puutteista, jotka johtuvat hallintamme ulkopuolisista olosuhteista, kuten:\n- Luonnonkatastrofit\n- Sodat tai konfliktit\n- Hallituksen päätökset\n- Merkittävät tekniset viat palveluntarjoajiltamme\n\nYlivoimaisen esteen sattuessa ilmoitamme sinulle mahdollisimman pian ja jatkamme palveluiden toimittamista tilanteen salliessa.",
    disputeHeader: "Riitatilanteet",
    disputeText: "Jos asiakkaan ja Hellström Trading i Göteborg AB:n välille syntyy riitatilanne, asia pyritään ensisijaisesti ratkaisemaan neuvotteluin. Jos sovintoon ei päästä, riita ratkaistaan Göteborgin käräjäoikeudessa Ruotsin lain mukaisesti.",
    personalDataHeader: "Henkilötiedot",
    personalDataText: "Noudatamme yleistä tietosuoja-asetusta (GDPR) ja suojaamme kaikki henkilötiedot korkeimpien turvallisuusstandardien mukaisesti.\n\n- **Tietojen Kerääminen:** Keräämme vain tietoja, jotka ovat välttämättömiä palveluiden tarjoamiseksi, kuten käyttäjätilin rekisteröinti ja laskutus.\n- **Tallennus:** Tietosi tallennetaan turvallisesti Amazon Web Servicesin (AWS) palvelimille.\n- **Käyttö:** Henkilötietoja käytetään yksinomaan palveluiden tarjoamiseen, eikä niitä koskaan jaeta kolmansille osapuolille.\n\nSinulla on oikeus pyytää pääsyä tietoihisi, korjata niitä tai poistaa ne milloin tahansa."
  }
},
da:{
  terms: {
    titleHeader: "Vilkår og Betingelser",
    titleText: "Version 1.0 | Sidst opdateret: 30-11-2024",
    generalHeader: "Generelt",
    generalText: "Disse vilkår og betingelser gælder for alle køb, der foretages via Hellström Trading i Göteborg AB (Org.nr.: 556572-0413) på hjemmesiden www.live.bakesoft.se. Ved at bruge vores hjemmeside accepterer du at overholde disse vilkår. Bemærk, at vi kan opdatere disse vilkår, og det er dit ansvar at holde dig opdateret med de seneste ændringer.\n\nHellström Trading i Göteborg AB har hovedkontor i Göteborg, Sverige, og alle transaktioner og aftaler er underlagt svensk lovgivning, herunder e-handelsloven og fjernsalgsloven. For mere information om disse love, besøg den officielle hjemmeside for Forbrugerombudsmanden i Sverige.\n\nVed at bruge www.live.bakesoft.se bekræfter du, at:\n1. Du er mindst 18 år gammel eller har forældre-/værgesamtykke.\n2. Du forstår og accepterer disse vilkår.",
    orderHeader: "Bestilling",
    orderText: "For at foretage en bestilling på www.live.bakesoft.se skal du oprette en konto. Under registreringen skal du købe mindst én (1) kredit for at aktivere din konto. Kreditter bruges til at få adgang til vores digitale tjenester og er en del af vores interne betalingssystem.\n\nHvis du oplever problemer med registrering eller bestilling, bedes du kontakte vores support via applikationen eller e-mail.",
    creditsHeader: "Kreditter (Adgangstokens)",
    creditsText: "- **Definition:** Kreditter er digitale adgangstokens, der er lagret på vores servere og kun bruges på www.live.bakesoft.se. Kreditter er ikke valuta og kan ikke ombyttes til penge eller andre valutaer.\n- **Brug:** Kreditter kan kun bruges til at betale for tjenester på vores platform og må ikke overdrages eller videresælges.\n- **Betingelser:** Kreditter er gyldige, så længe din konto er aktiv. Hvis din konto deaktiveres eller slettes, vil ubrugte kreditter blive ugyldige.",
    pricesHeader: "Priser",
    pricesText: "Alle priser inkluderer moms baseret på det land, du er registreret i, som angivet i dine kontoinstillinger.\n\nDer påløber ingen ekstra gebyrer. Eventuelle prisændringer vil blive meddelt på forhånd via applikationen.",
    paymentHeader: "Betaling",
    paymentText: "Vi samarbejder med Stripe for at håndtere betalinger og tilbyder flere betalingsmetoder afhængigt af dit land og dine præferencer. Vores betalingspartnere bruger den nyeste teknologi for at sikre sikre transaktioner.\n\n- **Efter betaling:** Når betalingen er behandlet, vil den ønskede tjeneste automatisk blive leveret inden for få minutter.\n- **Kortoplysninger:** Vi gemmer ikke betalingsoplysninger som kortnumre eller andre følsomme oplysninger på vores servere.\n\nHvis betalingen mislykkes, eller der opstår problemer, bedes du kontakte vores support via applikationen.",
    deliveryHeader: "Levering",
    deliveryText: "Når du betaler for nye tjenester eller konti, aktiveres tjenesten normalt inden for 1–5 minutter. Hvis leveringen forsinkes på grund af tekniske problemer, vil vi informere dig og forlænge adgangen svarende til den tid, tjenesten ikke var tilgængelig.",
    cancellationHeader: "Fortrydelsesret",
    cancellationText: "Da vi kun sælger digitale tjenester, gælder fortrydelsesretten ikke i henhold til fjernsalgsloven. Når en tjeneste er betalt og aktiveret, betragtes aftalen som bindende.",
    warrantyHeader: "Reklamation og Garanti",
    warrantyText: "I applikationen er der et supportpanel, hvor du kan indsende klager, stille spørgsmål eller anmode om hjælp. Vi bestræber os på at besvare alle forespørgsler inden for 24 timer.\n\nHvis der opstår tekniske problemer, der påvirker tjenestens funktionalitet, er vi ansvarlige for at løse disse så hurtigt som muligt. Ved større afbrydelser vil brugere blive kompenseret med forlænget adgang.",
    dataHeader: "Gemte Data",
    dataText: "Vi gemmer ikke personlige data eller brugeroprettede opskriftsdata på vores servere. Hvis du ønsker at slette dine data, kan dette gøres via din konto ved at klikke på 'Slet mine data'. Dette vil kun slette lokale data, der er gemt på din enhed.",
    forceMajeureHeader: "Force Majeure",
    forceMajeureText: "Vi er ikke ansvarlige for forsinkelser eller mangler i tjenester, der skyldes omstændigheder uden for vores kontrol, såsom:\n- Naturkatastrofer\n- Krig eller konflikter\n- Regeringsbeslutninger\n- Større tekniske fejl hos vores leverandører\n\nI tilfælde af force majeure vil vi informere dig så hurtigt som muligt og genoptage tjenesterne, når situationen tillader det.",
    disputeHeader: "Tvister",
    disputeText: "I tilfælde af en tvist mellem kunden og Hellström Trading i Göteborg AB skal tvisten først forsøges løst gennem forhandling. Hvis en løsning ikke opnås, afgøres tvisten ved tingretten i Göteborg i henhold til svensk lovgivning.",
    personalDataHeader: "Persondata",
    personalDataText: "Vi overholder GDPR (Generel Forordning om Databeskyttelse) og beskytter alle personlige data i overensstemmelse med de højeste sikkerhedsstandarder.\n\n- **Indsamling:** Vi indsamler kun data, der er nødvendige for at levere tjenester, såsom kontoregistrering og fakturering.\n- **Opbevaring:** Dine data opbevares sikkert på vores servere hos Amazon Web Services (AWS).\n- **Brug:** Persondata bruges udelukkende til at levere tjenester og deles aldrig med tredjepart.\n\nDu har ret til at anmode om adgang til, rettelse af eller sletning af dine data til enhver tid."
  }
},
pl:{
  terms: {
    titleHeader: "Regulamin",
    titleText: "Wersja 1.0 | Ostatnia aktualizacja: 30-11-2024",
    generalHeader: "Ogólne",
    generalText: "Niniejszy regulamin obowiązuje przy wszystkich zakupach dokonanych za pośrednictwem Hellström Trading i Göteborg AB (Nr rejestracyjny: 556572-0413) na stronie www.live.bakesoft.se. Korzystając z usług naszej strony internetowej, akceptujesz niniejsze warunki. Pamiętaj, że regulamin może być aktualizowany, a Twoim obowiązkiem jest zapoznanie się z jego najnowszą wersją.\n\nHellström Trading i Göteborg AB ma siedzibę w Göteborgu w Szwecji, a wszystkie transakcje i umowy podlegają prawu szwedzkiemu, w szczególności ustawie o handlu elektronicznym i ustawie o sprzedaży na odległość. Więcej informacji na temat tych przepisów znajdziesz na oficjalnej stronie Urzędu Ochrony Konsumentów w Szwecji.\n\nKorzystając z www.live.bakesoft.se, potwierdzasz, że:\n1. Masz co najmniej 18 lat lub posiadasz zgodę rodzica/opiekuna.\n2. Rozumiesz i akceptujesz niniejsze warunki.",
    orderHeader: "Zamówienie",
    orderText: "Aby złożyć zamówienie na www.live.bakesoft.se, musisz założyć konto. W trakcie rejestracji wymagane jest zakupienie co najmniej jednego (1) kredytu, aby aktywować konto. Kredyty są wykorzystywane do uzyskania dostępu do naszych usług cyfrowych i stanowią część naszego wewnętrznego systemu płatności.\n\nW przypadku problemów z rejestracją lub zamówieniem skontaktuj się z naszym działem wsparcia za pośrednictwem aplikacji lub e-maila.",
    creditsHeader: "Kredyty (Tokeny Dostępu)",
    creditsText: "- **Definicja:** Kredyty to cyfrowe tokeny dostępu przechowywane na naszych serwerach, używane wyłącznie na stronie www.live.bakesoft.se. Kredyty nie są walutą i nie można ich wymieniać na pieniądze ani inne waluty.\n- **Zastosowanie:** Kredyty można wykorzystywać wyłącznie do opłacania usług na naszej platformie i nie mogą być przekazywane ani odsprzedawane.\n- **Warunki:** Kredyty są ważne tak długo, jak aktywne jest Twoje konto. W przypadku dezaktywacji lub usunięcia konta, niewykorzystane kredyty tracą ważność.",
    pricesHeader: "Ceny",
    pricesText: "Wszystkie ceny zawierają podatek VAT, obliczany na podstawie kraju, w którym jesteś zarejestrowany, zgodnie z ustawieniami Twojego konta.\n\nNie są naliczane żadne dodatkowe opłaty. W przypadku zmiany cen, informacje o tym zostaną przekazane z wyprzedzeniem za pośrednictwem aplikacji.",
    paymentHeader: "Płatność",
    paymentText: "Współpracujemy z firmą Stripe w celu przetwarzania płatności i oferujemy różne metody płatności w zależności od Twojego kraju i preferencji. Nasi partnerzy płatniczy korzystają z najnowszych technologii, aby zapewnić bezpieczeństwo transakcji.\n\n- **Po dokonaniu płatności:** Po przetworzeniu płatności, zamówiona usługa zostanie automatycznie dostarczona w ciągu kilku minut.\n- **Informacje o karcie:** Nie przechowujemy na naszych serwerach żadnych danych dotyczących płatności, takich jak numery kart czy inne poufne informacje.\n\nW przypadku niepowodzenia płatności lub problemów skontaktuj się z naszym działem wsparcia za pośrednictwem aplikacji.",
    deliveryHeader: "Dostawa",
    deliveryText: "Po dokonaniu płatności za nowe usługi lub konta, usługa jest zwykle aktywowana w ciągu 1–5 minut. W przypadku opóźnień wynikających z problemów technicznych poinformujemy Cię o tym i przedłużymy dostęp o czas równy okresowi niedostępności usługi.",
    cancellationHeader: "Prawo do odstąpienia od umowy",
    cancellationText: "Ponieważ sprzedajemy wyłącznie usługi cyfrowe, prawo do odstąpienia od umowy zgodnie z ustawą o sprzedaży na odległość nie ma zastosowania. Po opłaceniu i aktywacji usługi umowa jest uznawana za wiążącą.",
    warrantyHeader: "Reklamacje i Gwarancja",
    warrantyText: "W aplikacji znajduje się panel wsparcia, w którym możesz zgłaszać reklamacje, zadawać pytania lub prosić o pomoc. Staramy się odpowiadać na wszystkie zgłoszenia w ciągu 24 godzin.\n\nW przypadku problemów technicznych wpływających na funkcjonalność usługi ponosimy odpowiedzialność za ich rozwiązanie tak szybko, jak to możliwe. W przypadku poważnych zakłóceń użytkownicy otrzymają rekompensatę w postaci przedłużenia dostępu.",
    dataHeader: "Dane Przechowywane",
    dataText: "Nie przechowujemy danych osobowych ani danych przepisów generowanych przez użytkowników na naszych serwerach. Jeśli chcesz usunąć swoje dane, możesz to zrobić na swoim koncie, klikając 'Usuń moje dane'. Spowoduje to usunięcie tylko lokalnych danych zapisanych na Twoim urządzeniu.",
    forceMajeureHeader: "Siła Wyższa",
    forceMajeureText: "Nie ponosimy odpowiedzialności za opóźnienia lub niedostępność usług spowodowane okolicznościami pozostającymi poza naszą kontrolą, takimi jak:\n- Katastrofy naturalne\n- Wojny lub konflikty\n- Decyzje rządowe\n- Poważne awarie techniczne u naszych dostawców\n\nW przypadku wystąpienia siły wyższej poinformujemy Cię tak szybko, jak to możliwe, i wznowimy świadczenie usług, gdy tylko sytuacja na to pozwoli.",
    disputeHeader: "Spory",
    disputeText: "W przypadku sporu między klientem a Hellström Trading i Göteborg AB, spór powinien zostać rozwiązany w drodze negocjacji. Jeśli nie można osiągnąć porozumienia, spór zostanie rozstrzygnięty przez sąd rejonowy w Göteborgu zgodnie z prawem szwedzkim.",
    personalDataHeader: "Dane Osobowe",
    personalDataText: "Przestrzegamy ogólnego rozporządzenia o ochronie danych (RODO) i chronimy wszystkie dane osobowe zgodnie z najwyższymi standardami bezpieczeństwa.\n\n- **Zbieranie:** Zbieramy tylko dane niezbędne do świadczenia usług, takie jak rejestracja konta i fakturowanie.\n- **Przechowywanie:** Twoje dane są bezpiecznie przechowywane na naszych serwerach w Amazon Web Services (AWS).\n- **Wykorzystanie:** Dane osobowe są wykorzystywane wyłącznie w celu świadczenia usług i nigdy nie są udostępniane stronom trzecim.\n\nMasz prawo zażądać dostępu do swoich danych, ich poprawienia lub usunięcia w dowolnym momencie."
  }
},
nl:{
  terms: {
    titleHeader: "Algemene Voorwaarden",
    titleText: "Versie 1.0 | Laatst bijgewerkt: 30-11-2024",
    generalHeader: "Algemeen",
    generalText: "Deze algemene voorwaarden zijn van toepassing op alle aankopen die worden gedaan via Hellström Trading i Göteborg AB (Organisatienummer: 556572-0413) op de website www.live.bakesoft.se. Door gebruik te maken van de diensten op onze website, ga je akkoord met deze voorwaarden. Let op dat wij deze voorwaarden kunnen bijwerken, en het is jouw verantwoordelijkheid om op de hoogte te blijven van de laatste wijzigingen.\n\nHellström Trading i Göteborg AB is gevestigd in Göteborg, Zweden, en alle transacties en overeenkomsten vallen onder Zweeds recht, met name de wet op elektronische handel en de wet op verkoop op afstand. Voor meer informatie over deze wetten kun je de officiële website van de Zweedse Consumentenbond bezoeken.\n\nDoor gebruik te maken van www.live.bakesoft.se bevestig je dat:\n1. Je minimaal 18 jaar oud bent of toestemming hebt van een ouder/voogd.\n2. Je deze voorwaarden begrijpt en accepteert.",
    orderHeader: "Bestelling",
    orderText: "Om een bestelling te plaatsen op www.live.bakesoft.se, moet je een account aanmaken. Tijdens de registratie dien je minimaal één (1) credit aan te schaffen om je account te activeren. Credits worden gebruikt om toegang te krijgen tot onze digitale diensten en maken deel uit van ons interne betalingssysteem.\n\nAls je problemen ondervindt bij het registreren of bestellen, neem dan contact op met onze ondersteuning via de applicatie of e-mail.",
    creditsHeader: "Credits (Toegangstokens)",
    creditsText: "- **Definitie:** Credits zijn digitale toegangstokens die worden opgeslagen op onze servers en alleen binnen www.live.bakesoft.se kunnen worden gebruikt. Credits zijn geen valuta en kunnen niet worden ingewisseld voor geld of andere valuta.\n- **Gebruik:** Credits kunnen alleen worden gebruikt om diensten op ons platform te betalen en mogen niet worden overgedragen of doorverkocht.\n- **Voorwaarden:** Credits blijven geldig zolang je account actief is. Als je account wordt gedeactiveerd of verwijderd, verliezen ongebruikte credits hun geldigheid.",
    pricesHeader: "Prijzen",
    pricesText: "Alle prijzen zijn inclusief belasting over de toegevoegde waarde (btw) en worden berekend op basis van het land waarin je geregistreerd bent, zoals aangegeven in je accountinstellingen.\n\nEr worden geen extra kosten in rekening gebracht. Eventuele prijswijzigingen worden van tevoren via de applicatie gecommuniceerd.",
    paymentHeader: "Betaling",
    paymentText: "Wij werken samen met Stripe voor de verwerking van betalingen en bieden verschillende betaalmethoden aan, afhankelijk van jouw land en voorkeuren. Onze betalingspartners gebruiken de nieuwste technologie om veilige transacties te garanderen.\n\n- **Na betaling:** Zodra de betaling is verwerkt, wordt de gevraagde dienst binnen enkele minuten automatisch geleverd.\n- **Kaartgegevens:** Wij slaan geen betalingsgegevens, zoals kaartnummers of andere gevoelige informatie, op onze servers op.\n\nAls de betaling mislukt of er problemen optreden, neem dan contact op met onze ondersteuning via de applicatie.",
    deliveryHeader: "Levering",
    deliveryText: "Bij betaling voor nieuwe diensten of accounts wordt de dienst doorgaans binnen 1–5 minuten geactiveerd. Als de levering vertraging oploopt door technische problemen, zullen wij je hiervan op de hoogte stellen en de toegang verlengen met een periode gelijk aan de onbeschikbaarheid van de dienst.",
    cancellationHeader: "Herroepingsrecht",
    cancellationText: "Aangezien wij uitsluitend digitale diensten verkopen, is het herroepingsrecht niet van toepassing conform de wet op verkoop op afstand. Zodra een dienst is betaald en geactiveerd, wordt de overeenkomst als bindend beschouwd.",
    warrantyHeader: "Klachten en Garantie",
    warrantyText: "In de applicatie is een ondersteuningspaneel beschikbaar waar je klachten kunt indienen, vragen kunt stellen of om hulp kunt verzoeken. Wij streven ernaar om alle verzoeken binnen 24 uur te beantwoorden.\n\nAls er technische problemen zijn die de functionaliteit van de dienst beïnvloeden, zijn wij verantwoordelijk voor een snelle oplossing. Bij grote storingen worden gebruikers gecompenseerd met verlengde toegang.",
    dataHeader: "Opgeslagen Gegevens",
    dataText: "Wij slaan geen persoonlijke gegevens of door gebruikers gegenereerde receptgegevens op onze servers op. Als je jouw gegevens wilt verwijderen, kun je dit doen via je account door te klikken op 'Mijn gegevens verwijderen'. Dit verwijdert alleen lokale gegevens die op je apparaat zijn opgeslagen.",
    forceMajeureHeader: "Overmacht",
    forceMajeureText: "Wij zijn niet verantwoordelijk voor vertragingen of gebreken in de dienstverlening die het gevolg zijn van omstandigheden buiten onze controle, zoals:\n- Natuurrampen\n- Oorlogen of conflicten\n- Overheidsbeslissingen\n- Grote technische storingen bij onze leveranciers\n\nIn geval van overmacht zullen wij je zo snel mogelijk op de hoogte stellen en de diensten hervatten zodra de situatie dit toelaat.",
    disputeHeader: "Geschillen",
    disputeText: "In geval van een geschil tussen de klant en Hellström Trading i Göteborg AB, zal het geschil in eerste instantie via onderhandeling worden opgelost. Als er geen overeenkomst wordt bereikt, zal het geschil worden beslecht door de rechtbank van Göteborg, volgens Zweeds recht.",
    personalDataHeader: "Persoonsgegevens",
    personalDataText: "Wij voldoen aan de Algemene Verordening Gegevensbescherming (AVG) en beschermen alle persoonlijke gegevens volgens de hoogste veiligheidsnormen.\n\n- **Verzameling:** Wij verzamelen alleen gegevens die nodig zijn voor het leveren van diensten, zoals accountregistratie en facturering.\n- **Opslag:** Je gegevens worden veilig opgeslagen op onze servers met Amazon Web Services (AWS).\n- **Gebruik:** Persoonsgegevens worden uitsluitend gebruikt voor het leveren van diensten en worden nooit gedeeld met derden.\n\nJe hebt het recht om toegang te vragen tot je gegevens, deze te corrigeren of te verwijderen op elk gewenst moment."
  }
},
el:{
  terms: {
    titleHeader: "Algemene Voorwaarden",
    titleText: "Versie 1.0 | Laatst bijgewerkt: 30-11-2024",
    generalHeader: "Algemeen",
    generalText: "Deze algemene voorwaarden zijn van toepassing op alle aankopen die worden gedaan via Hellström Trading i Göteborg AB (Organisatienummer: 556572-0413) op de website www.live.bakesoft.se. Door gebruik te maken van de diensten op onze website, ga je akkoord met deze voorwaarden. Let op dat wij deze voorwaarden kunnen bijwerken, en het is jouw verantwoordelijkheid om op de hoogte te blijven van de laatste wijzigingen.\n\nHellström Trading i Göteborg AB is gevestigd in Göteborg, Zweden, en alle transacties en overeenkomsten vallen onder Zweeds recht, met name de wet op elektronische handel en de wet op verkoop op afstand. Voor meer informatie over deze wetten kun je de officiële website van de Zweedse Consumentenbond bezoeken.\n\nDoor gebruik te maken van www.live.bakesoft.se bevestig je dat:\n1. Je minimaal 18 jaar oud bent of toestemming hebt van een ouder/voogd.\n2. Je deze voorwaarden begrijpt en accepteert.",
    orderHeader: "Bestelling",
    orderText: "Om een bestelling te plaatsen op www.live.bakesoft.se, moet je een account aanmaken. Tijdens de registratie dien je minimaal één (1) credit aan te schaffen om je account te activeren. Credits worden gebruikt om toegang te krijgen tot onze digitale diensten en maken deel uit van ons interne betalingssysteem.\n\nAls je problemen ondervindt bij het registreren of bestellen, neem dan contact op met onze ondersteuning via de applicatie of e-mail.",
    creditsHeader: "Credits (Toegangstokens)",
    creditsText: "- **Definitie:** Credits zijn digitale toegangstokens die worden opgeslagen op onze servers en alleen binnen www.live.bakesoft.se kunnen worden gebruikt. Credits zijn geen valuta en kunnen niet worden ingewisseld voor geld of andere valuta.\n- **Gebruik:** Credits kunnen alleen worden gebruikt om diensten op ons platform te betalen en mogen niet worden overgedragen of doorverkocht.\n- **Voorwaarden:** Credits blijven geldig zolang je account actief is. Als je account wordt gedeactiveerd of verwijderd, verliezen ongebruikte credits hun geldigheid.",
    pricesHeader: "Prijzen",
    pricesText: "Alle prijzen zijn inclusief belasting over de toegevoegde waarde (btw) en worden berekend op basis van het land waarin je geregistreerd bent, zoals aangegeven in je accountinstellingen.\n\nEr worden geen extra kosten in rekening gebracht. Eventuele prijswijzigingen worden van tevoren via de applicatie gecommuniceerd.",
    paymentHeader: "Betaling",
    paymentText: "Wij werken samen met Stripe voor de verwerking van betalingen en bieden verschillende betaalmethoden aan, afhankelijk van jouw land en voorkeuren. Onze betalingspartners gebruiken de nieuwste technologie om veilige transacties te garanderen.\n\n- **Na betaling:** Zodra de betaling is verwerkt, wordt de gevraagde dienst binnen enkele minuten automatisch geleverd.\n- **Kaartgegevens:** Wij slaan geen betalingsgegevens, zoals kaartnummers of andere gevoelige informatie, op onze servers op.\n\nAls de betaling mislukt of er problemen optreden, neem dan contact op met onze ondersteuning via de applicatie.",
    deliveryHeader: "Levering",
    deliveryText: "Bij betaling voor nieuwe diensten of accounts wordt de dienst doorgaans binnen 1–5 minuten geactiveerd. Als de levering vertraging oploopt door technische problemen, zullen wij je hiervan op de hoogte stellen en de toegang verlengen met een periode gelijk aan de onbeschikbaarheid van de dienst.",
    cancellationHeader: "Herroepingsrecht",
    cancellationText: "Aangezien wij uitsluitend digitale diensten verkopen, is het herroepingsrecht niet van toepassing conform de wet op verkoop op afstand. Zodra een dienst is betaald en geactiveerd, wordt de overeenkomst als bindend beschouwd.",
    warrantyHeader: "Klachten en Garantie",
    warrantyText: "In de applicatie is een ondersteuningspaneel beschikbaar waar je klachten kunt indienen, vragen kunt stellen of om hulp kunt verzoeken. Wij streven ernaar om alle verzoeken binnen 24 uur te beantwoorden.\n\nAls er technische problemen zijn die de functionaliteit van de dienst beïnvloeden, zijn wij verantwoordelijk voor een snelle oplossing. Bij grote storingen worden gebruikers gecompenseerd met verlengde toegang.",
    dataHeader: "Opgeslagen Gegevens",
    dataText: "Wij slaan geen persoonlijke gegevens of door gebruikers gegenereerde receptgegevens op onze servers op. Als je jouw gegevens wilt verwijderen, kun je dit doen via je account door te klikken op 'Mijn gegevens verwijderen'. Dit verwijdert alleen lokale gegevens die op je apparaat zijn opgeslagen.",
    forceMajeureHeader: "Overmacht",
    forceMajeureText: "Wij zijn niet verantwoordelijk voor vertragingen of gebreken in de dienstverlening die het gevolg zijn van omstandigheden buiten onze controle, zoals:\n- Natuurrampen\n- Oorlogen of conflicten\n- Overheidsbeslissingen\n- Grote technische storingen bij onze leveranciers\n\nIn geval van overmacht zullen wij je zo snel mogelijk op de hoogte stellen en de diensten hervatten zodra de situatie dit toelaat.",
    disputeHeader: "Geschillen",
    disputeText: "In geval van een geschil tussen de klant en Hellström Trading i Göteborg AB, zal het geschil in eerste instantie via onderhandeling worden opgelost. Als er geen overeenkomst wordt bereikt, zal het geschil worden beslecht door de rechtbank van Göteborg, volgens Zweeds recht.",
    personalDataHeader: "Persoonsgegevens",
    personalDataText: "Wij voldoen aan de Algemene Verordening Gegevensbescherming (AVG) en beschermen alle persoonlijke gegevens volgens de hoogste veiligheidsnormen.\n\n- **Verzameling:** Wij verzamelen alleen gegevens die nodig zijn voor het leveren van diensten, zoals accountregistratie en facturering.\n- **Opslag:** Je gegevens worden veilig opgeslagen op onze servers met Amazon Web Services (AWS).\n- **Gebruik:** Persoonsgegevens worden uitsluitend gebruikt voor het leveren van diensten en worden nooit gedeeld met derden.\n\nJe hebt het recht om toegang te vragen tot je gegevens, deze te corrigeren of te verwijderen op elk gewenst moment."
  }
},
zh:{
  terms: {
    titleHeader: "条款和条件",
    titleText: "版本 1.0 | 最后更新：2024-11-30",
    generalHeader: "概述",
    generalText: "本条款和条件适用于通过 Hellström Trading i Göteborg AB（注册号：556572-0413）在网站 www.live.bakesoft.se 上进行的所有购买。使用我们网站的服务即表示您同意遵守这些条款。请注意，我们可能会更新这些条款，您有责任了解最新的更改。\n\nHellström Trading i Göteborg AB 位于瑞典哥德堡，所有交易和协议均受瑞典法律管辖，特别是《电子商务法》和《远程销售法》。有关这些法律的更多信息，请访问瑞典消费者局的官方网站。\n\n通过使用 www.live.bakesoft.se，您确认：\n1. 您已年满 18 岁或已获得父母/监护人的同意。\n2. 您理解并接受这些条款。",
    orderHeader: "订单",
    orderText: "要在 www.live.bakesoft.se 上下订单，您需要创建一个账户。在注册过程中，您必须至少购买一个（1）信用点以激活您的账户。信用点用于访问我们的数字服务，是我们内部支付系统的一部分。\n\n如果您在注册或下单时遇到问题，请通过应用程序或电子邮件联系我们的支持团队。",
    creditsHeader: "信用点（访问令牌）",
    creditsText: "- **定义：** 信用点是存储在我们服务器上的数字访问令牌，仅限于 www.live.bakesoft.se 内使用。信用点不是货币，不能兑换为现金或其他货币。\n- **用途：** 信用点仅用于支付平台上的服务，不能转让或转售。\n- **条件：** 信用点在您的账户处于活跃状态时有效。如果您的账户被停用或删除，未使用的信用点将失效。",
    pricesHeader: "价格",
    pricesText: "所有价格均包含增值税（VAT），根据您的账户设置中指定的注册国家计算。\n\n不会产生额外费用。如有价格变动，将通过应用程序提前通知。",
    paymentHeader: "付款",
    paymentText: "我们与 Stripe 合作处理付款，并根据您的国家和偏好提供多种付款方式。我们的支付合作伙伴使用最新技术以确保交易安全。\n\n- **付款后：** 一旦付款完成，所需服务将在几分钟内自动提供。\n- **卡信息：** 我们不会在服务器上存储付款信息，例如卡号或其他敏感信息。\n\n如果付款失败或出现问题，请通过应用程序联系我们的支持团队。",
    deliveryHeader: "交付",
    deliveryText: "支付新服务或账户后，服务通常会在 1-5 分钟内激活。如果由于技术问题导致交付延迟，我们会通知您，并根据服务不可用的时间延长访问期限。",
    cancellationHeader: "取消权",
    cancellationText: "由于我们仅销售数字服务，根据《远程销售法》不适用取消权。一旦服务已支付并激活，合同即被视为具有约束力。",
    warrantyHeader: "投诉和保证",
    warrantyText: "应用程序中提供了一个支持面板，您可以提交投诉、提出问题或请求帮助。我们努力在 24 小时内回复所有请求。\n\n如果出现影响服务功能的技术问题，我们将尽快解决。对于重大中断，用户将通过延长访问时间获得补偿。",
    dataHeader: "存储数据",
    dataText: "我们不会在服务器上存储用户的个人数据或用户生成的配方数据。如果您希望删除您的数据，可以通过您的账户点击“删除我的数据”来实现。这只会删除存储在您设备上的本地数据。",
    forceMajeureHeader: "不可抗力",
    forceMajeureText: "对于因我们无法控制的情况（例如：\n- 自然灾害\n- 战争或冲突\n- 政府决定\n- 我们供应商的重大技术故障）导致的服务延迟或不足，我们不承担责任。\n\n在不可抗力的情况下，我们会尽快通知您，并在条件允许时恢复服务。",
    disputeHeader: "争议",
    disputeText: "如果客户与 Hellström Trading i Göteborg AB 之间发生争议，应首先通过谈判解决。如果无法达成协议，争议应根据瑞典法律由哥德堡地区法院裁决。",
    personalDataHeader: "个人数据",
    personalDataText: "我们遵守《通用数据保护条例》（GDPR），并根据最高安全标准保护所有个人数据。\n\n- **收集：** 我们仅收集提供服务所需的数据，例如账户注册和开具发票。\n- **存储：** 您的数据通过 Amazon Web Services（AWS）安全存储在我们的服务器上。\n- **使用：** 个人数据仅用于提供服务，绝不会与第三方共享。\n\n您有权随时请求访问、更正或删除您的数据。"
  }
}
}  
  
export default terms;
  