import React,{useContext}from 'react';
import '../style/PaymentSuccess.scss';
import {MyContext} from '../live/default';

const PaymentSuccess = () => {
    let ctx=useContext(MyContext)
  const handleLoginClick = () => {
    ctx.closeCompletePageHandler();

  };

//   <div className="bsLiveAccountSetupText">{ctx.paySuccessText}</div>
//   <div className="bsLiveAccountSetupText">{ctx.yourAccountSetupDoneText}</div>
//   <div className="bsLiveAccountSetupText">{ctx.youcannowLoginWithCredentialsText}</div>
//   <div className="bsLiveAccountSetupText">{ctx.thankYouForJoiningUsText}</div>
  return (
    <div className="payment-success-container">
      <div className="payment-success-card">
        <div className="payment-success-icon-wrapper">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            className="payment-success-check-icon"
          >
            <path d="M20 6 9 17l-5-5" />
          </svg>
        </div>

        <h1 className="payment-success-title">{ctx.paymentSuccessfullText}</h1>

        <div className="payment-success-message-box">
          <p></p>{ctx.yourAccountSetupDoneText}
        </div>

        <div className="payment-success-login-section">
          <p>{ctx.youcannowLoginWithCredentialsText}</p>
          <button 
            onClick={handleLoginClick} 
            className="payment-success-login-button"
          >
        {ctx.gotToLoginText}
          </button>
        </div>

        <div className="payment-success-footer">
          <p>{ctx.thankYouForJoiningUsText}</p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess