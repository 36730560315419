import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Live from './components/live/default';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Live />} />
        <Route path="/returncall" element={<Live />} />
        {/* Add other routes if needed */}
      </Routes>
    </BrowserRouter>
 // </React.StrictMode>
);


// i need a route for this url
///receptid=238&userid=1

