import React, { useState, useEffect, useRef } from 'react';
import { Search, ChefHat, Loader2 } from 'lucide-react';
import { usePostData1 } from '../hooks/useGetData';
import '../style/SmartSearch.scss';
import LiveIcon from './LiveIcon';




// Custom debounce hook
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SmartSearchInput = ({ onSearch,setFetchRecipeIdHandler }) => {
 
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const suggestionRef = useRef(null);
  const debouncedSearchTerm = useDebounce(input, 300);
  const[errMessages,setErrMessages]=useState(null)

  // Fetch suggestions when the debounced term changes
  useEffect(() => {
    if (!debouncedSearchTerm.trim() || debouncedSearchTerm.trim().length < 2) {
      setSuggestions([]);
      return;
    }
    const cache = {}; // In-memory cache object

    const FetchSuggestions = async () => {
      const apiKey = process.env.REACT_APP_LIVEKEY;
      setIsLoading(true);
      try {
        const response = await usePostData1(
          'searchSuggestions',
          { search: debouncedSearchTerm },
          apiKey
        );

        const formattedSuggestions = response.data[0].map((item) => ({
          id: item.receptid, // Unique identifier for the suggestion
          type: item.best_benaemn, // Use `benaemn` for display
          live: item.live, // Use `live` for, display
          creatorId:item.creatorId
        }));
        if(response.status===200){
        setSuggestions(formattedSuggestions);
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      } finally {
        setIsLoading(false);
      }
    };

    FetchSuggestions();
  }, [debouncedSearchTerm]);

  // Hide suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearch = async (searchText = input) => {
    const searchQuery = {
      query: searchText,
      timestamp: new Date().toISOString(),
    };

    if (onSearch) {
      onSearch(searchQuery);
    }

    setSearchParams(searchQuery);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (suggestion,creatorId) => {
    console.log(suggestion)
    //setInput(suggestion.type); // Set input to the selected suggestion's type
   // handleSearch(suggestion.type);
    setFetchRecipeIdHandler(suggestion,creatorId);
    setShowSuggestions(false);
  };

  const SuggestionIcon = () => <ChefHat className="icon" />; // Simplified icon logic

  return (
    <div className="smart-search">
      <div className="search-container" ref={suggestionRef}>
        <input
          type="text"
          className="search-input"
          placeholder="Search recipes (e.g. 'pancakes' or 'pasta')"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            setShowSuggestions(true);
          }}
          onFocus={() => setShowSuggestions(true)}
          onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        />
        {isLoading ? (
          <Loader2 className="search-icon loading" />
        ) : (
         <div></div>
        )}

        {showSuggestions && (
          <div className="suggestions-container">
            {isLoading && suggestions.length === 0 && (
              <div className="loading-message">
                <Loader2 className="loading-icon" />
                Loading suggestions...
              </div>
            )}
            {!isLoading && suggestions.length === 0 && input.length >= 2 && (
              <div className="no-results">No recipes found</div>
            )}
            {suggestions.map((suggestion) => (
              <div
                key={suggestion.id}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(suggestion.id,suggestion.creatorId)}
              >
                <SuggestionIcon />&nbsp;
                {suggestion.live==1?<LiveIcon/>:<img src='bslogo.jpg' alt="bakesoftlogo" className="bslogoinLiveSearch"/>}
              
                &nbsp;
                <div className="suggestion-content">
                  <div className="suggestion-text">{suggestion.type}
                  </div>
                 
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {searchParams && (
        <div className="search-params">
          <h3 className="params-title">Search Query:</h3>
          <div className="params-content">
            <p>Query: {searchParams.query}</p>
            <p className="timestamp">
              Searched at: {new Date(searchParams.timestamp).toLocaleString()}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartSearchInput;




