

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../style/CookieWarning.scss';



const CookieWarning = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsOpen(false);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 1 }}
          className="cookie-warning"
        >
          <div className="cookie-warning__content">
            <h2 className="cookie-warning__title">Cookie Policy</h2>


<p className="cookie-warning__description">
  We use cookies to enhance your browsing experience, we also save your modified recipes and account data
  on your side, together with your settings.<br></br><br></br>

  We do not sell or transfer any data to third parties.<br></br>
  We use no advertising or tracking cookies. We strive to 
  keep your data safe and secure.
  By clicking "Accept," you consent to our use of cookies.
</p>
            <div className="cookie-warning__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64"
                height="64"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5" />
                <path d="M8.5 8.5v.01" />
                <path d="M16 15.5v.01" />
                <path d="M12 12v.01" />
                <path d="M11 17v.01" />
                <path d="M7 14v.01" />
              </svg>
            </div>
            <div className="cookie-warning__actions">
              <button className="cookie-warning__button cookie-warning__button--primary" onClick={handleAccept}>
                Accept
              </button>
              {/* <a href="/cookie-policy" className="cookie-warning__button cookie-warning__button--secondary">
                Manage Preferences
              </a> */}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieWarning;

