


const AppError = {
    se: {
    300001: "Kan inte logga in dig med dessa uppgifter, försök igen",
    300002: "Kan inte genomföra betalning, försök igen eller kontakta support",
    300003: "Kan inte skapa bildlänk, försök igen",
    300004: "Kan inte uppdatera likes på server, försök senare",
    300005: "Kan inte uppdatera sedda recept på server, försök senare",
    300006: "Kan inte skapa bookmark på server, försök senare",
    300007: "Kan inte skapa bookmark på server, försök senare",
    300008: "Kan inte hämta kaloriekoefficient, försök senare",
    300009: "Kan inte skapa konto på server, försök senare",
    300010: "Kan inte creditera kund sina credits på server,kontakta support",
    300011: "Ni har redan konto på www.bakesoft.se eller så kan vi inte skapa konto med denna email adress, försök med en annan eller kontakta support",
    300012: "Password matchar inte, försök igen",
    300013:"Kan inte skap konto på server, kontakta support",
    300014:"Betalningsdetaljer kan inte verifieras, kontakta support",
    300015: "kan inte översätta recept, försök senare",
    300016: "Kan inte uppdatera språk kod på server, försök senare",
    300017: "Sökgränsen är nådd, försök igen 1 min senare",
    300018: "Detta recept är endast tillgängligt för användare på www.bakesoft.se,titta efter ikonen live",
    300019: "Kan inte uppdatera lösenord på server!",
    300020: "Emailadressen är inte korrekt, försök igen",
    300021: "Kan inte hitta denna emailadress på server!",
    300022: "Kan inte hämta kock röster från server!",
    300023:"Kan inte hämta kock profil på server!",
    300024:"Max gräns för uppnådd, försök senare",
    300025:"Kan inte hämta autentisering",
    300026:"För många hämtningar, försök senare lite senare",
    
    errMenu:{
        felkod: "felkod:",
        btnMessage: "Stäng",
        
    }
    },

    en: {
        300001: "Cannot log you in with theese credentials, try again",
        300002: "Payment for the recipe iúnlock cannot be done, try again later or contact support",
        300003: "Cannot create image link, try again",
        300004: "Cannot update likes on server, try again later",
        300006: "Cannot update viewed recipes on server, try again later",
        300007: "Cannot create bookmark on server, try again later",
        300008: "Cannot fetch calorie coefficient, try again later",
        300009: "Cannot create account on server, try again later",
        300010: "Cannot credit customer credits on server, contact support",
        300011: "Account cannot be created with this email address, try another",
        300012: "Password does not match, try again",
        300013: "Cannot create account on server, contact support",
        300014:"Payment details cannot be verified,contact support",
        300015: "Cannot translate recipe, try again later",
        300016: "Cannot update language code on server, try again later",
        300017: "Limit for search is reached, try again 1 min later",
        300018: "This recipe is only available for users at www.bakesoft.se,look for the live icon",
        300019: "Cannot update password on server!",
        300020: "Email address is not correct, try again",
        300021: "Cannot find this email address on server!",
        300022: "Cannot fetch chef votes from server!",
        300023:"Cannot fetch chef profile from server!",
        300024:"Max limit reached, try again later",
        300025:"Cannot fetch authentication",
        300026:"Too many fetches, try again later",
        errMenu: {
            felkod: "error code:",
            btnMessage: "Close",
        }
    },

    ja: {
        300001: "これらの認証情報ではログインできません。もう一度お試しください。",
        300002: "レシピの支払いは完了できませんでした。後で再試行するか、サポートにお問い合わせください",
        300003: "画像リンクを作成できません。もう一度お試しください。",
        300004: "サーバー上のいいねを更新できません。後で再試行してください。",
        300005: "サーバー上の閲覧されたレシピを更新できません。後で再試行してください。",
        300006: "サーバー上でブックマークを作成できません。後で再試行してください。",
        300007: "サーバー上でブックマークを作成できません。後で再試行してください。",
        300008: "カロリー係数を取得できません。後で再試行してください。",
        300009: "サーバー上でアカウントを作成できません。後で再試行してください。",
        300010: "サーバー上で顧客のクレジットを付与できません。サポートにお問い合わせください",
        300011: "このメールアドレスでアカウントを作成できません。別のものをお試しください。", 
        300012: "パスワードが一致しません。もう一度お試しください。",
        300013: "サーバー上でアカウントを作成できません。サポートにお問い合わせください",
        300014:"支払い詳細を確認できませんでした。サポートにお問い合わせください",
        300015: "レシピを翻訳できません。後で再試行してください",
        300016: "サーバー上で言語コードを更新できません。後で再試行してください",
        300017: "検索の制限に達しました。1分後にもう一度お試しください",
        300018: "このレシピはwww.bakesoft.seのユーザーのみが利用できます。ライブアイコンを探してください",
        300019: "サーバー上でパスワードを更新できません！",
        300020: "メールアドレスが正しくありません。もう一度お試しください",
        300021: "このメールアドレスをサーバーで見つけることができません！",
        300022: "サーバーからシェフの投票を取得できません！",
        300023:"サーバーからシェフのプロフィールを取得できません！",
        300024:"最大制限に達しました。後で再試行してください",
        300025:"認証情報を取得できません",
        300026:"フェッチが多すぎます。後で再試行してください",
       
       
        errMenu: {
            felkod: "エラーコード:",
            btnMessage: "閉じる",
        }
    }
}



export default AppError;