import React, { useState,useContext } from "react";
import "../style/AnimatedChef.scss";
import chefImage from "../assets/images/busychefsmall.jpg";
import { MyContext } from '../live/default';
const AnimatedChef = () => {
  let ctx=useContext(MyContext)
  const [vanish, setVanish] = useState(false);

  const handleClick = () => {
    setVanish(true); // Trigger the vanish animation on click
  };

  return (
    <div
      className={`chef-container ${vanish ? "vanish" : ""}`}
      onClick={handleClick}
    >
      {/* Chef Image with Modern Styled Text */}
      <div className="chef-image-wrapper">
        {/* Modern Styled Text */}
        <div className="animated-text">
          <span>{ctx.welcomeToBakesoftText1}</span>
          <div className="animated-text1">
          <span>{ctx.proRecipesText}</span><br></br>
          <div className="animated-text2">
          <span>{ctx.updaterecipeText}</span><br></br>
          </div>
          <div className="animated-text3">
          <span>{ctx. nutritionalText}</span><br></br>
          </div>
          <span>{ctx.signUpAndBonusText}</span><br></br>
          
          </div>
          
        </div>
        {/* Chef Image */}
        <img className="chef-image" src={chefImage} alt="Busy Chef" />
      </div>
    </div>
  );
};

export default AnimatedChef;




