import { useCallback } from "react";

function useFormatViews() {
  const formatViews = useCallback((views) => {
    if (views >= 1_000_000) {
      return (views / 1_000_000).toFixed(1) + "M"; // E.g., 1.2M for 1,200,000
    } else if (views >= 100_000) {
      return Math.floor(views / 1_000) + "k"; // E.g., 150k for 150,000
    } else if (views >= 10_000) {
      return Math.floor(views / 1_000) + "k"; // E.g., 10k for 10,500
    } else if (views >= 1_000) {
      return (views / 1_000).toFixed(1) + "k"; // E.g., 1.2k for 1,200
    } else {
      return views.toString(); // For values below 1,000
    }
  }, []);

  return { formatViews };
}

export default useFormatViews;


