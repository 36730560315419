import React, { useState, useEffect, useRef } from 'react';
import { Timer, Pause, Play, RefreshCw, Bell, Plus, Minus } from 'lucide-react';
import '../style/CoockingTimer.scss';
import timer from '../assets/sound/timer.mp3';

const CookingTimer = (props) => {
  let{kitchenTimerHandler} = props;
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    let interval;
    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime(prevTime => {
          if (prevTime <= 1) {
            setIsRunning(false);
            setIsComplete(true);
            // Play sound when timer completes
            if (audioRef.current) {
              audioRef.current.currentTime = 0;
              audioRef.current.play();
            }
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, time]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const startTimer = () => {
    if (time === 0) {
      setTime(minutes * 60);
    }
    setIsRunning(true);
    setIsComplete(false);
  };

  const CloseTimer = () => {
    kitchenTimerHandler();
  }


  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resetTimer = () => {
    // Stop the audio if it's playing
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    
    setTime(0);
    setMinutes(0);
    setIsRunning(false);
    setIsComplete(false);
  };

  const incrementMinutes = () => {
    setMinutes(prev => prev + 1);
  };

  const decrementMinutes = () => {
    setMinutes(prev => Math.max(0, prev - 1));
  };

  const quickPresets = [
    { label: '1 min', seconds: 60 },
    { label: '5 min', seconds: 300 },
    { label: '10 min', seconds: 600 },
  ];

  const stopAlarm = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setIsComplete(false);
  };

  return (
    <div className="cooking-timer">
      {/* Audio element for timer sound */}
      <audio 
        ref={audioRef} 
        src={timer}
        loop
      />
      <div className="cookintTimerCloseWrapper">
             <div className="cookingTimerClose" onClick={()=>CloseTimer()}>✖</div>
      </div>
      <div className="cooking-timer__header">
        <Timer className="cooking-timer__icon" size={24} />
        <h2 className="cooking-timer__title">Cooking Timer</h2>
      </div>

      <div className="cooking-timer__minutes-control">
        <button 
          onClick={decrementMinutes} 
          className="cooking-timer__minute-btn cooking-timer__minute-btn--decrement"
        >
          <Minus size={20} />
        </button>
        <div className="cooking-timer__minute-display">
          {minutes} min
        </div>
        <button 
          onClick={incrementMinutes} 
          className="cooking-timer__minute-btn cooking-timer__minute-btn--increment"
        >
          <Plus size={20} />
        </button>
      </div>

      <div className={`cooking-timer__display ${isComplete ? 'cooking-timer__display--complete' : ''}`}>
        {formatTime(time)}
        {isComplete && (
          <Bell className="cooking-timer__bell" size={36} />
        )}
      </div>

      <div className="cooking-timer__controls">
        {!isRunning ? (
          <button 
            onClick={startTimer} 
            disabled={minutes === 0}
            className={`cooking-timer__btn cooking-timer__btn--start ${minutes === 0 ? 'cooking-timer__btn--disabled' : ''}`}
          >
            <Play size={24} />
          </button>
        ) : (
          <button 
            onClick={pauseTimer} 
            className="cooking-timer__btn cooking-timer__btn--pause"
          >
            <Pause size={24} />
          </button>
        )}
        <button 
          onClick={resetTimer} 
          className="cooking-timer__btn cooking-timer__btn--reset"
        >
          <RefreshCw size={24} />
        </button>
      </div>

      {isComplete && (
        <div className="cooking-timer__alarm">
          <button 
            onClick={stopAlarm}
            className="cooking-timer__alarm-stop"
          >
            Stop Alarm
          </button>
        </div>
      )}

      <div className="cooking-timer__presets">
        {quickPresets.map((preset) => (
          <button
            key={preset.seconds}
            onClick={() => {
              setMinutes(preset.seconds / 60);
              setTime(preset.seconds);
            }}
            className="cooking-timer__preset-btn"
          >
            {preset.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CookingTimer;