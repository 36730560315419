 import React, {useContext} from 'react'
import {MyContext} from '../live/default';
import { VscClose } from "react-icons/vsc";
import './default.css'
export default function LogoutUserOnly(){
    let ctx=useContext(MyContext)
  
    return(
      <div className="bsLiveLogouUserOnlyWrapper">
        <div className="bsLiveLogoutUserOnlyButton"
        onClick={()=>ctx.LogoutHandler()}
        >
  
          {ctx.loggedOutMessage?<div className="bsLiveLogoutOnlyButtonText">{ctx.youreLoggedOutText}</div>:<div className="bsLiveLogoutOnlyButtonText">{ctx.logoutOnlyText}</div>}
          <VscClose className="bsLivePaymentCloseIcon3"
                  onClick={() => ctx.closeLogoutHandler(false)}
                  />
        </div>
  
    </div>
  
  
    )
  }
