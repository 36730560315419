
import React, { useState, useRef, useEffect } from 'react'
import Draggable from 'react-draggable'


export default function UnlockButton({
  width = 200,
  height = 60,
  backgroundColor = 'black',
  textColor = 'white',
  sliderColor = 'silver',
  successColor = '#4CAF50',
  unlockText,
  unlockedText2,
  onUnlock = () => {},
}) {
  const [unlocked, setUnlocked] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const containerRef = useRef(null)
  const dragRef = useRef(null)



  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: 0 })
    if (containerRef.current && dragRef.current) {
      const containerWidth = containerRef.current.offsetWidth
      const dragWidth = dragRef.current.offsetWidth
      if (data.x >= containerWidth - dragWidth - 5) {
        setUnlocked(true)
        onUnlock()
      }
    }
  }

  const handleStop = () => {
    if (!unlocked) {
      setPosition({ x: 0, y: 0 })
    }
  }

  useEffect(() => {
    if (unlocked) {
      const timer = setTimeout(() => {
        setUnlocked(false)
        setPosition({ x: 0, y: 0 })
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [unlocked])

  return (
    <div className="unlock-button-container" ref={containerRef}>
      <Draggable
        axis="x"
        bounds="parent"
        position={position}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <div className="drag-button" ref={dragRef}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </div>
      </Draggable>
      <div className={`unlock-text ${unlocked ? 'unlocked' : ''}`}>
  {unlocked ? unlockedText2 : unlockText}
</div>
      <style jsx>{`
        .unlock-button-container {
          width: ${width}px;
          height: ${height-5}px;
          background-color: ${backgroundColor};
          border-radius: ${height / 2}px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          z-index:10;
        }

        .drag-button {
          width: ${height - 9}px;
          height: ${height - 9}px;
          background-color: ${sliderColor};
          border-radius: ${(height - 4) / 2}px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 2px;
          left: 2px;
          z-index: 10;
          cursor: grab;
        }

        .drag-button svg {
          width: ${height / 2}px;
          height: ${height / 2}px;
          color: ${backgroundColor};
        }

        .drag-button:active {
          cursor: grabbing;
        }

        .unlock-text {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${textColor};
          font-weight: bold;
          font-size: ${height / 2.9}px;
          user-select: none;
          transition: opacity 0.3s ease;
        }

        .unlock-text.unlocked {
          color: ${successColor};
        }

        @keyframes unlock-success {
          0% {
            background-color: ${backgroundColor};
          }
          50% {
            background-color: ${successColor};
          }
          100% {
            background-color: ${backgroundColor};
          }
        }

        .unlock-button-container:global(.react-draggable-dragging) .unlock-text {
          opacity: 0;
        }

        .unlock-button-container:has(.drag-button:global(.react-draggable-dragging)) {
          background-color: ${backgroundColor === 'black' ? '#333' : backgroundColor};
        }

        .unlock-button-container:has(.unlocked) {
          animation: unlock-success 1s ease;
        }
      `}</style>
    </div>
  )
}