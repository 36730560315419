
import axios from "axios";

  async function useReportErr(url, payload) {
    const apiKey=process.env.REACT_APP_ERRORKEY;
    const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/${url}`,{payload},{
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey},
        body: JSON.stringify(payload),
      Credentials: true
    });
  
    return result;
  }

  export default useReportErr;