
// import React from "react";
// import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
// import '../style/PieChart.scss';

// const NutrientPieChart = ({ nutrientData,getSizeVal }) => {
//   // Aggregate nutrient data
//   let protein = 0;
//   let carbohydrates = 0;
//   let fat = 0;

//   nutrientData.forEach((item) => {
//     switch (item.nutrient) {
//       case "Protein":
//         protein += parseFloat(item.total_quantity);
//         break;
//       case "Carbohydrate, by difference":
//         carbohydrates += parseFloat(item.total_quantity);
//         break;
//       case "Fatty acids, total monounsaturated":
//       case "Fatty acids, total polyunsaturated":
//       case "Fatty acids, total saturated":
//       case "Fatty acids, total trans":
//         fat += parseFloat(item.total_quantity);
//         break;
//       default:
//         break;
//     }
//   });
// //"#FF4500"
//   // Prepare data for the pie chart
//   const chartData = [
//     { name: "Fats", value: fat, color: "#FFD700" },
//     { name: "Protein", value: protein, color: "#A0522D" },
//     { name: "Carbohydrates", value: carbohydrates, color: "#FFA500" }
//   ];
//    let gadgetWidth
//    let gadgetHeight
//    let radiusVal
//  if(getSizeVal>2&&getSizeVal<5){
//   gadgetWidth = 500
//   gadgetHeight = 250
//   radiusVal = 100
//  }else if(getSizeVal>4){
//   gadgetWidth = 600
//   gadgetHeight = 360
//   radiusVal = 120
//   }else{
//   gadgetWidth = 400
//   gadgetHeight = 240
//   radiusVal = 80
//  }


//   return (
//     <PieChart width={gadgetWidth} height={gadgetHeight}>
//       <Pie
//         data={chartData}
//         dataKey="value"
//         nameKey="name"
//         cx="50%"
//         cy="50%"
//         outerRadius={radiusVal}
//         fill="#8884d8"
//       >
//         {chartData.map((entry, index) => (
//           <Cell key={`cell-${index}`} fill={entry.color} />
//         ))}
//       </Pie>
//       <Tooltip />
//       <Legend />
//     </PieChart>
//   );
// };

// export default NutrientPieChart;

import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";
import '../style/PieChart.scss';

const NutrientPieChart = ({ nutrientData, getSizeVal }) => {
  // Aggregate nutrient data
  let protein = 0;
  let carbohydrates = 0;
  let fat = 0;

  nutrientData.forEach((item) => {
    switch (item.nutrient) {
      case "Protein":
        protein += parseFloat(item.total_quantity);
        break;
      case "Carbohydrate, by difference":
        carbohydrates += parseFloat(item.total_quantity);
        break;
      case "Fatty acids, total monounsaturated":
      case "Fatty acids, total polyunsaturated":
      case "Fatty acids, total saturated":
      case "Fatty acids, total trans":
        fat += parseFloat(item.total_quantity);
        break;
      default:
        break;
    }
  });

  // Prepare data for the pie chart
  const chartData = [
    { name: "Fats", value: fat, color: "#FFD700", unit: "grams" },
    { name: "Protein", value: protein, color: "#A0522D", unit: "grams" },
    { name: "Carbohydrates", value: carbohydrates, color: "#FFA500", unit: "grams" },
  ];

  let gadgetWidth;
  let gadgetHeight;
  let radiusVal;
  if (getSizeVal > 2 && getSizeVal < 5) {
    gadgetWidth = 500;
    gadgetHeight = 250;
    radiusVal = 100;
  } else if (getSizeVal > 4) {
    gadgetWidth = 600;
    gadgetHeight = 360;
    radiusVal = 120;
  } else {
    gadgetWidth = 400;
    gadgetHeight = 240;
    radiusVal = 80;
  }

  // Custom label for Pie chart (value with units)
  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6; // Position the label inside the slice
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white" // Adjust color for better visibility
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="12px"
        fontWeight="bold"
      >
        {`${chartData[index].value.toFixed(1)} ${chartData[index].unit}`}
      </text>
    );
  };

  return (
    <PieChart width={gadgetWidth} height={gadgetHeight}>
      <Pie
        data={chartData}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={radiusVal}
        fill="#8884d8"
        label={renderCustomLabel}
        labelLine={false} // Remove lines
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default NutrientPieChart;





