
import React,{useContext} from 'react';
import { ChefHat, DollarSign, Globe, Share2 } from 'lucide-react';
import '../style/BakesoftPromotion.scss';
import { VscClose } from "react-icons/vsc";
import { MyContext } from '../live/default';


const FeatureCard = ({ icon, title, description, }) => (
  <div className="feature-card">
    {icon}
    <div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </div>
);

const BakesoftPromotion = ({setShowJoinBakesoftAdd,liveSignupHandler,closeBakesoftPromotionHandler}) => {

let ctx=useContext(MyContext)
    const handleClick = () => {
        // Navigate to the desired route or external link
        window.location.href = "https://www.bakesoft.se/signup?signupbutton=2"; // For an external link
        // navigate("/your-route"); // Use this for internal routing
      };

  return (
    <div className="bakesoft-signup">
        <div className="bakesoftSignupCloseIcon"
       onClick={()=>closeBakesoftPromotionHandler()}
        ><VscClose/></div>
      <header>
        <h1>{ctx.welcomeToBakesoftText}</h1>
        <p>{ctx.unlockWorldOfCulinaryCreativityText}</p>
      </header>

      <section className="main-content">
        <div className="discover-recipes">
          <h2>{ctx.discoverAmazingRecipesText}</h2>
          <p>{ctx.liveBakesoftText}</p>
          
          <div className="features-grid">
            <FeatureCard
              icon={<DollarSign className="icon" />}
              title={ctx.affordableAccessHeader}
              description={ctx.affordableAccessText}
            />
            <FeatureCard
              icon={<ChefHat className="icon" />}
              title={ctx.customizationHeader}
              description={ctx.customizationText}
            />
            <FeatureCard
              icon={<Share2 className="icon" />}
              title={ctx.shareandConnectHeader}
              description={ctx.shareConnectText}
            />
            <FeatureCard
              icon={<Globe className="icon" />}
              title={ctx.globalCuisineHeader}
              description={ctx.globalCuisineText}
            />
          </div>
          
          <button className="cta-button"
          onClick={() =>liveSignupHandler()}
          >{ctx.signUpNowText}</button>
        </div>

        <div className="recipe-creators">
          <h2>{ctx.forRecipeCreatorsText}</h2>
          <p>{ctx.createRecipesEarnMoneyText}</p>
          
          <p>{ctx.advancedRecipeManagementText}</p>
          
          <ul>
            <li>{ctx.setYourPriceText}</li>
            <li>{ctx.trackEarningsText}</li>
            <li>{ctx.usersResponsibleText}</li>
          </ul>
          
          <button className="cta-button"
            onClick={() =>handleClick()}
          >{ctx.startCreatingText}</button>
        </div>
      </section>

      <footer>
        <p>{ctx.readMoreInTermsText}</p>
      </footer>
    </div>
  );
};

export default BakesoftPromotion;

