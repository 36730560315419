
import React, { useState, useEffect, useRef } from 'react';
import '../style/ScrollLimitMessage.scss';
import { MdDoneOutline } from "react-icons/md";
import { VscClose } from "react-icons/vsc";

const UserPlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <line x1="19" y1="8" x2="19" y2="14" />
    <line x1="22" y1="11" x2="16" y2="11" />
  </svg>
);

const LogInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
    <polyline points="10 17 15 12 10 7" />
    <line x1="15" y1="12" x2="3" y2="12" />
  </svg>
);

const ScrollLimitMessage = (props) => {
    const {setShowSignUp,setShowLoginByUserSelectChoice} = props;
  const [showMessage, setShowMessage] = useState(false);
  const targetRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setShowMessage(entry.isIntersecting);
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  if (!showMessage) {
    return <div ref={targetRef} className="h-1 w-full" />;
  }

const loginHandler = () => {
    setShowSignUp(false);
    setShowMessage(false);
    setShowLoginByUserSelectChoice(true);
}

const signUpHandler=()=>{
    setShowSignUp(true);
    setShowMessage(false);
}




  return (
    <>
      <div ref={targetRef} className="h-1 w-full" />
      <div className="scroll-limit-message slide-up">
        <div className="card">
          <div className="card__icon"
          onClick={()=>setShowMessage(false)}
          >
            <VscClose className="scrollLimitCloseIcon" /></div>
          <div className="card__header">
            <h2>Unlock Full Recipe Access</h2>
          </div>
          <div className="card__content">
            <p>Sign up or log in to explore our complete collection of delicious recipes.</p>
          </div>
          <div className="card__footer">
            <button className="button button--primary"
            onClick={()=>signUpHandler()}
            >
              <UserPlusIcon />
              Sign Up
            </button>
            <button className="button button--secondary"
            onClick={()=>loginHandler()}>
              <LogInIcon />
              Log In
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScrollLimitMessage;