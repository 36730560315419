import React, { useState, useEffect } from "react";
import { Star } from "lucide-react";
import "../style/ChefPresentation.scss";
import { VscClose } from "react-icons/vsc";

const ChefProfile = ({ setVotenum, chefProfileData,setShowChefProfile }) => {
  const [chef, setChef] = useState(null);
//let completeImageUrl = process.env.REACT_APP_CREATORCLOUDFRONT_URL +'/'+ creatorProfileImage;
  useEffect(() => {
    if (chefProfileData?.data?.profile) {
      const { fullName, benaemn } = chefProfileData.data.profile;
      const { sumRating, numRating,image } = chefProfileData.data;
      let completeImageUrl = process.env.REACT_APP_CREATORCLOUDFRONT_URL +'/'+ image;
      setChef({
        name: fullName || "Unknown Chef", // Fallback if no name
        image: completeImageUrl|| "/api/placeholder/200/200", // Assuming image is static
        message: benaemn || "No description available.", // Fallback message
        rating: sumRating || 0, // Fallback rating
        totalRatings: numRating || 0, // Fallback total ratings
      });
    }
  }, [chefProfileData]);

  const [userRated, setUserRated] = useState(false);

  const handleRating = (userRating) => {
    setVotenum(userRating);
    if (userRated) return;

    const newTotalRatings = chef.totalRatings + 1;
    const newRating =
      (chef.rating * chef.totalRatings + userRating) / newTotalRatings;

    setChef({
      ...chef,
      rating: newRating,
      totalRatings: newTotalRatings,
    });

    setUserRated(true);
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <Star
          key={i}
          size={20}
          className={i < Math.floor(chef.rating) ? "star-filled" : "star-empty"}
          onClick={() => handleRating(i + 1)}
          style={{ cursor: userRated ? "not-allowed" : "pointer" }}
        />
      );
    }
    return stars;
  };

  return (
    chef && (
        <>
          <section className="chef-profile">
          <div className="chefRatingCloseButton"
        onClick={()=>setShowChefProfile(false)}
        >
              <VscClose  className="chefRatingCloseIcon"/>
          </div>
                  <div className="profile-image">
                      <img src={chef.image} alt={chef.name} />
                  </div>

                  <div className="profile-content">
                      <h2>Chef - {chef.name}</h2>

                      <div className="rating">
                          <div className="stars">{renderStars()}</div>
                          <span>
                              {Number(chef.rating || 0).toFixed(1)} •{" "}
                              {chef.totalRatings.toLocaleString()} reviews
                          </span>
                      </div>

                      <p>{chef.message}</p>
                  </div>
              </section></>
    )
  );
};

export default ChefProfile;

