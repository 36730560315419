
import React from "react";
import "../style/Terms.css";
import { VscClose } from "react-icons/vsc";

const TermsComponent = ({Terms,
    setShowTerms
}) => {
    console.log(Terms)
  if (!Terms) {
    return <p className="error-message">Terms for the specified country code are not available.</p>;
  }

  const {
    titleHeader,
    titleText,
    generalHeader,
    generalText,
    orderHeader,
    orderText,
    creditsHeader,
    creditsText,
    pricesHeader,
    pricesText,
    paymentHeader,
    paymentText,
    deliveryHeader,
    deliveryText,
    cancellationHeader,
    cancellationText,
    warrantyHeader,
    warrantyText,
    dataHeader,
    dataText,
    forceMajeureHeader,
    forceMajeureText,
    disputeHeader,
    disputeText,
    personalDataHeader,
    personalDataText,
  } = Terms.terms

  return (
    <div className="terms-container">
        <div className="terms-Wrapper">
        <div className="terms-close-icon"
        onClick={()=>setShowTerms(false)}
        >
            <VscClose className="terms-close-icon" />
        </div>
        </div>
      <h1 className="terms-title">{titleHeader}</h1>
      <p className="terms-version">{titleText}</p>

      <section className="terms-section">
        <h2 className="terms-header">{generalHeader}</h2>
        <p className="terms-text">{generalText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{orderHeader}</h2>
        <p className="terms-text">{orderText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{creditsHeader}</h2>
        <p className="terms-text">{creditsText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{pricesHeader}</h2>
        <p className="terms-text">{pricesText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{paymentHeader}</h2>
        <p className="terms-text">{paymentText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{deliveryHeader}</h2>
        <p className="terms-text">{deliveryText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{cancellationHeader}</h2>
        <p className="terms-text">{cancellationText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{warrantyHeader}</h2>
        <p className="terms-text">{warrantyText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{dataHeader}</h2>
        <p className="terms-text">{dataText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{forceMajeureHeader}</h2>
        <p className="terms-text">{forceMajeureText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{disputeHeader}</h2>
        <p className="terms-text">{disputeText}</p>
      </section>

      <section className="terms-section">
        <h2 className="terms-header">{personalDataHeader}</h2>
        <p className="terms-text">{personalDataText}</p>
      </section>
    </div>
  );
};

export default TermsComponent;


