
import { useCallback } from 'react';

/**
 * Custom hook to obfuscate text by replacing parts with a placeholder.
 *
 * @returns {function} obfuscateText - A function to obfuscate text.
 */
export const useTextObfuscator = () => {
  const obfuscateText = useCallback(
    (text, percentageToObfuscate = 0.5, placeholder = 'x') => {
      const words = text.split(' ');
      const totalWords = words.length;
      const wordsToObfuscateCount = Math.ceil(totalWords * percentageToObfuscate);

      // Randomly select words to obfuscate
      const indicesToObfuscate = new Set();
      while (indicesToObfuscate.size < wordsToObfuscateCount) {
        const randomIndex = Math.floor(Math.random() * totalWords);
        indicesToObfuscate.add(randomIndex);
      }

      // Replace selected words with the placeholder
      const obfuscatedWords = words.map((word, index) =>
        indicesToObfuscate.has(index)
          ? typeof placeholder === 'string'
            ? placeholder.repeat(word.length)
            : placeholder(word)
          : word
      );

      return obfuscatedWords.join(' ');
    },
    []
  );

  return { obfuscateText };
};
export default useTextObfuscator;