const aboutPageLanguages = {
    se: {
      common: {
        companyLiveTextName: "Hellström Trading i Göteborg AB",
        contactEmail: "support@bakesoft.se",
        contactPhone: "+46792143182",
        companyAddress: "Skenevägen 4, 51260 Överlida",
        aboutHeading: "Om Oss",
        informationText: `
          Hellström Trading i Göteborg AB är ett dynamiskt och innovativt mjukvaruföretag som skapar skalbara lösningar för internet. 
          Med fokus på prestanda, tillförlitlighet och anpassningsförmåga specialiserar vi oss på att bygga mjukvaruprodukter som stärker företag att växa och lyckas i en alltmer digital värld.
        `,
        additionalInformationText: `
          Vår expertis sträcker sig över webbutveckling, digital infrastruktur och användarcentrerade mjukvarulösningar. 
          Vi prioriterar att leverera banbrytande teknik som möter våra kunders unika behov och möjliggör framgång på konkurrensutsatta marknader.
        `,
        closingText: `
          Beläget i hjärtat av Sverige är vårt team passionerat för att använda moderna verktyg och tekniker för att lösa komplexa utmaningar. 
          Våra lösningar är utformade för att vara robusta och framtidssäkra, vilket säkerställer att våra kunder når sina mål och etablerar långsiktig framgång. 
          På Hellström Trading tror vi på att bygga starka partnerskap genom att tillhandahålla exceptionellt stöd och innovation anpassat till varje kunds behov.
        `,
        whoWeAreHeading: "Vilka vi är",
        whatWeDoHeading: "Vad vi gör",
        ourCommitmentHeading: "Vårt engagemang",
        contactUsHeading: "Kontakta oss",
      },
    },
    en: {
      common: {
        companyLiveTextName: "Hellström Trading i Göteborg AB",
        contactEmail: "support@bakesoft.se",
        contactPhone: "+46792143182",
        companyAddress: "Skenevägen 4, 51260 Överlida",
        aboutHeading: "About Us",
        informationText: `
          Hellström Trading i Göteborg AB is a dynamic and innovative software company dedicated to creating scalable solutions for the internet. 
          With a focus on performance, reliability, and adaptability, we specialize in building software products that empower businesses to grow and succeed in an increasingly digital world.
        `,
        additionalInformationText: `
          Our expertise spans web development, digital infrastructure, and user-centric software solutions. 
          We prioritize delivering cutting-edge technology that meets the unique needs of our clients, enabling them to thrive in competitive markets.
        `,
        closingText: `
          Based in the heart of Sweden, our team is passionate about leveraging modern tools and techniques to solve complex challenges. 
          Our solutions are designed to be robust and future-proof, ensuring our clients achieve their goals and establish long-term success. 
          At Hellström Trading, we believe in forging long-term partnerships by providing exceptional support and innovation tailored to your goals.
        `,
        whoWeAreHeading: "Who We Are",
        whatWeDoHeading: "What We Do",
        ourCommitmentHeading: "Our Commitment",
        contactUsHeading: "Contact Us",
        
      },
    },
    gb: {
      common: {
        companyLiveTextName: "Hellström Trading i Göteborg AB",
        contactEmail: "support@bakesoft.se",
        contactPhone: "+46792143182",
        companyAddress: "Skenevägen 4, 51260 Överlida",
        aboutHeading: "About Us",
        informationText: `
          Hellström Trading i Göteborg AB is a dynamic and innovative software company creating scalable solutions for the internet. 
          Focused on performance, reliability, and adaptability, we specialise in building software products that empower businesses to grow and succeed in an increasingly digital world.
        `,
        additionalInformationText: `
          Our expertise covers web development, digital infrastructure, and user-centric software solutions. 
          We prioritise cutting-edge technology to meet the unique needs of our clients, helping them thrive in competitive markets.
        `,
        closingText: `
          Located in the heart of Sweden, our team is passionate about using modern tools and techniques to solve complex challenges. 
          Our solutions are robust and future-proof, ensuring clients achieve their goals and establish lasting success. 
          At Hellström Trading, we build strong partnerships by providing exceptional support and tailored innovation.
        `,
        whoWeAreHeading: "Who We Are",
        whatWeDoHeading: "What We Do",
        ourCommitmentHeading: "Our Commitment",
        contactUsHeading: "Contact Us",
        
      },
    },
    fr: {
      common: {
        companyLiveTextName: "Hellström Trading i Göteborg AB",
        contactEmail: "support@bakesoft.se",
        contactPhone: "+46792143182",
        companyAddress: "Skenevägen 4, 51260 Överlida",
        aboutHeading: "À Propos",
        informationText: `
          Hellström Trading i Göteborg AB est une entreprise innovante spécialisée dans la création de solutions évolutives pour internet. 
          Axée sur la performance, la fiabilité et l'adaptabilité, notre équipe développe des produits logiciels qui aident les entreprises à se développer dans un monde numérique en constante évolution.
        `,
        additionalInformationText: `
          Notre expertise inclut le développement web, l'infrastructure numérique et des solutions logicielles centrées sur l'utilisateur. 
          Nous mettons un point d'honneur à fournir des technologies de pointe pour répondre aux besoins uniques de nos clients et les aider à prospérer.
        `,
        closingText: `
          Située au cœur de la Suède, notre équipe utilise des outils modernes pour relever les défis complexes. 
          Nos solutions sont conçues pour être robustes et pérennes, garantissant le succès durable de nos clients. 
          Chez Hellström Trading, nous croyons en la construction de partenariats solides grâce à un soutien exceptionnel et des innovations adaptées.
        `,
        whoWeAreHeading: "Qui Nous Sommes",
        whatWeDoHeading: "Ce Que Nous Faisons",
        ourCommitmentHeading: "Notre Engagement",
        contactUsHeading: "Contactez-Nous",
        
      },
    },
    es: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Sobre Nosotros",
          informationText: `
            Hellström Trading i Göteborg AB es una empresa de software dinámica e innovadora dedicada a crear soluciones escalables para internet. 
            Con un enfoque en el rendimiento, la fiabilidad y la adaptabilidad, nos especializamos en desarrollar productos de software que ayudan a las empresas a crecer y tener éxito en un mundo cada vez más digital.
          `,
          additionalInformationText: `
            Nuestra experiencia abarca el desarrollo web, la infraestructura digital y soluciones de software centradas en el usuario. 
            Priorizamos la entrega de tecnología de vanguardia que satisface las necesidades únicas de nuestros clientes, permitiéndoles prosperar en mercados competitivos.
          `,
          closingText: `
            Ubicado en el corazón de Suecia, nuestro equipo se apasiona por aprovechar herramientas y técnicas modernas para resolver desafíos complejos. 
            Nuestras soluciones están diseñadas para ser sólidas y preparadas para el futuro, asegurando que nuestros clientes logren sus objetivos y establezcan un éxito duradero. 
            En Hellström Trading creemos en construir asociaciones sólidas proporcionando apoyo excepcional e innovación adaptada a las necesidades de cada cliente.
          `,
          whoWeAreHeading: "Quiénes Somos",
          whatWeDoHeading: "Qué Hacemos",
          ourCommitmentHeading: "Nuestro Compromiso",
          contactUsHeading: "Contáctenos",
          
        },
      },
      pt: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Sobre Nós",
          informationText: `
            Hellström Trading i Göteborg AB é uma empresa dinâmica e inovadora dedicada a criar soluções escaláveis para a internet. 
            Com foco em desempenho, confiabilidade e adaptabilidade, somos especializados em desenvolver produtos de software que ajudam empresas a crescer e ter sucesso em um mundo cada vez mais digital.
          `,
          additionalInformationText: `
            Nossa experiência abrange desenvolvimento web, infraestrutura digital e soluções de software centradas no usuário. 
            Priorizamos a entrega de tecnologia de ponta que atende às necessidades exclusivas de nossos clientes, permitindo que prosperem em mercados competitivos.
          `,
          closingText: `
            Localizada no coração da Suécia, nossa equipe é apaixonada por utilizar ferramentas e técnicas modernas para resolver desafios complexos. 
            Nossas soluções são projetadas para serem robustas e preparadas para o futuro, garantindo que nossos clientes alcancem seus objetivos e estabeleçam um sucesso duradouro. 
            Na Hellström Trading, acreditamos na construção de parcerias sólidas, fornecendo suporte excepcional e inovação personalizada.
          `,
          whoWeAreHeading: "Quem Somos",
          whatWeDoHeading: "O Que Fazemos",
          ourCommitmentHeading: "O Nosso Compromisso",
          contactUsHeading: "Contate-Nos",
          
          
        },
      },
      ja: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "会社概要",
          informationText: `
            Hellström Trading i Göteborg ABは、インターネット向けのスケーラブルなソリューションを提供する、ダイナミックで革新的なソフトウェア企業です。 
            パフォーマンス、信頼性、適応性に重点を置き、企業が成長し、デジタル化が進む世界で成功するためのソフトウェア製品を開発しています。
          `,
          additionalInformationText: `
            私たちの専門知識は、ウェブ開発、デジタルインフラストラクチャ、ユーザー中心のソフトウェアソリューションに及びます。 
            最先端の技術を提供することを優先し、クライアントが競争の激しい市場で成功できるよう支援します。
          `,
          closingText: `
            スウェーデンの中心に拠点を置く私たちのチームは、複雑な課題を解決するために最新のツールと技術を活用することに情熱を注いでいます。 
            私たちのソリューションは堅牢で将来性があり、クライアントが目標を達成し、長期的な成功を収めることを保証します。 
            Hellström Tradingでは、各クライアントのニーズに合わせた例外的なサポートとイノベーションを提供し、強力なパートナーシップを築くことを信じています。
          `,
          whoWeAreHeading: "私たちについて",
          whatWeDoHeading: "私たちの活動",
          ourCommitmentHeading: "私たちの取り組み",
          contactUsHeading: "お問い合わせ",
          
        },
      },
      tr: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Hakkımızda",
          informationText: `
            Hellström Trading i Göteborg AB, internet için ölçeklenebilir çözümler sunmaya odaklanmış dinamik ve yenilikçi bir yazılım şirketidir. 
            Performans, güvenilirlik ve uyarlanabilirlik üzerine yoğunlaşarak, işletmelerin büyümesini ve dijital dünyada başarılı olmasını sağlayan yazılım ürünleri geliştiriyoruz.
          `,
          additionalInformationText: `
            Uzmanlık alanımız web geliştirme, dijital altyapı ve kullanıcı odaklı yazılım çözümlerini kapsar. 
            Müşterilerimizin benzersiz ihtiyaçlarını karşılayan ve rekabetçi pazarlarda başarılı olmalarını sağlayan en son teknolojileri sunmayı önceliklendiriyoruz.
          `,
          closingText: `
            İsveç'in kalbinde bulunan ekibimiz, karmaşık sorunları çözmek için modern araçları ve teknikleri kullanmaya tutkuyla bağlıdır. 
            Çözümlerimiz sağlam ve geleceğe yöneliktir, müşterilerimizin hedeflerine ulaşmasını ve uzun vadeli başarılar elde etmesini garanti eder. 
            Hellström Trading olarak, her müşterinin ihtiyaçlarına uygun olağanüstü destek ve yenilikler sunarak güçlü ortaklıklar kurmaya inanıyoruz.
          `,
          whoWeAreHeading: "Kimiz",
          whatWeDoHeading: "Ne Yapıyoruz",
          ourCommitmentHeading: "Taahhüdümüz",
          contactUsHeading: "Bize Ulaşın",
          
        },
      },
      kr: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "회사 소개",
          informationText: `
            Hellström Trading i Göteborg AB는 인터넷을 위한 확장 가능한 솔루션을 제공하는 역동적이고 혁신적인 소프트웨어 회사입니다. 
            성능, 신뢰성 및 적응성을 중점으로 디지털화된 세계에서 기업이 성장하고 성공할 수 있도록 지원하는 소프트웨어 제품을 개발합니다.
          `,
          additionalInformationText: `
            저희의 전문성은 웹 개발, 디지털 인프라 및 사용자 중심의 소프트웨어 솔루션에 걸쳐 있습니다. 
            고객의 고유한 요구를 충족시키고 경쟁적인 시장에서 성공할 수 있도록 첨단 기술을 제공하는 것을 우선으로 합니다.
          `,
          closingText: `
            스웨덴의 중심부에 위치한 저희 팀은 복잡한 문제를 해결하기 위해 현대적인 도구와 기술을 활용하는 데 열정적입니다. 
            저희 솔루션은 견고하고 미래 지향적으로 설계되어 고객이 목표를 달성하고 장기적인 성공을 구축할 수 있도록 보장합니다. 
            Hellström Trading은 각 고객의 요구에 맞춘 뛰어난 지원과 혁신을 제공하여 강력한 파트너십을 구축한다고 믿습니다.
          `,
          whoWeAreHeading: "우리는 누구인가",
          whatWeDoHeading: "우리가 하는 일",
          ourCommitmentHeading: "우리의 약속",
          contactUsHeading: "문의하기",
          
        },
      },
      de: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Über Uns",
          informationText: `
            Hellström Trading i Göteborg AB ist ein dynamisches und innovatives Softwareunternehmen, das sich auf die Erstellung skalierbarer Lösungen für das Internet spezialisiert hat. 
            Mit Fokus auf Leistung, Zuverlässigkeit und Anpassungsfähigkeit entwickeln wir Softwareprodukte, die Unternehmen dabei unterstützen, in einer zunehmend digitalen Welt zu wachsen und erfolgreich zu sein.
          `,
          additionalInformationText: `
            Unsere Expertise umfasst Webentwicklung, digitale Infrastruktur und nutzerzentrierte Softwarelösungen. 
            Wir legen Wert auf die Bereitstellung modernster Technologien, die den individuellen Bedürfnissen unserer Kunden entsprechen und ihnen ermöglichen, in wettbewerbsintensiven Märkten zu bestehen.
          `,
          closingText: `
            Im Herzen Schwedens ansässig, ist unser Team leidenschaftlich daran interessiert, moderne Werkzeuge und Techniken zu nutzen, um komplexe Herausforderungen zu lösen. 
            Unsere Lösungen sind darauf ausgelegt, robust und zukunftssicher zu sein, damit unsere Kunden ihre Ziele erreichen und langfristigen Erfolg sichern können. 
            Bei Hellström Trading glauben wir an den Aufbau starker Partnerschaften, indem wir außergewöhnliche Unterstützung und Innovation bieten, die auf die Bedürfnisse jedes Kunden zugeschnitten sind.
          `,
          whoWeAreHeading: "Wer Wir Sind",
          whatWeDoHeading: "Was Wir Tun",
          ourCommitmentHeading: "Unser Engagement",
          contactUsHeading: "Kontaktieren Sie Uns",
          
        },
      },
      vi: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Về Chúng Tôi",
          informationText: `
            Hellström Trading i Göteborg AB là một công ty phần mềm năng động và đổi mới chuyên cung cấp các giải pháp mở rộng quy mô cho internet. 
            Với trọng tâm là hiệu suất, độ tin cậy và khả năng thích ứng, chúng tôi phát triển các sản phẩm phần mềm giúp các doanh nghiệp phát triển và thành công trong một thế giới ngày càng số hóa.
          `,
          additionalInformationText: `
            Chuyên môn của chúng tôi bao gồm phát triển web, cơ sở hạ tầng kỹ thuật số và các giải pháp phần mềm lấy người dùng làm trung tâm. 
            Chúng tôi ưu tiên cung cấp công nghệ tiên tiến đáp ứng nhu cầu độc đáo của khách hàng, giúp họ thành công trên thị trường cạnh tranh.
          `,
          closingText: `
            Có trụ sở tại trung tâm Thụy Điển, đội ngũ của chúng tôi đam mê sử dụng các công cụ và kỹ thuật hiện đại để giải quyết những thách thức phức tạp. 
            Các giải pháp của chúng tôi được thiết kế để chắc chắn và hướng tới tương lai, đảm bảo khách hàng đạt được mục tiêu và xây dựng thành công lâu dài. 
            Tại Hellström Trading, chúng tôi tin tưởng vào việc xây dựng các mối quan hệ đối tác mạnh mẽ bằng cách cung cấp hỗ trợ và đổi mới đặc biệt phù hợp với nhu cầu của từng khách hàng.
          `,
          whoWeAreHeading: "Chúng Tôi Là Ai",
          whatWeDoHeading: "Chúng Tôi Làm Gì",
          ourCommitmentHeading: "Cam Kết Của Chúng Tôi",
          contactUsHeading: "Liên Hệ Với Chúng Tôi",
          
        },
      },
      it: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Chi Siamo",
          informationText: `
            Hellström Trading i Göteborg AB è un'azienda di software dinamica e innovativa, dedicata alla creazione di soluzioni scalabili per internet. 
            Con un'attenzione particolare alle prestazioni, all'affidabilità e all'adattabilità, ci specializziamo nella creazione di prodotti software che consentono alle aziende di crescere e avere successo in un mondo sempre più digitale.
          `,
          additionalInformationText: `
            La nostra esperienza spazia dallo sviluppo web all'infrastruttura digitale e alle soluzioni software incentrate sull'utente. 
            Diamo priorità all'offerta di tecnologie all'avanguardia che soddisfano le esigenze uniche dei nostri clienti, consentendo loro di prosperare in mercati competitivi.
          `,
          closingText: `
            Situato nel cuore della Svezia, il nostro team è appassionato nell'utilizzare strumenti e tecniche moderni per risolvere sfide complesse. 
            Le nostre soluzioni sono progettate per essere robuste e a prova di futuro, garantendo che i nostri clienti raggiungano i loro obiettivi e costruiscano un successo a lungo termine. 
            In Hellström Trading, crediamo nella costruzione di partnership solide offrendo supporto eccezionale e innovazione su misura per le esigenze di ogni cliente.
          `,
          whoWeAreHeading: "Chi Siamo",
          whatWeDoHeading: "Cosa Facciamo",
          ourCommitmentHeading: "Il Nostro Impegno",
          contactUsHeading: "Contattaci",
          
        },
      },
      no: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Om Oss",
          informationText: `
            Hellström Trading i Göteborg AB er et dynamisk og innovativt programvareselskap som er dedikert til å skape skalerbare løsninger for internett. 
            Med fokus på ytelse, pålitelighet og tilpasningsevne, spesialiserer vi oss på å bygge programvareprodukter som gjør det mulig for bedrifter å vokse og lykkes i en stadig mer digital verden.
          `,
          additionalInformationText: `
            Vår ekspertise strekker seg fra webutvikling til digital infrastruktur og brukersentrerte programvareløsninger. 
            Vi prioriterer å levere banebrytende teknologi som oppfyller våre kunders unike behov og gjør det mulig for dem å lykkes i konkurranseutsatte markeder.
          `,
          closingText: `
            Basert i hjertet av Sverige er vårt team lidenskapelig opptatt av å bruke moderne verktøy og teknikker for å løse komplekse utfordringer. 
            Våre løsninger er designet for å være robuste og fremtidsrettede, og sikrer at våre kunder når sine mål og oppnår langsiktig suksess. 
            Hos Hellström Trading tror vi på å bygge sterke partnerskap ved å tilby eksepsjonell støtte og innovasjon som er skreddersydd til hver kundes behov.
          `,
          whoWeAreHeading: "Hvem Vi Er",
          whatWeDoHeading: "Hva Vi Gjør",
          ourCommitmentHeading: "Vårt Engasjement",
          contactUsHeading: "Kontakt Oss",
          
        },
      },
      fi: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Meistä",
          informationText: `
            Hellström Trading i Göteborg AB on dynaaminen ja innovatiivinen ohjelmistoyritys, joka on omistautunut luomaan skaalautuvia ratkaisuja internetiin. 
            Keskitymme suorituskykyyn, luotettavuuteen ja mukautuvuuteen, ja olemme erikoistuneet ohjelmistotuotteiden kehittämiseen, jotka auttavat yrityksiä kasvamaan ja menestymään yhä digitaalisemmassa maailmassa.
          `,
          additionalInformationText: `
            Osaamisemme kattaa verkkokehityksen, digitaalisen infrastruktuurin ja käyttäjäkeskeiset ohjelmistoratkaisut. 
            Priorisoimme huipputeknologian tarjoamisen, joka vastaa asiakkaidemme ainutlaatuisia tarpeita ja auttaa heitä menestymään kilpailluilla markkinoilla.
          `,
          closingText: `
            Ruotsin sydämessä sijaitseva tiimimme on intohimoinen nykyaikaisten työkalujen ja tekniikoiden hyödyntämisessä monimutkaisten haasteiden ratkaisemiseksi. 
            Ratkaisumme on suunniteltu kestämään ja vastaamaan tulevaisuuden tarpeisiin, mikä varmistaa, että asiakkaamme saavuttavat tavoitteensa ja rakentavat pitkäaikaisen menestyksen. 
            Hellström Trading uskoo vahvojen kumppanuuksien rakentamiseen tarjoamalla poikkeuksellista tukea ja innovaatioita, jotka on räätälöity jokaisen asiakkaan tarpeisiin.
          `,
          whoWeAreHeading: "Keitä Olemme",
          whatWeDoHeading: "Mitä Teemme",
          ourCommitmentHeading: "Sitoutumisemme",
          contactUsHeading: "Ota Yhteyttä",
          
        },
      },
      dk: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Om Os",
          informationText: `
            Hellström Trading i Göteborg AB er en dynamisk og innovativ softwarevirksomhed, der er dedikeret til at skabe skalerbare løsninger til internettet. 
            Med fokus på ydeevne, pålidelighed og tilpasningsevne specialiserer vi os i at udvikle softwareprodukter, der giver virksomheder mulighed for at vokse og få succes i en stadig mere digital verden.
          `,
          additionalInformationText: `
            Vores ekspertise omfatter webudvikling, digital infrastruktur og brugervenlige softwareløsninger. 
            Vi prioriterer at levere banebrydende teknologi, der opfylder vores kunders unikke behov og gør det muligt for dem at trives på konkurrencedygtige markeder.
          `,
          closingText: `
            Baseret i hjertet af Sverige er vores team passioneret omkring at bruge moderne værktøjer og teknikker til at løse komplekse udfordringer. 
            Vores løsninger er designet til at være robuste og fremtidssikrede, hvilket sikrer, at vores kunder når deres mål og opnår langsigtet succes. 
            Hos Hellström Trading tror vi på at opbygge stærke partnerskaber ved at levere enestående support og innovation skræddersyet til hver kundes behov.
          `,
          whoWeAreHeading: "Hvem Vi Er",
          whatWeDoHeading: "Hvad Vi Gør",
          ourCommitmentHeading: "Vores Engagement",
          contactUsHeading: "Kontakt Os",
          
        },
      },
      pl: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "O Nas",
          informationText: `
            Hellström Trading i Göteborg AB to dynamiczna i innowacyjna firma programistyczna, zajmująca się tworzeniem skalowalnych rozwiązań dla internetu. 
            Koncentrujemy się na wydajności, niezawodności i elastyczności, specjalizując się w tworzeniu produktów oprogramowania, które pomagają firmom rozwijać się i odnosić sukcesy w coraz bardziej zdigitalizowanym świecie.
          `,
          additionalInformationText: `
            Nasza wiedza obejmuje rozwój stron internetowych, infrastrukturę cyfrową oraz rozwiązania programistyczne zorientowane na użytkownika. 
            Priorytetem jest dostarczanie najnowocześniejszych technologii, które odpowiadają unikalnym potrzebom naszych klientów, umożliwiając im sukces na konkurencyjnych rynkach.
          `,
          closingText: `
            Nasz zespół, zlokalizowany w sercu Szwecji, z pasją wykorzystuje nowoczesne narzędzia i techniki do rozwiązywania skomplikowanych problemów. 
            Nasze rozwiązania są zaprojektowane tak, aby były solidne i przyszłościowe, zapewniając klientom osiągnięcie celów i długoterminowy sukces. 
            W Hellström Trading wierzymy w budowanie silnych partnerstw poprzez zapewnianie wyjątkowego wsparcia i innowacji dostosowanych do potrzeb każdego klienta.
          `,
          whoWeAreHeading: "Kim Jesteśmy",
          whatWeDoHeading: "Co Robimy",
          ourCommitmentHeading: "Nasze Zaangażowanie",
          contactUsHeading: "Skontaktuj Się Z Nami",
          
        },
      },
      nl: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Over Ons",
          informationText: `
            Hellström Trading i Göteborg AB is een dynamisch en innovatief softwarebedrijf dat zich toelegt op het creëren van schaalbare oplossingen voor internet. 
            Met een focus op prestaties, betrouwbaarheid en aanpassingsvermogen, specialiseren we ons in het ontwikkelen van softwareproducten die bedrijven helpen te groeien en succesvol te zijn in een steeds digitalere wereld.
          `,
          additionalInformationText: `
            Onze expertise omvat webontwikkeling, digitale infrastructuur en gebruikersgerichte softwareoplossingen. 
            We geven prioriteit aan het leveren van geavanceerde technologieën die voldoen aan de unieke behoeften van onze klanten en hen in staat stellen te gedijen op concurrerende markten.
          `,
          closingText: `
            Gevestigd in het hart van Zweden, is ons team gepassioneerd over het gebruik van moderne tools en technieken om complexe uitdagingen op te lossen. 
            Onze oplossingen zijn ontworpen om robuust en toekomstbestendig te zijn, zodat onze klanten hun doelen kunnen bereiken en langdurig succes kunnen opbouwen. 
            Bij Hellström Trading geloven we in het opbouwen van sterke partnerschappen door uitzonderlijke ondersteuning en innovatie te bieden die is afgestemd op de behoeften van elke klant.
          `,
          whoWeAreHeading: "Wie Wij Zijn",
          whatWeDoHeading: "Wat Wij Doen",
          ourCommitmentHeading: "Onze Toewijding",
          contactUsHeading: "Neem Contact Met Ons Op",
          
        },
      },
      el: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Σχετικά Με Εμάς",
          informationText: `
            Η Hellström Trading i Göteborg AB είναι μια δυναμική και καινοτόμος εταιρεία λογισμικού, αφιερωμένη στη δημιουργία κλιμακούμενων λύσεων για το διαδίκτυο. 
            Εστιάζουμε στην απόδοση, την αξιοπιστία και την προσαρμοστικότητα, εξειδικευόμενοι στην ανάπτυξη προϊόντων λογισμικού που ενισχύουν τις επιχειρήσεις να αναπτυχθούν και να επιτύχουν σε έναν ολοένα και πιο ψηφιακό κόσμο.
          `,
          additionalInformationText: `
            Η εξειδίκευσή μας περιλαμβάνει την ανάπτυξη ιστοσελίδων, την ψηφιακή υποδομή και λύσεις λογισμικού με επίκεντρο τον χρήστη. 
            Δίνουμε προτεραιότητα στην παροχή τεχνολογιών αιχμής που ανταποκρίνονται στις μοναδικές ανάγκες των πελατών μας, επιτρέποντάς τους να ευδοκιμήσουν σε ανταγωνιστικές αγορές.
          `,
          closingText: `
            Με έδρα την καρδιά της Σουηδίας, η ομάδα μας είναι παθιασμένη με τη χρήση σύγχρονων εργαλείων και τεχνικών για την επίλυση σύνθετων προκλήσεων. 
            Οι λύσεις μας έχουν σχεδιαστεί ώστε να είναι ανθεκτικές και έτοιμες για το μέλλον, εξασφαλίζοντας ότι οι πελάτες μας επιτυγχάνουν τους στόχους τους και χτίζουν μακροπρόθεσμη επιτυχία. 
            Στην Hellström Trading πιστεύουμε στην οικοδόμηση ισχυρών συνεργασιών παρέχοντας εξαιρετική υποστήριξη και καινοτομία προσαρμοσμένη στις ανάγκες κάθε πελάτη.
          `,
          whoWeAreHeading: "Ποιοι Είμαστε",
          whatWeDoHeading: "Τι Κάνουμε",
          ourCommitmentHeading: "Η Δέσμευσή Μας",
          contactUsHeading: "Επικοινωνήστε Μαζί Μας",
          
        },
      },
      zh: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "关于我们",
          informationText: `
            Hellström Trading i Göteborg AB 是一家充满活力且创新的软件公司，致力于为互联网创建可扩展的解决方案。 
            我们专注于性能、可靠性和适应性，专注于开发帮助企业在日益数字化的世界中成长和成功的软件产品。
          `,
          additionalInformationText: `
            我们的专业领域包括网络开发、数字基础设施和以用户为中心的软件解决方案。 
            我们优先提供满足客户独特需求的尖端技术，帮助他们在竞争激烈的市场中取得成功。
          `,
          closingText: `
            我们的团队位于瑞典的中心，热衷于利用现代工具和技术解决复杂的挑战。 
            我们的解决方案旨在坚固且具有未来前瞻性，确保客户实现目标并建立长期成功。 
            在 Hellström Trading，我们相信通过提供卓越的支持和创新来建立牢固的合作伙伴关系，以满足每位客户的需求。
          `,
          whoWeAreHeading: "我们是谁",
          whatWeDoHeading: "我们做什么",
          ourCommitmentHeading: "我们的承诺",
          contactUsHeading: "联系我们",
          
        },
      },
      ph: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Tungkol sa Amin",
          informationText: `
            Ang Hellström Trading i Göteborg AB ay isang dynamic at makabagong kompanya ng software na nakatuon sa paglikha ng scalable na solusyon para sa internet. 
            Sa pokus sa performance, reliability, at adaptability, kami ay dalubhasa sa paggawa ng mga software products na tumutulong sa mga negosyo na lumago at magtagumpay sa isang mas digital na mundo.
          `,
          additionalInformationText: `
            Ang aming kaalaman ay sumasaklaw sa web development, digital infrastructure, at user-centric na mga solusyon sa software. 
            Inuuna namin ang pagbibigay ng mga makabagong teknolohiya na tumutugon sa mga natatanging pangangailangan ng aming mga kliyente, na nagbibigay-daan sa kanila na magtagumpay sa mga mapagkumpitensyang merkado.
          `,
          closingText: `
            Matatagpuan sa puso ng Sweden, ang aming koponan ay masigasig sa paggamit ng modernong mga kasangkapan at pamamaraan upang lutasin ang mga kumplikadong hamon. 
            Ang aming mga solusyon ay idinisenyo upang maging matatag at handa para sa hinaharap, na tinitiyak na ang aming mga kliyente ay makakamit ang kanilang mga layunin at makapagtatayo ng pangmatagalang tagumpay. 
            Sa Hellström Trading, naniniwala kami sa pagtatayo ng matibay na pakikipagsosyo sa pamamagitan ng pagbibigay ng pambihirang suporta at inobasyon na iniayon sa mga pangangailangan ng bawat kliyente.
          `,
          whoWeAreHeading: "Sino Kami",
          whatWeDoHeading: "Ano ang Ginagawa Namin",
          ourCommitmentHeading: "Ang Aming Pangako",
          contactUsHeading: "Makipag-ugnayan sa Amin",
          
        },
      },
      ar: {
        common: {
          companyLiveTextName: "شركة هيلستروم للتجارة في غوتنبرغ AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "سكينيفاغن 4، 51260 أوفرليدا",
          aboutHeading: "من نحن",
          informationText: `
            شركة هيلستروم للتجارة في غوتنبرغ AB هي شركة برمجيات ديناميكية ومبتكرة مكرسة لتقديم حلول قابلة للتوسع عبر الإنترنت.
            مع التركيز على الأداء والموثوقية والمرونة، نحن متخصصون في بناء منتجات برمجية تمكّن الشركات من النمو والنجاح في عالم رقمي متزايد.
          `,
          additionalInformationText: `
            خبرتنا تمتد إلى تطوير الويب، والبنية التحتية الرقمية، وحلول البرمجيات التي تركز على المستخدم.
            نحن نولي الأولوية لتقديم أحدث التقنيات التي تلبي الاحتياجات الفريدة لعملائنا، مما يمكنهم من الازدهار في الأسواق التنافسية.
          `,
          closingText: `
            مقرنا في قلب السويد، نحن فريق شغوف باستخدام الأدوات والأساليب الحديثة لحل التحديات المعقدة.
            تم تصميم حلولنا لتكون قوية ومستدامة، مما يضمن تحقيق عملائنا لأهدافهم وبناء نجاح طويل الأمد.
            في شركة هيلستروم للتجارة، نؤمن ببناء شراكات طويلة الأمد من خلال تقديم دعم استثنائي وابتكار مصمم خصيصًا لتحقيق أهدافكم.
          `,
          whoWeAreHeading: "من نحن",
          whatWeDoHeading: "ماذا نفعل",
          ourCommitmentHeading: "التزامنا",
          contactUsHeading: "اتصل بنا",
        },
      },
      fa: {
        common: {
          companyLiveTextName: "هلستروم تریدینگ در یوتبری AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "اسکن‌وگن 4، 51260 اورلیدا",
          aboutHeading: "درباره ما",
          informationText: `
            هلستروم تریدینگ در یوتبری AB یک شرکت نرم‌افزاری پویا و نوآور است که به ارائه راه‌حل‌های قابل گسترش برای اینترنت اختصاص دارد.
            با تمرکز بر عملکرد، قابلیت اطمینان و انطباق‌پذیری، ما در توسعه محصولات نرم‌افزاری که به کسب‌وکارها امکان رشد و موفقیت در دنیای دیجیتالی رو به افزایش می‌دهد، تخصص داریم.
          `,
          additionalInformationText: `
            تخصص ما شامل توسعه وب، زیرساخت دیجیتال و راه‌حل‌های نرم‌افزاری کاربرمحور است.
            ما اولویت را به ارائه فناوری‌های پیشرفته‌ای می‌دهیم که نیازهای منحصربه‌فرد مشتریانمان را برآورده کند و به آنها امکان می‌دهد در بازارهای رقابتی رشد کنند.
          `,
          closingText: `
            تیم ما که در قلب سوئد مستقر است، به استفاده از ابزارها و روش‌های مدرن برای حل چالش‌های پیچیده متعهد است.
            راه‌حل‌های ما طوری طراحی شده‌اند که قوی و آینده‌نگر باشند، و اطمینان حاصل شود که مشتریانمان به اهداف خود دست می‌یابند و موفقیتی پایدار ایجاد می‌کنند.
            در هلستروم تریدینگ، ما باور داریم که با ارائه پشتیبانی بی‌نظیر و نوآوری‌های متناسب با اهداف شما، شراکت‌های بلندمدتی ایجاد کنیم.
          `,
          whoWeAreHeading: "ما کی هستیم",
          whatWeDoHeading: "چه کاری انجام می‌دهیم",
          ourCommitmentHeading: "تعهد ما",
          contactUsHeading: "با ما تماس بگیرید",
        },
      },
      sw: {
        common: {
          companyLiveTextName: "Hellström Trading i Göteborg AB",
          contactEmail: "support@bakesoft.se",
          contactPhone: "+46792143182",
          companyAddress: "Skenevägen 4, 51260 Överlida",
          aboutHeading: "Kuhusu Sisi",
          informationText: `
            Hellström Trading i Göteborg AB ni kampuni ya programu yenye ubunifu na nguvu inayojitolea kutoa suluhisho zinazoweza kupanuka kwa mtandao.
            Kwa kuzingatia utendaji, uaminifu, na kubadilika, tunabobea katika kujenga bidhaa za programu zinazowezesha biashara kukua na kufanikiwa katika ulimwengu wa kidijitali unaokua kwa kasi.
          `,
          additionalInformationText: `
            Utaalamu wetu unahusisha maendeleo ya wavuti, miundombinu ya kidijitali, na suluhisho za programu zinazozingatia watumiaji.
            Tunapanga kutoa teknolojia za kisasa zinazokidhi mahitaji ya kipekee ya wateja wetu, kuwawezesha kustawi katika masoko yenye ushindani mkubwa.
          `,
          closingText: `
            Tukiwa katika moyo wa Uswidi, timu yetu ina shauku ya kutumia zana za kisasa na mbinu za kutatua changamoto ngumu.
            Suluhisho zetu zimeundwa kuwa imara na endelevu, kuhakikisha wateja wetu wanatimiza malengo yao na kufanikisha mafanikio ya muda mrefu.
            Katika Hellström Trading, tunaamini katika kujenga ushirikiano wa muda mrefu kwa kutoa msaada bora na uvumbuzi uliobinafsishwa kulingana na malengo yako.
          `,
          whoWeAreHeading: "Sisi ni Nani",
          whatWeDoHeading: "Tunachofanya",
          ourCommitmentHeading: "Ahadi Yetu",
          contactUsHeading: "Wasiliana Nasi",
        },
      },
      
      
    }   
    export default aboutPageLanguages;                                      
                  
            
      
      