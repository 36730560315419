
import React, { useState } from 'react';
import '../style/LanguageSelector.scss';


const LanguageSelector = (props) => {
    let {languageCountries,setSelectedLanguage,getSelectedLanguage
        ,setCloseLanguageSelector,setTrigger,trigger
    } = props

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleConfirm = () => {
    if (getSelectedLanguage) {
       localStorage.setItem('LoginLiveCountry', getSelectedLanguage);
        localStorage.setItem('DefaultLiveCountry', getSelectedLanguage);
        console.log(getSelectedLanguage)
        setCloseLanguageSelector(true);
        setTrigger(!trigger)
    }
  };

  return (
    <div className="language-selector">
      <div className="language-selector__card">
        <h2 className="language-selector__title">Welcome</h2>
        <p className="language-selector__description">
          By selecting a language, we can make your experience better.
        </p>
        <select
          className="language-selector__select"
          value={getSelectedLanguage}
          onChange={(e)=>handleLanguageChange(e)}
        >
          <option value="">Select a language</option>
          {languageCountries.map((lang,index) => (
            <option key={index} value={lang.cc}>
              {lang.benaemn}
            </option>
          ))}
        </select>
        <button
          className="language-selector__button"
          onClick={handleConfirm}
          disabled={!getSelectedLanguage}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default LanguageSelector;

