
const languages = {
    se: {
      common: {
     headerGreeting: "... great recipes for amazing moments.",
     selectMenu: "Meny",
     inputTitleBookmark: "Bokmärk detta recept",
     imageTitleChef: "Kock profil",
     translateRecipeTextGrid: "Översätt",
     titleShareOnSocialMedia: "Dela på sociala medier",
     textMinutesInShort: "min",
     titleTextLikeRecipe: "Gilla detta recept",
     titleSeenByVievers: "Sett av användare",
     noDescriptionText: "Ingen beskrivning tillgänglig",
     scrollMessageText:"Översätter fantastiska recept",
     scrollMessageText2:"Hämtar fantastiska recept",
     translateRecipeText: "Översätt recept",
     translateCostText: "Pris för översättning 1 kredit/lyckad översättning",
     selectLanguageText: "Välj språk",
    noNameAvailableText: "Inget namn tillgängligt",
    savedRecipeText: "Sparade recept",
    clickToUnlockText: "Klicka/låsa upp",
    ingredientText: "Ingrediens",
    pricePerKgText: "Pris/kg",
    priceText: "Pris",
    amountText: "Gram",
    kaloriesText: "Kalorier",
    increaseIngredientValueText: "Öka ingrediensvärde",
    decreaseIngredientValueText: "Minska ingrediensvärde",
    changeNameText: "Ändra namn",
    saveText: "Spara",
    savedText: "Sparad",
    deleteText: "Radera",
    totalText: "Total",
    addIngredientText: "Lägg till ingrediens",
    addText: "Lägg till",
    placeHolderIngredientNameText: "Ingrediens namn",
    descriptionText: "Beskrivning",


    unlockThisRecipeText: "Lås upp detta recept för",
    creditsToUseText: "- krediter att använda",
    creditsText: "Krediter",
    noCreditsText: "Du har inga krediter för att låsa upp detta recept",
    addMoreCreditsText: "Lägg till fler krediter",
    gotoPaymentText: "Gå till betalning",
    chefInFocusText: "Kock i fokus ",
     
    bsLivePaymentText:"Bakesoft betalnings information",
    bsLiveAmountText:"Belopp",
    bsLiveCurrencyText:"Valuta",
    bsLivePaymentTermsText:"Betalningsvillkor",


    paymentSuccessfullText:"Betalning lyckades",
    thankYouForPurchaseText:"Tack för ditt köp!",
    selectCurrencyText:"Välj valuta",
    selectLanguageText2:"Välj språk",
    placeHolderEmailText:"Skriv in din e-postadress",
    placeHolderPasswordText:"Skriv in ditt lösenord",
    loginText2:"Logga in",
    resetPassText:"Reset password",
    
    logOutText:"Logga ut",
    norecipeAvailableText:"Inga recept tillgängliga.",
    notAvailableText: "Inte tillgänglig",
    nameText: "Namn:",
    countryText: "Land:",
    currencyText: "Valuta:",
    noTransactionsFoundText:"Inga transaktioner hittades.",
    paySuccessText:"Betalningen lyckades",
    yourAccountSetupDoneText:"Ditt konto är nu klart",
    youcannowLoginWithCredentialsText:"Du kan nu logga in med dina inloggningsuppgifter!",
    thankYouForJoiningUsText:"Tack och välkommen till oss!",

    JoinBakesoftText:"Gå med i Bakesoft och tjäna pengar på dina härliga recept!",
    createAccountTodayText:"Skapa ett konto idag och sälj dina recept redan idag!",
    plattformOfferingText:"Vi erbjuder en bra plattform där du kan skapa och sälja dina recept till världen!",
    signUpOfferingText:"Registrera dig idag och få 14 dagars prova på period!",
    joinBakesoftNowText:"Gå med i www.bakesoft.se nu!",
    accountDoneSetupText:"Ditt www.bakesoft.se konto är nu klart!",
    loginemailandpassText:"Logga in med e-postadress och lösenord!",
    topublishRecipesOnLiveText:"För att publicera recept på live.bakesoft.se ,måste du ha ett betal konto.",
    menyText:"Meny",
    signUpText:"Registrera konto",
    myAccountText:"Mitt konto",
    myBookmarkedRecipesText:"Mina bokmärkta recept",
    SignUpToBakesoftText:"Registrera dig på live.bakesoft.se",
    howDoesItWorkText:"Hur fungerar det?",


    liveSignUpText:"Registrera konto",
    liveSignUpText1:"Registrera konto på live.bakesoft.se",
    confirmPasswordText:"Bekräfta lösenord",
    currencyNLanguageText:"Valuta och språk",
    buyOneCreditText:"Köp 1 kredit",
    unlockedRecipesText:"Upplåsta recept",
    accountDetailsText:"Konto uppgifter",
    receiptText:"Kvitto",
    savedRecipesText:"Sparade recept",
    endDateText:"Slutdatum",
    deleteSavedRecipeText:"Radera",
    nameText: "Namn",
    slideToUnlockText: "Dra för att låsa upp",
    unlockedText2: "Upplåst",
    logoutOnlyText: "Logga ut",
    youreLoggedOutText: "Du är utloggad",
    bakesoftSignUp1: "live.bakesoft.se är en reklamfri plattform för fantastiska recept.",
    bakesoftSignUp2: "Lås upp recept för 1 USD/kredit. Anpassa dem, få näringsinfo, dela på sociala medier och översätt till olika språk. Bli medlem gratis – ingen abonnemangsavgift. En kredit krävs per recept. Utforska recept från olika kockar, länder och språk.",
    bakesoftSignUp3: "Vill du skapa recept och tjäna pengar?",
    bakesoftSignUp4: "Använd www.bakesoft.se för avancerad recepthantering. Publicera på våra plattformar och tjäna pengar när dina recept låses upp. Pris: 19 USD/månad.",
    bakesoftSignUp5: "Sätt pris (1–5 USD/recept) och tjäna ca 30% per såld kredit. Följ intäkter och utbetalningar i appen. Alla användare ansvarar för skatter och lagar. Läs mer i våra villkor!",

    
    termsAndConditionsText:"Villkor och bestämmelser",
    gotToLoginText:"Gå till inloggning",
    serviceS2Text: "Köp 5 receptkrediter och få 10% rabatt",
    serviceS3Text: "Köp 10 receptkrediter och få 20% rabatt",
    serviceS4Text: "Registrera dig på bakesoft.se gratis – 14 dagars provperiod",
    creditsText: "Krediter",
    updateLanguageHeader: "Uppdatera språk",
    submitButtonChangeLanguageText: "Ändra språk",
    kitchenTimerText: "Köks timer",
    showHideText: "Visa/Dölj",
    forgotPasswordText: "Glömt lösenord?",
    resetPassText:"Återställ lösenord",
    resetLinkText:"Återställnings länk skickad till din e-postadress",
    unlockRecipeText: "Lås upp recept",
    recipeUnlockedText: "Recept upplåst",

    availableInText: "Tillgänglig i",
    languagesText: "Språk",
    translateTo16LanguagesText: "Översätt till 16 språk",
    proChefText: "Proffskock",
    voteRegisteredText: "Din röst har registrerats",
    copyLinkText: "Kopiera länk",
    pinterestText: "Dela på Pinterest",
    instagramText: "Dela på Instagram",
    twitterText: "Dela på X",
    facebookText: "Dela på Facebook",
    linkCopiedText: "Länk kopierad",

    welcomeToBakesoftText1: "Välkommen till Bakesoft",
    proRecipesText: "Proffsrecept för kockar och bagare",
    updaterecipeText: "Ändra, lägg till och uppdatera ingredienser",
    nutritionalText: "Näringsvärden",
    signUpAndBonusText: "Registrera dig och få 5 kreditbonus",
    
    signupbonusText: "Registrera dig och få 5 kreditbonus",
    nocommitmentText: "Ingen bindningstid,ej abonnemang",
    aboutUsLiveText: "Om oss",


    
},
bakesoftPromotion: {
    welcomeToBakesoftText: "Välkommen till Bakesoft",
    unlockWorldOfCulinaryCreativityText: "Lås upp en värld av kulinarisk kreativitet",
    discoverAmazingRecipesText: "Upptäck fantastiska recept",
    liveBakesoftText: "live.bakesoft.se är en reklamfri plattform för fantastiska recept.",
    affordableAccessText: "Lås upp recept för 1 USD/kredit. Inga abonnemangsavgifter!",
    customizationText: "Anpassa recept och få näringsinformation",
    shareConnectText: "Dela dina kulinariska skapelser på sociala medier",
    globalCuisineText: "Utforska recept från olika kockar, länder och språk",
    signUpNowText: "Registrera dig nu",
    forRecipeCreatorsText: "För receptskapare",
    createRecipesEarnMoneyText: "Vill du skapa recept och tjäna pengar?",
    advancedRecipeManagementText: "Använd www.bakesoft.se för avancerad recepthantering. Publicera på våra plattformar och tjäna pengar varje gång ditt recept låses upp. Pris: 19 USD/månad.",
    setYourPriceText: "Ställ in ditt pris (1–5 USD/recept) och tjäna 33% per såld kredit",
    trackEarningsText: "Följ intäkter och utbetalningar i appen",
    usersResponsibleText: "Användare ansvarar för skatter och lagar i sitt land",
    startCreatingText: "Börja skapa",
    readMoreInTermsText: "Läs mer i våra villkor!",
    affordableAccessHeader: "Prisvänlig tillgång",
    customizationHeader:"Anpassning",
    shareandConnectHeader:"Dela och anslut",
    globalCuisineHeader:"Global matlagning",
    voteRegisteredText: "Din röst har registrerats",


},
    },
    en: {
        common: {
            headerGreeting: "... great recipes for amazing moments.",
selectMenu: "Menu",
inputTitleBookmark: "Bookmark this recipe",
imageTitleChef: "Chef profile",
translateRecipeTextGrid: "Translate",
titleShareOnSocialMedia: "Share on social media",
textMinutesInShort: "min",
titleTextLikeRecipe: "Like this recipe",
titleSeenByVievers: "Seen by users",
noDescriptionText: "No description available",
scrollMessageText: "Translating amazing recipes",
scrollMessageText2: "Fetching amazing recipes",
translateRecipeText: "Translate recipe",
translateCostText: "Translation cost 1 credit/successful translation",
selectLanguageText: "Select language",
noNameAvailableText: "No name available",
savedRecipeText: "Saved recipes",
clickToUnlockText: "Click/Unlock",
ingredientText: "Ingredient",
pricePerKgText: "Price/kg",
priceText: "Price",
amountText: "Amount",
kaloriesText: "Calories",
increaseIngredientValueText: "Increase ingredient value",
decreaseIngredientValueText: "Decrease ingredient value",
changeNameText: "Change name",
saveText: "Save",
savedText: "Saved",
deleteText: "Delete",
totalText: "Total",
addIngredientText: "Add ingredient",
addText: "Add",
placeHolderIngredientNameText: "Ingredient name",
descriptionText: "Description",

unlockThisRecipeText: "Unlock this recipe for",
creditsToUseText: "- credits to use",
creditsText: "credits",
noCreditsText: "You have no credits to unlock this recipe",
addMoreCreditsText: "Add more credits",
gotoPaymentText: "Go to payment",
chefInFocusText: "Chef in focus",

bsLivePaymentText: "Bakesoft payment information",
bsLiveAmountText: "Amount",
bsLiveCurrencyText: "Currency",
bsLivePaymentTermsText: "Payment terms",

paymentSuccessfullText: "Payment successful",
thankYouForPurchaseText: "Thank you for your purchase!",
selectCurrencyText: "Select currency",
selectLanguageText2: "Select language",
placeHolderEmailText: "Enter your email address",
placeHolderPasswordText: "Enter your password",
loginText2: "Login",

logOutText: "Logout",
norecipeAvailableText: "No recipes available.",
notAvailableText: "Not available",
nameText: "Name:",
countryText: "Country:",
currencyText: "Currency:",
noTransactionsFoundText: "No transactions found.",
paySuccessText: "Payment succeeded",
yourAccountSetupDoneText: "Your account is now ready",
youcannowLoginWithCredentialsText: "You can now log in with your credentials!",
thankYouForJoiningUsText: "Thank you and welcome to us!",

JoinBakesoftText: "Join Bakesoft and earn money with your amazing recipes!",
createAccountTodayText: "Create an account today and start selling your recipes now!",
plattformOfferingText: "We offer a great platform where you can create and sell your recipes to the world!",
signUpOfferingText: "Sign up today and get a 14-day trial period!",
joinBakesoftNowText: "Join www.bakesoft.se now!",
accountDoneSetupText: "Your www.bakesoft.se account is now ready!",
loginemailandpassText: "Log in with email and password!",
topublishRecipesOnLiveText: "To publish recipes on live.bakesoft.se, you need a paid account.",

menyText:"Menu",
signUpText:"Register account",
myAccountText:"My account",
myBookmarkedRecipesText:"My bookmarked recipes",
SignUpToBakesoftText:"Sign up to live.Bakesoft.se",
howDoesItWorkText:"How does it work?",
liveSignUpText:"Sign up",
liveSignUpText1:"Sign up to live.bakesoft.se",
confirmPasswordText:"Confirm password",
currencyNLanguageText:"Currency and language",
buyOneCreditText:"Buy 1 credit",
unlockedRecipesText:"Unlocked recipes",
accountDetailsText:"Account details",
receiptText:"Receipt",
savedRecipesText:"Saved recipes",
endDateText:"End date",
deleteSavedRecipeText:"Delete",
nameText: "Name",
slideToUnlockText: "Slide to unlock",
unlockedText2: "Unlocked",
logoutOnlyText: "Logout",
youreLoggedOutText: "You're logged out",
bakesoftSignUp1: "live.bakesoft.se is an ad-free platform for amazing recipes.",
bakesoftSignUp2: "Unlock recipes for $1/credit. Customize them, get nutrition info, share on social media, and translate to different languages. Membership is free – no subscription fees. One credit unlocks one recipe. Explore recipes from different chefs, countries, and languages.",
bakesoftSignUp3: "Want to create recipes and earn money?",
bakesoftSignUp4: "Use www.bakesoft.se for advanced recipe management. Publish on our platforms and earn money every time your recipe is unlocked. Price: $19/month.",
bakesoftSignUp5: "Set your price ($1–$5/recipe) and earn 33% per sold credit. Track earnings and payouts in the app. Users are responsible for taxes and laws in their country. Read more in our terms!",


termsAndConditionsText:"Terms and Conditions",
gotToLoginText:"Go to login",
serviceS2Text:"Buy 5 recipe credits and get 10% discount",
serviceS3Text:"Buy 10 recipe credits and get 20% discount",
serviceS4Text:"Sign up för  bakesoft.se gratis – 14 dagars prova på",
creditsText: "credits",
updateLanguageHeader: "Update language",
submitButtonChangeLanguageText:"Update",
kitchenTimerText: "Kitchen Timer",
showHideText: "Show/Hide",
forgotPasswordText: "Forgot password?",
resetPassText:"Reset password",
resetLinkText:"Reset link sent to your email address",
unlockRecipeText: "Unlock recipe",
recipeUnlockedText: "Recipe unlocked",

availableInText: "Available in",
languagesText: "Languages",
translateTo16LanguagesText: "Translate to 16 languages",
proChefText: "Pro Chef",
voteRegisteredText: "Your vote has been registered",
copyLinkText: "Copy link",
pinterestText: "Share on Pinterest",
instagremText: "Share on Instagram",
twitterText: "Share on X",
facebookText: "Share on Facebook",
linkCopiedText: "Link copied",

welcomeToBakesoftText1: "Welcome to Bakesoft",
proRecipesText: "Professional recipes for chefs and bakers",
updaterecipeText: "Edit, add, and update ingredients",
nutritionalText: "Nutritional values",
signUpAndBonusText: "Sign up and get a 5-credit bonus",

signupbonusText: "Sign up and get a 5-credit bonus",
nocommitmentText: "No commitment, no subscription",
aboutUsLiveText: "About Us",





},
bakesoftPromotion: {
    welcomeToBakesoftText: "Welcome to Bakesoft",
    unlockWorldOfCulinaryCreativityText: "Unlock a world of culinary creativity",
    discoverAmazingRecipesText: "Discover Amazing Recipes",
    liveBakesoftText: "live.bakesoft.se is an ad-free platform for amazing recipes.",
    affordableAccessText: "Unlock recipes for $1/credit. No subscription fees!",
    customizationText: "Customize recipes and get nutrition information",
    shareConnectText: "Share your culinary creations on social media",
    globalCuisineText: "Explore recipes from different chefs, countries, and languages",
    signUpNowText: "Sign Up Now",
    forRecipeCreatorsText: "For Recipe Creators",
    createRecipesEarnMoneyText: "Want to create recipes and earn money?",
    advancedRecipeManagementText: "Use www.bakesoft.se for advanced recipe management. Publish on our platforms and earn money every time your recipe is unlocked. Price: $19/month.",
    
    setYourPriceText: "Set your price ($1–$5/recipe) and earn 33% per sold credit",
    trackEarningsText: "Track earnings and payouts in the application",
    usersResponsibleText: "Users are responsible for taxes and laws in their country",
    startCreatingText: "Start Creating",
    readMoreInTermsText: "Read more in our terms!",

    affordableAccessHeader: "Affordable Access",
    customizationHeader:"Customization",
    shareandConnectHeader:"Share and Connect",
    globalCuisineHeader:"Global Cuisine",

},
    },
    gb: {
        common: {
            headerGreeting: "... great recipes for amazing moments.",
selectMenu: "Menu",
inputTitleBookmark: "Bookmark this recipe",
imageTitleChef: "Chef profile",
translateRecipeTextGrid: "Translate",
titleShareOnSocialMedia: "Share on social media",
textMinutesInShort: "min",
titleTextLikeRecipe: "Like this recipe",
titleSeenByVievers: "Seen by users",
noDescriptionText: "No description available",
scrollMessageText: "Translating amazing recipes",
scrollMessageText2: "Fetching amazing recipes",
translateRecipeText: "Translate recipe",
translateCostText: "Translation cost 1 credit/successful translation",
selectLanguageText: "Select language",
noNameAvailableText: "No name available",
savedRecipeText: "Saved recipes",
clickToUnlockText: "Click/Unlock",
ingredientText: "Ingredient",
pricePerKgText: "Price/kg",
priceText: "Price",
amountText: "Gram",
kaloriesText: "Calories",
increaseIngredientValueText: "Increase ingredient value",
decreaseIngredientValueText: "Decrease ingredient value",
changeNameText: "Change name",
saveText: "Save",
savedText: "Saved",
deleteText: "Delete",
totalText: "Total",
addIngredientText: "Add ingredient",
addText: "Add",
placeHolderIngredientNameText: "Ingredient name",
descriptionText: "Description",

unlockThisRecipeText: "Unlock this recipe for",
creditsToUseText: "- credits to use",
creditsText: "credits",
noCreditsText: "You have no credits to unlock this recipe",
addMoreCreditsText: "Add more credits",
gotoPaymentText: "Go to payment",
chefInFocusText: "Chef in focus",

bsLivePaymentText: "Bakesoft payment information",
bsLiveAmountText: "Amount",
bsLiveCurrencyText: "Currency",
bsLivePaymentTermsText: "Payment terms",

paymentSuccessfullText: "Payment successful",
thankYouForPurchaseText: "Thank you for your purchase!",
selectCurrencyText: "Select currency",
selectLanguageText2: "Select language",
placeHolderEmailText: "Enter your email address",
placeHolderPasswordText: "Enter your password",
loginText2: "Login",

logOutText: "Logout",
norecipeAvailableText: "No recipes available.",
notAvailableText: "Not available",
nameText: "Name:",
countryText: "Country:",
currencyText: "Currency:",
noTransactionsFoundText: "No transactions found.",
paySuccessText: "Payment succeeded",
yourAccountSetupDoneText: "Your account is now ready",
youcannowLoginWithCredentialsText: "You can now log in with your credentials!",
thankYouForJoiningUsText: "Thank you and welcome to us!",

JoinBakesoftText: "Join Bakesoft and earn money with your amazing recipes!",
createAccountTodayText: "Create an account today and start selling your recipes now!",
plattformOfferingText: "We offer a great platform where you can create and sell your recipes to the world!",
signUpOfferingText: "Sign up today and get a 14-day trial period!",
joinBakesoftNowText: "Join www.bakesoft.se now!",
accountDoneSetupText: "Your www.bakesoft.se account is now ready!",
loginemailandpassText: "Log in with email and password!",
topublishRecipesOnLiveText: "To publish recipes on live.bakesoft.se, you need a paid account.",

menyText:"Menu",
signUpText:"Register account",
myAccountText:"My account",
myBookmarkedRecipesText:"My bookmarked recipes",
SignUpToBakesoftText:"Sign up to live.Bakesoft.se",
howDoesItWorkText:"How does it work?",
liveSignUpText:"Sign up",
liveSignUpText1:"Sign up to live.bakesoft.se",
confirmPasswordText:"Confirm password",
currencyNLanguageText:"Currency and language",
buyOneCreditText:"Buy 1 credit",
unlockedRecipesText:"Unlocked recipes",
accountDetailsText:"Account details",
receiptText:"Receipt",
savedRecipesText:"Saved recipes",
endDateText:"End date",
deleteSavedRecipeText:"Delete",
nameText: "Name",
slideToUnlockText: "Slide to unlock",
unlockedText2: "Unlocked",
logoutOnlyText: "Logout",
youreLoggedOutText: "You're logged out",

bakesoftSignUp1: "live.bakesoft.se is an ad-free platform for amazing recipes.",
bakesoftSignUp2: "Unlock recipes for $1/credit. Customize them, get nutrition info, share on social media, and translate to different languages. Membership is free – no subscription fees. One credit unlocks one recipe. Explore recipes from different chefs, countries, and languages.",
bakesoftSignUp3: "Want to create recipes and earn money?",
bakesoftSignUp4: "Use www.bakesoft.se for advanced recipe management. Publish on our platforms and earn money every time your recipe is unlocked. Price: $19/month.",
bakesoftSignUp5: "Set your price ($1–$5/recipe) and earn 33% per sold credit. Track earnings and payouts in the app. Users are responsible for taxes and laws in their country. Read more in our terms!",


termsAndConditionsText:"Terms and Conditions",
gotToLoginText:"Go to login",
serviceS2Text: "Buy 5 recipe credits and get a 10% discount",
serviceS3Text: "Buy 10 recipe credits and get a 20% discount",
serviceS4Text: "Sign up for bakesoft.se for free – 14-day trial",
creditsText: "credits",
updateLanguageHeader: "Update language",
submitButtonChangeLanguageText:"Update",
kitchenTimerText: "Kitchen Timer",
showHideText: "Show/Hide",
forgotPasswordText: "Forgot password?",
resetPassText:"Reset password",
resetLinkText:"Reset link sent to your email address",
unlockRecipeText: "Unlock recipe",
recipeUnlockedText: "Recipe unlocked",

availableInText: "Available in",
languagesText: "Languages",
translateTo16LanguagesText: "Translate to 16 languages",
proChefText: "Pro Chef",
voteRegisteredText: "Your vote has been registered",
copyLinkText: "Copy link",
pinterestText: "Share on Pinterest",
instagremText: "Share on Instagram",
twitterText: "Share on X",
facebookText: "Share on Facebook",
linkCopiedText: "Link copied",

welcomeToBakesoftText1: "Welcome to Bakesoft",
proRecipesText: "Professional recipes for chefs and bakers",
updaterecipeText: "Edit, add, and update ingredients",
nutritionalText: "Nutritional values",
signUpAndBonusText: "Sign up and get a 5-credit bonus",

signupbonusText: "Sign up and get a 5-credit bonus",
nocommitmentText: "No commitment, no subscription",
aboutUsLiveText: "About Us",



        },
        bakesoftPromotion: {
            welcomeToBakesoftText: "Welcome to Bakesoft",
            unlockWorldOfCulinaryCreativityText: "Unlock a world of culinary creativity",
            discoverAmazingRecipesText: "Discover Amazing Recipes",
            liveBakesoftText: "live.bakesoft.se is an ad-free platform for amazing recipes.",
            affordableAccessText: "Unlock recipes for $1/credit. No subscription fees!",
            customizationText: "Customize recipes and get nutrition information",
            shareConnectText: "Share your culinary creations on social media",
            globalCuisineText: "Explore recipes from different chefs, countries, and languages",
            signUpNowText: "Sign Up Now",
            forRecipeCreatorsText: "For Recipe Creators",
            createRecipesEarnMoneyText: "Want to create recipes and earn money?",
            advancedRecipeManagementText: "Use www.bakesoft.se for advanced recipe management. Publish on our platforms and earn money every time your recipe is unlocked. Price: $19/month.",
            
            setYourPriceText: "Set your price ($1–$5/recipe) and earn 33% per sold credit",
            trackEarningsText: "Track earnings and payouts in the application",
            usersResponsibleText: "Users are responsible for taxes and laws in their country",
            startCreatingText: "Start Creating",
            readMoreInTermsText: "Read more in our terms!",

            affordableAccessHeader: "Affordable Access",
            customizationHeader:"Customization",
            shareandConnectHeader:"Share and Connect",
            globalCuisineHeader:"Global Cuisine",

            welcomeToBakesoftText1: "Welcome to Bakesoft",
            proRecipesText: "Professional recipes for chefs and bakers",
            updaterecipeText: "Edit, add, and update ingredients",
            nutritionalText: "Nutritional values",
            signUpAndBonusText: "Sign up and get a 5-credit bonus",
            
        
        },
    },
    fr: {
        common: {
            headerGreeting: "... de délicieuses recettes pour des moments incroyables.",
            selectMenu: "Menu",
            inputTitleBookmark: "Mettre cette recette en favori",
            imageTitleChef: "Profil du chef",
            translateRecipeTextGrid: "Traduire",
            titleShareOnSocialMedia: "Partager sur les réseaux sociaux",
            textMinutesInShort: "min",
            titleTextLikeRecipe: "Aimer cette recette",
            titleSeenByVievers: "Vu par les utilisateurs",
            noDescriptionText: "Aucune description disponible",
            scrollMessageText: "Traduction de recettes incroyables",
            scrollMessageText2: "Récupération de recettes incroyables",
            translateRecipeText: "Traduire la recette",
            translateCostText: "Coût de la traduction : 1 crédit/traduction réussie",
            selectLanguageText: "Choisir la langue",
            noNameAvailableText: "Aucun nom disponible",
            savedRecipeText: "Recettes enregistrées",
            clickToUnlockText: "Cliquez/Débloquez",
            ingredientText: "Ingrédient",
            pricePerKgText: "Prix/kg",
            priceText: "Prix",
            amountText: "Gramme",
            kaloriesText: "Calories",
            increaseIngredientValueText: "Augmenter la valeur de l'ingrédient",
            decreaseIngredientValueText: "Diminuer la valeur de l'ingrédient",
            changeNameText: "Changer le nom",
            saveText: "Enregistrer",
            savedText: "Enregistré",
            deleteText: "Supprimer",
            totalText: "Total",
            addIngredientText: "Ajouter un ingrédient",
            addText: "Ajouter",
            placeHolderIngredientNameText: "Nom de l'ingrédient",
            descriptionText: "Description",
            
            unlockThisRecipeText: "Débloquez cette recette pour",
            creditsToUseText: "- crédits à utiliser",
            creditsText: "Crédits",
            noCreditsText: "Vous n'avez pas de crédits pour débloquer cette recette",
            addMoreCreditsText: "Ajouter plus de crédits",
            gotoPaymentText: "Aller au paiement",
            chefInFocusText: "Chef à l'honneur",
            
            bsLivePaymentText: "Informations de paiement Bakesoft",
            bsLiveAmountText: "Montant",
            bsLiveCurrencyText: "Devise",
            bsLivePaymentTermsText: "Conditions de paiement",
            
            paymentSuccessfullText: "Paiement réussi",
            thankYouForPurchaseText: "Merci pour votre achat !",
            selectCurrencyText: "Choisir une devise",
            selectLanguageText2: "Choisir une langue",
            placeHolderEmailText: "Entrez votre adresse e-mail",
            placeHolderPasswordText: "Entrez votre mot de passe",
            loginText2: "Connexion",
            
            logOutText: "Déconnexion",
            norecipeAvailableText: "Aucune recette disponible.",
            notAvailableText: "Non disponible",
            nameText: "Nom :",
            countryText: "Pays :",
            currencyText: "Devise :",
            noTransactionsFoundText: "Aucune transaction trouvée.",
            paySuccessText: "Paiement réussi",
            yourAccountSetupDoneText: "Votre compte est maintenant prêt",
            youcannowLoginWithCredentialsText: "Vous pouvez maintenant vous connecter avec vos identifiants !",
            thankYouForJoiningUsText: "Merci et bienvenue chez nous !",
            
            JoinBakesoftText: "Rejoignez Bakesoft et gagnez de l'argent avec vos recettes incroyables !",
            createAccountTodayText: "Créez un compte aujourd'hui et commencez à vendre vos recettes dès maintenant !",
            plattformOfferingText: "Nous offrons une excellente plateforme où vous pouvez créer et vendre vos recettes au monde entier !",
            signUpOfferingText: "Inscrivez-vous aujourd'hui et bénéficiez d'une période d'essai de 14 jours !",
            joinBakesoftNowText: "Rejoignez www.bakesoft.se maintenant !",
            accountDoneSetupText: "Votre compte www.bakesoft.se est maintenant prêt !",
            loginemailandpassText: "Connectez-vous avec votre e-mail et mot de passe !",
            topublishRecipesOnLiveText: "Pour publier des recettes sur live.bakesoft.se, vous devez avoir un compte payant.",
            menyText:"Menu",
            signUpText:"Créer un compte",
            myAccountText:"Mon compte",
            myBookmarkedRecipesText:"Mes recettes favorites",
            SignUpToBakesoftText:"Inscrivez-vous à live.bakesoft.se",
            howDoesItWorkText:"Comment ça marche ?",
            liveSignUpText:"S'inscrire",

            liveSignUpText1:"S'inscrire à live.bakesoft.se",
            confirmPasswordText:"Confirmer le mot de passe",
            currencyNLanguageText:"Devise et langue",
            buyOneCreditText:"Acheter 1 crédit",
            unlockedRecipesText:"Recettes débloquées",
            accountDetailsText:"Détails du compte",
            receiptText:"Reçu",
            savedRecipesText:"Recettes enregistrées",
            endDateText:"Date de fin",
            deleteSavedRecipeText:"Supprimer",
            nameText: "Nom",
            slideToUnlockText: "Faites glisser pour déverrouiller",
            unlockedText2: "Débloqué",
            logoutOnlyText: "Déconnexion",
            youreLoggedOutText: "Vous êtes déconnecté",
            bakesoftSignUp1: "live.bakesoft.se est une plateforme sans publicité pour des recettes incroyables.",
            bakesoftSignUp2: "Débloquez des recettes pour 1 USD/crédit. Personnalisez-les, obtenez des informations nutritionnelles, partagez-les sur les réseaux sociaux et traduisez-les dans différentes langues. L'adhésion est gratuite – pas de frais d'abonnement. Un crédit permet de débloquer une recette. Découvrez des recettes de différents chefs, pays et langues.",
            bakesoftSignUp3: "Vous voulez créer des recettes et gagner de l'argent ?",
            bakesoftSignUp4: "Utilisez www.bakesoft.se pour une gestion avancée des recettes. Publiez sur nos plateformes et gagnez de l'argent chaque fois qu'une de vos recettes est débloquée. Prix : 19 USD/mois.",
            bakesoftSignUp5: "Fixez votre prix (1–5 USD/recette) et gagnez 33 % par crédit vendu. Suivez vos revenus et paiements dans l'application. Les utilisateurs sont responsables des taxes et lois dans leur pays. Consultez nos conditions pour en savoir plus !",
            
            termsAndConditionsText:"Termes et conditions",
            gotToLoginText:"Aller à la connexion",
            serviceS2Text: "5 crédits de recettes à -10%",
            serviceS3Text: "10 crédits de recettes à -20%",
            serviceS4Text: "Inscrivez-vous gratuitement – essai 14 jours",
            creditsText: "Crédits",
            updateLanguageHeader: "Mettre à jour la langue",
            submitButtonChangeLanguageText:"Mettre à jour",
            kitchenTimerText: "Minuteur de cuisine",
            showHideText: "Afficher/Masquer",
            forgotPasswordText: "Mot de passe oublié ?",
            resetPassText:"Réinitialiser le mot de passe",
            resetLinkText:"Lien de réinitialisation envoyé à votre adresse e-mail",

            unlockRecipeText: "Débloquer la recette",
            recipeUnlockedText: "Recette débloquée",

            availableInText: "Disponible en",
            languagesText: "Langues",
            translateTo16LanguagesText: "Traduire en 16 langues",
            proChefText: "Chef Pro",
            voteRegisteredText: "Votre vote a été enregistré",
            copyLinkText: "Copier le lien",
            pinterestText: "Partager sur Pinterest",
            instagramText: "Partager sur Instagram",
            twitterText: "Partager sur X",
            facebookText: "Partager sur Facebook",
            linkCopiedText: "Lien copié",

             
            welcomeToBakesoftText1: "Bienvenue sur Bakesoft",
            proRecipesText: "Recettes professionnelles pour chefs et boulangers",
            updaterecipeText: "Modifier, ajouter et mettre à jour les ingrédients",
            nutritionalText: "Valeurs nutritionnelles",
            signUpAndBonusText: "Inscrivez-vous et obtenez un bonus de 5 crédits",

            signupbonusText: "Inscrivez-vous et recevez un bonus de 5 crédits",
            nocommitmentText: "Sans engagement, pas d'abonnement",
            aboutUsLiveText: "À propos de nous",
            
            
            


        },
        bakesoftPromotion: {
            welcomeToBakesoftText: "Bienvenue chez Bakesoft",
            unlockWorldOfCulinaryCreativityText: "Déverrouillez un monde de créativité culinaire",
            discoverAmazingRecipesText: "Découvrez des recettes incroyables",
            liveBakesoftText: "live.bakesoft.se est une plateforme sans publicité pour des recettes incroyables.",
            affordableAccessText: "Déverrouillez des recettes pour 1 $/crédit. Pas de frais d'abonnement !",
            customizationText: "Personnalisez les recettes et obtenez des informations nutritionnelles",
            shareConnectText: "Partagez vos créations culinaires sur les réseaux sociaux",
            globalCuisineText: "Explorez des recettes de différents chefs, pays et langues",
            signUpNowText: "Inscrivez-vous maintenant",
            forRecipeCreatorsText: "Pour les créateurs de recettes",
            createRecipesEarnMoneyText: "Vous souhaitez créer des recettes et gagner de l'argent ?",
            advancedRecipeManagementText: "Utilisez www.bakesoft.se pour une gestion avancée des recettes. Publiez sur nos plateformes et gagnez de l'argent chaque fois que votre recette est débloquée. Prix : 19 $/mois.",
            
            setYourPriceText: "Fixez votre prix (1 $–5 $/recette) et gagnez 33 % par crédit vendu",
            trackEarningsText: "Suivez vos gains et paiements dans l'application",
            usersResponsibleText: "Les utilisateurs sont responsables des taxes et lois dans leur pays",
            startCreatingText: "Commencez à créer",
            readMoreInTermsText: "En savoir plus dans nos conditions !",

            affordableAccessHeader: "Accès abordable",
            customizationHeader: "Personnalisation",
            shareandConnectHeader: "Partager et se connecter",
            globalCuisineHeader: "Cuisine mondiale",

        },
        
    },
    es: {
        common: {
            headerGreeting: "... grandes recetas para momentos increíbles.",
            selectMenu: "Menú",
            inputTitleBookmark: "Agregar esta receta a los favoritos",
            imageTitleChef: "Perfil del chef",
            translateRecipeTextGrid: "Traducir",
            titleShareOnSocialMedia: "Compartir en redes sociales",
            textMinutesInShort: "min",
            titleTextLikeRecipe: "Me gusta esta receta",
            titleSeenByVievers: "Visto por usuarios",
            noDescriptionText: "No hay descripción disponible",
            scrollMessageText: "Traduciendo recetas increíbles",
            scrollMessageText2: "Obteniendo recetas increíbles",
            translateRecipeText: "Traducir receta",
            translateCostText: "Costo de traducción: 1 crédito/traducción exitosa",
            selectLanguageText: "Seleccionar idioma",
            noNameAvailableText: "Nombre no disponible",
            savedRecipeText: "Recetas guardadas",
            clickToUnlockText: "Hacer clic/Desbloquear",
            ingredientText: "Ingrediente",
            pricePerKgText: "Precio/kg",
            priceText: "Precio",
            amountText: "Gramo",
            kaloriesText: "Calorías",
            increaseIngredientValueText: "Aumentar el valor del ingrediente",
            decreaseIngredientValueText: "Disminuir el valor del ingrediente",
            changeNameText: "Cambiar nombre",
            saveText: "Guardar",
            savedText: "Guardado",
            deleteText: "Eliminar",
            totalText: "Total",
            addIngredientText: "Agregar ingrediente",
            addText: "Agregar",
            placeHolderIngredientNameText: "Nombre del ingrediente",
            descriptionText: "Descripción",
          
            unlockThisRecipeText: "Desbloquear esta receta por",
            creditsToUseText: "- créditos a usar",
            creditsText: "Créditos",
            noCreditsText: "No tienes créditos para desbloquear esta receta",
            addMoreCreditsText: "Agregar más créditos",
            gotoPaymentText: "Ir al pago",
            chefInFocusText: "Chef en foco",
          
            bsLivePaymentText: "Información de pago de Bakesoft",
            bsLiveAmountText: "Cantidad",
            bsLiveCurrencyText: "Moneda",
            bsLivePaymentTermsText: "Términos de pago",
          
            paymentSuccessfullText: "Pago exitoso",
            thankYouForPurchaseText: "¡Gracias por tu compra!",
            selectCurrencyText: "Seleccionar moneda",
            selectLanguageText2: "Seleccionar idioma",
            placeHolderEmailText: "Ingresa tu dirección de correo electrónico",
            placeHolderPasswordText: "Ingresa tu contraseña",
            loginText2: "Iniciar sesión",
          
            logOutText: "Cerrar sesión",
            norecipeAvailableText: "No hay recetas disponibles.",
            notAvailableText: "No disponible",
            nameText: "Nombre:",
            countryText: "País:",
            currencyText: "Moneda:",
            noTransactionsFoundText: "No se encontraron transacciones.",
            paySuccessText: "Pago exitoso",
            yourAccountSetupDoneText: "Tu cuenta ya está lista",
            youcannowLoginWithCredentialsText: "¡Ahora puedes iniciar sesión con tus credenciales!",
            thankYouForJoiningUsText: "¡Gracias y bienvenido con nosotros!",
          
            JoinBakesoftText: "¡Únete a Bakesoft y gana dinero con tus increíbles recetas!",
            createAccountTodayText: "¡Crea una cuenta hoy y empieza a vender tus recetas ahora!",
            plattformOfferingText: "¡Ofrecemos una excelente plataforma donde puedes crear y vender tus recetas al mundo!",
            signUpOfferingText: "¡Regístrate hoy y obtén un periodo de prueba de 14 días!",
            joinBakesoftNowText: "¡Únete ahora a www.bakesoft.se!",
            accountDoneSetupText: "¡Tu cuenta de www.bakesoft.se ya está lista!",
            loginemailandpassText: "Inicia sesión con correo electrónico y contraseña",
            topublishRecipesOnLiveText: "Para publicar recetas en live.bakesoft.se, necesitas una cuenta de pago.",
            menyText:"Menú",
            signUpText:"Crear cuenta",
            myAccountText:"Mi cuenta",
            myBookmarkedRecipesText:"Mis recetas favoritas",
            SignUpToBakesoftText:"Regístrate en live.bakesoft.se",
            howDoesItWorkText:"¿Cómo funciona?",
            liveSignUpText:"Regístrate",
            liveSignUpText1:"Regístrate en live.bakesoft.se",
            confirmPasswordText:"Confirmar contraseña",
            currencyNLanguageText:"Moneda e idioma",
            buyOneCreditText:"Comprar 1 crédito",
            unlockedRecipesText:"Recetas abiertas",
            accountDetailsText:"Detalles de la cuenta",
            receiptText:"Recibo",
            savedRecipesText:"Recetas guard",
            endDateText:"Fecha de finalización",
            deleteSavedRecipeText:"Eliminar",
            nameText: "Nombre",
            slideToUnlockText: "Deslizar para desbloquear",
            unlockedText2: "Desbloqueado",
            logoutOnlyText: "Cerrar sesión",
            youreLoggedOutText: "Has cerrado sesión",

            bakesoftSignUp1: "live.bakesoft.se es una plataforma sin publicidad para recetas increíbles.",
            bakesoftSignUp2: "Desbloquea recetas por 1 USD/crédito. Personalízalas, obtén información nutricional, compártelas en redes sociales y tradúcelas a diferentes idiomas. La membresía es gratuita, sin tarifas de suscripción. Un crédito desbloquea una receta. Explora recetas de diferentes chefs, países e idiomas.",
            bakesoftSignUp3: "¿Quieres crear recetas y ganar dinero?",
            bakesoftSignUp4: "Usa www.bakesoft.se para una gestión avanzada de recetas. Publica en nuestras plataformas y gana dinero cada vez que se desbloquee una de tus recetas. Precio: 19 USD/mes.",
            bakesoftSignUp5: "Establece tu precio (1–5 USD/receta) y gana el 33 % por cada crédito vendido. Rastrea ingresos y pagos en la app. Los usuarios son responsables de los impuestos y leyes en su país. ¡Lee nuestros términos para más información!"
            ,
            termsAndConditionsText:"Términos y condiciones",
            gotToLoginText:"Ir a inicio de sesión",
            serviceS2Text: "5 créditos, 10% descuento",
            serviceS3Text: "10 créditos, 20% descuento",
            serviceS4Text: "Regístrate gratis bakesoft.se – 14 días prueba",
            creditsText: "Créditos",
            updateLanguageHeader: "Actualizar idioma",
            submitButtonChangeLanguageText:"Actualizar",
            kitchenTimerText: "Temporizador de cocina",
            showHideText: "Mostrar/Ocultar",
            forgotPasswordText: "¿Olvidaste tu contraseña?",
            resetPassText:"Restablecer contraseña",
            resetLinkText:"Enlace de restablecimiento enviado a tu dirección de correo electrónico",
            unlockRecipeText: "Desbloquear receta",
            recipeUnlockedText: "Receta desbloqueada",

            availableInText: "Disponible en",
            languagesText: "Idiomas",
            translateTo16LanguagesText: "Traducir a 16 idiomas",
            proChefText: "Chef Profesional",
            voteRegisteredText: "Tu voto ha sido registrado",

            copyLinkText: "Copiar enlace",
            pinterestText: "Compartir en Pinterest",
            instagramText: "Compartir en Instagram",
            twitterText: "Compartir en X",
            facebookText: "Compartir en Facebook",
            linkCopiedText: "Enlace copiado",

            welcomeToBakesoftText1: "Bienvenido a Bakesoft",
            proRecipesText: "Recetas profesionales para chefs y panaderos",
            updaterecipeText: "Editar, agregar y actualizar ingredientes",
            nutritionalText: "Valores nutricionales",
            signUpAndBonusText: "Regístrate y obtén un bono de 5 créditos",
            
            signupbonusText: "Regístrate y obtén un bono de 5 créditos",
            nocommitmentText: "Sin compromiso, sin suscripción",
            aboutUsLiveText: "Sobre nosotros",
            
            
            
            
        
        },
        bakesoftPromotion: {
            welcomeToBakesoftText: "Bienvenido a Bakesoft",
            unlockWorldOfCulinaryCreativityText: "Desbloquea un mundo de creatividad culinaria",
            discoverAmazingRecipesText: "Descubre recetas increíbles",
            liveBakesoftText: "live.bakesoft.se es una plataforma sin anuncios para recetas increíbles.",
            affordableAccessText: "Desbloquea recetas por $1/crédito. ¡Sin tarifas de suscripción!",
            customizationText: "Personaliza recetas y obtén información nutricional",
            shareConnectText: "Comparte tus creaciones culinarias en las redes sociales",
            globalCuisineText: "Explora recetas de diferentes chefs, países e idiomas",
            signUpNowText: "Regístrate ahora",
            forRecipeCreatorsText: "Para los creadores de recetas",
            createRecipesEarnMoneyText: "¿Quieres crear recetas y ganar dinero?",
            advancedRecipeManagementText: "Usa www.bakesoft.se para una gestión avanzada de recetas. Publica en nuestras plataformas y gana dinero cada vez que se desbloquee tu receta. Precio: $19/mes.",
            
            setYourPriceText: "Establece tu precio ($1–$5/receta) y gana el 33 % por crédito vendido",
            trackEarningsText: "Sigue tus ganancias y pagos en la aplicación",
            usersResponsibleText: "Los usuarios son responsables de los impuestos y las leyes en su país",
            startCreatingText: "Comienza a crear",
            readMoreInTermsText: "¡Lee más en nuestros términos!",

            affordableAccessHeader: "Acceso asequible",
            customizationHeader: "Personalización",
            shareandConnectHeader: "Compartir y conectar",
            globalCuisineHeader: "Cocina global",

            signupbonusText: "Inscrivez-vous et recevez un bonus de 5 crédits",
            nocommitmentText: "Sans engagement, pas d'abonnement",

            signupbonusText: "Regístrate y obtén un bono de 5 créditos",
            nocommitmentText: "Sin compromiso, sin suscripción",
            
            
            


        },
        
    },
    pt: {
        common: {
        headerGreeting: "... receitas incríveis para momentos especiais.",
selectMenu: "Menu",
inputTitleBookmark: "Adicionar receita aos favoritos",
imageTitleChef: "Perfil do chef",
translateRecipeTextGrid: "Traduzir",
titleShareOnSocialMedia: "Compartilhar nas redes sociais",
textMinutesInShort: "min",
titleTextLikeRecipe: "Gostar desta receita",
titleSeenByVievers: "Visto por usuários",
noDescriptionText: "Descrição não disponível",
scrollMessageText: "Traduzindo receitas incríveis",
scrollMessageText2: "Buscando receitas incríveis",
translateRecipeText: "Traduzir receita",
translateCostText: "Custo da tradução: 1 crédito/tradução bem-sucedida",
selectLanguageText: "Selecionar idioma",
noNameAvailableText: "Nome não disponível",
savedRecipeText: "Receitas salvas",
clickToUnlockText: "Clique/Desbloqueie",
ingredientText: "Ingrediente",
pricePerKgText: "Preço/kg",
priceText: "Preço",
amountText: "Gramas",
kaloriesText: "Calorias",
increaseIngredientValueText: "Aumentar valor do ingrediente",
decreaseIngredientValueText: "Diminuir valor do ingrediente",
changeNameText: "Alterar nome",
saveText: "Salvar",
savedText: "Salvo",
deleteText: "Excluir",
totalText: "Total",
addIngredientText: "Adicionar ingrediente",
addText: "Adicionar",
placeHolderIngredientNameText: "Nome do ingrediente",
descriptionText: "Descrição",

unlockThisRecipeText: "Desbloqueie esta receita por",
creditsToUseText: "- créditos necessários",
creditsText: "créditos",
noCreditsText: "Você não possui créditos para desbloquear esta receita",
addMoreCreditsText: "Adicionar mais créditos",
gotoPaymentText: "Ir para pagamento",
chefInFocusText: "Chef em destaque",

bsLivePaymentText: "Informações de pagamento da Bakesoft",
bsLiveAmountText: "Quantidade",
bsLiveCurrencyText: "Moeda",
bsLivePaymentTermsText: "Termos de pagamento",

paymentSuccessfullText: "Pagamento realizado com sucesso",
thankYouForPurchaseText: "Obrigado pela sua compra!",
selectCurrencyText: "Selecionar moeda",
selectLanguageText2: "Selecionar idioma",
placeHolderEmailText: "Digite seu endereço de e-mail",
placeHolderPasswordText: "Digite sua senha",
loginText2: "Entrar",

logOutText: "Sair",
norecipeAvailableText: "Nenhuma receita disponível.",
notAvailableText: "Não disponível",
nameText: "Nome:",
countryText: "País:",
currencyText: "Moeda:",
noTransactionsFoundText: "Nenhuma transação encontrada.",
paySuccessText: "Pagamento realizado com sucesso",
yourAccountSetupDoneText: "Sua conta está pronta",
youcannowLoginWithCredentialsText: "Agora você pode fazer login com suas credenciais!",
thankYouForJoiningUsText: "Obrigado e seja bem-vindo!",

JoinBakesoftText: "Junte-se à Bakesoft e ganhe dinheiro com suas receitas incríveis!",
createAccountTodayText: "Crie sua conta hoje e comece a vender suas receitas agora!",
plattformOfferingText: "Oferecemos uma ótima plataforma onde você pode criar e vender suas receitas para o mundo!",
signUpOfferingText: "Inscreva-se hoje e obtenha um período de teste de 14 dias!",
joinBakesoftNowText: "Junte-se ao www.bakesoft.se agora!",
accountDoneSetupText: "Sua conta www.bakesoft.se está pronta!",
loginemailandpassText: "Faça login com e-mail e senha!",
topublishRecipesOnLiveText: "Para publicar receitas no live.bakesoft.se, é necessário ter uma conta paga.",
        
menyText:"Menu",
signUpText:"Criar conta",
myAccountText:"Minha conta",
myBookmarkedRecipesText:"Minhas receitas favoritas",
SignUpToBakesoftText:"Inscreva-se na live.bakesoft.se",
howDoesItWorkText:"Como funciona?",
liveSignUpText1:"Inscreva-se na live.bakesoft.se",
liveSignUpText:"Inscreva-se",
confirmPasswordText:"Confirmar senha",
currencyNLanguageText:"Moeda e idioma",
buyOneCreditText:"Comprar 1 crédito",
unlockedRecipesText:"Receitas desbloqueadas",
accountDetailsText:"Detalhes da conta",
receiptText:"Recibo",
savedRecipesText:"Receitas salvas",
endDateText:"Data de término",
deleteSavedRecipeText:"Excluir",
nameText: "Nome",
slideToUnlockText: "Deslize para desbloquear",
unlockedText2: "Desbloqueado",
logoutOnlyText: "Sair",
youreLoggedOutText: "Você saiu",
bakesoftSignUp1: "live.bakesoft.se é uma plataforma sem anúncios para receitas incríveis.",
bakesoftSignUp2: "Desbloqueie receitas por 1 USD/crédito. Personalize-as, obtenha informações nutricionais, compartilhe nas redes sociais e traduza para diferentes idiomas. A adesão é gratuita, sem taxas de assinatura. Um crédito desbloqueia uma receita. Explore receitas de diferentes chefs, países e idiomas.",
bakesoftSignUp3: "Quer criar receitas e ganhar dinheiro?",
bakesoftSignUp4: "Use www.bakesoft.se para gerenciamento avançado de receitas. Publique nas nossas plataformas e ganhe dinheiro sempre que uma de suas receitas for desbloqueada. Preço: 19 USD/mês.",
bakesoftSignUp5: "Defina seu preço (1–5 USD/receita) e ganhe 33% por crédito vendido. Acompanhe ganhos e pagamentos no app. Os usuários são responsáveis pelos impostos e leis no seu país. Leia nossos termos para mais informações!"
,
termsAndConditionsText:"Términos y condiciones",
gotToLoginText:"Ir a inicio de sesión",
serviceS2Text: "5 créditos de receitas com 10% off",
serviceS3Text: "10 créditos de receitas com 20% off",
serviceS4Text: "Cadastre-se grátis – teste de 14 dias",
creditsText: "créditos",
updateLanguageHeader: "Atualizar idioma",
submitButtonChangeLanguageText:"Atualizar",
kitchenTimerText: "Temporizador de cozinha",
 showHideText: "Mostrar/Ocultar",
 forgotPasswordText: "Esqueceu a senha?",
 resetPassText:"Redefinir senha",
 resetLinkText:"Link de redefinição enviado para seu endereço de e-mail",
 unlockRecipeText: "Desbloquear receita",
 recipeUnlockedText: "Receita desbloqueada",

 availableInText: "Disponível em",
languagesText: "Idiomas",
translateTo16LanguagesText: "Traduzir para 16 idiomas",
proChefText: "Chef Profissional",

copyLinkText: "Copiar link",
pinterestText: "Compartilhar no Pinterest",
instagramText: "Compartilhar no Instagram",
twitterText: "Compartilhar no X",
facebookText: "Compartilhar no Facebook",
linkCopiedText: "Link copiado",

welcomeToBakesoftText1: "Bem-vindo ao Bakesoft",
proRecipesText: "Receitas profissionais para chefs e padeiros",
updaterecipeText: "Editar, adicionar e atualizar ingredientes",
nutritionalText: "Valores nutricionais",
signUpAndBonusText: "Cadastre-se e ganhe um bônus de 5 créditos",

signupbonusText: "Registe-se e receba um bônus de 5 créditos",
nocommitmentText: "Sem compromisso, sem assinatura",
aboutUsLiveText: "Sobre nós",




},
bakesoftPromotion: {
    welcomeToBakesoftText: "Bem-vindo ao Bakesoft",
    unlockWorldOfCulinaryCreativityText: "Desbloqueie um mundo de criatividade culinária",
    discoverAmazingRecipesText: "Descubra receitas incríveis",
    liveBakesoftText: "live.bakesoft.se é uma plataforma sem anúncios para receitas incríveis.",
    affordableAccessText: "Desbloqueie receitas por $1/crédito. Sem taxas de assinatura!",
    customizationText: "Personalize receitas e obtenha informações nutricionais",
    shareConnectText: "Compartilhe suas criações culinárias nas redes sociais",
    globalCuisineText: "Explore receitas de diferentes chefs, países e idiomas",
    signUpNowText: "Cadastre-se agora",
    forRecipeCreatorsText: "Para Criadores de Receitas",
    createRecipesEarnMoneyText: "Quer criar receitas e ganhar dinheiro?",
    advancedRecipeManagementText: "Use www.bakesoft.se para gerenciamento avançado de receitas. Publique em nossas plataformas e ganhe dinheiro toda vez que sua receita for desbloqueada. Preço: $19/mês.",
    
    setYourPriceText: "Defina seu preço ($1–$5/receita) e ganhe 33% por crédito vendido",
    trackEarningsText: "Acompanhe seus ganhos e pagamentos no aplicativo",
    usersResponsibleText: "Os usuários são responsáveis pelos impostos e leis em seu país",
    startCreatingText: "Comece a criar",
    readMoreInTermsText: "Leia mais em nossos termos!",

    affordableAccessHeader: "Acesso acessível",
    customizationHeader: "Personalização",
    shareandConnectHeader: "Compartilhar e conectar",
    globalCuisineHeader: "Cozinha global",
    voteRegisteredText: "Seu voto foi registrado",

},

    },
    ja: {
        common: {
            headerGreeting: "... 素晴らしい瞬間のための素晴らしいレシピ",
            selectMenu: "メニュー",
            inputTitleBookmark: "このレシピをブックマークする",
            imageTitleChef: "シェフのプロフィール",
            translateRecipeTextGrid: "翻訳",
            titleShareOnSocialMedia: "ソーシャルメディアで共有",
            textMinutesInShort: "分",
            titleTextLikeRecipe: "このレシピが好き",
            titleSeenByVievers: "ユーザーによって閲覧されました",
            noDescriptionText: "説明はありません",
            scrollMessageText: "素晴らしいレシピを翻訳中",
            scrollMessageText2: "素晴らしいレシピを取得中",
            translateRecipeText: "レシピを翻訳",
            translateCostText: "翻訳コスト：1クレジット/成功した翻訳",
            selectLanguageText: "言語を選択",
            noNameAvailableText: "利用可能な名前はありません",
            savedRecipeText: "保存されたレシピ",
            clickToUnlockText: "クリック/解除",
            ingredientText: "材料",
            pricePerKgText: "価格/ kg",
            priceText: "価格",
            amountText: "グラム",
            kaloriesText: "カロリー",
            increaseIngredientValueText: "材料の値を増やす",
            decreaseIngredientValueText: "材料の値を減らす",
            changeNameText: "名前を変更",
            saveText: "保存",
            savedText: "保存済み",
            deleteText: "削除",
            totalText: "合計",
            addIngredientText: "材料を追加",
            addText: "追加",
            placeHolderIngredientNameText: "材料名",
            descriptionText: "説明",
            
            unlockThisRecipeText: "このレシピを解除するには",
            creditsToUseText: "- 使用するクレジット",
            creditsText: "クレジット",
            noCreditsText: "このレシピを解除するクレジットがありません",
            addMoreCreditsText: "さらにクレジットを追加",
            gotoPaymentText: "支払いに進む",
            chefInFocusText: "注目のシェフ",
            
            bsLivePaymentText: "Bakesoft 支払い情報",
            bsLiveAmountText: "金額",
            bsLiveCurrencyText: "通貨",
            bsLivePaymentTermsText: "支払い条件",
            
            paymentSuccessfullText: "支払いが成功しました",
            thankYouForPurchaseText: "ご購入ありがとうございます！",
            selectCurrencyText: "通貨を選択",
            selectLanguageText2: "言語を選択",
            placeHolderEmailText: "メールアドレスを入力",
            placeHolderPasswordText: "パスワードを入力",
            loginText2: "ログイン",
            
            logOutText: "ログアウト",
            norecipeAvailableText: "利用可能なレシピはありません。",
            notAvailableText: "利用不可",
            nameText: "名前:",
            countryText: "国:",
            currencyText: "通貨:",
            noTransactionsFoundText: "取引が見つかりません。",
            paySuccessText: "支払いが成功しました",
            yourAccountSetupDoneText: "アカウントの準備が整いました",
            youcannowLoginWithCredentialsText: "今すぐ資格情報でログインできます！",
            thankYouForJoiningUsText: "ご参加ありがとうございます！",
            
            JoinBakesoftText: "Bakesoftに参加して、あなたの素晴らしいレシピでお金を稼ぎましょう！",
            createAccountTodayText: "今日アカウントを作成して、今すぐレシピを販売し始めましょう！",
            plattformOfferingText: "素晴らしいプラットフォームを提供しており、世界中にあなたのレシピを作成して販売できます！",
            signUpOfferingText: "今すぐ登録して、14日間の試用期間を取得！",
            joinBakesoftNowText: "今すぐwww.bakesoft.seに参加！",
            accountDoneSetupText: "あなたのwww.bakesoft.seアカウントが準備できました！",
            loginemailandpassText: "メールとパスワードでログイン！",
            topublishRecipesOnLiveText: "live.bakesoft.seにレシピを公開するには、有料アカウントが必要です。",
            menyText:"メニュー",
            signUpText:"アカウントを作成",
            myAccountText:"マイアカウント",
            myBookmarkedRecipesText:"ブックマークしたレシピ",
            SignUpToBakesoftText:"live.bakesoft.seにサインアップ",
            howDoesItWorkText:"どのように動作しますか？",
            liveSignUpText1:"live.bakesoft.seにサインアップ",
            liveSignUpText:"サインアップ",
            confirmPasswordText:"パスワードを確認",
            currencyNLanguageText:"通貨と言語",
            buyOneCreditText:"1クレジットを購入",
            unlockedRecipesText:"ロック解除されたレシピ",
            accountDetailsText:"アカウントの詳細",
            receiptText:"領収書",
            savedRecipesText:"保存されたレシピ",
            endDateText:"終了日",
            deleteSavedRecipeText:"削除",
            nameText: "名前",
            slideToUnlockText: "スライドしてロックを解除",
            unlockedText2: "ロック解除",
            logoutOnlyText: "ログアウト",
            youreLoggedOutText: "ログアウトしました",

            bakesoftSignUp1: "live.bakesoft.seは素晴らしいレシピのための広告なしプラットフォームです。",
            bakesoftSignUp2: "1クレジットにつき1 USDでレシピをアンロックできます。カスタマイズしたり、栄養情報を取得したり、SNSで共有したり、さまざまな言語に翻訳したりできます。メンバーシップは無料で、サブスクリプション料金はありません。1クレジットで1つのレシピをアンロックできます。さまざまなシェフ、国、言語のレシピを探索してください。",
            bakesoftSignUp3: "レシピを作成してお金を稼ぎたいですか？",
            bakesoftSignUp4: "www.bakesoft.seを使って、レシピ管理を高度に行いましょう。私たちのプラットフォームで公開し、あなたのレシピがアンロックされるたびに収益を得ましょう。料金: 19 USD/月。",
            bakesoftSignUp5: "価格を設定してください（1～5 USD/レシピ）。販売されたクレジットごとに33%の収益を得られます。アプリ内で収益や支払いを追跡できます。ユーザーは自国の税金や法律に責任を持ちます。詳細は利用規約をご確認ください！"
            ,
            termsAndConditionsText:"利用規約",
            gotToLoginText:"ログインに移動",
            serviceS2Text: "レシピクレジットを5つ購入して10％割引を受ける",
            serviceS3Text: "レシピクレジットを10個購入して20％割引を受ける",
            serviceS4Text: "bakesoft.seに無料登録 – 14日間の無料お試し",
            creditsText: "クレジット",
            updateLanguageHeader: "言語を更新",
            submitButtonChangeLanguageText:"更新",
            kitchenTimerText: "キッチンタイマー",
             showHideText: "表示/非表示",
             forgotPasswordText: "パスワードをお忘れですか？",
             resetPassText:"パスワードをリセット",
             unlockRecipeText: "レシピをアンロック",
             recipeUnlockedText: "レシピがアンロックされました",

             availableInText: "利用可能な言語",
             languagesText: "言語",
             translateTo16LanguagesText: "16言語に翻訳",
             proChefText: "プロシェフ",

             voteRegisteredText: "あなたの投票は登録されました",

             copyLinkText: "リンクをコピー",
             pinterestText: "Pinterestで共有",
             instagramText: "Instagramで共有",
             twitterText: "X で共有",
             facebookText: "Facebookで共有",
             linkCopiedText: "リンクがコピーされました",

             welcomeToBakesoftText1: "ベイクソフトへようこそ",
             proRecipesText: "シェフやパン職人のためのプロのレシピ",
             updaterecipeText: "材料を編集、追加、更新",
             nutritionalText: "栄養価",
             signUpAndBonusText: "登録して5クレジットボーナスを獲得",

             signupbonusText: "登録して5クレジットボーナスを獲得",
             nocommitmentText: "契約なし、サブスクリプション不要",
             aboutUsLiveText: "私たちについて",
             
             
             
             

            },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Bakesoftへようこそ",
        unlockWorldOfCulinaryCreativityText: "料理の創造性の世界を解き放とう",
        discoverAmazingRecipesText: "驚くべきレシピを発見しよう",
        liveBakesoftText: "live.bakesoft.seは素晴らしいレシピのための広告なしのプラットフォームです。",
        affordableAccessText: "1ドル/クレジットでレシピをアンロック。サブスクリプション料金なし！",
        customizationText: "レシピをカスタマイズして栄養情報を取得",
        shareConnectText: "ソーシャルメディアで料理の作品を共有",
        globalCuisineText: "さまざまなシェフ、国、言語のレシピを探索",
        signUpNowText: "今すぐ登録",
        forRecipeCreatorsText: "レシピクリエイターの方へ",
        createRecipesEarnMoneyText: "レシピを作成してお金を稼ぎませんか？",
        advancedRecipeManagementText: "www.bakesoft.seを使用して、レシピを高度に管理。私たちのプラットフォームで公開し、レシピがアンロックされるたびに収入を得ることができます。価格：19ドル/月。",
        
        setYourPriceText: "価格を設定（1ドル～5ドル/レシピ）して、販売されたクレジットの33％を稼ぐ",
        trackEarningsText: "アプリ内で収益と支払いを追跡",
        usersResponsibleText: "ユーザーは自身の国の税金や法律に責任を持つ必要があります",
        startCreatingText: "作成を始めよう",
        readMoreInTermsText: "利用規約でさらに詳しく読む！",
        resetLinkText:"リセットリンクがメールアドレスに送信されました",

        affordableAccessHeader: "手頃なアクセス",
        customizationHeader: "カスタマイズ",
        shareandConnectHeader: "共有と接続",
        globalCuisineHeader: "世界の料理",

    },
    
    },
    tr:
    {

        common: {
            headerGreeting: "... harika anlar için harika tarifler",
            selectMenu: "Menü",
            inputTitleBookmark: "Bu tarifi yer işaretlerine ekle",
            imageTitleChef: "Şef profili",
            translateRecipeTextGrid: "Çevir",
            titleShareOnSocialMedia: "Sosyal medyada paylaş",
            textMinutesInShort: "dak",
            titleTextLikeRecipe: "Bu tarifi beğen",
            titleSeenByVievers: "Kullanıcılar tarafından görüntülendi",
            noDescriptionText: "Açıklama mevcut değil",
            scrollMessageText: "Harika tarifler çevriliyor",
            scrollMessageText2: "Harika tarifler getiriliyor",
            translateRecipeText: "Tarifi çevir",
            translateCostText: "Çeviri maliyeti: 1 kredi/başarılı çeviri",
            selectLanguageText: "Dil seçin",
            noNameAvailableText: "Ad mevcut değil",
            savedRecipeText: "Kaydedilen tarifler",
            clickToUnlockText: "Tıklayın/Kilidi açın",
            ingredientText: "Malzeme",
            pricePerKgText: "Fiyat/kg",
            priceText: "Fiyat",
            amountText: "Gram",
            kaloriesText: "Kalori",
            increaseIngredientValueText: "Malzeme değerini artır",
            decreaseIngredientValueText: "Malzeme değerini azalt",
            changeNameText: "Adı değiştir",
            saveText: "Kaydet",
            savedText: "Kaydedildi",
            deleteText: "Sil",
            totalText: "Toplam",
            addIngredientText: "Malzeme ekle",
            addText: "Ekle",
            placeHolderIngredientNameText: "Malzeme adı",
            descriptionText: "Açıklama",
            
            unlockThisRecipeText: "Bu tarifi kilidini açmak için",
            creditsToUseText: "- kullanılacak krediler",
            creditsText: "Krediler",
            noCreditsText: "Bu tarifi açmak için krediniz yok",
            addMoreCreditsText: "Daha fazla kredi ekle",
            gotoPaymentText: "Ödemeye git",
            chefInFocusText: "Odaktaki şef",
            
            bsLivePaymentText: "Bakesoft ödeme bilgileri",
            bsLiveAmountText: "Miktar",
            bsLiveCurrencyText: "Para birimi",
            bsLivePaymentTermsText: "Ödeme koşulları",
            
            paymentSuccessfullText: "Ödeme başarılı",
            thankYouForPurchaseText: "Satın aldığınız için teşekkürler!",
            selectCurrencyText: "Para birimini seçin",
            selectLanguageText2: "Dili seçin",
            placeHolderEmailText: "E-posta adresinizi girin",
            placeHolderPasswordText: "Şifrenizi girin",
            loginText2: "Giriş yap",
            
            logOutText: "Çıkış yap",
            norecipeAvailableText: "Mevcut tarif yok.",
            notAvailableText: "Mevcut değil",
            nameText: "Ad:",
            countryText: "Ülke:",
            currencyText: "Para birimi:",
            noTransactionsFoundText: "Hiçbir işlem bulunamadı.",
            paySuccessText: "Ödeme başarılı oldu",
            yourAccountSetupDoneText: "Hesabınız şimdi hazır",
            youcannowLoginWithCredentialsText: "Şimdi kimlik bilgilerinizle giriş yapabilirsiniz!",
            thankYouForJoiningUsText: "Bize katıldığınız için teşekkür ederiz!",
            
            JoinBakesoftText: "Bakesoft'a katılın ve harika tariflerinizle para kazanın!",
            createAccountTodayText: "Bugün bir hesap oluşturun ve tariflerinizi şimdi satmaya başlayın!",
            plattformOfferingText: "Dünyaya tariflerinizi oluşturup satabileceğiniz harika bir platform sunuyoruz!",
            signUpOfferingText: "Bugün kaydolun ve 14 günlük deneme süresi kazanın!",
            joinBakesoftNowText: "Hemen şimdi www.bakesoft.se'ye katılın!",
            accountDoneSetupText: "www.bakesoft.se hesabınız artık hazır!",
            loginemailandpassText: "E-posta ve şifre ile giriş yapın!",
            topublishRecipesOnLiveText: "live.bakesoft.se'de tarif yayınlamak için ücretli bir hesaba ihtiyacınız var.",
            menyText:"Menü",
            signUpText:"Hesap oluştur",
            myAccountText:"Hesabım",
            myBookmarkedRecipesText:"Yer işaretli tariflerim",
            SignUpToBakesoftText:"Sign up to live.bakesoft.se",
            howDoesItWorkText:"Nasıl çalışır?",
            liveSignUpText1:"live.bakesoft.se'ye kaydol",

            confirmPasswordText:"Şifreyi onayla",
            currencyNLanguageText:"Para birimi ve dil",
            liveSignupText:"Kayıt ol",
            buyOneCreditText:"1 kredi satın al",
            unlockedRecipesText:"Kilidi açılan tarifler",
            accountDetailsText:"Hesap detayları",
            receiptText:"Fiş",
            savedRecipesText:"Kaydedilen tarifler",
            endDateText:"Bitiş tarihi",
            deleteSavedRecipeText:"Sil",
            nameText: "Ad",
            slideToUnlockText: "Kilidi açmak için kaydır",
            unlockedText2: "Kilidi açıldı",
            logoutOnlyText: "Çıkış",
            youreLoggedOutText: "Çıkış yaptınız",
            bakesoftSignUp1: "live.bakesoft.se, harika tarifler için reklamsız bir platformdur.",
            bakesoftSignUp2: "Tarifleri 1 USD/kredi karşılığında açabilirsiniz. Tarifleri kişiselleştirin, besin bilgisi alın, sosyal medyada paylaşın ve farklı dillere çevirin. Üyelik ücretsizdir, abonelik ücreti yoktur. Bir tarif açmak için bir kredi gereklidir. Farklı şeflerin, ülkelerin ve dillerin tariflerini keşfedin.",
            bakesoftSignUp3: "Kendi tariflerinizi oluşturup para kazanmak ister misiniz?",
            bakesoftSignUp4: "www.bakesoft.se'yi kullanarak gelişmiş tarif yönetimi yapın. Tariflerinizi platformlarımızda yayınlayın ve her açıldığında gelir elde edin. Fiyat: 19 USD/ay.",
            bakesoftSignUp5: "Fiyatınızı belirleyin (1–5 USD/tarif) ve satılan her krediden %33 kazanın. Uygulamada kazançlarınızı ve ödemelerinizi takip edin. Kullanıcılar, kendi ülkelerindeki vergi ve yasalardan sorumludur. Daha fazla bilgi için şartlarımızı okuyun!"
            ,
            termsAndConditionsText:"Şartlar ve koşullar",
            gotToLoginText:"Girişe git",
            serviceS2Text: "5 tarif kredisi satın alın ve %10 indirim kazanın",
            serviceS3Text: "10 tarif kredisi satın alın ve %20 indirim kazanın",
            serviceS4Text: "bakesoft.se'ye ücretsiz kaydolun – 14 günlük deneme",
            creditsText: "Krediler",
            updateLanguageHeader: "Dili güncelle",
            submitButtonChangeLanguageText:"Güncelle",
            kitchenTimerText: "Mutfak zamanlayıcısı",
             showHideText: "Göster/Gizle",
             forgotPasswordText: "Şifrenizi mi unuttunuz?",
             resetPassText:"Şifreyi sıfırla",
             resetLinkText:"Sıfırlama bağlantısı e-posta adresinize gönderildi",
             unlockRecipeText: "Tarifi kilidi aç",
             recipeUnlockedText: "Tarif kilidi açıldı",
             voteRegisteredText: "Oyunuz kaydedildi",

             copyLinkText: "Bağlantıyı kopyala",
             pinterestText: "Pinterest'te paylaş",
             instagramText: "Instagram'da paylaş",
             twitterText: "X 'da paylaş",
             facebookText: "Facebook'ta paylaş",
             linkCopiedText: "Bağlantı kopyalandı",

             welcomeToBakesoftText1: "Bakesoft'a Hoş Geldiniz",
             proRecipesText: "Şefler ve fırıncılar için profesyonel tarifler",
             updaterecipeText: "Malzemeleri düzenleyin, ekleyin ve güncelleyin",
             nutritionalText: "Besin değerleri",
             signUpAndBonusText: "Kayıt olun ve 5 kredi bonus kazanın",

             signupbonusText: "Kaydolun ve 5 kredi bonusu kazanın",
             nocommitmentText: "Taahhüt yok, abonelik yok",
             aboutUsLiveText: "Hakkımızda",
             
             
             


            
        },
        bakesoftPromotion: {
            welcomeToBakesoftText: "Bakesoft'a Hoş Geldiniz",
            unlockWorldOfCulinaryCreativityText: "Mutfak yaratıcılığının dünyasını keşfedin",
            discoverAmazingRecipesText: "Harika tarifler keşfedin",
            liveBakesoftText: "live.bakesoft.se, harika tarifler için reklamsız bir platformdur.",
            affordableAccessText: "Tarifleri 1$/kredi karşılığında açın. Abonelik ücreti yok!",
            customizationText: "Tarifleri özelleştirin ve besin bilgisi alın",
            shareConnectText: "Yemek yaratımlarınızı sosyal medyada paylaşın",
            globalCuisineText: "Farklı şeflerin, ülkelerin ve dillerin tariflerini keşfedin",
            signUpNowText: "Hemen Kaydolun",
            forRecipeCreatorsText: "Tarif Oluşturanlar İçin",
            createRecipesEarnMoneyText: "Tarif oluşturup para kazanmak ister misiniz?",
            advancedRecipeManagementText: "www.bakesoft.se'yi kullanarak gelişmiş tarif yönetimi yapın. Tariflerinizi platformlarımızda yayınlayın ve her tarif açıldığında para kazanın. Fiyat: 19$/ay.",
            
            setYourPriceText: "Fiyatınızı belirleyin (1$–5$/tarif) ve satılan kredilerden %33 kazanın",
            trackEarningsText: "Kazanımlarınızı ve ödemelerinizi uygulama üzerinden takip edin",
            usersResponsibleText: "Kullanıcılar kendi ülkelerindeki vergi ve yasalardan sorumludur",
            startCreatingText: "Yaratmaya Başlayın",
            readMoreInTermsText: "Şartlarımızı okuyun!",

            affordableAccessHeader: "Uygun Fiyatlı Erişim",
            customizationHeader: "Özelleştirme",
            shareandConnectHeader: "Paylaş ve Bağlan",
            globalCuisineHeader: "Küresel Mutfak",

            availableInText: "Mevcut Diller",
            languagesText: "Diller",
            translateTo16LanguagesText: "16 dile çevir",
            proChefText: "Profesyonel Şef",
            

        },
        
    },
    kr: {
        common: {
            headerGreeting: "... 멋진 순간을 위한 훌륭한 레시피",
selectMenu: "메뉴",
inputTitleBookmark: "이 레시피를 북마크에 추가",
imageTitleChef: "셰프 프로필",
translateRecipeTextGrid: "번역",
titleShareOnSocialMedia: "소셜 미디어에서 공유",
textMinutesInShort: "분",
titleTextLikeRecipe: "이 레시피를 좋아요",
titleSeenByVievers: "사용자가 본 횟수",
noDescriptionText: "설명이 없습니다",
scrollMessageText: "훌륭한 레시피를 번역 중",
scrollMessageText2: "훌륭한 레시피를 가져오는 중",
translateRecipeText: "레시피 번역",
translateCostText: "번역 비용: 1 크레딧/성공적인 번역",
selectLanguageText: "언어 선택",
noNameAvailableText: "이름이 없습니다",
savedRecipeText: "저장된 레시피",
clickToUnlockText: "클릭/잠금 해제",
ingredientText: "재료",
pricePerKgText: "가격/kg",
priceText: "가격",
amountText: "수량",
kaloriesText: "칼로리",
increaseIngredientValueText: "재료 값 증가",
decreaseIngredientValueText: "재료 값 감소",
changeNameText: "이름 변경",
saveText: "저장",
savedText: "저장됨",
deleteText: "삭제",
totalText: "총합",
addIngredientText: "재료 추가",
addText: "추가",
placeHolderIngredientNameText: "재료 이름",
descriptionText: "설명",

unlockThisRecipeText: "이 레시피를 잠금 해제하려면",
creditsToUseText: "- 사용 가능한 크레딧",
creditsText: "크레딧",
noCreditsText: "이 레시피를 잠금 해제할 크레딧이 없습니다",
addMoreCreditsText: "크레딧 추가",
gotoPaymentText: "결제로 이동",
chefInFocusText: "주목받는 셰프",

bsLivePaymentText: "Bakesoft 결제 정보",
bsLiveAmountText: "금액",
bsLiveCurrencyText: "통화",
bsLivePaymentTermsText: "결제 조건",

paymentSuccessfullText: "결제가 성공적으로 완료되었습니다",
thankYouForPurchaseText: "구매해 주셔서 감사합니다!",
selectCurrencyText: "통화 선택",
selectLanguageText2: "언어 선택",
placeHolderEmailText: "이메일 주소 입력",
placeHolderPasswordText: "비밀번호 입력",
loginText2: "로그인",

logOutText: "로그아웃",
norecipeAvailableText: "사용 가능한 레시피가 없습니다.",
notAvailableText: "사용 불가",
nameText: "이름:",
countryText: "국가:",
currencyText: "통화:",
noTransactionsFoundText: "거래 내역이 없습니다.",
paySuccessText: "결제가 성공적으로 완료되었습니다",
yourAccountSetupDoneText: "계정 설정이 완료되었습니다",
youcannowLoginWithCredentialsText: "이제 자격 증명으로 로그인할 수 있습니다!",
thankYouForJoiningUsText: "가입해 주셔서 감사합니다!",

JoinBakesoftText: "Bakesoft에 가입하고 훌륭한 레시피로 수익을 창출하세요!",
createAccountTodayText: "오늘 계정을 만들어 지금 바로 레시피 판매를 시작하세요!",
plattformOfferingText: "레시피를 제작하고 전 세계에 판매할 수 있는 훌륭한 플랫폼을 제공합니다!",
signUpOfferingText: "지금 가입하고 14일간의 무료 체험 기간을 누리세요!",
joinBakesoftNowText: "지금 www.bakesoft.se에 가입하세요!",
accountDoneSetupText: "www.bakesoft.se 계정이 준비되었습니다!",
loginemailandpassText: "이메일과 비밀번호로 로그인하세요!",
topublishRecipesOnLiveText: "live.bakesoft.se에 레시피를 게시하려면 유료 계정이 필요합니다.",

menyText:"메뉴",
signUpText:"계정 만들기",
myAccountText:"내 계정",
myBookmarkedRecipesText:"내 즐겨찾기 레시피",
SignUpToBakesoftText:"live.bakesoft.se에 가입",
howDoesItWorkText:"어떻게 작동합니까?",
liveSignUpText1:"live.bakesoft.se에 가입",

confirmPasswordText:"비밀번호 확인",
currencyNLanguageText:"통화 및 언어",
liveSignupText:"가입",
buyOneCreditText:"1 크레딧 구매",
unlockedRecipesText:"잠금 해제된 레시피",
accountDetailsText:"계정 세부정보",
receiptText:"영수증",
savedRecipesText:"저장된 레시피",
endDateText:"종료 날짜",
deleteSavedRecipeText:"삭제",
nameText: "이름",
slideToUnlockText: "잠금 해제하려면 슬라이드하세요",
unlockedText2: "잠금 해제됨",
logoutOnlyText: "로그아웃",
youreLoggedOutText: "로그아웃되었습니다",

bakesoftSignUp1: "live.bakesoft.se는 놀라운 요리 레시피를 위한 광고 없는 플랫폼입니다.",
bakesoftSignUp2: "레시피를 1 USD/크레딧으로 잠금 해제하세요. 레시피를 맞춤 설정하고, 영양 정보를 확인하며, SNS에 공유하고, 다양한 언어로 번역할 수 있습니다. 회원가입은 무료이며, 구독료는 없습니다. 한 레시피를 열기 위해 1 크레딧이 필요합니다. 다양한 셰프, 국가, 언어의 레시피를 탐색하세요.",
bakesoftSignUp3: "자신만의 레시피를 만들고 돈을 벌고 싶으신가요?",
bakesoftSignUp4: "www.bakesoft.se에서 고급 레시피 관리 기능을 활용하세요. 우리의 플랫폼에 레시피를 게시하고, 레시피가 잠금 해제될 때마다 수익을 얻으세요. 가격: 월 19 USD.",
bakesoftSignUp5: "가격을 설정하세요 (1–5 USD/레시피) 그리고 판매된 크레딧당 33%의 수익을 얻으세요. 앱에서 수익과 지급 내역을 확인할 수 있습니다. 사용자는 자신의 국가에서의 세금 및 법적 문제를 책임집니다. 자세한 내용은 이용 약관을 확인하세요!"
,
termsAndConditionsText:"약관 및 조건",
gotToLoginText:"로그인하러 가기",
serviceS2Text: "레시피 크레딧 5개 구매 시 10% 할인",
serviceS3Text: "레시피 크레딧 10개 구매 시 20% 할인",
serviceS4Text: "bakesoft.se에 무료로 가입하세요 – 14일 무료 체험",
creditsText: "크레딧",
updateLanguageHeader: "언어 업데이트",
submitButtonChangeLanguageText:"업데이트",
kitchenTimerText: "주방 타이머",
 showHideText: "표시/숨기기",
 forgotPasswordText: "비밀번호를 잊으셨나요?",
 resetPassText:"비밀번호 재설정",
 resetLinkText:"재설정 링크가 이메일로 전송되었습니다",
 unlockRecipeText: "레시피 잠금 해제",
 recipeUnlockedText: "레시피가 잠금 해제되었습니다",

 availableInText: "사용 가능한 언어",
 languagesText: "언어",
 translateTo16LanguagesText: "16개 언어로 번역",
 proChefText: "프로 셰프",
 voteRegisteredText: "투표가 등록되었습니다",

 copyLinkText: "링크 복사",
 pinterestText: "Pinterest에서 공유",
 instagramText: "Instagram에서 공유",
 twitterText: "X 에서 공유",
 facebookText: "Facebook에서 공유",
 linkCopiedText: "링크가 복사되었습니다",

 welcomeToBakesoftText1: "Bakesoft에 오신 것을 환영합니다",
proRecipesText: "셰프와 제빵사를 위한 전문 레시피",
updaterecipeText: "재료 수정, 추가 및 업데이트",
nutritionalText: "영양 정보",
signUpAndBonusText: "가입하고 5 크레딧 보너스를 받으세요",

signupbonusText: "가입하고 5크레딧 보너스를 받아보세요",
nocommitmentText: "약정 없음, 구독 없음",
aboutUsLiveText: "우리에 대해",


 
 

        },

        bakesoftPromotion: {
            welcomeToBakesoftText: "Bakesoft에 오신 것을 환영합니다",
            unlockWorldOfCulinaryCreativityText: "요리 창의성의 세계를 열어보세요",
            discoverAmazingRecipesText: "놀라운 레시피를 발견하세요",
            liveBakesoftText: "live.bakesoft.se는 놀라운 레시피를 위한 광고 없는 플랫폼입니다.",
            affordableAccessText: "레시피를 1달러/크레딧으로 잠금 해제하세요. 구독료 없음!",
            customizationText: "레시피를 맞춤화하고 영양 정보를 얻으세요",
            shareConnectText: "요리 창작물을 소셜 미디어에 공유하세요",
            globalCuisineText: "다양한 셰프, 국가 및 언어의 레시피를 탐색하세요",
            signUpNowText: "지금 가입하세요",
            forRecipeCreatorsText: "레시피 제작자를 위해",
            createRecipesEarnMoneyText: "레시피를 제작하고 돈을 벌고 싶으신가요?",
            advancedRecipeManagementText: "www.bakesoft.se를 사용하여 고급 레시피 관리를 하세요. 플랫폼에 게시하고 레시피가 잠금 해제될 때마다 수익을 얻으세요. 가격: 월 $19.",
            
            setYourPriceText: "가격을 설정하세요 ($1–$5/레시피) 그리고 판매된 크레딧당 33%를 벌어보세요",
            trackEarningsText: "앱에서 수익과 지급 내역을 추적하세요",
            usersResponsibleText: "사용자는 자신이 거주하는 국가의 세금과 법률을 준수해야 합니다",
            startCreatingText: "창작을 시작하세요",
            readMoreInTermsText: "이용 약관에서 자세히 알아보세요!",

            affordableAccessHeader: "합리적인 접근",
            customizationHeader: "맞춤 설정",
            shareandConnectHeader: "공유 및 연결",
            globalCuisineHeader: "세계 요리",

        },
        
    },
    de: {
        common: {
            headerGreeting: "... großartige Rezepte für wunderbare Momente",
selectMenu: "Menü",
inputTitleBookmark: "Dieses Rezept speichern",
imageTitleChef: "Profil des Kochs",
translateRecipeTextGrid: "Übersetzen",
titleShareOnSocialMedia: "In sozialen Medien teilen",
textMinutesInShort: "Min",
titleTextLikeRecipe: "Dieses Rezept mögen",
titleSeenByVievers: "Gesehen von Nutzern",
noDescriptionText: "Keine Beschreibung verfügbar",
scrollMessageText: "Großartige Rezepte werden übersetzt",
scrollMessageText2: "Großartige Rezepte werden geladen",
translateRecipeText: "Rezept übersetzen",
translateCostText: "Übersetzungskosten: 1 Kredit/erfolgreiche Übersetzung",
selectLanguageText: "Sprache auswählen",
noNameAvailableText: "Kein Name verfügbar",
savedRecipeText: "Gespeicherte Rezepte",
clickToUnlockText: "Klicken/Entsperren",
ingredientText: "Zutat",
pricePerKgText: "Preis/kg",
priceText: "Preis",
amountText: "Gramm",
kaloriesText: "Kalorien",
increaseIngredientValueText: "Zutatenwert erhöhen",
decreaseIngredientValueText: "Zutatenwert verringern",
changeNameText: "Namen ändern",
saveText: "Speichern",
savedText: "Gespeichert",
deleteText: "Löschen",
totalText: "Gesamt",
addIngredientText: "Zutat hinzufügen",
addText: "Hinzufügen",
placeHolderIngredientNameText: "Zutatenname",
descriptionText: "Beschreibung",

unlockThisRecipeText: "Dieses Rezept entsperren für",
creditsToUseText: "- zu verwendende Credits",
creditsText: "Credits",
noCreditsText: "Sie haben keine Credits, um dieses Rezept zu entsperren",
addMoreCreditsText: "Mehr Credits hinzufügen",
gotoPaymentText: "Zur Zahlung gehen",
chefInFocusText: "Im Fokus: Der Koch",

bsLivePaymentText: "Bakesoft Zahlungsinformationen",
bsLiveAmountText: "Betrag",
bsLiveCurrencyText: "Währung",
bsLivePaymentTermsText: "Zahlungsbedingungen",

paymentSuccessfullText: "Zahlung erfolgreich",
thankYouForPurchaseText: "Vielen Dank für Ihren Einkauf!",
selectCurrencyText: "Währung auswählen",
selectLanguageText2: "Sprache auswählen",
placeHolderEmailText: "Ihre E-Mail-Adresse eingeben",
placeHolderPasswordText: "Ihr Passwort eingeben",
loginText2: "Anmelden",

logOutText: "Abmelden",
norecipeAvailableText: "Keine Rezepte verfügbar.",
notAvailableText: "Nicht verfügbar",
nameText: "Name:",
countryText: "Land:",
currencyText: "Währung:",
noTransactionsFoundText: "Keine Transaktionen gefunden.",
paySuccessText: "Zahlung erfolgreich",
yourAccountSetupDoneText: "Ihr Konto ist jetzt einsatzbereit",
youcannowLoginWithCredentialsText: "Sie können sich jetzt mit Ihren Zugangsdaten anmelden!",
thankYouForJoiningUsText: "Vielen Dank und willkommen bei uns!",

JoinBakesoftText: "Treten Sie Bakesoft bei und verdienen Sie Geld mit Ihren großartigen Rezepten!",
createAccountTodayText: "Erstellen Sie noch heute ein Konto und beginnen Sie sofort mit dem Verkauf Ihrer Rezepte!",
plattformOfferingText: "Wir bieten eine großartige Plattform, auf der Sie Ihre Rezepte erstellen und weltweit verkaufen können!",
signUpOfferingText: "Melden Sie sich noch heute an und erhalten Sie eine 14-tägige Testphase!",
joinBakesoftNowText: "Treten Sie jetzt www.bakesoft.se bei!",
accountDoneSetupText: "Ihr www.bakesoft.se Konto ist jetzt fertig!",
loginemailandpassText: "Melden Sie sich mit E-Mail und Passwort an!",
topublishRecipesOnLiveText: "Um Rezepte auf live.bakesoft.se zu veröffentlichen, benötigen Sie ein kostenpflichtiges Konto.",
menyText:"Menü",
signUpText:"Konto erstellen",
myAccountText:"Mein Konto",
myBookmarkedRecipesText:"Meine L",
SignUpToBakesoftText:"Bei live.bakesoft.se anmelden",
howDoesItWorkText:"Wie funktioniert es?",
liveSignUpText1:"Bei live.bakesoft.se anmelden",

confirmPasswordText:"Passwort bestätigen",
currencyNLanguageText:"Währung und Sprache",
liveSignupText:"Anmelden",
buyOneCreditText:"1 Kredit kaufen",
unlockedRecipesText:"Entsperrte Rezepte",
accountDetailsText:"Kontodetails",
receiptText:"Beleg",
savedRecipesText:"Gespeicherte Rezepte",
endDateText:"Enddatum",
deleteSavedRecipeText:"Löschen",
nameText: "Name",
slideToUnlockText: "Zum Entsperren schieben",
unlockedText2: "Entsperrt",
logoutOnlyText: "Abmelden",
youreLoggedOutText: "Sie haben sich abgemeldet",

bakesoftSignUp1: "live.bakesoft.se ist eine werbefreie Plattform für fantastische Rezepte.",
bakesoftSignUp2: "Schalte Rezepte für 1 USD/Kredit frei. Passe sie an, erhalte Nährwertinformationen, teile sie in sozialen Medien und übersetze sie in verschiedene Sprachen. Die Mitgliedschaft ist kostenlos – keine Abogebühren. Ein Kredit schaltet ein Rezept frei. Entdecke Rezepte von verschiedenen Köchen, Ländern und Sprachen.",
bakesoftSignUp3: "Möchtest du eigene Rezepte erstellen und damit Geld verdienen?",
bakesoftSignUp4: "Nutze www.bakesoft.se für fortschrittliches Rezeptmanagement. Veröffentliche deine Rezepte auf unseren Plattformen und verdiene Geld, jedes Mal, wenn ein Rezept freigeschaltet wird. Preis: 19 USD/Monat.",
bakesoftSignUp5: "Lege deinen Preis fest (1–5 USD/Rezept) und verdiene 33 % pro verkauftem Kredit. Verfolge Einnahmen und Auszahlungen in der App. Nutzer sind für Steuern und gesetzliche Regelungen in ihrem Land selbst verantwortlich. Lies unsere Bedingungen für weitere Informationen!"
,
termsAndConditionsText:"Nutzungsbedingungen",
gotToLoginText:"Zum Login gehen",
serviceS2Text: "5 Rezept-Credits mit 10% Rabatt",
serviceS3Text: "10 Rezept-Credits mit 20% Rabatt",
serviceS4Text: "Kostenlos anmelden – 14 Tage testen",
creditsText: "Credits",
updateLanguageHeader: "Sprache aktualisieren",
submitButtonChangeLanguageText:"Aktualisieren",
kitchenTimerText: "Küchentimer",
 showHideText: "Anzeigen/Ausblenden",
 forgotPasswordText: "Passwort vergessen?",
 resetPassText:"Passwort zurücksetzen",
 resetLinkText:"Reset-Link wurde an Ihre E-Mail-Adresse gesendet",
 unlockRecipeText: "Rezept entsperren",
 recipeUnlockedText: "Rezept entsperrt",
 voteRegisteredText: "Ihre Stimme wurde registriert",

 copyLinkText: "Link kopieren",
 pinterestText: "Auf Pinterest teilen",
 instagramText: "Auf Instagram teilen",
 twitterText: "Auf X teilen",
 facebookText: "Auf Facebook teilen",
 linkCopiedText: "Link kopiert",

 welcomeToBakesoftText1: "Willkommen bei Bakesoft",
proRecipesText: "Professionelle Rezepte für Köche und Bäcker",
updaterecipeText: "Zutaten bearbeiten, hinzufügen und aktualisieren",
nutritionalText: "Nährwerte",
signUpAndBonusText: "Registrieren Sie sich und erhalten Sie 5 Bonus-Credits",

signupbonusText: "Registrieren Sie sich und erhalten Sie 5 Kredit-Bonus",
nocommitmentText: "Keine Bindung, kein Abonnement",
aboutUsLiveText: "Über uns",


 

},
bakesoftPromotion: {
    welcomeToBakesoftText: "Willkommen bei Bakesoft",
    unlockWorldOfCulinaryCreativityText: "Entdecken Sie eine Welt kulinarischer Kreativität",
    discoverAmazingRecipesText: "Entdecken Sie erstaunliche Rezepte",
    liveBakesoftText: "live.bakesoft.se ist eine werbefreie Plattform für erstaunliche Rezepte.",
    affordableAccessText: "Entsperren Sie Rezepte für 1 $/Kredit. Keine Abonnementgebühren!",
    customizationText: "Passen Sie Rezepte an und erhalten Sie Nährwertinformationen",
    shareConnectText: "Teilen Sie Ihre kulinarischen Kreationen in sozialen Medien",
    globalCuisineText: "Entdecken Sie Rezepte von verschiedenen Köchen, Ländern und Sprachen",
    signUpNowText: "Jetzt registrieren",
    forRecipeCreatorsText: "Für Rezeptautoren",
    createRecipesEarnMoneyText: "Möchten Sie Rezepte erstellen und Geld verdienen?",
    advancedRecipeManagementText: "Nutzen Sie www.bakesoft.se für ein fortschrittliches Rezeptmanagement. Veröffentlichen Sie auf unseren Plattformen und verdienen Sie Geld, jedes Mal wenn Ihr Rezept freigeschaltet wird. Preis: 19 $/Monat.",
    
    setYourPriceText: "Legen Sie Ihren Preis fest (1–5 $/Rezept) und verdienen Sie 33 % pro verkauftem Kredit",
    trackEarningsText: "Verfolgen Sie Einnahmen und Auszahlungen in der App",
    usersResponsibleText: "Die Nutzer sind für Steuern und Gesetze in ihrem Land verantwortlich",
    startCreatingText: "Jetzt starten",
    readMoreInTermsText: "Lesen Sie mehr in unseren Bedingungen!",

    affordableAccessHeader: "Erschwinglicher Zugang",
    customizationHeader: "Anpassung",
    shareandConnectHeader: "Teilen und Verbinden",
    globalCuisineHeader: "Globale Küche",

    availableInText: "Verfügbar in",
    languagesText: "Sprachen",
    translateTo16LanguagesText: "In 16 Sprachen übersetzen",
    proChefText: "Profi-Koch",
    

},

    },
    vi: {
        common: {
            headerGreeting: "... những công thức tuyệt vời cho những khoảnh khắc đáng nhớ",
            selectMenu: "Menu",
            inputTitleBookmark: "Đánh dấu công thức này",
            imageTitleChef: "Hồ sơ đầu bếp",
            translateRecipeTextGrid: "Dịch",
            titleShareOnSocialMedia: "Chia sẻ trên mạng xã hội",
            textMinutesInShort: "phút",
            titleTextLikeRecipe: "Yêu thích công thức này",
            titleSeenByVievers: "Được xem bởi người dùng",
            noDescriptionText: "Không có mô tả",
            scrollMessageText: "Đang dịch những công thức tuyệt vời",
            scrollMessageText2: "Đang tải những công thức tuyệt vời",
            translateRecipeText: "Dịch công thức",
            translateCostText: "Chi phí dịch: 1 tín dụng/dịch thành công",
            selectLanguageText: "Chọn ngôn ngữ",
            noNameAvailableText: "Không có tên",
            savedRecipeText: "Công thức đã lưu",
            clickToUnlockText: "Nhấn/Mở khóa",
            ingredientText: "Nguyên liệu",
            pricePerKgText: "Giá/kg",
            priceText: "Giá",
            amountText: "Số lượng",
            kaloriesText: "Calo",
            increaseIngredientValueText: "Tăng giá trị nguyên liệu",
            decreaseIngredientValueText: "Giảm giá trị nguyên liệu",
            changeNameText: "Đổi tên",
            saveText: "Lưu",
            savedText: "Đã lưu",
            deleteText: "Xóa",
            totalText: "Tổng cộng",
            addIngredientText: "Thêm nguyên liệu",
            addText: "Thêm",
            placeHolderIngredientNameText: "Tên nguyên liệu",
            descriptionText: "Mô tả",
            
            unlockThisRecipeText: "Mở khóa công thức này với",
            creditsToUseText: "- tín dụng cần sử dụng",
            creditsText: "tín dụng",
            noCreditsText: "Bạn không có đủ tín dụng để mở khóa công thức này",
            addMoreCreditsText: "Thêm tín dụng",
            gotoPaymentText: "Đi đến thanh toán",
            chefInFocusText: "Đầu bếp nổi bật",
            
            bsLivePaymentText: "Thông tin thanh toán Bakesoft",
            bsLiveAmountText: "Số tiền",
            bsLiveCurrencyText: "Loại tiền",
            bsLivePaymentTermsText: "Điều khoản thanh toán",
            
            paymentSuccessfullText: "Thanh toán thành công",
            thankYouForPurchaseText: "Cảm ơn bạn đã mua hàng!",
            selectCurrencyText: "Chọn loại tiền",
            selectLanguageText2: "Chọn ngôn ngữ",
            placeHolderEmailText: "Nhập địa chỉ email của bạn",
            placeHolderPasswordText: "Nhập mật khẩu của bạn",
            loginText2: "Đăng nhập",
            
            logOutText: "Đăng xuất",
            norecipeAvailableText: "Không có công thức nào.",
            notAvailableText: "Không khả dụng",
            nameText: "Tên:",
            countryText: "Quốc gia:",
            currencyText: "Loại tiền:",
            noTransactionsFoundText: "Không tìm thấy giao dịch nào.",
            paySuccessText: "Thanh toán thành công",
            yourAccountSetupDoneText: "Tài khoản của bạn đã sẵn sàng",
            youcannowLoginWithCredentialsText: "Bạn có thể đăng nhập ngay bây giờ với thông tin đăng nhập của mình!",
            thankYouForJoiningUsText: "Cảm ơn và chào mừng bạn đến với chúng tôi!",
            
            JoinBakesoftText: "Tham gia Bakesoft và kiếm tiền với những công thức tuyệt vời của bạn!",
            createAccountTodayText: "Tạo tài khoản ngay hôm nay và bắt đầu bán công thức của bạn ngay bây giờ!",
            plattformOfferingText: "Chúng tôi cung cấp một nền tảng tuyệt vời để bạn tạo và bán công thức của mình trên toàn thế giới!",
            signUpOfferingText: "Đăng ký ngay hôm nay và nhận 14 ngày dùng thử miễn phí!",
            joinBakesoftNowText: "Tham gia ngay www.bakesoft.se!",
            accountDoneSetupText: "Tài khoản www.bakesoft.se của bạn đã sẵn sàng!",
            loginemailandpassText: "Đăng nhập bằng email và mật khẩu!",
            topublishRecipesOnLiveText: "Để đăng công thức lên live.bakesoft.se, bạn cần có tài khoản trả phí.",
            menyText:"Menu",
            signUpText:"Tạo tài khoản",
            myAccountText:"Tài khoản của tôi",
            myBookmarkedRecipesText:"Công thức đã đánh dấu của tôi",
            SignUpToBakesoftText:"Đăng ký live.bakesoft.se",
            howDoesItWorkText:"Làm thế nào để hoạt động?",
            liveSignUpText:"Đăng ký",

            confirmPasswordText:"Xác nhận mật khẩu",
            currencyNLanguageText:"Tiền tệ và ngôn ngữ",
            liveSignUpText:"Đăng ký",
            buyOneCreditText:"Mua 1 tín dụng",
            unlockedRecipesText:"Công thức đã mở khóa",
            accountDetailsText:"Chi tiết tài khoản",
            receiptText:"Biên lai",
            savedRecipesText:"Công thức đã lưu",
            endDateText:"Ngày kết thúc",
            deleteSavedRecipeText:"Xóa",
            nameText: "Tên",
            slideToUnlockText: "Trượt để mở khóa",
            unlockedText2: "Đã mở khóa",
            logoutOnlyText: "Đăng xuất",
            youreLoggedOutText: "Bạn đã đăng xuất",

            bakesoftSignUp1: "live.bakesoft.se là một nền tảng không có quảng cáo dành cho những công thức nấu ăn tuyệt vời.",
            bakesoftSignUp2: "Mở khóa công thức với 1 USD/tín dụng. Tùy chỉnh công thức, nhận thông tin dinh dưỡng, chia sẻ lên mạng xã hội và dịch sang nhiều ngôn ngữ. Đăng ký thành viên miễn phí – không có phí thuê bao. Một tín dụng mở khóa một công thức. Khám phá công thức từ các đầu bếp, quốc gia và ngôn ngữ khác nhau.",
            bakesoftSignUp3: "Bạn muốn tạo công thức riêng và kiếm tiền từ nó?",
            bakesoftSignUp4: "Sử dụng www.bakesoft.se để quản lý công thức nâng cao. Đăng công thức của bạn lên nền tảng của chúng tôi và kiếm tiền mỗi lần công thức được mở khóa. Giá: 19 USD/tháng.",
            bakesoftSignUp5: "Đặt giá của bạn (1–5 USD/công thức) và kiếm 33% từ mỗi tín dụng bán được. Theo dõi thu nhập và khoản thanh toán trong ứng dụng. Người dùng tự chịu trách nhiệm về thuế và luật pháp tại quốc gia của mình. Đọc điều khoản của chúng tôi để biết thêm thông tin!"
,            
            termsAndConditionsText:"Điều khoản và điều kiện",
            gotToLoginText:"Đi đến trang đăng nhập",
            serviceS2Text: "Mua 5 tín chỉ công thức và nhận giảm giá 10%",
            serviceS3Text: "Mua 10 tín chỉ công thức và nhận giảm giá 20%",
            serviceS4Text: "Đăng ký bakesoft.se miễn phí – dùng thử 14 ngày",
            creditsText: "tín dụng",
            updateLanguageHeader: "Cập nhật ngôn ngữ",
            submitButtonChangeLanguageText:"Cập nhật",
            kitchenTimerText: "Đồng hồ bếp",
             showHideText: "Hiện/Ẩn",
             forgotPasswordText: "Quên mật khẩu?",
             resetPassText:"Đặt lại mật khẩu",
             resetLinkText:"Liên kết đặt lại đã được gửi qua email của bạn",
             unlockRecipeText: "Mở khóa công thức",
             recipeUnlockedText: "Công thức đã mở khóa",

             copyLinkText: "Sao chép liên kết",
             pinterestText: "Chia sẻ trên Pinterest",
             instagramText: "Chia sẻ trên Instagram",
             twitterText: "Chia sẻ trên X",
             facebookText: "Chia sẻ trên Facebook",
             linkCopiedText: "Liên kết đã được sao chép",
             
             welcomeToBakesoftText1: "Chào mừng đến với Bakesoft",
             proRecipesText: "Công thức chuyên nghiệp dành cho đầu bếp và thợ làm bánh",
             updaterecipeText: "Chỉnh sửa, thêm và cập nhật nguyên liệu",
             nutritionalText: "Giá trị dinh dưỡng",
             signUpAndBonusText: "Đăng ký và nhận thưởng 5 tín dụng",
             
             signupbonusText: "Đăng ký và nhận thưởng 5 tín dụng",
             nocommitmentText: "Không ràng buộc, không cần đăng ký",
             aboutUsLiveText: "Về chúng tôi",
             
             
        },
        bakesoftPromotion: {
            welcomeToBakesoftText: "Chào mừng đến với Bakesoft",
            unlockWorldOfCulinaryCreativityText: "Khám phá thế giới sáng tạo ẩm thực",
            discoverAmazingRecipesText: "Khám phá những công thức tuyệt vời",
            liveBakesoftText: "live.bakesoft.se là nền tảng không có quảng cáo dành cho các công thức tuyệt vời.",
            affordableAccessText: "Mở khóa công thức với giá 1$/tín dụng. Không có phí đăng ký!",
            customizationText: "Tùy chỉnh công thức và nhận thông tin dinh dưỡng",
            shareConnectText: "Chia sẻ sáng tạo ẩm thực của bạn trên mạng xã hội",
            globalCuisineText: "Khám phá công thức từ các đầu bếp, quốc gia và ngôn ngữ khác nhau",
            signUpNowText: "Đăng ký ngay",
            forRecipeCreatorsText: "Dành cho những người tạo công thức",
            createRecipesEarnMoneyText: "Muốn tạo công thức và kiếm tiền?",
            advancedRecipeManagementText: "Sử dụng www.bakesoft.se để quản lý công thức nâng cao. Xuất bản trên nền tảng của chúng tôi và kiếm tiền mỗi lần công thức của bạn được mở khóa. Giá: 19$/tháng.",
            
            setYourPriceText: "Đặt giá của bạn (1$–5$/công thức) và kiếm 33% mỗi tín dụng bán được",
            trackEarningsText: "Theo dõi thu nhập và thanh toán trong ứng dụng",
            usersResponsibleText: "Người dùng chịu trách nhiệm về thuế và luật pháp tại quốc gia của họ",
            startCreatingText: "Bắt đầu sáng tạo",
            readMoreInTermsText: "Đọc thêm trong điều khoản của chúng tôi!",

            affordableAccessHeader: "Truy cập giá cả phải chăng",
            customizationHeader: "Tùy chỉnh",
            shareandConnectHeader: "Chia sẻ và Kết nối",
            globalCuisineHeader: "Ẩm thực toàn cầu",

            availableInText: "Có sẵn bằng",
            languagesText: "Ngôn ngữ",
            translateTo16LanguagesText: "Dịch sang 16 ngôn ngữ",
            proChefText: "Đầu bếp chuyên nghiệp",
            voteRegisteredText: "Phiếu bầu của bạn đã được đăng ký",
            
            welcomeToBakesoftText1: "Chào mừng đến với Bakesoft",
            proRecipesText: "Công thức chuyên nghiệp cho đầu bếp và thợ làm bánh",
            updaterecipeText: "Chỉnh sửa, thêm và cập nhật nguyên liệu",
            nutritionalText: "Giá trị dinh dưỡng",
            signUpAndBonusText: "Đăng ký và nhận 5 tín dụng thưởng",
            
        },
        
},
it: {
    common: {
        headerGreeting: "... ricette straordinarie per momenti indimenticabili",
selectMenu: "Menu",
inputTitleBookmark: "Aggiungi questa ricetta ai segnalibri",
imageTitleChef: "Profilo dello chef",
translateRecipeTextGrid: "Traduci",
titleShareOnSocialMedia: "Condividi sui social media",
textMinutesInShort: "min",
titleTextLikeRecipe: "Mi piace questa ricetta",
titleSeenByVievers: "Visto dagli utenti",
noDescriptionText: "Nessuna descrizione disponibile",
scrollMessageText: "Traducendo ricette straordinarie",
scrollMessageText2: "Caricando ricette straordinarie",
translateRecipeText: "Traduci ricetta",
translateCostText: "Costo della traduzione: 1 credito/traduzione riuscita",
selectLanguageText: "Seleziona lingua",
noNameAvailableText: "Nome non disponibile",
savedRecipeText: "Ricette salvate",
clickToUnlockText: "Clicca/Sblocca",
ingredientText: "Ingrediente",
pricePerKgText: "Prezzo/kg",
priceText: "Prezzo",
amountText: "Grammi",
kaloriesText: "Calorie",
increaseIngredientValueText: "Aumenta il valore dell'ingrediente",
decreaseIngredientValueText: "Diminuisci il valore dell'ingrediente",
changeNameText: "Modifica nome",
saveText: "Salva",
savedText: "Salvato",
deleteText: "Elimina",
totalText: "Totale",
addIngredientText: "Aggiungi ingrediente",
addText: "Aggiungi",
placeHolderIngredientNameText: "Nome ingrediente",
descriptionText: "Descrizione",

unlockThisRecipeText: "Sblocca questa ricetta per",
creditsToUseText: "- crediti da utilizzare",
creditsText: "crediti",
noCreditsText: "Non hai crediti sufficienti per sbloccare questa ricetta",
addMoreCreditsText: "Aggiungi più crediti",
gotoPaymentText: "Vai al pagamento",
chefInFocusText: "Chef in evidenza",

bsLivePaymentText: "Informazioni di pagamento Bakesoft",
bsLiveAmountText: "Importo",
bsLiveCurrencyText: "Valuta",
bsLivePaymentTermsText: "Termini di pagamento",

paymentSuccessfullText: "Pagamento completato con successo",
thankYouForPurchaseText: "Grazie per il tuo acquisto!",
selectCurrencyText: "Seleziona valuta",
selectLanguageText2: "Seleziona lingua",
placeHolderEmailText: "Inserisci il tuo indirizzo email",
placeHolderPasswordText: "Inserisci la tua password",
loginText2: "Accedi",

logOutText: "Esci",
norecipeAvailableText: "Nessuna ricetta disponibile.",
notAvailableText: "Non disponibile",
nameText: "Nome:",
countryText: "Paese:",
currencyText: "Valuta:",
noTransactionsFoundText: "Nessuna transazione trovata.",
paySuccessText: "Pagamento riuscito",
yourAccountSetupDoneText: "Il tuo account è ora pronto",
youcannowLoginWithCredentialsText: "Ora puoi accedere con le tue credenziali!",
thankYouForJoiningUsText: "Grazie e benvenuto!",

JoinBakesoftText: "Unisciti a Bakesoft e guadagna con le tue ricette straordinarie!",
createAccountTodayText: "Crea un account oggi e inizia a vendere le tue ricette ora!",
plattformOfferingText: "Offriamo una piattaforma eccezionale dove puoi creare e vendere le tue ricette in tutto il mondo!",
signUpOfferingText: "Iscriviti oggi e ottieni 14 giorni di prova gratuita!",
joinBakesoftNowText: "Unisciti ora a www.bakesoft.se!",
accountDoneSetupText: "Il tuo account www.bakesoft.se è ora pronto!",
loginemailandpassText: "Accedi con email e password!",
topublishRecipesOnLiveText: "Per pubblicare ricette su live.bakesoft.se, è necessario un account a pagamento.",
menyText:"Menu",
signUpText:"Crea un account",
myAccountText:"Il mio account",
myBookmarkedRecipesText:"Le mie ricette preferite",
SignUpToBakesoftText:"Iscriviti a live.bakesoft.se",
howDoesItWorkText:"Come funziona?",
liveSignUpText:"Iscriviti",

confirmPasswordText:"Conferma password",
currencyNLanguageText:"Valuta e lingua",
liveSignUpText1:"Iscriviti a live.bakesoft.se",
buyOneCreditText:"Acquista 1 credito",
unlockedRecipesText:"Ricette sbloccate",
accountDetailsText:"Dettagli dell'account",
receiptText:"Ricevuta",
savedRecipesText:"Ricette salvate",
endDateText:"Data di fine",
deleteSavedRecipeText:"Elimina",
nameText: "Nome",
slideToUnlockText: "Scorri per sbloccare",
unlockedText2: "Sbloccato",
logoutOnlyText: "Esci",
youreLoggedOutText: "Sei uscito",

bakesoftSignUp1: "live.bakesoft.se è una piattaforma senza pubblicità per ricette straordinarie.",
bakesoftSignUp2: "Sblocca ricette per 1 USD/credito. Personalizzale, ottieni informazioni nutrizionali, condividile sui social media e traducile in diverse lingue. L'iscrizione è gratuita, senza costi di abbonamento. Un credito sblocca una ricetta. Esplora ricette di chef, paesi e lingue diverse.",
bakesoftSignUp3: "Vuoi creare ricette e guadagnare soldi?",
bakesoftSignUp4: "Usa www.bakesoft.se per una gestione avanzata delle ricette. Pubblica sulle nostre piattaforme e guadagna ogni volta che una ricetta viene sbloccata. Prezzo: 19 USD/mese.",
bakesoftSignUp5: "Stabilisci il tuo prezzo (1–5 USD/ricetta) e guadagna il 33% per ogni credito venduto. Monitora guadagni e pagamenti nell'app. Gli utenti sono responsabili di tasse e leggi nel proprio paese. Leggi i nostri termini per maggiori informazioni!"
,
termsAndConditionsText:"Termini e condizioni",
gotToLoginText:"Vai al login",
serviceS2Text: "Acquista 5 crediti ricetta e ottieni uno sconto del 10%",
serviceS3Text: "Acquista 10 crediti ricetta e ottieni uno sconto del 20%",
serviceS4Text: "Iscriviti gratuitamente a bakesoft.se – prova di 14 giorni",
creditsText: "Crediti",
updateLanguageHeader: "Aggiorna lingua",
submitButtonChangeLanguageText:"Aggiorna",
kitchenTimerText: "Timer da cucina",
 showHideText: "Mostra/Nascondi",
 forgotPasswordText: "Hai dimenticato la password?",
 resetPassText:"Reimposta password",
 resetLinkText:"Il link di reimpostazione è stato inviato alla tua email",
 unlockRecipeText: "Sblocca ricetta",
 recipeUnlockedText: "Ricetta sbloccata",

 availableInText: "Disponibile in",
 languagesText: "Lingue",
 translateTo16LanguagesText: "Traduci in 16 lingue",
 proChefText: "Chef Professionista",
 voteRegisteredText: "Il tuo voto è stato registrato",
 copyLinkText: "Copia link",
 pinterestText: "Condividi su Pinterest",
 instagramText: "Condividi su Instagram",
 twitterText: "Condividi su X",
 facebookText: "Condividi su Facebook",
 linkCopiedText: "Link copiato",

 welcomeToBakesoftText1: "Benvenuto su Bakesoft",
proRecipesText: "Ricette professionali per chef e panettieri",
updaterecipeText: "Modifica, aggiungi e aggiorna gli ingredienti",
nutritionalText: "Valori nutrizionali",
signUpAndBonusText: "Registrati e ottieni un bonus di 5 crediti",

signupbonusText: "Registrati e ottieni un bonus di 5 crediti",
nocommitmentText: "Nessun impegno, nessun abbonamento",
aboutUsLiveText: "Chi siamo",

 
 
 


    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Benvenuto su Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Scopri un mondo di creatività culinaria",
        discoverAmazingRecipesText: "Scopri ricette incredibili",
        liveBakesoftText: "live.bakesoft.se è una piattaforma senza pubblicità per ricette straordinarie.",
        affordableAccessText: "Sblocca ricette a 1$/credito. Nessun costo di abbonamento!",
        customizationText: "Personalizza le ricette e ottieni informazioni nutrizionali",
        shareConnectText: "Condividi le tue creazioni culinarie sui social media",
        globalCuisineText: "Esplora ricette di diversi chef, paesi e lingue",
        signUpNowText: "Registrati ora",
        forRecipeCreatorsText: "Per i creatori di ricette",
        createRecipesEarnMoneyText: "Vuoi creare ricette e guadagnare?",
        advancedRecipeManagementText: "Utilizza www.bakesoft.se per la gestione avanzata delle ricette. Pubblica sulle nostre piattaforme e guadagna ogni volta che la tua ricetta viene sbloccata. Prezzo: 19$/mese.",
        
        setYourPriceText: "Imposta il tuo prezzo (1$–5$/ricetta) e guadagna il 33% per credito venduto",
        trackEarningsText: "Tieni traccia dei guadagni e dei pagamenti nell'applicazione",
        usersResponsibleText: "Gli utenti sono responsabili di tasse e leggi nel proprio paese",
        startCreatingText: "Inizia a creare",
        readMoreInTermsText: "Leggi di più nei nostri termini!",

        affordableAccessHeader: "Accesso conveniente",
        customizationHeader: "Personalizzazione",
        shareandConnectHeader: "Condividi e Connetti",
        globalCuisineHeader: "Cucina globale",

    },
    
},
no: {
    common: {
        headerGreeting: "... fantastiske oppskrifter for uforglemmelige øyeblikk",
selectMenu: "Meny",
inputTitleBookmark: "Bokmerk denne oppskriften",
imageTitleChef: "Kokkens profil",
translateRecipeTextGrid: "Oversett",
titleShareOnSocialMedia: "Del på sosiale medier",
textMinutesInShort: "min",
titleTextLikeRecipe: "Liker denne oppskriften",
titleSeenByVievers: "Sett av brukere",
noDescriptionText: "Ingen beskrivelse tilgjengelig",
scrollMessageText: "Oversetter fantastiske oppskrifter",
scrollMessageText2: "Henter fantastiske oppskrifter",
translateRecipeText: "Oversett oppskrift",
translateCostText: "Oversettelseskostnad: 1 kreditt/vellykket oversettelse",
selectLanguageText: "Velg språk",
noNameAvailableText: "Ingen navn tilgjengelig",
savedRecipeText: "Lagrede oppskrifter",
clickToUnlockText: "Klikk/Lås opp",
ingredientText: "Ingrediens",
pricePerKgText: "Pris/kg",
priceText: "Pris",
amountText: "Gram",
kaloriesText: "Kalorier",
increaseIngredientValueText: "Øk ingrediensverdien",
decreaseIngredientValueText: "Reduser ingrediensverdien",
changeNameText: "Endre navn",
saveText: "Lagre",
savedText: "Lagret",
deleteText: "Slett",
totalText: "Totalt",
addIngredientText: "Legg til ingrediens",
addText: "Legg til",
placeHolderIngredientNameText: "Ingrediensnavn",
descriptionText: "Beskrivelse",

unlockThisRecipeText: "Lås opp denne oppskriften for",
creditsToUseText: "- kreditter som trengs",
creditsText: "kreditter",
noCreditsText: "Du har ingen kreditter til å låse opp denne oppskriften",
addMoreCreditsText: "Legg til flere kreditter",
gotoPaymentText: "Gå til betaling",
chefInFocusText: "Fokusert kokk",

bsLivePaymentText: "Bakesoft betalingsinformasjon",
bsLiveAmountText: "Beløp",
bsLiveCurrencyText: "Valuta",
bsLivePaymentTermsText: "Betalingsvilkår",

paymentSuccessfullText: "Betaling vellykket",
thankYouForPurchaseText: "Takk for ditt kjøp!",
selectCurrencyText: "Velg valuta",
selectLanguageText2: "Velg språk",
placeHolderEmailText: "Skriv inn e-postadressen din",
placeHolderPasswordText: "Skriv inn passordet ditt",
loginText2: "Logg inn",

logOutText: "Logg ut",
norecipeAvailableText: "Ingen oppskrifter tilgjengelig.",
notAvailableText: "Ikke tilgjengelig",
nameText: "Navn:",
countryText: "Land:",
currencyText: "Valuta:",
noTransactionsFoundText: "Ingen transaksjoner funnet.",
paySuccessText: "Betaling gjennomført",
yourAccountSetupDoneText: "Kontoen din er nå klar",
youcannowLoginWithCredentialsText: "Du kan nå logge inn med dine legitimasjoner!",
thankYouForJoiningUsText: "Takk og velkommen til oss!",

JoinBakesoftText: "Bli med i Bakesoft og tjen penger på dine fantastiske oppskrifter!",
createAccountTodayText: "Opprett en konto i dag og begynn å selge oppskriftene dine nå!",
plattformOfferingText: "Vi tilbyr en flott plattform der du kan lage og selge oppskriftene dine til hele verden!",
signUpOfferingText: "Registrer deg i dag og få en 14-dagers prøveperiode!",
joinBakesoftNowText: "Bli med nå på www.bakesoft.se!",
accountDoneSetupText: "Din www.bakesoft.se-konto er nå klar!",
loginemailandpassText: "Logg inn med e-post og passord!",
topublishRecipesOnLiveText: "For å publisere oppskrifter på live.bakesoft.se trenger du en betalt konto.",
menyText:"Meny",
signUpText:"Opprett konto",
myAccountText:"Min konto",
myBookmarkedRecipesText:"Mine bokmerkede oppskrifter",
SignUpToBakesoftText:"Meld deg på Bakesoft",
howDoesItWorkText:"Hvordan fungerer det?",
liveSignUpText1:"Meld deg på live.bakesoft.se",

confirmPasswordText:"Bekreft passord",
currencyNLanguageText:"Valuta og språk",
liveSignupText:"Meld deg",
buyOneCreditText:"Kjøp 1 kreditt",
unlockedRecipesText:"Låste oppskrifter",
accountDetailsText:"Kontodetaljer",
receiptText:"Kvittering",
savedRecipesText:"Lagrede oppskrifter",
endDateText:"Sluttdato",
deleteSavedRecipeText:"Slett",
nameText: "Navn",
slideToUnlockText: "Skyv for å låse opp",
unlockedText2: "Låst opp",
logoutOnlyText: "Logg ut",
youreLoggedOutText: "Du er logget ut",

bakesoftSignUp1: "live.bakesoft.se er en reklamefri plattform for fantastiske oppskrifter.",
bakesoftSignUp2: "Lås opp oppskrifter for 1 USD/kreditt. Tilpass dem, få næringsinformasjon, del på sosiale medier og oversett til ulike språk. Medlemskap er gratis – ingen abonnementsavgifter. Én kreditt låser opp én oppskrift. Utforsk oppskrifter fra ulike kokker, land og språk.",
bakesoftSignUp3: "Vil du lage egne oppskrifter og tjene penger?",
bakesoftSignUp4: "Bruk www.bakesoft.se til avansert oppskriftsadministrasjon. Publiser på våre plattformer og tjen penger hver gang oppskriften din låses opp. Pris: 19 USD/måned.",
bakesoftSignUp5: "Sett din egen pris (1–5 USD/oppskrift) og tjen 33 % per solgte kreditt. Følg med på inntekter og utbetalinger i appen. Brukere er ansvarlige for skatter og lover i sitt land. Les våre vilkår for mer informasjon!"
,
termsAndConditionsText:"Vilkår og betingelser",
gotToLoginText:"Gå til innlogging",
serviceS2Text: "Kjøp 5 oppskriftskreditter og få 10% rabatt",
serviceS3Text: "Kjøp 10 oppskriftskreditter og få 20% rabatt",
serviceS4Text: "Registrer deg gratis på bakesoft.se – 14 dagers prøvetid",
creditsText: "kreditter",
updateLanguageHeader: "Oppdater språk",
submitButtonChangeLanguageText:"Oppdater",
kitchenTimerText: "Kjøkkentimer",
 showHideText: "Vis/Skjul",
 forgotPasswordText: "Glemt passord?",
 resetPassText:"Tilbakestill passord",
 resetLinkText:"Tilbakestillingslenken er sendt til e-posten din",

 unlockRecipeText: "Lås opp oppskrift",
 recipeUnlockedText: "Oppskrift låst opp",

 availableInText: "Tilgjengelig på",
 languagesText: "Språk",
 translateTo16LanguagesText: "Oversett til 16 språk",
 proChefText: "Proffkokk",
 voteRegisteredText: "Stemmen din er registrert",

 copyLinkText: "Kopier lenke",
pinterestText: "Del på Pinterest",
instagramText: "Del på Instagram",
twitterText: "Del på X",
facebookText: "Del på Facebook",
linkCopiedText: "Lenken er kopiert",

welcomeToBakesoftText1: "Velkommen til Bakesoft",
proRecipesText: "Profesjonelle oppskrifter for kokker og bakere",
updaterecipeText: "Rediger, legg til og oppdater ingredienser",
nutritionalText: "Næringsverdier",
signUpAndBonusText: "Registrer deg og få 5 kredittbonus",

signupbonusText: "Registrer deg og få 5 kredittbonus",
nocommitmentText: "Ingen binding, ingen abonnement",
aboutUsLiveText: "Om oss",


 
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Velkommen til Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Lås opp en verden av kulinarisk kreativitet",
        discoverAmazingRecipesText: "Oppdag fantastiske oppskrifter",
        liveBakesoftText: "live.bakesoft.se er en annonsefri plattform for fantastiske oppskrifter.",
        affordableAccessText: "Lås opp oppskrifter for $1/kreditt. Ingen abonnementsavgifter!",
        customizationText: "Tilpass oppskrifter og få næringsinformasjon",
        shareConnectText: "Del dine kulinariske kreasjoner på sosiale medier",
        globalCuisineText: "Utforsk oppskrifter fra ulike kokker, land og språk",
        signUpNowText: "Registrer deg nå",
        forRecipeCreatorsText: "For oppskriftsskapere",
        createRecipesEarnMoneyText: "Vil du lage oppskrifter og tjene penger?",
        advancedRecipeManagementText: "Bruk www.bakesoft.se for avansert oppskriftsadministrasjon. Publiser på våre plattformer og tjen penger hver gang oppskriften din låses opp. Pris: $19/måned.",
        
        setYourPriceText: "Sett prisen din ($1–$5/oppskrift) og tjen 33 % per solgte kreditt",
        trackEarningsText: "Følg med på inntekter og utbetalinger i appen",
        usersResponsibleText: "Brukere er ansvarlige for skatter og lover i sitt land",
        startCreatingText: "Begynn å skape",
        readMoreInTermsText: "Les mer i våre vilkår!",

        affordableAccessHeader: "Rimelig tilgang",
        customizationHeader: "Tilpasning",
        shareandConnectHeader: "Del og koble",
        globalCuisineHeader: "Globalt kjøkken",

    },
    
},
fi: {
    common: {
        headerGreeting: "... upeita reseptejä unohtumattomiin hetkiin",
selectMenu: "Valikko",
inputTitleBookmark: "Lisää tämä resepti kirjanmerkkeihin",
imageTitleChef: "Kokin profiili",
translateRecipeTextGrid: "Käännä",
titleShareOnSocialMedia: "Jaa sosiaalisessa mediassa",
textMinutesInShort: "min",
titleTextLikeRecipe: "Tykkää tästä reseptistä",
titleSeenByVievers: "Nähnyt käyttäjät",
noDescriptionText: "Ei kuvausta saatavilla",
scrollMessageText: "Käännetään upeita reseptejä",
scrollMessageText2: "Haetaan upeita reseptejä",
translateRecipeText: "Käännä resepti",
translateCostText: "Käännöksen hinta: 1 krediitti/onnistunut käännös",
selectLanguageText: "Valitse kieli",
noNameAvailableText: "Ei nimeä saatavilla",
savedRecipeText: "Tallennetut reseptit",
clickToUnlockText: "Klikkaa/Avaa lukitus",
ingredientText: "Ainesosa",
pricePerKgText: "Hinta/kg",
priceText: "Hinta",
amountText: "Grammaa",
kaloriesText: "Kalorit",
increaseIngredientValueText: "Lisää ainesosan arvoa",
decreaseIngredientValueText: "Vähennä ainesosan arvoa",
changeNameText: "Vaihda nimi",
saveText: "Tallenna",
savedText: "Tallennettu",
deleteText: "Poista",
totalText: "Yhteensä",
addIngredientText: "Lisää ainesosa",
addText: "Lisää",
placeHolderIngredientNameText: "Ainesosan nimi",
descriptionText: "Kuvaus",

unlockThisRecipeText: "Avaa tämän reseptin lukitus hintaan",
creditsToUseText: "- käytettävät krediitit",
creditsText: "krediitit",
noCreditsText: "Sinulla ei ole tarpeeksi krediittejä tämän reseptin avaamiseen",
addMoreCreditsText: "Lisää krediittejä",
gotoPaymentText: "Siirry maksamaan",
chefInFocusText: "Keskiössä oleva kokki",

bsLivePaymentText: "Bakesoft maksutiedot",
bsLiveAmountText: "Määrä",
bsLiveCurrencyText: "Valuutta",
bsLivePaymentTermsText: "Maksuehdot",

paymentSuccessfullText: "Maksu onnistui",
thankYouForPurchaseText: "Kiitos ostoksestasi!",
selectCurrencyText: "Valitse valuutta",
selectLanguageText2: "Valitse kieli",
placeHolderEmailText: "Syötä sähköpostiosoitteesi",
placeHolderPasswordText: "Syötä salasanasi",
loginText2: "Kirjaudu sisään",

logOutText: "Kirjaudu ulos",
norecipeAvailableText: "Reseptit eivät ole saatavilla.",
notAvailableText: "Ei saatavilla",
nameText: "Nimi:",
countryText: "Maa:",
currencyText: "Valuutta:",
noTransactionsFoundText: "Ei löytynyt tapahtumia.",
paySuccessText: "Maksu onnistui",
yourAccountSetupDoneText: "Tilisi on nyt valmis",
youcannowLoginWithCredentialsText: "Voit nyt kirjautua sisään tunnuksillasi!",
thankYouForJoiningUsText: "Kiitos ja tervetuloa mukaan!",

JoinBakesoftText: "Liity Bakesoftiin ja ansaitse rahaa upeilla resepteilläsi!",
createAccountTodayText: "Luo tili tänään ja ala myydä reseptejäsi heti!",
plattformOfferingText: "Tarjoamme loistavan alustan, jossa voit luoda ja myydä reseptejäsi maailmanlaajuisesti!",
signUpOfferingText: "Rekisteröidy tänään ja saat 14 päivän kokeilujakson!",
joinBakesoftNowText: "Liity nyt osoitteessa www.bakesoft.se!",
accountDoneSetupText: "www.bakesoft.se-tilisi on nyt valmis!",
loginemailandpassText: "Kirjaudu sisään sähköpostilla ja salasanalla!",
topublishRecipesOnLiveText: "Julkaistaksesi reseptejä live.bakesoft.se:ssä tarvitset maksullisen tilin.",


menyText:"Valikko",
signUpText:"Luo tili",
myAccountText:"Oma tili",
myBookmarkedRecipesText:"Kirjanmerkittyjä reseptejäni",
SignUpToBakesoftText:"Rekisteröidy Bakesoftiin",
howDoesItWorkText:"Kuinka se toimii?",
liveSignUpText1:"Rekisteröidy  live.bakesoft.se",

confirmPasswordText:"Vahvista salasana",
currencyNLanguageText:"Valuutta ja kieli",
liveSignupText:"Rekisteröidy",
buyOneCreditText:"Osta 1 krediitti",
unlockedRecipesText:"Avatut reseptit",
accountDetailsText:"Tilin tiedot",
receiptText:"Kuitti",
savedRecipesText:"Tallennetut reseptit",
endDateText:"Päättymispäivä",
deleteSavedRecipeText:"Poista",
nameText: "Nimi",
slideToUnlockText: "Siirrä avataksesi",
unlockedText2: "Avattu",
logoutOnlyText: "Kirjaudu ulos",
youreLoggedOutText: "Olet kirjautunut ulos",

bakesoftSignUp1: "live.bakesoft.se on mainokseton alusta upeille resepteille.",
bakesoftSignUp2: "Avaa reseptejä hintaan 1 USD/krediitti. Muokkaa reseptejä, hanki ravintotiedot, jaa sosiaalisessa mediassa ja käännä eri kielille. Jäsenyys on ilmainen – ei tilausmaksuja. Yksi krediitti avaa yhden reseptin. Tutustu eri kokkien, maiden ja kielten resepteihin.",
bakesoftSignUp3: "Haluatko luoda omia reseptejä ja ansaita rahaa?",
bakesoftSignUp4: "Käytä www.bakesoft.se-sivustoa edistyneeseen reseptien hallintaan. Julkaise reseptisi alustoillamme ja ansaitse rahaa aina, kun resepti avataan. Hinta: 19 USD/kuukausi.",
bakesoftSignUp5: "Aseta hinta (1–5 USD/resepti) ja ansaitse 33 % jokaisesta myydystä krediitistä. Seuraa tuloja ja maksuja sovelluksessa. Käyttäjät ovat vastuussa veroista ja laeista omassa maassaan. Lue lisää ehdoistamme!"
,
termsAndConditionsText:"Käyttöehdot",
gotToLoginText:"Mene kirjautumissivulle",
serviceS2Text: "Osta 5 reseptiluottoa ja saat 10 % alennuksen",
serviceS3Text: "Osta 10 reseptiluottoa ja saat 20 % alennuksen",
serviceS4Text: "Rekisteröidy ilmaiseksi bakesoft.se:ssä – 14 päivän kokeilu",
creditsText: "krediittiä",
updateLanguageHeader: "Päivitä kieli",
submitButtonChangeLanguageText:"Päivitä",
kitchenTimerText: "Keittiöajastin",
 showHideText: "Näytä/Piilota",
 forgotPasswordText: "Unohditko salasanasi?",
 resetPassText:"Nollaa salasana",
 resetLinkText:"Nollauslinkki on lähetetty sähköpostiisi",

 unlockRecipeText: "Avaa resepti",
 recipeUnlockedText: "Resepti avattu",

 availableInText: "Saatavilla kielillä",
 languagesText: "Kielet",
 translateTo16LanguagesText: "Käännä 16 kielelle",
 proChefText: "Ammattikokki",
 voteRegisteredText: "Äänesi on rekisteröity",

 copyLinkText: "Kopioi linkki",
pinterestText: "Jaa Pinterestissä",
instagramText: "Jaa Instagramissa",
twitterText: "Jaa X:ssä",
facebookText: "Jaa Facebookissa",
linkCopiedText: "Linkki kopioitu",

welcomeToBakesoftText1: "Tervetuloa Bakesoftiin",
proRecipesText: "Ammattimaisia reseptejä kokeille ja leipureille",
updaterecipeText: "Muokkaa, lisää ja päivitä aineksia",
nutritionalText: "Ravintoarvot",
signUpAndBonusText: "Rekisteröidy ja saat 5 krediittibonuksen",

signupbonusText: "Rekisteröidy ja saat 5 krediittibonusta",
nocommitmentText: "Ei sitoutumista, ei tilausta",
aboutUsLiveText: "Tietoa meistä",


 
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Tervetuloa Bakesoftiin",
        unlockWorldOfCulinaryCreativityText: "Avaa kulinaarisen luovuuden maailma",
        discoverAmazingRecipesText: "Löydä upeita reseptejä",
        liveBakesoftText: "live.bakesoft.se on mainokseton alusta upeille resepteille.",
        affordableAccessText: "Avaa reseptejä hintaan 1 $/krediitti. Ei tilausmaksuja!",
        customizationText: "Mukauta reseptejä ja saat ravintotietoja",
        shareConnectText: "Jaa kulinaariset luomuksesi sosiaalisessa mediassa",
        globalCuisineText: "Tutki reseptejä eri kokeilta, maista ja kielistä",
        signUpNowText: "Rekisteröidy nyt",
        forRecipeCreatorsText: "Reseptin tekijöille",
        createRecipesEarnMoneyText: "Haluatko luoda reseptejä ja ansaita rahaa?",
        advancedRecipeManagementText: "Käytä www.bakesoft.se-sivustoa edistyneeseen reseptien hallintaan. Julkaise reseptisi alustoillamme ja ansaitse rahaa joka kerta, kun resepti avataan. Hinta: 19 $/kk.",
        
        setYourPriceText: "Määritä hintasi (1–5 $/resepti) ja ansaitse 33 % jokaisesta myydystä krediitistä",
        trackEarningsText: "Seuraa tuloja ja maksuja sovelluksessa",
        usersResponsibleText: "Käyttäjät ovat vastuussa veroista ja lainsäädännöstä omassa maassaan",
        startCreatingText: "Aloita luominen",
        readMoreInTermsText: "Lue lisää ehdoistamme!",

        affordableAccessHeader: "Edullinen pääsy",
        customizationHeader: "Mukauttaminen",
        shareandConnectHeader: "Jaa ja yhdistä",
        globalCuisineHeader: "Maailman keittiö",

    },
    
},
dk: {
    common: {
        headerGreeting: "... fantastiske opskrifter til uforglemmelige øjeblikke",
selectMenu: "Menu",
inputTitleBookmark: "Bogmærk denne opskrift",
imageTitleChef: "Kokkeprofil",
translateRecipeTextGrid: "Oversæt",
titleShareOnSocialMedia: "Del på sociale medier",
textMinutesInShort: "min",
titleTextLikeRecipe: "Synes godt om denne opskrift",
titleSeenByVievers: "Set af brugere",
noDescriptionText: "Ingen beskrivelse tilgængelig",
scrollMessageText: "Oversætter fantastiske opskrifter",
scrollMessageText2: "Henter fantastiske opskrifter",
translateRecipeText: "Oversæt opskrift",
translateCostText: "Oversættelsespris: 1 kredit/vellykket oversættelse",
selectLanguageText: "Vælg sprog",
noNameAvailableText: "Ingen navn tilgængelig",
savedRecipeText: "Gemte opskrifter",
clickToUnlockText: "Klik/Lås op",
ingredientText: "Ingrediens",
pricePerKgText: "Pris/kg",
priceText: "Pris",
amountText: "Gram",
kaloriesText: "Kalorier",
increaseIngredientValueText: "Forøg ingrediensværdi",
decreaseIngredientValueText: "Formindsk ingrediensværdi",
changeNameText: "Ændr navn",
saveText: "Gem",
savedText: "Gemt",
deleteText: "Slet",
totalText: "I alt",
addIngredientText: "Tilføj ingrediens",
addText: "Tilføj",
placeHolderIngredientNameText: "Ingrediensnavn",
descriptionText: "Beskrivelse",

unlockThisRecipeText: "Lås op for denne opskrift for",
creditsToUseText: "- kreditter at bruge",
creditsText: "kreditter",
noCreditsText: "Du har ingen kreditter til at låse op for denne opskrift",
addMoreCreditsText: "Tilføj flere kreditter",
gotoPaymentText: "Gå til betaling",
chefInFocusText: "Fokuseret kok",

bsLivePaymentText: "Bakesoft betalingsoplysninger",
bsLiveAmountText: "Beløb",
bsLiveCurrencyText: "Valuta",
bsLivePaymentTermsText: "Betalingsbetingelser",

paymentSuccessfullText: "Betaling gennemført",
thankYouForPurchaseText: "Tak for dit køb!",
selectCurrencyText: "Vælg valuta",
selectLanguageText2: "Vælg sprog",
placeHolderEmailText: "Indtast din e-mailadresse",
placeHolderPasswordText: "Indtast din adgangskode",
loginText2: "Log ind",

logOutText: "Log ud",
norecipeAvailableText: "Ingen opskrifter tilgængelige.",
notAvailableText: "Ikke tilgængelig",
nameText: "Navn:",
countryText: "Land:",
currencyText: "Valuta:",
noTransactionsFoundText: "Ingen transaktioner fundet.",
paySuccessText: "Betaling lykkedes",
yourAccountSetupDoneText: "Din konto er nu klar",
youcannowLoginWithCredentialsText: "Du kan nu logge ind med dine legitimationsoplysninger!",
thankYouForJoiningUsText: "Tak og velkommen til os!",

JoinBakesoftText: "Bliv en del af Bakesoft og tjen penge på dine fantastiske opskrifter!",
createAccountTodayText: "Opret en konto i dag og begynd at sælge dine opskrifter nu!",
plattformOfferingText: "Vi tilbyder en fantastisk platform, hvor du kan skabe og sælge dine opskrifter til hele verden!",
signUpOfferingText: "Tilmeld dig i dag og få en 14-dages prøveperiode!",
joinBakesoftNowText: "Bliv medlem af www.bakesoft.se nu!",
accountDoneSetupText: "Din www.bakesoft.se konto er nu klar!",
loginemailandpassText: "Log ind med e-mail og adgangskode!",
topublishRecipesOnLiveText: "For at udgive opskrifter på live.bakesoft.se skal du have en betalt konto.",

menyText:"Menu",
signUpText:"Opret konto",
myAccountText:"Min konto",
myBookmarkedRecipesText:"Mine bogmærkede opskrifter",
SignUpToBakesoftText:"Tilmeld dig Bakesoft",
howDoesItWorkText:"Hvordan fungerer det?",
liveSignUpText1:"Tilmeld dig live.bakesoft.se",

confirmPasswordText:"Bekræft adgangskode",
currencyNLanguageText:"Valuta og sprog",
liveSignupText:"Tilmeld dig",
buyOneCreditText:"Køb 1 kredit",
unlockedRecipesText:"Oplåste opskrifter",
accountDetailsText:"Kontodetaljer",
receiptText:"Kvittering",
savedRecipesText:"Gemte opskrifter",
endDateText:"Slutdato",
deleteSavedRecipeText:"Slet",
nameText: "Navn",
slideToUnlockText: "Skub for at låse op",
unlockedText2: "Oplåst",
logoutOnlyText: "Log ud",
youreLoggedOutText: "Du er logget ud",

bakesoftSignUp1: "live.bakesoft.se er en reklamefri platform for fantastiske opskrifter.",
bakesoftSignUp2: "Lås opskrifter op for 1 USD/kredit. Tilpas dem, få næringsinformation, del på sociale medier og oversæt til forskellige sprog. Medlemskab er gratis – ingen abonnementsgebyrer. Én kredit låser én opskrift op. Udforsk opskrifter fra forskellige kokke, lande og sprog.",
bakesoftSignUp3: "Vil du skabe dine egne opskrifter og tjene penge?",
bakesoftSignUp4: "Brug www.bakesoft.se til avanceret opskriftshåndtering. Udgiv dine opskrifter på vores platforme og tjen penge hver gang en opskrift låses op. Pris: 19 USD/måned.",
bakesoftSignUp5: "Sæt din egen pris (1–5 USD/opskrift) og tjen 33 % for hver solgt kredit. Følg dine indtægter og udbetalinger i appen. Brugere er selv ansvarlige for skatter og lovgivning i deres land. Læs vores vilkår for mere information!"
,
termsAndConditionsText:"Vilkår og betingelser",
gotToLoginText:"Gå til login",
serviceS2Text: "Køb 5 opskriftskreditter og få 10% rabat",
serviceS3Text: "Køb 10 opskriftskreditter og få 20% rabat",
serviceS4Text: "Tilmeld dig gratis på bakesoft.se – 14 dages prøveperiode",
creditsText: "kreditter",
updateLanguageHeader: "Opdater sprog",
submitButtonChangeLanguageText:"Opdater",
kitchenTimerText: "Køkkentimer",
 showHideText: "Vis/Skjul",
 forgotPasswordText: "Glemt adgangskode?",
 resetPassText:"Nulstil adgangskode",
 resetLinkText:"Nulstillingslinket er sendt til din e-mail",

 unlockRecipeText: "Lås op for opskrift",
 recipeUnlockedText: "Opskrift låst op",

 availableInText: "Tilgængelig på",
 languagesText: "Sprog",
 translateTo16LanguagesText: "Oversæt til 16 sprog",
 proChefText: "Professionel Kok",
 voteRegisteredText: "Din stemme er registreret",

 copyLinkText: "Kopiér link",
pinterestText: "Del på Pinterest",
instagramText: "Del på Instagram",
twitterText: "Del på X",
facebookText: "Del på Facebook",
linkCopiedText: "Link kopieret",

welcomeToBakesoftText1: "Velkommen til Bakesoft",
proRecipesText: "Professionelle opskrifter til kokke og bagere",
updaterecipeText: "Rediger, tilføj og opdater ingredienser",
nutritionalText: "Næringsværdier",
signUpAndBonusText: "Tilmeld dig og få 5 kreditbonus",

signupbonusText: "Registrér dig og få 5 kreditbonus",
nocommitmentText: "Ingen binding, ingen abonnement",
aboutUsLiveText: "Om os",



 
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Velkommen til Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Lås op for en verden af kulinarisk kreativitet",
        discoverAmazingRecipesText: "Opdag fantastiske opskrifter",
        liveBakesoftText: "live.bakesoft.se er en reklamefri platform for fantastiske opskrifter.",
        affordableAccessText: "Lås opskrifter op for $1/kredit. Ingen abonnementsgebyrer!",
        customizationText: "Tilpas opskrifter og få ernæringsinformation",
        shareConnectText: "Del dine kulinariske kreationer på sociale medier",
        globalCuisineText: "Udforsk opskrifter fra forskellige kokke, lande og sprog",
        signUpNowText: "Tilmeld dig nu",
        forRecipeCreatorsText: "For opskriftsskabere",
        createRecipesEarnMoneyText: "Vil du lave opskrifter og tjene penge?",
        advancedRecipeManagementText: "Brug www.bakesoft.se til avanceret opskriftsstyring. Udgiv på vores platforme og tjen penge, hver gang din opskrift bliver låst op. Pris: $19/måned.",
        
        setYourPriceText: "Sæt din pris ($1–$5/opskrift) og tjen 33 % pr. solgte kredit",
        trackEarningsText: "Følg indtjeninger og udbetalinger i appen",
        usersResponsibleText: "Brugere er ansvarlige for skatter og lovgivning i deres land",
        startCreatingText: "Begynd at skabe",
        readMoreInTermsText: "Læs mere i vores vilkår!",

        affordableAccessHeader: "Overkommelig adgang",
        customizationHeader: "Tilpasning",
        shareandConnectHeader: "Del og forbind",
        globalCuisineHeader: "Globalt køkken",

    },
    
},
pl: {
    common: {
        headerGreeting: "... świetne przepisy na niezapomniane chwile",
selectMenu: "Menu",
inputTitleBookmark: "Dodaj przepis do zakładek",
imageTitleChef: "Profil szefa kuchni",
translateRecipeTextGrid: "Tłumacz",
titleShareOnSocialMedia: "Udostępnij w mediach społecznościowych",
textMinutesInShort: "min",
titleTextLikeRecipe: "Polub ten przepis",
titleSeenByVievers: "Oglądane przez użytkowników",
noDescriptionText: "Brak opisu",
scrollMessageText: "Tłumaczenie wspaniałych przepisów",
scrollMessageText2: "Pobieranie wspaniałych przepisów",
translateRecipeText: "Tłumacz przepis",
translateCostText: "Koszt tłumaczenia: 1 kredyt/udane tłumaczenie",
selectLanguageText: "Wybierz język",
noNameAvailableText: "Brak dostępnej nazwy",
savedRecipeText: "Zapisane przepisy",
clickToUnlockText: "Kliknij/Odblokuj",
ingredientText: "Składnik",
pricePerKgText: "Cena/kg",
priceText: "Cena",
amountText: "Gram",
kaloriesText: "Kalorie",
increaseIngredientValueText: "Zwiększ wartość składnika",
decreaseIngredientValueText: "Zmniejsz wartość składnika",
changeNameText: "Zmień nazwę",
saveText: "Zapisz",
savedText: "Zapisano",
deleteText: "Usuń",
totalText: "Razem",
addIngredientText: "Dodaj składnik",
addText: "Dodaj",
placeHolderIngredientNameText: "Nazwa składnika",
descriptionText: "Opis",

unlockThisRecipeText: "Odblokuj ten przepis za",
creditsToUseText: "- kredyty do wykorzystania",
creditsText: "kredyty",
noCreditsText: "Nie masz wystarczających kredytów, aby odblokować ten przepis",
addMoreCreditsText: "Dodaj więcej kredytów",
gotoPaymentText: "Przejdź do płatności",
chefInFocusText: "Szef kuchni w centrum uwagi",

bsLivePaymentText: "Informacje o płatnościach Bakesoft",
bsLiveAmountText: "Kwota",
bsLiveCurrencyText: "Waluta",
bsLivePaymentTermsText: "Warunki płatności",

paymentSuccessfullText: "Płatność zakończona sukcesem",
thankYouForPurchaseText: "Dziękujemy za zakup!",
selectCurrencyText: "Wybierz walutę",
selectLanguageText2: "Wybierz język",
placeHolderEmailText: "Wpisz swój adres e-mail",
placeHolderPasswordText: "Wpisz swoje hasło",
loginText2: "Zaloguj się",

logOutText: "Wyloguj się",
norecipeAvailableText: "Brak dostępnych przepisów.",
notAvailableText: "Niedostępny",
nameText: "Nazwa:",
countryText: "Kraj:",
currencyText: "Waluta:",
noTransactionsFoundText: "Nie znaleziono transakcji.",
paySuccessText: "Płatność powiodła się",
yourAccountSetupDoneText: "Twoje konto jest już gotowe",
youcannowLoginWithCredentialsText: "Możesz teraz zalogować się za pomocą swoich danych!",
thankYouForJoiningUsText: "Dziękujemy i witamy w naszym gronie!",

JoinBakesoftText: "Dołącz do Bakesoft i zarabiaj na swoich wspaniałych przepisach!",
createAccountTodayText: "Załóż konto już dziś i zacznij sprzedawać swoje przepisy!",
plattformOfferingText: "Oferujemy wspaniałą platformę, na której możesz tworzyć i sprzedawać swoje przepisy na całym świecie!",
signUpOfferingText: "Zarejestruj się już dziś i zyskaj 14-dniowy okres próbny!",
joinBakesoftNowText: "Dołącz do www.bakesoft.se już teraz!",
accountDoneSetupText: "Twoje konto www.bakesoft.se jest gotowe!",
loginemailandpassText: "Zaloguj się za pomocą e-maila i hasła!",
topublishRecipesOnLiveText: "Aby opublikować przepisy na live.bakesoft.se, potrzebujesz płatnego konta.",
menyText:"Menu",
signUpText:"Zarejestruj się",
myAccountText:"Moje konto",
myBookmarkedRecipesText:"Moje zapisane przepisy",
SignUpToBakesoftText:"Zarejestruj się w Bakesoft",
howDoesItWorkText:"Jak to działa?",
liveSignUpText1:"Zarejestruj się live.bakesoft.se",

confirmPasswordText:"Potwierdź hasło",
currencyNLanguageText:"Waluta i język",
liveSignupText:"Zarejestruj się",
buyOneCreditText:"Kup 1 kredyt",
unlockedRecipesText:"Odblokowane przepisy",
accountDetailsText:"Dane konta",
receiptText:"Paragon",
savedRecipesText:"Zapisane przepisy",
endDateText:"Data zakończenia",
deleteSavedRecipeText:"Usuń",
nameText: "Nazwa",
slideToUnlockText: "Przesuń, aby odblokować",
unlockedText2: "Odblokowane",
logoutOnlyText: "Wyloguj",
youreLoggedOutText: "Jesteś wylogowany",

bakesoftSignUp1: "live.bakesoft.se to platforma bez reklam z niesamowitymi przepisami.",
bakesoftSignUp2: "Odblokuj przepisy za 1 USD/kredyt. Dostosuj je, uzyskaj informacje o wartości odżywczej, udostępniaj w mediach społecznościowych i tłumacz na różne języki. Członkostwo jest bezpłatne – brak opłat abonamentowych. Jeden kredyt odblokowuje jeden przepis. Odkrywaj przepisy od różnych szefów kuchni, z różnych krajów i w różnych językach.",
bakesoftSignUp3: "Chcesz tworzyć własne przepisy i zarabiać na nich pieniądze?",
bakesoftSignUp4: "Korzystaj z www.bakesoft.se do zaawansowanego zarządzania przepisami. Publikuj na naszych platformach i zarabiaj za każdym razem, gdy twój przepis zostanie odblokowany. Cena: 19 USD/miesiąc.",
bakesoftSignUp5: "Ustal swoją cenę (1–5 USD/przepis) i zarabiaj 33% za każdy sprzedany kredyt. Śledź zarobki i wypłaty w aplikacji. Użytkownicy są odpowiedzialni za podatki i przestrzeganie prawa w swoim kraju. Przeczytaj nasze warunki, aby dowiedzieć się więcej!"
,
termsAndConditionsText:"Warunki i zasady",
gotToLoginText:"Przejdź do logowania",
serviceS2Text: "5 kredytów z 10% rabatem",
serviceS3Text: "10 kredytów z 20% rabatem",
serviceS4Text: "Zarejestruj się za darmo – 14 dni próbne",
creditsText: "kredyty",
updateLanguageHeader: "Zaktualizuj język",
submitButtonChangeLanguageText:"Zaktualizuj",
kitchenTimerText: "Minutnik",
 showHideText: "Pokaż/Ukryj",
 forgotPasswordText: "Zapomniałeś hasła?",
 resetPassText:"Zresetuj hasło",
 resetLinkText:"Link do resetowania hasła został wysłany na Twój adres e-mail",
 unlockRecipeText: "Odblokuj przepis",
recipeUnlockedText: "Przepis odblokowany",

availableInText: "Dostępne w",
languagesText: "Języki",
translateTo16LanguagesText: "Przetłumacz na 16 języków",
proChefText: "Profesjonalny Szef Kuchni",
voteRegisteredText: "Twój głos został zarejestrowany",

copyLinkText: "Kopiuj link",
pinterestText: "Udostępnij na Pinterest",
instagramText: "Udostępnij na Instagramie",
twitterText: "Udostępnij na X",
facebookText: "Udostępnij na Facebooku",
linkCopiedText: "Skopiowano link",

welcomeToBakesoftText1: "Witamy w Bakesoft",
proRecipesText: "Profesjonalne przepisy dla kucharzy i piekarzy",
updaterecipeText: "Edytuj, dodawaj i aktualizuj składniki",
nutritionalText: "Wartości odżywcze",
signUpAndBonusText: "Zarejestruj się i otrzymaj 5 kredytów bonusowych",

signupbonusText: "Zarejestruj się i otrzymaj 5 kredytów bonusowych",
nocommitmentText: "Brak zobowiązań, brak abonamentu",
aboutUsLiveText: "O nas",




    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Witamy w Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Odkryj świat kulinarnej kreatywności",
        discoverAmazingRecipesText: "Odkryj niesamowite przepisy",
        liveBakesoftText: "live.bakesoft.se to platforma bez reklam dla niesamowitych przepisów.",
        affordableAccessText: "Odblokuj przepisy za 1 $/kredyt. Bez opłat abonamentowych!",
        customizationText: "Dostosuj przepisy i uzyskaj informacje o wartościach odżywczych",
        shareConnectText: "Udostępniaj swoje kulinarne dzieła w mediach społecznościowych",
        globalCuisineText: "Odkrywaj przepisy od różnych szefów kuchni, z różnych krajów i w różnych językach",
        signUpNowText: "Zarejestruj się teraz",
        forRecipeCreatorsText: "Dla twórców przepisów",
        createRecipesEarnMoneyText: "Chcesz tworzyć przepisy i zarabiać?",
        advancedRecipeManagementText: "Korzystaj z www.bakesoft.se do zaawansowanego zarządzania przepisami. Publikuj na naszych platformach i zarabiaj za każdym razem, gdy twój przepis zostanie odblokowany. Cena: 19 $/miesiąc.",
        
        setYourPriceText: "Ustal swoją cenę (1–5 $/przepis) i zarabiaj 33% za każdy sprzedany kredyt",
        trackEarningsText: "Śledź swoje zarobki i wypłaty w aplikacji",
        usersResponsibleText: "Użytkownicy są odpowiedzialni za podatki i przepisy prawne w swoim kraju",
        startCreatingText: "Zacznij tworzyć",
        readMoreInTermsText: "Przeczytaj więcej w naszych warunkach!",

        affordableAccessHeader: "Przystępny dostęp",
        customizationHeader: "Dostosowywanie",
        shareandConnectHeader: "Udostępnij i połącz",
        globalCuisineHeader: "Kuchnia światowa",

    },
    
},
nl: {
    common: {
        headerGreeting: "... geweldige recepten voor onvergetelijke momenten",
selectMenu: "Menu",
inputTitleBookmark: "Markeer dit recept",
imageTitleChef: "Chef-profiel",
translateRecipeTextGrid: "Vertaal",
titleShareOnSocialMedia: "Delen op sociale media",
textMinutesInShort: "min",
titleTextLikeRecipe: "Vind dit recept leuk",
titleSeenByVievers: "Bekeken door gebruikers",
noDescriptionText: "Geen beschrijving beschikbaar",
scrollMessageText: "Geweldige recepten vertalen",
scrollMessageText2: "Geweldige recepten ophalen",
translateRecipeText: "Vertaal recept",
translateCostText: "Vertaalkosten: 1 credit/geslaagde vertaling",
selectLanguageText: "Kies taal",
noNameAvailableText: "Geen naam beschikbaar",
savedRecipeText: "Opgeslagen recepten",
clickToUnlockText: "Klik/Ontgrendel",
ingredientText: "Ingrediënt",
pricePerKgText: "Prijs/kg",
priceText: "Prijs",
amountText: "Gram",
kaloriesText: "Calorieën",
increaseIngredientValueText: "Verhoog ingrediëntwaarde",
decreaseIngredientValueText: "Verlaag ingrediëntwaarde",
changeNameText: "Wijzig naam",
saveText: "Opslaan",
savedText: "Opgeslagen",
deleteText: "Verwijderen",
totalText: "Totaal",
addIngredientText: "Ingrediënt toevoegen",
addText: "Toevoegen",
placeHolderIngredientNameText: "Naam ingrediënt",
descriptionText: "Beschrijving",

unlockThisRecipeText: "Ontgrendel dit recept voor",
creditsToUseText: "- credits te gebruiken",
creditsText: "credits",
noCreditsText: "Je hebt geen credits om dit recept te ontgrendelen",
addMoreCreditsText: "Meer credits toevoegen",
gotoPaymentText: "Ga naar betaling",
chefInFocusText: "Chef in de spotlight",

bsLivePaymentText: "Bakesoft betalingsinformatie",
bsLiveAmountText: "Bedrag",
bsLiveCurrencyText: "Valuta",
bsLivePaymentTermsText: "Betalingsvoorwaarden",

paymentSuccessfullText: "Betaling geslaagd",
thankYouForPurchaseText: "Bedankt voor je aankoop!",
selectCurrencyText: "Kies valuta",
selectLanguageText2: "Kies taal",
placeHolderEmailText: "Voer je e-mailadres in",
placeHolderPasswordText: "Voer je wachtwoord in",
loginText2: "Inloggen",

logOutText: "Uitloggen",
norecipeAvailableText: "Geen recepten beschikbaar.",
notAvailableText: "Niet beschikbaar",
nameText: "Naam:",
countryText: "Land:",
currencyText: "Valuta:",
noTransactionsFoundText: "Geen transacties gevonden.",
paySuccessText: "Betaling geslaagd",
yourAccountSetupDoneText: "Je account is nu klaar",
youcannowLoginWithCredentialsText: "Je kunt nu inloggen met je gegevens!",
thankYouForJoiningUsText: "Bedankt en welkom bij ons!",

JoinBakesoftText: "Word lid van Bakesoft en verdien geld met je geweldige recepten!",
createAccountTodayText: "Maak vandaag nog een account aan en begin nu met het verkopen van je recepten!",
plattformOfferingText: "We bieden een geweldig platform waar je je recepten kunt maken en wereldwijd kunt verkopen!",
signUpOfferingText: "Registreer vandaag nog en ontvang een proefperiode van 14 dagen!",
joinBakesoftNowText: "Word nu lid van www.bakesoft.se!",
accountDoneSetupText: "Je www.bakesoft.se-account is nu klaar!",
loginemailandpassText: "Log in met e-mail en wachtwoord!",
topublishRecipesOnLiveText: "Om recepten te publiceren op live.bakesoft.se, heb je een betaald account nodig.",

menyText:"Menu",
signUpText:"Account aanmaken",
myAccountText:"Mijn account",
myBookmarkedRecipesText:"Mijn bladwijzerrecepten",
SignUpToBakesoftText:"Aanmelden bij live.bakesoft.se",
howDoesItWorkText:"Hoe werkt het?",
liveSignUpText1:"Aanmelden bij live.bakesoft.se",

confirmPasswordText:"Bevestig wachtwoord",
currencyNLanguageText:"Valuta en taal",
liveSignupText:"Aanmelden",
buyOneCreditText:"Koop 1 credit",
unlockedRecipesText:"Ontgrendelde recepten",
accountDetailsText:"Accountgegevens",
receiptText:"Ontvangst",
savedRecipesText:"Opgeslagen recepten",
endDateText:"Einddatum",
deleteSavedRecipeText:"Verwijderen",
nameText: "Naam",
slideToUnlockText: "Schuif om",
unlockedText2: "Ontgrendeld",
logoutOnlyText: "Uitloggen",
youreLoggedOutText: "Je bent uitgelogd",

bakesoftSignUp1: "live.bakesoft.se is een advertentievrij platform voor geweldige recepten.",
bakesoftSignUp2: "Ontgrendel recepten voor 1 USD/krediet. Pas ze aan, ontvang voedingsinformatie, deel ze op sociale media en vertaal ze naar verschillende talen. Lidmaatschap is gratis – geen abonnementskosten. Eén krediet ontgrendelt één recept. Ontdek recepten van verschillende chefs, landen en talen.",
bakesoftSignUp3: "Wil je je eigen recepten maken en hiermee geld verdienen?",
bakesoftSignUp4: "Gebruik www.bakesoft.se voor geavanceerd receptbeheer. Publiceer je recepten op onze platforms en verdien geld telkens wanneer een recept wordt ontgrendeld. Kosten: 19 USD/maand.",
bakesoftSignUp5: "Bepaal je prijs (1–5 USD/recept) en verdien 33% per verkocht krediet. Volg inkomsten en uitbetalingen in de app. Gebruikers zijn verantwoordelijk voor belastingen en wetten in hun eigen land. Lees onze voorwaarden voor meer informatie!"
,
termsAndConditionsText:"Algemene voorwaarden",
gotToLoginText:"Ga naar inloggen",
serviceS2Text: "Koop 5 receptencredits en krijg 10% korting",
serviceS3Text: "Koop 10 receptencredits en krijg 20% korting",
serviceS4Text: "Meld je gratis aan op bakesoft.se – 14 dagen proefperiode",
creditsText: "kredieten",
updateLanguageHeader: "Taal bijwerken",
submitButtonChangeLanguageText:"Bijwerken",
  kitchenTimerText: "Keukentimer",
   showHideText: "Weergeven/Verbergen",
    forgotPasswordText: "Wachtwoord vergeten?",
    resetPassText:"Wachtwoord opnieuw instellen",
    resetLinkText:"De resetlink is naar je e-mailadres gestuurd",

    unlockRecipeText: "Ontgrendel recept",
    recipeUnlockedText: "Recept ontgrendeld",


    availableInText: "Beschikbaar in",
    languagesText: "Talen",
    translateTo16LanguagesText: "Vertaal naar 16 talen",
    proChefText: "Professionele Chef",
    voteRegisteredText: "Je stem is geregistreerd",
    
    copyLinkText: "Kopieer link",
    pinterestText: "Delen op Pinterest",
    instagramText: "Delen op Instagram",
    twitterText: "Delen op X",
    facebookText: "Delen op Facebook",
    linkCopiedText: "Link gekopieerd",

    welcomeToBakesoftText1: "Welkom bij Bakesoft",
proRecipesText: "Professionele recepten voor koks en bakkers",
updaterecipeText: "Bewerk, voeg toe en update ingrediënten",
nutritionalText: "Voedingswaarden",
signUpAndBonusText: "Meld je aan en ontvang 5 bonuskredieten",

signupbonusText: "Meld je aan en ontvang 5 bonuskredieten",
nocommitmentText: "Geen verplichtingen, geen abonnement",
aboutUsLiveText: "Over ons",



    

    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Welkom bij Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Ontgrendel een wereld van culinaire creativiteit",
        discoverAmazingRecipesText: "Ontdek geweldige recepten",
        liveBakesoftText: "live.bakesoft.se is een advertentievrij platform voor geweldige recepten.",
        affordableAccessText: "Ontgrendel recepten voor $1/krediet. Geen abonnementskosten!",
        customizationText: "Pas recepten aan en krijg voedingsinformatie",
        shareConnectText: "Deel je culinaire creaties op sociale media",
        globalCuisineText: "Ontdek recepten van verschillende chefs, landen en talen",
        signUpNowText: "Meld je nu aan",
        forRecipeCreatorsText: "Voor receptenmakers",
        createRecipesEarnMoneyText: "Wil je recepten maken en geld verdienen?",
        advancedRecipeManagementText: "Gebruik www.bakesoft.se voor geavanceerd receptbeheer. Publiceer op onze platforms en verdien geld elke keer dat jouw recept wordt ontgrendeld. Prijs: $19/maand.",
        
        setYourPriceText: "Stel je prijs in ($1–$5/recept) en verdien 33% per verkocht krediet",
        trackEarningsText: "Volg je inkomsten en betalingen in de app",
        usersResponsibleText: "Gebruikers zijn verantwoordelijk voor belastingen en wetten in hun land",
        startCreatingText: "Begin met creëren",
        readMoreInTermsText: "Lees meer in onze voorwaarden!",

        affordableAccessHeader: "Betaalbare toegang",
        customizationHeader: "Aanpassing",
        shareandConnectHeader: "Delen en verbinden",
        globalCuisineHeader: "Wereldkeuken",

        welcomeToBakesoftText1: "Welkom bij Bakesoft",
        proRecipesText: "Professionele recepten voor koks en bakkers",
        updaterecipeText: "Bewerk, voeg toe en werk ingrediënten bij",
        nutritionalText: "Voedingswaarden",
        signUpAndBonusText: "Meld je aan en ontvang 5 kredietbonus",
        
    },
    
},
el: {
    common: {
        headerGreeting: "... υπέροχες συνταγές για αξέχαστες στιγμές",
selectMenu: "Μενού",
inputTitleBookmark: "Αποθήκευση αυτής της συνταγής",
imageTitleChef: "Προφίλ Σεφ",
translateRecipeTextGrid: "Μετάφραση",
titleShareOnSocialMedia: "Κοινοποίηση στα κοινωνικά δίκτυα",
textMinutesInShort: "λεπτά",
titleTextLikeRecipe: "Μου αρέσει αυτή η συνταγή",
titleSeenByVievers: "Εμφανίστηκε από χρήστες",
noDescriptionText: "Δεν υπάρχει διαθέσιμη περιγραφή",
scrollMessageText: "Μετάφραση υπέροχων συνταγών",
scrollMessageText2: "Φόρτωση υπέροχων συνταγών",
translateRecipeText: "Μετάφραση συνταγής",
translateCostText: "Κόστος μετάφρασης: 1 πίστωση/επιτυχής μετάφραση",
selectLanguageText: "Επιλογή γλώσσας",
noNameAvailableText: "Δεν υπάρχει διαθέσιμο όνομα",
savedRecipeText: "Αποθηκευμένες συνταγές",
clickToUnlockText: "Κλικ/Ξεκλείδωμα",
ingredientText: "Συστατικό",
pricePerKgText: "Τιμή/κιλό",
priceText: "Τιμή",
amountText: "Γραμμάρια",
kaloriesText: "Θερμίδες",
increaseIngredientValueText: "Αύξηση ποσότητας συστατικού",
decreaseIngredientValueText: "Μείωση ποσότητας συστατικού",
changeNameText: "Αλλαγή ονόματος",
saveText: "Αποθήκευση",
savedText: "Αποθηκεύτηκε",
deleteText: "Διαγραφή",
totalText: "Σύνολο",
addIngredientText: "Προσθήκη συστατικού",
addText: "Προσθήκη",
placeHolderIngredientNameText: "Όνομα συστατικού",
descriptionText: "Περιγραφή",

unlockThisRecipeText: "Ξεκλειδώστε αυτή τη συνταγή για",
creditsToUseText: "- πόντους που χρειάζονται",
creditsText: "πόντοι",
noCreditsText: "Δεν έχετε πόντους για να ξεκλειδώσετε αυτή τη συνταγή",
addMoreCreditsText: "Προσθέστε περισσότερους πόντους",
gotoPaymentText: "Μετάβαση στην πληρωμή",
chefInFocusText: "Σεφ σε εστίαση",

bsLivePaymentText: "Πληροφορίες πληρωμής Bakesoft",
bsLiveAmountText: "Ποσό",
bsLiveCurrencyText: "Νόμισμα",
bsLivePaymentTermsText: "Όροι πληρωμής",

paymentSuccessfullText: "Η πληρωμή ολοκληρώθηκε με επιτυχία",
thankYouForPurchaseText: "Ευχαριστούμε για την αγορά σας!",
selectCurrencyText: "Επιλογή νομίσματος",
selectLanguageText2: "Επιλογή γλώσσας",
placeHolderEmailText: "Εισαγάγετε το email σας",
placeHolderPasswordText: "Εισαγάγετε τον κωδικό σας",
loginText2: "Σύνδεση",

logOutText: "Αποσύνδεση",
norecipeAvailableText: "Δεν υπάρχουν διαθέσιμες συνταγές.",
notAvailableText: "Μη διαθέσιμο",
nameText: "Όνομα:",
countryText: "Χώρα:",
currencyText: "Νόμισμα:",
noTransactionsFoundText: "Δεν βρέθηκαν συναλλαγές.",
paySuccessText: "Η πληρωμή ήταν επιτυχής",
yourAccountSetupDoneText: "Ο λογαριασμός σας είναι έτοιμος",
youcannowLoginWithCredentialsText: "Τώρα μπορείτε να συνδεθείτε με τα στοιχεία σας!",
thankYouForJoiningUsText: "Ευχαριστούμε που συμμετείχατε και καλώς ήρθατε!",

JoinBakesoftText: "Εγγραφείτε στο Bakesoft και κερδίστε χρήματα με τις υπέροχες συνταγές σας!",
createAccountTodayText: "Δημιουργήστε λογαριασμό σήμερα και αρχίστε να πουλάτε τις συνταγές σας τώρα!",
plattformOfferingText: "Προσφέρουμε μια εξαιρετική πλατφόρμα όπου μπορείτε να δημιουργήσετε και να πουλήσετε τις συνταγές σας σε όλο τον κόσμο!",
signUpOfferingText: "Εγγραφείτε σήμερα και λάβετε 14 ημέρες δοκιμής!",
joinBakesoftNowText: "Εγγραφείτε τώρα στο www.bakesoft.se!",
accountDoneSetupText: "Ο λογαριασμός σας στο www.bakesoft.se είναι έτοιμος!",
loginemailandpassText: "Συνδεθείτε με email και κωδικό πρόσβασης!",
topublishRecipesOnLiveText: "Για να δημοσιεύσετε συνταγές στο live.bakesoft.se, χρειάζεστε έναν επί πληρωμή λογαριασμό.",

menyText:"Μενού",
signUpText:"Εγγραφή",
myAccountText:"Ο λογαριασμός μου",
myBookmarkedRecipesText:"Οι συνταγές μου στις σελιδοδείκτες",
SignUpToBakesoftText:"Εγγραφείτε στο Bakesoft",
howDoesItWorkText:"Πώς λειτουργεί;",
liveSignUpText1:"Εγγραφή live.bakesoft.se",

confirmPasswordText:"Επιβεβαίωση κωδικού πρόσβασης",
currencyNLanguageText:"Νόμισμα και γλώσσα",
liveSignupText:"Εγγραφή",
buyOneCreditText:"Αγοράστε 1 πίστωση",
unlockedRecipesText:"Ξεκλειδωμένες συνταγές",
accountDetailsText:"Στοιχεία λογαριασμού",
receiptText:"Απόδειξη",
savedRecipesText:"Αποθηκευμένες συνταγές",
endDateText:"Ημερομηνία λήξης",
deleteSavedRecipeText:"Διαγραφή",
nameText: "Όνομα",
slideToUnlockText: "Σύρετε για ξεκλείδωμα",
unlockedText2: "Ξεκλειδωμένο",
logoutOnlyText: "Αποσύνδεση",
youreLoggedOutText: "Έχετε αποσυνδεθεί",

bakesoftSignUp1: "Το live.bakesoft.se είναι μια πλατφόρμα χωρίς διαφημίσεις για υπέροχες συνταγές.",
bakesoftSignUp2: "Ξεκλειδώστε συνταγές με 1 USD/πίστωση. Προσαρμόστε τις, λάβετε διατροφικές πληροφορίες, μοιραστείτε τις στα κοινωνικά δίκτυα και μεταφράστε τις σε διάφορες γλώσσες. Η εγγραφή είναι δωρεάν – χωρίς συνδρομητικές χρεώσεις. Μία πίστωση ξεκλειδώνει μία συνταγή. Ανακαλύψτε συνταγές από διαφορετικούς σεφ, χώρες και γλώσσες.",
bakesoftSignUp3: "Θέλετε να δημιουργήσετε τις δικές σας συνταγές και να κερδίσετε χρήματα;",
bakesoftSignUp4: "Χρησιμοποιήστε το www.bakesoft.se για προηγμένη διαχείριση συνταγών. Δημοσιεύστε στις πλατφόρμες μας και κερδίστε κάθε φορά που ξεκλειδώνεται μια συνταγή σας. Κόστος: 19 USD/μήνα.",
bakesoftSignUp5: "Ορίστε την τιμή σας (1–5 USD/συνταγή) και κερδίστε 33% για κάθε πίστωση που πωλείται. Παρακολουθήστε έσοδα και πληρωμές μέσω της εφαρμογής. Οι χρήστες είναι υπεύθυνοι για φόρους και νόμους στη χώρα τους. Διαβάστε τους όρους μας για περισσότερες πληροφορίες!"
,
termsAndConditionsText:"Όροι και προϋποθέσεις",
gotToLoginText:"Μετάβαση στη σύνδεση",
serviceS2Text: "5 συνταγές με 10% έκπτωση",
serviceS3Text: "10 συνταγές με 20% έκπτωση",
serviceS4Text: "Δωρεάν εγγραφή – 14 μέρες δοκιμή",
creditsText: "πίστωση",
updateLanguageHeader: "Ενημέρωση γλώσσας",
submitButtonChangeLanguageText:"Ενημέρωση",
  kitchenTimerText: "Χρονόμετρο κουζίνας",
   showHideText: "Εμφάνιση/Απόκρυψη",
   resetPassText:"Ξεχάσατε τον κωδικό σας;",
   resetLinkText:"Ο σύνδεσμος επαναφοράς κωδικού στάλθηκε στο email σας",

   unlockRecipeText: "Ξεκλείδωμα συνταγής",
   recipeUnlockedText: "Η συνταγή ξεκλειδώθηκε",

   availableInText: "Διαθέσιμο σε",
   languagesText: "Γλώσσες",
   translateTo16LanguagesText: "Μετάφραση σε 16 γλώσσες",
   proChefText: "Επαγγελματίας Σεφ",
   voteRegisteredText: "Η ψήφος σας καταχωρήθηκε",

   copyLinkText: "Αντιγραφή συνδέσμου",
   pinterestText: "Κοινοποίηση στο Pinterest",
   instagramText: "Κοινοποίηση στο Instagram",
   twitterText: "Κοινοποίηση στο X",
   facebookText: "Κοινοποίηση στο Facebook",
   linkCopiedText: "Ο σύνδεσμος αντιγράφηκε",

   welcomeToBakesoftText1: "Καλώς ήρθατε στο Bakesoft",
proRecipesText: "Επαγγελματικές συνταγές για σεφ και αρτοποιούς",
updaterecipeText: "Επεξεργαστείτε, προσθέστε και ενημερώστε συστατικά",
nutritionalText: "Διατροφικές αξίες",
signUpAndBonusText: "Εγγραφείτε και κερδίστε 5 μονάδες μπόνους",

signupbonusText: "Εγγραφείτε και λάβετε μπόνους 5 πιστώσεων",
nocommitmentText: "Χωρίς δεσμεύσεις, χωρίς συνδρομή",
aboutUsLiveText: "Σχετικά με εμάς",

   
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Καλώς ήρθατε στο Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Ξεκλειδώστε έναν κόσμο μαγειρικής δημιουργικότητας",
        discoverAmazingRecipesText: "Ανακαλύψτε καταπληκτικές συνταγές",
        liveBakesoftText: "Το live.bakesoft.se είναι μια πλατφόρμα χωρίς διαφημίσεις για καταπληκτικές συνταγές.",
        affordableAccessText: "Ξεκλειδώστε συνταγές με $1/πίστωση. Χωρίς συνδρομητικές χρεώσεις!",
        customizationText: "Προσαρμόστε τις συνταγές και λάβετε διατροφικές πληροφορίες",
        shareConnectText: "Μοιραστείτε τις δημιουργίες σας στα κοινωνικά δίκτυα",
        globalCuisineText: "Εξερευνήστε συνταγές από διαφορετικούς σεφ, χώρες και γλώσσες",
        signUpNowText: "Εγγραφείτε τώρα",
        forRecipeCreatorsText: "Για δημιουργούς συνταγών",
        createRecipesEarnMoneyText: "Θέλετε να δημιουργήσετε συνταγές και να κερδίσετε χρήματα;",
        advancedRecipeManagementText: "Χρησιμοποιήστε το www.bakesoft.se για προηγμένη διαχείριση συνταγών. Δημοσιεύστε στις πλατφόρμες μας και κερδίστε χρήματα κάθε φορά που η συνταγή σας ξεκλειδώνεται. Τιμή: $19/μήνα.",
        
        setYourPriceText: "Ορίστε την τιμή σας ($1–$5/συνταγή) και κερδίστε 33% ανά πώληση πίστωσης",
        trackEarningsText: "Παρακολουθήστε τα κέρδη και τις πληρωμές στην εφαρμογή",
        usersResponsibleText: "Οι χρήστες είναι υπεύθυνοι για τους φόρους και τους νόμους στη χώρα τους",
        startCreatingText: "Ξεκινήστε να δημιουργείτε",
        readMoreInTermsText: "Διαβάστε περισσότερα στους όρους μας!",
        forgotPasswordText: "Ξεχάσατε τον κωδικό σας;",

        affordableAccessHeader: "Προσιτή πρόσβαση",
        customizationHeader: "Προσαρμογή",
        shareandConnectHeader: "Κοινοποίηση και σύνδεση",
        globalCuisineHeader: "Παγκόσμια κουζίνα",

    },
    
},
zh: {
    common: {
        headerGreeting: "... 为美好时光准备的美味食谱",
selectMenu: "菜单",
inputTitleBookmark: "收藏此食谱",
imageTitleChef: "厨师简介",
translateRecipeTextGrid: "翻译",
titleShareOnSocialMedia: "分享到社交媒体",
textMinutesInShort: "分钟",
titleTextLikeRecipe: "喜欢这个食谱",
titleSeenByVievers: "被用户查看",
noDescriptionText: "暂无描述",
scrollMessageText: "正在翻译美味食谱",
scrollMessageText2: "正在加载美味食谱",
translateRecipeText: "翻译食谱",
translateCostText: "翻译费用：1 积分/成功翻译",
selectLanguageText: "选择语言",
noNameAvailableText: "暂无名称",
savedRecipeText: "已保存的食谱",
clickToUnlockText: "点击/解锁",
ingredientText: "食材",
pricePerKgText: "价格/公斤",
priceText: "价格",
amountText: "克",
kaloriesText: "卡路里",
increaseIngredientValueText: "增加食材数量",
decreaseIngredientValueText: "减少食材数量",
changeNameText: "更改名称",
saveText: "保存",
savedText: "已保存",
deleteText: "删除",
totalText: "总计",
addIngredientText: "添加食材",
addText: "添加",
placeHolderIngredientNameText: "食材名称",
descriptionText: "描述",

unlockThisRecipeText: "解锁此食谱需要",
creditsToUseText: "- 所需积分",
creditsText: "积分",
noCreditsText: "您没有足够的积分来解锁此食谱",
addMoreCreditsText: "添加更多积分",
gotoPaymentText: "前往支付",
chefInFocusText: "焦点厨师",

bsLivePaymentText: "Bakesoft 支付信息",
bsLiveAmountText: "金额",
bsLiveCurrencyText: "货币",
bsLivePaymentTermsText: "支付条款",

paymentSuccessfullText: "支付成功",
thankYouForPurchaseText: "感谢您的购买！",
selectCurrencyText: "选择货币",
selectLanguageText2: "选择语言",
placeHolderEmailText: "输入您的电子邮件地址",
placeHolderPasswordText: "输入您的密码",
loginText2: "登录",

logOutText: "登出",
norecipeAvailableText: "暂无食谱。",
notAvailableText: "不可用",
nameText: "名称：",
countryText: "国家：",
currencyText: "货币：",
noTransactionsFoundText: "未找到交易。",
paySuccessText: "支付成功",
yourAccountSetupDoneText: "您的账户已准备就绪",
youcannowLoginWithCredentialsText: "您现在可以使用凭据登录！",
thankYouForJoiningUsText: "感谢您的加入，欢迎！",

JoinBakesoftText: "加入 Bakesoft，通过您的美味食谱赚钱！",
createAccountTodayText: "今天创建一个账户，现在开始出售您的食谱！",
plattformOfferingText: "我们提供一个出色的平台，您可以在全球范围内创建和销售您的食谱！",
signUpOfferingText: "今天注册，享受 14 天免费试用！",
joinBakesoftNowText: "立即加入 www.bakesoft.se！",
accountDoneSetupText: "您的 www.bakesoft.se 账户已准备就绪！",
loginemailandpassText: "使用电子邮件和密码登录！",
topublishRecipesOnLiveText: "要在 live.bakesoft.se 上发布食谱，您需要一个付费账户。",

menyText:"菜单",
signUpText:"注册",
myAccountText:"我的账户",
myBookmarkedRecipesText:"我的收藏食谱",
SignUpToBakesoftText:"注册 Bakesoft",
howDoesItWorkText:"它是如何工作的？",
liveSignUpText:"注册",

confirmPasswordText:"确认密码",
currencyNLanguageText:"货币和语言",
liveSignupText1:"注册 live.bakesoft.se",
buyOneCreditText:"购买 1 积分",
unlockedRecipesText:"已解锁食谱",
accountDetailsText:"账户详情",
receiptText:"收据",
savedRecipesText:"已保存食谱",
endDateText:"结束日期",
deleteSavedRecipeText:"删除",
nameText: "名称",
slideToUnlockText: "滑动解锁",
unlockedText2: "已解锁",
logoutOnlyText: "仅登出",
youreLoggedOutText: "您已登出",

bakesoftSignUp1: "live.bakesoft.se 是一个没有广告的精彩食谱平台。",
bakesoftSignUp2: "每个食谱仅需 1 美元/积分即可解锁。您可以自定义食谱，获取营养信息，在社交媒体上分享，并翻译成多种语言。注册会员免费，无需订阅费用。一个积分解锁一个食谱。探索来自不同厨师、国家和语言的食谱。",
bakesoftSignUp3: "想创建自己的食谱并赚取收入吗？",
bakesoftSignUp4: "使用 www.bakesoft.se 进行高级食谱管理。在我们的平台上发布食谱，每次食谱被解锁时都可以赚取收入。价格：19 美元/月。",
bakesoftSignUp5: "设置您的价格（1–5 美元/食谱），每售出一个积分可赚取 33% 的收入。在应用程序中跟踪收入和付款。用户需自行负责所在国家的税务和法律。了解更多信息，请阅读我们的条款！"
,
termsAndConditionsText:"条款和条件",
gotToLoginText:"前往登录",
serviceS2Text: "购买5个配方积分，享受10%折扣",
serviceS3Text: "购买10个配方积分，享受20%折扣",
serviceS4Text: "免费注册bakesoft.se – 14天试用",
creditsText: "积分",
updateLanguageHeader: "更新语言",
submitButtonChangeLanguageText:"更新",
    kitchenTimerText: "厨房计时器",
     showHideText: "显示/隐藏",
     forgotPasswordText: "忘记密码？",
     resetPassText:"重置密码",
     resetLinkText:"重置密码链接已发送到您的电子邮件",

     unlockRecipeText: "解锁食谱",
     recipeUnlockedText: "食谱已解锁",

     availableInText: "可用语言",
     languagesText: "语言",
     translateTo16LanguagesText: "翻译成16种语言",
     proChefText: "专业厨师",
     voteRegisteredText: "您的投票已注册",

     copyLinkText: "复制链接",
     pinterestText: "分享到Pinterest",
     instagramText: "分享到Instagram",
     twitterText: "分享到 X",
     facebookText: "分享到Facebook",
     linkCopiedText: "链接已复制",

     welcomeToBakesoftText1: "欢迎来到Bakesoft",
proRecipesText: "为厨师和烘焙师提供的专业食谱",
updaterecipeText: "编辑、添加和更新配料",
nutritionalText: "营养价值",
signUpAndBonusText: "注册即可获得5点积分奖励",

signupbonusText: "注册即可获得5点积分奖励",
nocommitmentText: "无需承诺，无需订阅",
aboutUsLiveText: "关于我们",

     
     
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "欢迎来到 Bakesoft",
        unlockWorldOfCulinaryCreativityText: "开启烹饪创意的世界",
        discoverAmazingRecipesText: "发现令人惊叹的食谱",
        liveBakesoftText: "live.bakesoft.se 是一个无广告的精彩食谱平台。",
        affordableAccessText: "以 1 美元/点数解锁食谱。无需订阅费用！",
        customizationText: "自定义食谱并获取营养信息",
        shareConnectText: "在社交媒体上分享您的烹饪创作",
        globalCuisineText: "探索来自不同厨师、国家和语言的食谱",
        signUpNowText: "立即注册",
        forRecipeCreatorsText: "面向食谱创作者",
        createRecipesEarnMoneyText: "想要创建食谱并赚取收入吗？",
        advancedRecipeManagementText: "使用 www.bakesoft.se 进行高级食谱管理。在我们的平台上发布，每次您的食谱被解锁时都能赚取收入。价格：每月 19 美元。",
        
        setYourPriceText: "设置您的价格（1 美元–5 美元/食谱），并从每个售出的点数中赚取 33%",
        trackEarningsText: "在应用程序中跟踪收入和付款",
        usersResponsibleText: "用户需自行负责其国家的税务和法律",
        startCreatingText: "开始创作",
        readMoreInTermsText: "在我们的条款中了解更多！",

                affordableAccessHeader: "实惠的访问",
        customizationHeader: "自定义",
        shareandConnectHeader: "分享与连接",
        globalCuisineHeader: "全球美食",

        welcomeToBakesoftText1: "欢迎来到Bakesoft",
        proRecipesText: "厨师和面包师的专业食谱",
        updaterecipeText: "编辑、添加和更新食材",
        nutritionalText: "营养价值",
        signUpAndBonusText: "注册并获得5积分奖励",
        


    },
    
},
ph: {
    common: {
        headerGreeting: "... magagandang mga recipe para sa di malilimutang sandali",
selectMenu: "Menu",
inputTitleBookmark: "I-bookmark ang recipe na ito",
imageTitleChef: "Profile ng Chef",
translateRecipeTextGrid: "Isalin",
titleShareOnSocialMedia: "Ibahagi sa social media",
textMinutesInShort: "min",
titleTextLikeRecipe: "Magustuhan ang recipe na ito",
titleSeenByVievers: "Nakita ng mga user",
noDescriptionText: "Walang available na paglalarawan",
scrollMessageText: "Isinasalin ang magagandang recipe",
scrollMessageText2: "Kinukuha ang magagandang recipe",
translateRecipeText: "Isalin ang recipe",
translateCostText: "Gastos sa pagsasalin: 1 credit/tagumpay na pagsasalin",
selectLanguageText: "Piliin ang wika",
noNameAvailableText: "Walang pangalan na available",
savedRecipeText: "Naka-save na mga recipe",
clickToUnlockText: "I-click/I-unlock",
ingredientText: "Sangkap",
pricePerKgText: "Presyo/kg",
priceText: "Presyo",
amountText: "Gram",
kaloriesText: "Calorie",
increaseIngredientValueText: "Dagdagan ang halaga ng sangkap",
decreaseIngredientValueText: "Bawasan ang halaga ng sangkap",
changeNameText: "Palitan ang pangalan",
saveText: "I-save",
savedText: "Naka-save",
deleteText: "Tanggalin",
totalText: "Kabuuan",
addIngredientText: "Magdagdag ng sangkap",
addText: "Magdagdag",
placeHolderIngredientNameText: "Pangalan ng sangkap",
descriptionText: "Paglalarawan",

unlockThisRecipeText: "I-unlock ang recipe na ito para sa",
creditsToUseText: "- kredito na gagamitin",
creditsText: "kredito",
noCreditsText: "Wala kang sapat na kredito upang i-unlock ang recipe na ito",
addMoreCreditsText: "Magdagdag ng mas maraming kredito",
gotoPaymentText: "Pumunta sa pagbabayad",
chefInFocusText: "Chef sa spotlight",

bsLivePaymentText: "Impormasyon ng pagbabayad ng Bakesoft",
bsLiveAmountText: "Halaga",
bsLiveCurrencyText: "Pera",
bsLivePaymentTermsText: "Mga tuntunin ng pagbabayad",

paymentSuccessfullText: "Matagumpay ang pagbabayad",
thankYouForPurchaseText: "Salamat sa iyong pagbili!",
selectCurrencyText: "Piliin ang pera",
selectLanguageText2: "Piliin ang wika",
placeHolderEmailText: "Ilagay ang iyong email address",
placeHolderPasswordText: "Ilagay ang iyong password",
loginText2: "Mag-login",

logOutText: "Mag-logout",
norecipeAvailableText: "Walang magagamit na mga recipe.",
notAvailableText: "Hindi magagamit",
nameText: "Pangalan:",
countryText: "Bansa:",
currencyText: "Pera:",
noTransactionsFoundText: "Walang nakitang mga transaksyon.",
paySuccessText: "Matagumpay ang pagbabayad",
yourAccountSetupDoneText: "Handa na ang iyong account",
youcannowLoginWithCredentialsText: "Maaari ka nang mag-login gamit ang iyong mga kredensyal!",
thankYouForJoiningUsText: "Salamat at maligayang pagdating sa amin!",

JoinBakesoftText: "Sumali sa Bakesoft at kumita ng pera gamit ang iyong magagandang recipe!",
createAccountTodayText: "Gumawa ng account ngayon at simulan ang pagbebenta ng iyong mga recipe ngayon din!",
plattformOfferingText: "Nag-aalok kami ng isang mahusay na platform kung saan maaari kang lumikha at magbenta ng iyong mga recipe sa buong mundo!",
signUpOfferingText: "Mag-sign up ngayon at makakuha ng 14 na araw na trial period!",
joinBakesoftNowText: "Sumali na sa www.bakesoft.se ngayon!",
accountDoneSetupText: "Handa na ang iyong www.bakesoft.se account!",
loginemailandpassText: "Mag-login gamit ang email at password!",
topublishRecipesOnLiveText: "Upang mag-publish ng mga recipe sa live.bakesoft.se, kailangan mo ng bayad na account.",

menyText:"Menu",
signUpText:"Mag-sign up",
myAccountText:"Aking account",
myBookmarkedRecipesText:"Aking mga naka-bookmark na recipe",
SignUpToBakesoftText:"Mag-sign up sa Bakesoft",
howDoesItWorkText:"Paano Ito Gumagana?",
liveSignUpText:"Mag-sign up",

confirmPasswordText:"Kumpirmahin ang password",
currencyNLanguageText:"Pera at Wika",
liveSignupText1:"Mag-sign up live.bakesoft.se",
buyOneCreditText:"Bumili ng 1 credit",
unlockedRecipesText:"Mga na-unlock na recipe",
accountDetailsText:"Mga Detalye ng Account",
receiptText:"Resibo",
savedRecipesText:"Naka-save na mga recipe",
endDateText:"Petsa ng Pagtatapos",
deleteSavedRecipeText:"Tanggalin",
nameText: "Pangalan",
slideToUnlockText: "I-slide para i-unlock",
unlockedText2: "Na-unlock",
logoutOnlyText: "Mag-logout",
youreLoggedOutText: "Ikaw ay naka-logout",

bakesoftSignUp1: "Ang live.bakesoft.se ay isang platform na walang mga ads para sa mga kamangha-manghang recipe.",
bakesoftSignUp2: "I-unlock ang mga recipe sa halagang 1 USD/kredito. Pwede mong i-customize, tingnan ang impormasyon sa nutrisyon, i-share sa social media, at isalin sa iba't ibang wika. Libre ang pagiging miyembro – walang subscription fees. Isang kredito ang kailangan para ma-unlock ang isang recipe. Tuklasin ang mga recipe mula sa iba’t ibang chef, bansa, at wika.",
bakesoftSignUp3: "Gusto mo bang lumikha ng sariling recipe at kumita ng pera?",
bakesoftSignUp4: "Gamitin ang www.bakesoft.se para sa advanced na pamamahala ng recipe. I-publish ang iyong mga recipe sa aming mga platform at kumita tuwing na-unlock ang isang recipe. Presyo: 19 USD/buwan.",
bakesoftSignUp5: "Magtakda ng presyo (1–5 USD/recipe) at kumita ng 33% kada nabentang kredito. Subaybayan ang kita at payout sa app. Responsibilidad ng mga user ang buwis at batas sa kanilang bansa. Basahin ang aming mga kondisyon para sa karagdagang impormasyon!"
,

termsAndConditionsText:"Mga Tuntunin at Kondisyon",
gotToLoginText:"Pumunta sa login",
serviceS2Text: "5 credits, 10% off",
serviceS3Text: "10 credits, 20% off",
serviceS4Text: "Libre signup – 14 araw na trial",
creditsText: "credits",
updateLanguageHeader: "I-update ang Wika",
submitButtonChangeLanguageText:"I-update",
    kitchenTimerText: "Kitchen Timer",
     showHideText: "Ipakita/Itago",
     forgotPasswordText: "Nakalimutan ang password?",
     resetPassText:"I-reset ang password",
     resetLinkText:"Ang link para sa pag-reset ng password ay naipadala sa iyong email",
     
     unlockRecipeText: "I-unlock ang recipe",
     recipeUnlockedText: "Na-unlock na ang recipe",

     availableInText: "Magagamit sa",
     languagesText: "Mga Wika",
     translateTo16LanguagesText: "Isalin sa 16 na wika",
     proChefText: "Propesyonal na Kusinero",
     voteRegisteredText: "Ang iyong boto ay narehistro",

     copyLinkText: "Kopyahin ang link",
     pinterestText: "Ibahagi sa Pinterest",
     instagramText: "Ibahagi sa Instagram",
     twitterText: "Ibahagi sa X",
     facebookText: "Ibahagi sa Facebook",
     linkCopiedText: "Ang link ay na-kopya",

     welcomeToBakesoftText1: "Maligayang pagdating sa Bakesoft",
     proRecipesText: "Propesyonal na mga recipe para sa mga chef at panadero",
     updaterecipeText: "I-edit, magdagdag, at i-update ang mga sangkap",
     nutritionalText: "Halaga ng nutrisyon",
     signUpAndBonusText: "Magrehistro at makakuha ng 5 credit na bonus",

     signupbonusText: "Mag-sign up at makakuha ng 5-credit bonus",
     nocommitmentText: "Walang commitment, walang subscription",
     aboutUsLiveText: "Tungkol sa amin",

     
     
     
     
    },
    bakesoftPromotion: {
        welcomeToBakesoftText: "Maligayang Pagdating sa Bakesoft",
        unlockWorldOfCulinaryCreativityText: "Buksan ang mundo ng malikhaing pagluluto",
        discoverAmazingRecipesText: "Tuklasin ang mga kamangha-manghang recipe",
        liveBakesoftText: "Ang live.bakesoft.se ay isang platform na walang advertisement para sa mga kamangha-manghang recipe.",
        affordableAccessText: "Buksan ang mga recipe sa halagang $1/kredito. Walang bayad na subscription!",
        customizationText: "I-customize ang mga recipe at makakuha ng impormasyon tungkol sa nutrisyon",
        shareConnectText: "Ibahagi ang iyong mga likha sa pagluluto sa social media",
        globalCuisineText: "Tuklasin ang mga recipe mula sa iba't ibang chef, bansa, at wika",
        signUpNowText: "Mag-sign Up Ngayon",
        forRecipeCreatorsText: "Para sa mga Gumagawa ng Recipe",
        createRecipesEarnMoneyText: "Gusto mo bang gumawa ng mga recipe at kumita?",
        advancedRecipeManagementText: "Gamitin ang www.bakesoft.se para sa advanced na pamamahala ng recipe. Mag-publish sa aming mga platform at kumita sa tuwing ang iyong recipe ay mabubuksan. Presyo: $19/buwan.",
        
        setYourPriceText: "Itakda ang iyong presyo ($1–$5/recipe) at kumita ng 33% bawat nabentang kredito",
        trackEarningsText: "Subaybayan ang iyong kita at bayad sa app",
        usersResponsibleText: "Ang mga user ay responsable para sa buwis at batas sa kanilang bansa",
        startCreatingText: "Magsimula ng Paglikha",
        readMoreInTermsText: "Basahin ang higit pa sa aming mga tuntunin!",

        affordableAccessHeader: "Abot-kayang Access",
        customizationHeader: "Pag-customize",
        shareandConnectHeader: "Ibahagi at Kumonekta",
        globalCuisineHeader: "Pandaigdigang Lutuin",

    },
    
},
ar: {
    common: {
      headerGreeting: "... وصفات رائعة للحظات مذهلة.",
      selectMenu: "القائمة",
      inputTitleBookmark: "إضافة هذه الوصفة إلى الإشارات المرجعية",
      imageTitleChef: "ملف تعريف الشيف",
      translateRecipeTextGrid: "ترجمة",
      titleShareOnSocialMedia: "مشاركة على وسائل التواصل الاجتماعي",
      textMinutesInShort: "دقيقة",
      titleTextLikeRecipe: "أحب هذه الوصفة",
      titleSeenByVievers: "تمت مشاهدتها من قبل المستخدمين",
      noDescriptionText: "لا يوجد وصف متاح",
      scrollMessageText: "ترجمة الوصفات المذهلة",
      scrollMessageText2: "جلب الوصفات المذهلة",
      translateRecipeText: "ترجمة الوصفة",
      translateCostText: "تكلفة الترجمة 1 ائتمان/ترجمة ناجحة",
      selectLanguageText: "اختر اللغة",
      noNameAvailableText: "لا يوجد اسم متاح",
      savedRecipeText: "الوصفات المحفوظة",
      clickToUnlockText: "اضغط/لفتح",
      ingredientText: "المكون",
      pricePerKgText: "السعر/كجم",
      priceText: "السعر",
      amountText: "الكمية",
      kaloriesText: "السعرات الحرارية",
      increaseIngredientValueText: "زيادة قيمة المكون",
      decreaseIngredientValueText: "تقليل قيمة المكون",
      changeNameText: "تغيير الاسم",
      saveText: "حفظ",
      savedText: "تم الحفظ",
      deleteText: "حذف",
      totalText: "الإجمالي",
      addIngredientText: "إضافة مكون",
      addText: "إضافة",
      placeHolderIngredientNameText: "اسم المكون",
      descriptionText: "الوصف",
      unlockThisRecipeText: "افتح هذه الوصفة مقابل",
      creditsToUseText: "- أرصدة للاستخدام",
      creditsText: "أرصدة",
      noCreditsText: "ليس لديك أرصدة لفتح هذه الوصفة",
      addMoreCreditsText: "أضف المزيد من الأرصدة",
      gotoPaymentText: "اذهب إلى الدفع",
      chefInFocusText: "الشيف في دائرة الضوء",
      bsLivePaymentText: "معلومات الدفع من Bakesoft",
      bsLiveAmountText: "المبلغ",
      bsLiveCurrencyText: "العملة",
      bsLivePaymentTermsText: "شروط الدفع",
      paymentSuccessfullText: "تم الدفع بنجاح",
      thankYouForPurchaseText: "شكرًا لك على الشراء!",
      selectCurrencyText: "اختر العملة",
      selectLanguageText2: "اختر اللغة",
      placeHolderEmailText: "أدخل عنوان بريدك الإلكتروني",
      placeHolderPasswordText: "أدخل كلمة المرور الخاصة بك",
      loginText2: "تسجيل الدخول",
      logOutText: "تسجيل الخروج",
      norecipeAvailableText: "لا توجد وصفات متاحة.",
      notAvailableText: "غير متاح",
      nameText: "الاسم:",
      countryText: "البلد:",
      currencyText: "العملة:",
      noTransactionsFoundText: "لم يتم العثور على معاملات.",
      paySuccessText: "تم الدفع بنجاح",
      yourAccountSetupDoneText: "تم إعداد حسابك الآن",
      youcannowLoginWithCredentialsText: "يمكنك الآن تسجيل الدخول باستخدام بيانات اعتمادك!",
      thankYouForJoiningUsText: "شكرًا لك ومرحبًا بك معنا!",
      JoinBakesoftText: "انضم إلى Bakesoft واربح المال مع وصفاتك المذهلة!",
      createAccountTodayText: "أنشئ حسابًا اليوم وابدأ ببيع وصفاتك الآن!",
      plattformOfferingText: "نحن نقدم منصة رائعة حيث يمكنك إنشاء وبيع وصفاتك للعالم!",
      signUpOfferingText: "اشترك اليوم واحصل على فترة تجريبية لمدة 14 يومًا!",
      joinBakesoftNowText: "انضم إلى www.bakesoft.se الآن!",
      accountDoneSetupText: "تم الآن إعداد حسابك على www.bakesoft.se!",
      loginemailandpassText: "سجّل الدخول باستخدام البريد الإلكتروني وكلمة المرور!",
      topublishRecipesOnLiveText: "لنشر الوصفات على live.bakesoft.se، تحتاج إلى حساب مدفوع.",
      menyText: "القائمة",
      signUpText: "تسجيل حساب",
      myAccountText: "حسابي",
      myBookmarkedRecipesText: "وصفاتي المحفوظة",
      SignUpToBakesoftText: "اشترك في live.Bakesoft.se",
      howDoesItWorkText: "كيف يعمل؟",
      liveSignUpText: "اشترك",
      liveSignUpText1: "اشترك في live.bakesoft.se",
      confirmPasswordText: "تأكيد كلمة المرور",
      currencyNLanguageText: "العملة واللغة",
      buyOneCreditText: "اشترِ 1 ائتمان",
      unlockedRecipesText: "الوصفات المفتوحة",
      accountDetailsText: "تفاصيل الحساب",
      receiptText: "الإيصال",
      savedRecipesText: "الوصفات المحفوظة",
      endDateText: "تاريخ الانتهاء",
      deleteSavedRecipeText: "حذف",
      nameText: "الاسم",
      slideToUnlockText: "اسحب للفتح",
      unlockedText2: "تم الفتح",
      logoutOnlyText: "تسجيل الخروج",
      youreLoggedOutText: "لقد تم تسجيل خروجك",
      bakesoftSignUp1: "live.bakesoft.se هي منصة خالية من الإعلانات للوصفات المذهلة.",
      bakesoftSignUp2: "افتح الوصفات مقابل 1 دولار/ائتمان. خصصها، احصل على معلومات التغذية، شاركها على وسائل التواصل الاجتماعي، وترجمها إلى لغات مختلفة. الاشتراك مجاني - لا توجد رسوم اشتراك.",
      bakesoftSignUp3: "هل تريد إنشاء وصفات وكسب المال؟",
      bakesoftSignUp4: "استخدم www.bakesoft.se لإدارة الوصفات المتقدمة. انشر على منصاتنا واربح المال في كل مرة يتم فيها فتح وصفتك. السعر: 19 دولارًا/شهر.",
      bakesoftSignUp5: "حدد سعرك (1-5 دولار/الوصفة) واربح 33% عن كل ائتمان مُباع. تتبع الأرباح والمدفوعات في التطبيق.",
      termsAndConditionsText: "الشروط والأحكام",
      gotToLoginText: "انتقل إلى تسجيل الدخول",
      serviceS2Text: "اشترِ 5 أرصدة للوصفات واحصل على خصم 10%",
      serviceS3Text: "اشترِ 10 أرصدة للوصفات واحصل على خصم 20%",
      serviceS4Text: "اشترك في bakesoft.se مجانًا - تجربة لمدة 14 يومًا",
      creditsText: "أرصدة",
      updateLanguageHeader: "تحديث اللغة",
      submitButtonChangeLanguageText: "تحديث",
      kitchenTimerText: "مؤقت المطبخ",
      showHideText: "إظهار/إخفاء",
      forgotPasswordText: "هل نسيت كلمة المرور؟",
      resetPassText: "إعادة تعيين كلمة المرور",
      resetLinkText: "تم إرسال رابط إعادة التعيين إلى بريدك الإلكتروني",
      unlockRecipeText: "افتح الوصفة",
      recipeUnlockedText: "تم فتح الوصفة",
      availableInText: "متاح بـ",
      languagesText: "اللغات",
      translateTo16LanguagesText: "ترجمة إلى 16 لغة",
      proChefText: "شيف محترف",
      voteRegisteredText: "تم تسجيل تصويتك",
      copyLinkText: "نسخ الرابط",
      pinterestText: "مشاركة على Pinterest",
      instagremText: "مشاركة على Instagram",
      twitterText: "مشاركة على X",
      facebookText: "مشاركة على Facebook",
      linkCopiedText: "تم نسخ الرابط",
      welcomeToBakesoftText1: "مرحبًا بك في Bakesoft",
      proRecipesText: "وصفات احترافية للشيفات والخبازين",
      updaterecipeText: "قم بتعديل، إضافة، وتحديث المكونات",
      nutritionalText: "القيم الغذائية",
      signUpAndBonusText: "اشترك واحصل على مكافأة 5 أرصدة",
      signupbonusText: "اشترك واحصل على مكافأة 5 أرصدة",
      nocommitmentText: "لا التزام، لا اشتراك",
      aboutUsLiveText: "معلومات عنا",
    },
    bakesoftPromotion: {
      welcomeToBakesoftText: "مرحبًا بك في Bakesoft",
      unlockWorldOfCulinaryCreativityText: "افتح عالمًا من الإبداع في الطهي",
      discoverAmazingRecipesText: "اكتشف وصفات مذهلة",
      liveBakesoftText: "live.bakesoft.se هي منصة خالية من الإعلانات للوصفات المذهلة.",
      affordableAccessText: "افتح الوصفات مقابل 1 دولار/ائتمان. لا توجد رسوم اشتراك!",
      customizationText: "خصص الوصفات واحصل على معلومات التغذية",
      shareConnectText: "شارك إبداعاتك الطهيّة على وسائل التواصل الاجتماعي",
      globalCuisineText: "استكشف الوصفات من شيفات، دول، ولغات مختلفة",
      signUpNowText: "اشترك الآن",
      forRecipeCreatorsText: "لصانعي الوصفات",
      createRecipesEarnMoneyText: "هل تريد إنشاء وصفات وكسب المال؟",
      advancedRecipeManagementText: "استخدم www.bakesoft.se لإدارة الوصفات المتقدمة. انشر على منصاتنا واربح المال في كل مرة يتم فيها فتح وصفتك. السعر: 19 دولارًا/شهر.",
      setYourPriceText: "حدد سعرك (1-5 دولار/الوصفة) واربح 33% عن كل ائتمان مُباع",
      trackEarningsText: "تتبع الأرباح والمدفوعات في التطبيق",
      usersResponsibleText: "المستخدمون مسؤولون عن الضرائب والقوانين في بلدهم",
      startCreatingText: "ابدأ الآن",
      readMoreInTermsText: "اقرأ المزيد في الشروط!",
      affordableAccessHeader: "وصول ميسور التكلفة",
      customizationHeader: "التخصيص",
      shareandConnectHeader: "المشاركة والاتصال",
      globalCuisineHeader: "المأكولات العالمية",
    },
  },
  fa: {
    common: {
      headerGreeting: "... دستورپخت‌های فوق‌العاده برای لحظات شگفت‌انگیز.",
      selectMenu: "منو",
      inputTitleBookmark: "این دستورپخت را نشانک‌گذاری کنید",
      imageTitleChef: "پروفایل سرآشپز",
      translateRecipeTextGrid: "ترجمه",
      titleShareOnSocialMedia: "اشتراک‌گذاری در شبکه‌های اجتماعی",
      textMinutesInShort: "دقیقه",
      titleTextLikeRecipe: "این دستورپخت را دوست داشته باشید",
      titleSeenByVievers: "توسط کاربران مشاهده شده",
      noDescriptionText: "توضیحی موجود نیست",
      scrollMessageText: "ترجمه دستورپخت‌های شگفت‌انگیز",
      scrollMessageText2: "دریافت دستورپخت‌های شگفت‌انگیز",
      translateRecipeText: "ترجمه دستورپخت",
      translateCostText: "هزینه ترجمه ۱ اعتبار/ترجمه موفق",
      selectLanguageText: "انتخاب زبان",
      noNameAvailableText: "نامی موجود نیست",
      savedRecipeText: "دستورپخت‌های ذخیره شده",
      clickToUnlockText: "کلیک کنید/باز کنید",
      ingredientText: "ماده اولیه",
      pricePerKgText: "قیمت/کیلوگرم",
      priceText: "قیمت",
      amountText: "مقدار",
      kaloriesText: "کالری",
      increaseIngredientValueText: "افزایش مقدار ماده اولیه",
      decreaseIngredientValueText: "کاهش مقدار ماده اولیه",
      changeNameText: "تغییر نام",
      saveText: "ذخیره",
      savedText: "ذخیره شد",
      deleteText: "حذف",
      totalText: "مجموع",
      addIngredientText: "افزودن ماده اولیه",
      addText: "اضافه کردن",
      placeHolderIngredientNameText: "نام ماده اولیه",
      descriptionText: "توضیحات",
      unlockThisRecipeText: "باز کردن این دستورپخت با",
      creditsToUseText: "- اعتبار برای استفاده",
      creditsText: "اعتبار",
      noCreditsText: "شما اعتباری برای باز کردن این دستورپخت ندارید",
      addMoreCreditsText: "اعتبار بیشتری اضافه کنید",
      gotoPaymentText: "رفتن به پرداخت",
      chefInFocusText: "سرآشپز در مرکز توجه",
      bsLivePaymentText: "اطلاعات پرداخت Bakesoft",
      bsLiveAmountText: "مقدار",
      bsLiveCurrencyText: "واحد پول",
      bsLivePaymentTermsText: "شرایط پرداخت",
      paymentSuccessfullText: "پرداخت با موفقیت انجام شد",
      thankYouForPurchaseText: "از خرید شما متشکریم!",
      selectCurrencyText: "انتخاب واحد پول",
      selectLanguageText2: "انتخاب زبان",
      placeHolderEmailText: "آدرس ایمیل خود را وارد کنید",
      placeHolderPasswordText: "رمز عبور خود را وارد کنید",
      loginText2: "ورود",
      logOutText: "خروج",
      norecipeAvailableText: "دستورپختی موجود نیست.",
      notAvailableText: "در دسترس نیست",
      nameText: "نام:",
      countryText: "کشور:",
      currencyText: "واحد پول:",
      noTransactionsFoundText: "تراکنشی یافت نشد.",
      paySuccessText: "پرداخت موفقیت‌آمیز بود",
      yourAccountSetupDoneText: "اکنون حساب شما آماده است",
      youcannowLoginWithCredentialsText: "اکنون می‌توانید با اطلاعات خود وارد شوید!",
      thankYouForJoiningUsText: "متشکریم و خوش آمدید!",
      JoinBakesoftText: "به Bakesoft بپیوندید و با دستورپخت‌های شگفت‌انگیز خود درآمد کسب کنید!",
      createAccountTodayText: "امروز یک حساب کاربری ایجاد کنید و اکنون دستورپخت‌های خود را بفروشید!",
      plattformOfferingText: "ما یک پلتفرم فوق‌العاده ارائه می‌دهیم که می‌توانید دستورپخت‌های خود را ایجاد کرده و به دنیا بفروشید!",
      signUpOfferingText: "همین امروز ثبت‌نام کنید و ۱۴ روز دوره آزمایشی دریافت کنید!",
      joinBakesoftNowText: "اکنون به www.bakesoft.se بپیوندید!",
      accountDoneSetupText: "حساب کاربری شما در www.bakesoft.se اکنون آماده است!",
      loginemailandpassText: "با ایمیل و رمز عبور وارد شوید!",
      topublishRecipesOnLiveText: "برای انتشار دستورپخت‌ها در live.bakesoft.se، به یک حساب کاربری پولی نیاز دارید.",
      menyText: "منو",
      signUpText: "ثبت‌نام",
      myAccountText: "حساب من",
      myBookmarkedRecipesText: "دستورپخت‌های نشانک‌گذاری شده من",
      SignUpToBakesoftText: "برای live.Bakesoft.se ثبت‌نام کنید",
      howDoesItWorkText: "چگونه کار می‌کند؟",
      liveSignUpText: "ثبت‌نام",
      liveSignUpText1: "برای live.bakesoft.se ثبت‌نام کنید",
      confirmPasswordText: "تأیید رمز عبور",
      currencyNLanguageText: "واحد پول و زبان",
      buyOneCreditText: "خرید ۱ اعتبار",
      unlockedRecipesText: "دستورپخت‌های باز شده",
      accountDetailsText: "جزئیات حساب کاربری",
      receiptText: "رسید",
      savedRecipesText: "دستورپخت‌های ذخیره شده",
      endDateText: "تاریخ پایان",
      deleteSavedRecipeText: "حذف",
      nameText: "نام",
      slideToUnlockText: "برای باز کردن بکشید",
      unlockedText2: "باز شد",
      logoutOnlyText: "خروج",
      youreLoggedOutText: "شما از حساب خارج شده‌اید",
      bakesoftSignUp1: "live.bakesoft.se یک پلتفرم بدون تبلیغات برای دستورپخت‌های شگفت‌انگیز است.",
      bakesoftSignUp2: "دستورپخت‌ها را با ۱ دلار/اعتبار باز کنید. بدون هزینه اشتراک!",
      bakesoftSignUp3: "آیا می‌خواهید دستورپخت ایجاد کنید و درآمد کسب کنید؟",
      bakesoftSignUp4: "از www.bakesoft.se برای مدیریت پیشرفته دستورپخت‌ها استفاده کنید. در پلتفرم‌های ما منتشر کنید و هر بار که دستورپخت شما باز شود درآمد کسب کنید. قیمت: ۱۹ دلار/ماه.",
      bakesoftSignUp5: "قیمت خود را تنظیم کنید (۱-۵ دلار/دستورپخت) و از هر اعتبار فروخته شده ۳۳٪ درآمد کسب کنید. درآمدها و پرداخت‌ها را در برنامه ردیابی کنید.",
      termsAndConditionsText: "شرایط و ضوابط",
      gotToLoginText: "به ورود بروید",
      serviceS2Text: "۵ اعتبار دستورپخت بخرید و ۱۰٪ تخفیف بگیرید",
      serviceS3Text: "۱۰ اعتبار دستورپخت بخرید و ۲۰٪ تخفیف بگیرید",
      serviceS4Text: "در bakesoft.se رایگان ثبت‌نام کنید – ۱۴ روز دوره آزمایشی",
      creditsText: "اعتبارها",
      updateLanguageHeader: "به‌روزرسانی زبان",
      submitButtonChangeLanguageText: "به‌روزرسانی",
      kitchenTimerText: "تایمر آشپزخانه",
      showHideText: "نمایش/مخفی کردن",
      forgotPasswordText: "رمز عبور را فراموش کرده‌اید؟",
      resetPassText: "بازنشانی رمز عبور",
      resetLinkText: "لینک بازنشانی به آدرس ایمیل شما ارسال شد",
      unlockRecipeText: "باز کردن دستورپخت",
      recipeUnlockedText: "دستورپخت باز شد",
      availableInText: "موجود در",
      languagesText: "زبان‌ها",
      translateTo16LanguagesText: "ترجمه به ۱۶ زبان",
      proChefText: "سرآشپز حرفه‌ای",
      voteRegisteredText: "رأی شما ثبت شد",
      copyLinkText: "کپی لینک",
      pinterestText: "اشتراک‌گذاری در Pinterest",
      instagremText: "اشتراک‌گذاری در Instagram",
      twitterText: "اشتراک‌گذاری در X",
      facebookText: "اشتراک‌گذاری در Facebook",
      linkCopiedText: "لینک کپی شد",
      welcomeToBakesoftText1: "به Bakesoft خوش آمدید",
      proRecipesText: "دستورپخت‌های حرفه‌ای برای سرآشپزها و نانوایان",
      updaterecipeText: "ویرایش، اضافه کردن و به‌روزرسانی مواد",
      nutritionalText: "ارزش‌های تغذیه‌ای",
      signUpAndBonusText: "ثبت‌نام کنید و ۵ اعتبار جایزه بگیرید",
      signupbonusText: "ثبت‌نام کنید و ۵ اعتبار جایزه بگیرید",
      nocommitmentText: "بدون تعهد، بدون اشتراک",
      aboutUsLiveText: "درباره ما",
    },
    bakesoftPromotion: {
      welcomeToBakesoftText: "به Bakesoft خوش آمدید",
      unlockWorldOfCulinaryCreativityText: "دنیای خلاقیت آشپزی را باز کنید",
      discoverAmazingRecipesText: "دستورپخت‌های شگفت‌انگیز را کشف کنید",
      liveBakesoftText: "live.bakesoft.se یک پلتفرم بدون تبلیغات برای دستورپخت‌های شگفت‌انگیز است.",
      affordableAccessText: "دستورپخت‌ها را با ۱ دلار/اعتبار باز کنید. بدون هزینه اشتراک!",
      customizationText: "دستورپخت‌ها را شخصی‌سازی کنید و اطلاعات تغذیه‌ای بگیرید",
      shareConnectText: "ایده‌های آشپزی خود را در شبکه‌های اجتماعی به اشتراک بگذارید",
      globalCuisineText: "دستورپخت‌ها را از سرآشپزها، کشورها و زبان‌های مختلف بررسی کنید",
      signUpNowText: "اکنون ثبت‌نام کنید",
      forRecipeCreatorsText: "برای خالقان دستورپخت",
      createRecipesEarnMoneyText: "آیا می‌خواهید دستورپخت ایجاد کنید و درآمد کسب کنید؟",
      advancedRecipeManagementText: "از www.bakesoft.se برای مدیریت پیشرفته دستورپخت‌ها استفاده کنید. در پلتفرم‌های ما منتشر کنید و هر بار که دستورپخت شما باز شود درآمد کسب کنید. قیمت: ۱۹ دلار/ماه.",
      setYourPriceText: "قیمت خود را تنظیم کنید (۱-۵ دلار/دستورپخت) و از هر اعتبار فروخته شده ۳۳٪ درآمد کسب کنید",
      trackEarningsText: "درآمدها و پرداخت‌ها را در برنامه ردیابی کنید",
      usersResponsibleText: "کاربران مسئول مالیات‌ها و قوانین در کشور خود هستند",
      startCreatingText: "شروع به خلق کنید",
      readMoreInTermsText: "در شرایط ما بیشتر بخوانید!",
      affordableAccessHeader: "دسترسی مقرون‌به‌صرفه",
      customizationHeader: "شخصی‌سازی",
      shareandConnectHeader: "اشتراک‌گذاری و ارتباط",
      globalCuisineHeader: "غذاهای بین‌المللی",
    },
  },
  sw: {
    common: {
      headerGreeting: "... mapishi mazuri kwa wakati wa kushangaza.",
      selectMenu: "Menyu",
      inputTitleBookmark: "Hifadhi mapishi haya",
      imageTitleChef: "Profaili ya mpishi",
      translateRecipeTextGrid: "Tafsiri",
      titleShareOnSocialMedia: "Shiriki kwenye mitandao ya kijamii",
      textMinutesInShort: "dak",
      titleTextLikeRecipe: "Penda mapishi haya",
      titleSeenByVievers: "Imetazamwa na watumiaji",
      noDescriptionText: "Hakuna maelezo yanayopatikana",
      scrollMessageText: "Tafsiri mapishi mazuri",
      scrollMessageText2: "Inapakia mapishi mazuri",
      translateRecipeText: "Tafsiri mapishi",
      translateCostText: "Gharama ya tafsiri ni 1 mkopo/tafsiri iliyofanikiwa",
      selectLanguageText: "Chagua lugha",
      noNameAvailableText: "Hakuna jina lililopatikana",
      savedRecipeText: "Mapishi yaliyohifadhiwa",
      clickToUnlockText: "Bonyeza/Kufungua",
      ingredientText: "Kiungo",
      pricePerKgText: "Bei/kg",
      priceText: "Bei",
      amountText: "Kiasi",
      kaloriesText: "Kalori",
      increaseIngredientValueText: "Ongeza thamani ya kiungo",
      decreaseIngredientValueText: "Punguza thamani ya kiungo",
      changeNameText: "Badilisha jina",
      saveText: "Hifadhi",
      savedText: "Imehifadhiwa",
      deleteText: "Futa",
      totalText: "Jumla",
      addIngredientText: "Ongeza kiungo",
      addText: "Ongeza",
      placeHolderIngredientNameText: "Jina la kiungo",
      descriptionText: "Maelezo",
      unlockThisRecipeText: "Fungua mapishi haya kwa",
      creditsToUseText: "- mikopo ya kutumia",
      creditsText: "Mikopo",
      noCreditsText: "Huna mikopo ya kufungua mapishi haya",
      addMoreCreditsText: "Ongeza mikopo zaidi",
      gotoPaymentText: "Nenda kwenye malipo",
      chefInFocusText: "Mpishi aliyeangaziwa",
      bsLivePaymentText: "Maelezo ya malipo ya Bakesoft",
      bsLiveAmountText: "Kiasi",
      bsLiveCurrencyText: "Sarafu",
      bsLivePaymentTermsText: "Masharti ya malipo",
      paymentSuccessfullText: "Malipo yamefanikiwa",
      thankYouForPurchaseText: "Asante kwa kununua!",
      selectCurrencyText: "Chagua sarafu",
      selectLanguageText2: "Chagua lugha",
      placeHolderEmailText: "Weka barua pepe yako",
      placeHolderPasswordText: "Weka nenosiri lako",
      loginText2: "Ingia",
      logOutText: "Ondoka",
      norecipeAvailableText: "Hakuna mapishi yanayopatikana.",
      notAvailableText: "Haipatikani",
      nameText: "Jina:",
      countryText: "Nchi:",
      currencyText: "Sarafu:",
      noTransactionsFoundText: "Hakuna miamala iliyopatikana.",
      paySuccessText: "Malipo yamefaulu",
      yourAccountSetupDoneText: "Akaunti yako sasa iko tayari",
      youcannowLoginWithCredentialsText: "Sasa unaweza kuingia ukitumia kitambulisho chako!",
      thankYouForJoiningUsText: "Asante na karibu sana!",
      JoinBakesoftText: "Jiunge na Bakesoft na upate pesa kupitia mapishi yako mazuri!",
      createAccountTodayText: "Fungua akaunti leo na anza kuuza mapishi yako sasa!",
      plattformOfferingText: "Tunatoa jukwaa zuri ambapo unaweza kuunda na kuuza mapishi yako duniani!",
      signUpOfferingText: "Jiandikishe leo na upate kipindi cha majaribio cha siku 14!",
      joinBakesoftNowText: "Jiunge na www.bakesoft.se sasa!",
      accountDoneSetupText: "Akaunti yako ya www.bakesoft.se sasa iko tayari!",
      loginemailandpassText: "Ingia ukitumia barua pepe na nenosiri!",
      topublishRecipesOnLiveText: "Kuchapisha mapishi kwenye live.bakesoft.se, unahitaji akaunti iliyolipwa.",
      menyText: "Menyu",
      signUpText: "Jiandikishe",
      myAccountText: "Akaunti yangu",
      myBookmarkedRecipesText: "Mapishi yangu yaliyohifadhiwa",
      SignUpToBakesoftText: "Jiandikishe kwenye live.Bakesoft.se",
      howDoesItWorkText: "Inafanyaje kazi?",
      liveSignUpText: "Jiandikishe",
      liveSignUpText1: "Jiandikishe kwenye live.bakesoft.se",
      confirmPasswordText: "Thibitisha nenosiri",
      currencyNLanguageText: "Sarafu na lugha",
      buyOneCreditText: "Nunua mkopo 1",
      unlockedRecipesText: "Mapishi yaliyofunguliwa",
      accountDetailsText: "Maelezo ya akaunti",
      receiptText: "Risiti",
      savedRecipesText: "Mapishi yaliyohifadhiwa",
      endDateText: "Tarehe ya kumalizika",
      deleteSavedRecipeText: "Futa",
      nameText: "Jina",
      slideToUnlockText: "Telezesha kufungua",
      unlockedText2: "Imefunguliwa",
      logoutOnlyText: "Ondoka",
      youreLoggedOutText: "Umetoka nje ya akaunti",
      bakesoftSignUp1: "live.bakesoft.se ni jukwaa lisilo na matangazo kwa mapishi mazuri.",
      bakesoftSignUp2: "Fungua mapishi kwa $1/mkopo. Hakuna ada ya usajili!",
      bakesoftSignUp3: "Unataka kuunda mapishi na kupata pesa?",
      bakesoftSignUp4: "Tumia www.bakesoft.se kwa usimamizi wa mapishi wa hali ya juu. Chapisha kwenye majukwaa yetu na upate pesa kila mara mapishi yako yanapofunguliwa. Bei: $19/mwezi.",
      bakesoftSignUp5: "Weka bei yako ($1-$5/mapishi) na upate 33% kwa kila mkopo uliochukuliwa. Fuata mapato na malipo kwenye programu.",
      termsAndConditionsText: "Masharti na Kanuni",
      gotToLoginText: "Nenda kwenye ingia",
      serviceS2Text: "Nunua mikopo 5 ya mapishi na upate punguzo la 10%",
      serviceS3Text: "Nunua mikopo 10 ya mapishi na upate punguzo la 20%",
      serviceS4Text: "Jiandikishe kwenye bakesoft.se bila malipo - jaribio la siku 14",
      creditsText: "Mikopo",
      updateLanguageHeader: "Sasisha lugha",
      submitButtonChangeLanguageText: "Sasisha",
      kitchenTimerText: "Kipima muda cha jikoni",
      showHideText: "Onyesha/Ficha",
      forgotPasswordText: "Umesahau nenosiri?",
      resetPassText: "Weka upya nenosiri",
      resetLinkText: "Kiungo cha kuweka upya kimetumwa kwa barua pepe yako",
      unlockRecipeText: "Fungua mapishi",
      recipeUnlockedText: "Mapishi yamefunguliwa",
      availableInText: "Inapatikana katika",
      languagesText: "Lugha",
      translateTo16LanguagesText: "Tafsiri katika lugha 16",
      proChefText: "Mpishi Mtaalamu",
      voteRegisteredText: "Kura yako imesajiliwa",
      copyLinkText: "Nakili kiungo",
      pinterestText: "Shiriki kwenye Pinterest",
      instagremText: "Shiriki kwenye Instagram",
      twitterText: "Shiriki kwenye X",
      facebookText: "Shiriki kwenye Facebook",
      linkCopiedText: "Kiungo kimenakiliwa",
      welcomeToBakesoftText1: "Karibu Bakesoft",
      proRecipesText: "Mapishi ya kitaalamu kwa wapishi na waokaji",
      updaterecipeText: "Hariri, ongeza, na sasisha viungo",
      nutritionalText: "Thamani za lishe",
      signUpAndBonusText: "Jiandikishe na upate bonasi ya mikopo 5",
      signupbonusText: "Jiandikishe na upate bonasi ya mikopo 5",
      nocommitmentText: "Hakuna ahadi, hakuna usajili",
      aboutUsLiveText: "Kuhusu sisi",
    },
    bakesoftPromotion: {
      welcomeToBakesoftText: "Karibu Bakesoft",
      unlockWorldOfCulinaryCreativityText: "Fungua ulimwengu wa ubunifu wa kupika",
      discoverAmazingRecipesText: "Gundua mapishi mazuri",
      liveBakesoftText: "live.bakesoft.se ni jukwaa lisilo na matangazo kwa mapishi mazuri.",
      affordableAccessText: "Fungua mapishi kwa $1/mkopo. Hakuna ada ya usajili!",
      customizationText: "Boresha mapishi na upate taarifa za lishe",
      shareConnectText: "Shiriki ubunifu wako wa kupika kwenye mitandao ya kijamii",
      globalCuisineText: "Chunguza mapishi kutoka kwa wapishi, nchi, na lugha tofauti",
      signUpNowText: "Jiandikishe sasa",
      forRecipeCreatorsText: "Kwa Wapishi wa Mapishi",
      createRecipesEarnMoneyText: "Unataka kuunda mapishi na kupata pesa?",
      advancedRecipeManagementText: "Tumia www.bakesoft.se kwa usimamizi wa mapishi wa hali ya juu. Chapisha kwenye majukwaa yetu na upate pesa kila mara mapishi yako yanapofunguliwa. Bei: $19/mwezi.",
      setYourPriceText: "Weka bei yako ($1-$5/mapishi) na upate 33% kwa kila mkopo uliochukuliwa",
      trackEarningsText: "Fuatilia mapato na malipo katika programu",
      usersResponsibleText: "Watumiaji wanawajibika kwa ushuru na sheria za nchi yao",
      startCreatingText: "Anza kuunda",
      readMoreInTermsText: "Soma zaidi katika masharti yetu!",
      affordableAccessHeader: "Upatikanaji Nafuu",
      customizationHeader: "Kubinafsisha",
      shareandConnectHeader: "Shiriki na Unganisha",
      globalCuisineHeader: "Vyakula vya Ulimwenguni",
    },
  },
  
  
  
}
    export default languages;